<svg
 width="900"
 height="535"
 viewBox="0 0 238.125 141.552"
 version="1.1"
 id="svg580"
 sodipodi:docname="auto-graph.svg"
 inkscape:version="1.0.1 (3bc2e813f5, 2020-09-07)" {...$$props} >
  <style
     id="style586"></style>
  <metadata
     id="metadata584">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
        <dc:title />
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <sodipodi:namedview
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1"
     objecttolerance="10"
     gridtolerance="10"
     guidetolerance="10"
     inkscape:pageopacity="0"
     inkscape:pageshadow="2"
     inkscape:window-width="2560"
     inkscape:window-height="1369"
     id="namedview582"
     showgrid="false"
     inkscape:zoom="1.2311111"
     inkscape:cx="600.48284"
     inkscape:cy="138.09775"
     inkscape:window-x="1272"
     inkscape:window-y="-8"
     inkscape:window-maximized="1"
     inkscape:current-layer="svg580"
     inkscape:document-rotation="0"
     showguides="false"
     inkscape:snap-grids="false"
     inkscape:snap-to-guides="false"
     inkscape:snap-others="false"
     inkscape:object-nodes="false"
     inkscape:snap-nodes="false">
    <sodipodi:guide
       position="0,141.55204"
       orientation="0,900"
       id="guide597" />
    <sodipodi:guide
       position="238.12493,141.55204"
       orientation="535,0"
       id="guide599" />
    <sodipodi:guide
       position="238.12493,0"
       orientation="0,-900"
       id="guide601" />
    <sodipodi:guide
       position="0,0"
       orientation="-535,0"
       id="guide603" />
  </sodipodi:namedview>
  <defs />
  <g
     id="door">
    <path
       d="M29.87 38.137v-14.81l.246-.037V38.11l.015.002zM30.919 23.173l4.007-.587v15.018l.032.006.067-.007h.097l.065.011-4.268.42zm3.35 1.762l-1.338.187v6.73l.314-.036 1.025-.12v-6.637z"
       fill="#bebebe"
       id="path6" />
    <path
       d="M29.87 38.137l.261-.026-.015-.002.787-.078.016.003 4.268-.42.311.057-5.312.522z"
       fill="#434343"
       id="path8" />
    <path
       d="M35.498 37.671l-.311-.057-.065-.012-.097.001-.067.007-.032-.006.054-.005.148-.003.396.073z"
       fill="#b8c8d2"
       id="path10" />
    <path
       d="M30.919 23.173v14.861l-.016-.003V23.168z"
       fill="#bbb"
       id="path12" />
    <path
       d="M30.116 23.284l.787-.116v14.863l-.787.078V23.29zm.01 3.26l.764-.104v-.264l-.763.104zM34.98 37.6l-.054.004V22.533l.054-.007z"
       fill="#5b5b5b"
       id="path14" />
    <path
       d="M35.128 37.596l-.148.003V22.526l.148-.005z"
       fill="#606060"
       id="path16" />
    <path
       d="M35.524 37.669l-.396-.073V22.521l.396.108z"
       fill="#3b3b3b"
       id="path18" />
    <path
       d="M32.931 25.122l1.339-.187v.124l-1.025.143z"
       fill="#434343"
       id="path20" />
    <path
       d="M32.931 31.853v-6.731l.314.08v6.614z"
       fill="#7c7c7c"
       id="path22" />
    <path
       d="M30.89 26.44l-.763.103v-.263l.763-.104z"
       fill="#5b5b5b"
       id="path24" />
    <path
       d="M29.87 23.326v14.811M34.926 22.586l-4.007.587"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path26" />
    <path
       d="M29.87 38.137l.261-.026"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path28" />
    <path
       d="M30.186 38.193l5.312-.522M29.87 38.137l.316.056"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path30" />
    <path
       d="M35.187 37.614l.311.057M34.27 24.935l-1.339.187M32.931 25.122v6.73"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path32" />
    <path
       d="M34.27 25.06l-1.025.142M33.245 25.202v6.614"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path34" />
    <path
       d="M32.931 25.122l.314.08M30.919 38.034l4.268-.42M30.919 23.173v14.861"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path36" />
    <path
       d="M30.116 23.29l-.246.036M30.903 23.168l-.787.116"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path38" />
    <path
       d="M30.116 38.109l.787-.078M30.903 23.168v14.863"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path40" />
    <path
       d="M30.919 23.173l-.016-.005M30.131 38.111l-.015-.002"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path42" />
    <path
       d="M30.919 38.034l-.016-.003"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path44" />
    <path
       d="M35.187 37.614l-.065-.012M34.958 37.61l-.032-.006"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path46" />
    <path
       d="M34.926 37.604l.054-.005M34.98 37.6l.148-.004"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path48" />
    <path
       d="M35.025 37.603l-.067.007M35.025 37.603h.097"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path50" />
    <path
       d="M35.128 37.596l.396.073"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path52" />
    <path
       d="M35.524 37.669l-.026.002M35.128 22.521l.396.108M34.98 22.526l.148-.005M34.926 22.533l.054-.007M35.524 37.669v-15.04"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path54" />
    <path
       d="M35.128 37.596V22.521M34.98 37.6V22.525"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path56" />
    <path
       d="M32.931 31.853l.314-.037M33.245 31.816l1.025-.12M34.27 31.695V25.06M34.27 25.06v-.125M30.116 38.109V23.29M30.116 23.29v-.006M34.926 37.604V22.586M34.926 22.586v-.053"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path58" />
  </g>
  <g
     id="clock"
     style="fill:#cccccc">
    <path
       d="M201.999 31.004l-.712-.986.17-.065.712.987z"
       fill="#3a3a3a"
       id="path60"
       style="fill:#cccccc" />
    <path
       d="M201.287 30.018l.712.986.447 1.14.152 1.216-.152 1.21-.447 1.124-.712.96-.929.73-.975.408-1.106.134-1.167-.178-1.088-.488-.935-.764-.718-.99-.452-1.145-.154-1.224.154-1.218.452-1.13.718-.962.935-.73.926-.381 1.164-.137 1.163.184 1.083.491zm-2.866 3.15l-.01-.02-.057-.058-.068-.041-.076-.022h-.079l-.075.02-.079.03-2.656-2.825-.04.016-.092.123 2.648 2.814-.023.042-.019.079.001.082.022.079.04.07.056.059.068.041.076.021h.078l.076-.02.082-.03.067-.04.055-.058.038-.071.019-.08-.001-.066 2.558-.922.043-.015-.076-.137-2.576.928z"
       fill="#e5e5e5"
       id="path62"
       style="fill:#cccccc" />
    <path
       d="M199.275 28.763l-1.163-.184.165-.068 1.165.185z"
       fill="#343434"
       id="path64"
       style="fill:#cccccc" />
    <path
       d="M201.287 36.654l.712-.96.17-.056-.713.962z"
       fill="#3a3a3a"
       id="path66"
       style="fill:#cccccc" />
    <path
       d="M199.383 37.792l.975-.407.168-.053-1.084.452z"
       fill="#363636"
       id="path68"
       style="fill:#cccccc" />
    <path
       d="M202.446 32.143l-.447-1.14.17-.063.448 1.142z"
       fill="#3b3b3b"
       id="path70"
       style="fill:#cccccc" />
    <path
       d="M202.598 33.36l-.152-1.217.171-.061.153 1.218z"
       fill="#3c3c3c"
       id="path72"
       style="fill:#cccccc" />
    <path
       d="M200.358 37.385l.929-.731.17-.054-.931.732z"
       fill="#383838"
       id="path74"
       style="fill:#cccccc" />
    <path
       d="M198.112 28.58l-1.164.136.162-.068 1.167-.137z"
       fill="#343434"
       id="path76"
       style="fill:#cccccc" />
    <path
       d="M201.287 30.018l-.929-.764.168-.067.93.766z"
       fill="#383838"
       id="path78"
       style="fill:#cccccc" />
    <path
       d="M200.358 29.254l-1.083-.49.167-.068 1.084.491z"
       fill="#363636"
       id="path80"
       style="fill:#cccccc" />
    <path
       d="M202.446 34.57l.152-1.21.172-.06-.153 1.213z"
       fill="#3c3c3c"
       id="path82"
       style="fill:#cccccc" />
    <path
       d="M201.999 35.694l.447-1.123.171-.058-.448 1.125z"
       fill="#3b3b3b"
       id="path84"
       style="fill:#cccccc" />
    <path
       d="M198.41 33.148l.011.02.03.05.02.08.001.015.001.067-.02.079-.037.07-.055.058-.067.041-.075.021h-.079l-.076-.022-.068-.04-.056-.059-.04-.07-.022-.08v-.082l.018-.079.023-.042.015-.029.055-.058.035-.02.032-.02.076-.021h.078l.076.021.068.042.056.058z"
       id="path86"
       style="fill:#cccccc" />
    <path
       d="M198.45 33.219l-.029-.052 2.576-.928.076.137-.043.015-2.558.922v-.015l-.022-.08z"
       id="path88"
       style="fill:#cccccc" />
    <path
       d="M198.218 33.649l.076-.021-.082.03-.076.02z"
       fill="#e5e5e5"
       id="path90"
       style="fill:#cccccc" />
    <path
       d="M198.14 33.649h.078l-.082.03h-.078z"
       fill="#41474a"
       id="path92"
       style="fill:#cccccc" />
    <path
       d="M198.064 33.627l.076.022-.082.03-.076-.022z"
       fill="#444a4e"
       id="path94"
       style="fill:#cccccc" />
    <path
       d="M197.996 33.586l.068.041-.082.03-.068-.041z"
       fill="#474d51"
       id="path96"
       style="fill:#cccccc" />
    <path
       d="M197.94 33.528l.056.058-.082.03-.056-.058z"
       fill="#495054"
       id="path98"
       style="fill:#cccccc" />
    <path
       d="M197.9 33.457l.04.071-.082.03-.04-.071z"
       fill="#4b5256"
       id="path100"
       style="fill:#cccccc" />
    <path
       d="M197.878 33.378l.021.08-.081.029-.022-.08z"
       fill="#4c5357"
       id="path102"
       style="fill:#cccccc" />
    <path
       d="M197.877 33.296l.001.082-.082.03-.001-.082z"
       fill="#4d5458"
       id="path104"
       style="fill:#cccccc" />
    <path
       d="M197.896 33.217l-.02.08-.081.03.02-.08z"
       fill="#4d5357"
       id="path106"
       style="fill:#cccccc" />
    <path
       d="M197.919 33.175l-.023.042-.082.03.023-.042.04-.015z"
       fill="#4b5256"
       id="path108"
       style="fill:#cccccc" />
    <path
       d="M197.934 33.146l-.015.03-.041.014.015-.029z"
       fill="#4b5256"
       id="path110"
       style="fill:#cccccc" />
    <path
       d="M197.989 33.089l-.055.057-.04.015.054-.057z"
       fill="#4a5054"
       id="path112"
       style="fill:#cccccc" />
    <path
       d="M198.024 33.067l-.035.022-.041.015.035-.022z"
       id="path114"
       style="fill:#cccccc" />
    <path
       d="M197.983 33.082l-.035.022-.055.057-.015.03-2.649-2.816.092-.123 2.656 2.825z"
       id="path116"
       style="fill:#cccccc" />
    <path
       d="M195.23 30.375l2.648 2.815-.041.015-2.648-2.814z"
       fill="#495054"
       id="path118"
       style="fill:#cccccc" />
    <path
       d="M195.32 30.252l-.09.123-.04.016.09-.123z"
       fill="#4a5155"
       id="path120"
       style="fill:#cccccc" />
    <path
       d="M199.104 38.217l-1.237.152.41-.129 1.242-.151zM197.867 38.369l-1.24-.19.406-.13 1.244.191z"
       fill="#343434"
       id="path122"
       style="fill:#cccccc" />
    <path
       d="M196.628 38.179l-1.156-.518.4-.131 1.16.52z"
       fill="#363636"
       id="path124"
       style="fill:#cccccc" />
    <path
       d="M195.472 37.66l-.994-.81.396-.136.998.816z"
       fill="#383838"
       id="path126"
       style="fill:#cccccc" />
    <path
       d="M194.478 36.85l-.764-1.05.394-.14.766 1.054z"
       fill="#3a3a3a"
       id="path128"
       style="fill:#cccccc" />
    <path
       d="M193.714 35.8l-.48-1.218.392-.144.482 1.222z"
       fill="#3b3b3b"
       id="path130"
       style="fill:#cccccc" />
    <path
       d="M193.234 34.582l-.163-1.3.39-.15.165 1.306zM193.07 33.282l.164-1.294.392-.156-.165 1.3z"
       fill="#3c3c3c"
       id="path132"
       style="fill:#cccccc" />
    <path
       d="M193.234 31.988l.48-1.2.394-.16-.482 1.204z"
       fill="#3b3b3b"
       id="path134"
       style="fill:#cccccc" />
    <path
       d="M193.714 30.788l.764-1.022.396-.165-.766 1.027z"
       fill="#3a3a3a"
       id="path136"
       style="fill:#cccccc" />
    <path
       d="M194.478 29.766l.994-.776.4-.169-.998.78z"
       fill="#383838"
       id="path138"
       style="fill:#cccccc" />
    <path
       d="M195.872 28.821l1.16-.478 1.245-.145 1.242.196 1.157.525.992.817.76 1.053.478 1.217.163 1.3-.163 1.293-.478 1.2-.76 1.026-.992.78-1.157.484-1.242.151-1.244-.19-1.161-.52-.998-.816-.766-1.054-.482-1.222-.165-1.306.165-1.3.482-1.204.766-1.027zm-2.11 4.316l.155 1.224.452 1.146.718.989.935.764 1.088.488 1.167.178 1.106-.134.059-.008 1.084-.452.93-.732.713-.962.448-1.125.153-1.213-.153-1.218-.448-1.142-.713-.987-.93-.766-1.084-.491-1.165-.185-1.167.137-.162.068-.926.38-.935.731-.718.963-.452 1.129z"
       fill="#999"
       id="path140"
       style="fill:#cccccc" />
    <path
       d="M201.999 31.004l-.712-.986M199.275 28.763l-1.163-.184M201.287 36.654l.712-.96M199.383 37.792l.975-.407M202.446 32.143l-.447-1.14M202.598 33.36l-.152-1.217M200.358 37.385l.929-.731M198.112 28.58l-1.164.136M201.287 30.018l-.929-.764M200.358 29.254l-1.083-.49M202.446 34.57l.152-1.21M201.999 35.694l.447-1.123"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path142"
       style="fill:#cccccc" />
    <path
       d="M198.421 33.167l-.01-.02M198.45 33.219l-.029-.052M198.472 33.298l-.022-.08M198.472 33.313v-.015M198.473 33.38l-.001-.067M198.454 33.459l.019-.08M198.416 33.53l.038-.071M198.361 33.587l.055-.057M198.294 33.628l.067-.04"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path144"
       style="fill:#cccccc" />
    <path
       d="M198.218 33.649l.076-.021M198.14 33.649h.078M198.064 33.627l.076.022M197.996 33.586l.068.041M197.94 33.528l.056.058M197.9 33.457l.04.071M197.878 33.378l.021.08M197.877 33.296l.001.082M197.896 33.217l-.02.08M197.919 33.175l-.023.042M197.934 33.146l-.015.03M197.989 33.089l-.055.057M198.024 33.067l-.035.022M198.056 33.048l-.032.02"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path146"
       style="fill:#cccccc" />
    <path
       d="M198.131 33.027l-.075.02M198.21 33.027h-.079M198.286 33.049l-.076-.022M198.354 33.09l-.068-.041M198.41 33.148l-.056-.058M198.212 33.658l.082-.03M197.977 33.077l.08-.03M201.073 32.376l-.076-.137M198.421 33.167l2.576-.928M201.03 32.391l.043-.015"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path148"
       style="fill:#cccccc" />
    <path
       d="M197.878 33.19l-2.649-2.815M195.23 30.375l.09-.123M197.983 33.082l-.035.022M197.948 33.104l-.055.057M197.893 33.161l-.015.03M195.19 30.39l.04-.015M197.837 33.205l.04-.015"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path150"
       style="fill:#cccccc" />
    <path
       d="M195.28 30.268l.04-.016"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path152"
       style="fill:#cccccc" />
    <path
       d="M197.878 33.19l.04-.015M197.983 33.082l.04-.015M195.19 30.39l.09-.122M197.837 33.205l-2.648-2.814M197.837 33.205l-.023.042M197.814 33.247l-.019.08M197.795 33.326l.001.082M197.796 33.408l.022.079M197.818 33.487l.04.071M197.858 33.558l.056.058M197.914 33.616l.068.041M197.982 33.657l.076.021M198.058 33.678h.078M198.136 33.679l.076-.021M198.472 33.313l2.558-.922"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path154"
       style="fill:#cccccc" />
    <path
       d="M197.867 38.369l1.237-.152M196.628 38.179l1.239.19M195.472 37.66l1.156.519M194.478 36.85l.994.81M193.714 35.8l.764 1.05M193.234 34.582l.48 1.217M193.07 33.282l.164 1.3M193.234 31.988l-.163 1.294M193.714 30.788l-.48 1.2M194.478 29.766l-.764 1.022M195.472 28.99l-.994.776M195.872 28.821l-.4.169M199.52 38.089l-.416.128M197.033 28.343l-1.161.478"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path156"
       style="fill:#cccccc" />
    <path
       d="M195.872 28.821l-.998.78M194.874 29.6l-.766 1.028M194.108 30.628l-.482 1.204M193.626 31.832l-.165 1.3M193.461 33.132l.165 1.306M193.626 34.438l.482 1.222M194.108 35.66l.766 1.054M194.874 36.714l.998.816M195.872 37.53l1.16.52M197.033 38.05l1.244.19M198.277 38.24l1.242-.151"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path158"
       style="fill:#cccccc" />
    <path
       d="M199.52 38.089l1.156-.483M200.676 37.606l.992-.781M201.668 36.825l.76-1.026M202.428 35.8l.478-1.2M202.906 34.6l.163-1.295M203.069 33.305l-.163-1.299M202.906 32.006l-.478-1.217M202.428 30.789l-.76-1.053M201.668 29.736l-.992-.817M200.676 28.919l-1.157-.525M199.52 28.394l-1.243-.196M198.277 28.198l-1.244.145M193.763 33.137l.154 1.224M193.917 31.919l-.154 1.218M194.369 30.79l-.452 1.129M195.087 29.827l-.718.963"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path160"
       style="fill:#cccccc" />
    <path
       d="M198.277 28.511l-1.167.137M199.442 28.696l-1.165-.185M200.526 29.187l-1.084-.491M201.456 29.953l-.93-.766M202.17 30.94l-.714-.987M202.617 32.082l-.448-1.142M202.77 33.3l-.153-1.218M202.617 34.513l.153-1.213M202.17 35.638l.447-1.125M201.456 36.6l.713-.962M200.526 37.332l.93-.732M199.442 37.784l1.084-.452"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path162"
       style="fill:#cccccc" />
    <path
       d="M197.11 37.748l1.167.178M196.022 37.26l1.088.488M195.087 36.496l.935.764M194.369 35.507l.718.989M193.917 34.361l.452 1.146"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path164"
       style="fill:#cccccc" />
    <path
       d="M196.948 28.716l-.926.38"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path166"
       style="fill:#cccccc" />
    <path
       d="M197.983 33.082l-.006-.005M197.977 33.077l-2.656-2.825M198.277 37.926l1.106-.134M199.383 37.792l.059-.008M196.022 29.097l-.935.73"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path168"
       style="fill:#cccccc" />
  </g>
  <g
     id="g366">
    <path
       d="M233.044 108.213v.521l-32.995.692v1.592l-.013.103-.038.097-.06.084-.079.065-.091.041-.099.016-.098-.011-.092-.038-.079-.062-.06-.08-.038-.096-.013-.103v-1.592l-1.388.03-24.768.519v1.613l-.014.105-.039.098-.062.085-.08.066-.095.042-.101.016-.101-.011-.095-.039-.08-.062-.063-.082-.039-.097-.013-.104v-1.614l-1.02.022-23.295.488v1.634l-.013.106-.04.1-.064.085-.083.067-.097.043-.104.016-.104-.011-.096-.04-.083-.062-.064-.084-.04-.097-.014-.106v-1.634l-.666.014-85.559 1.794-.624.013v1.705l-.014.11-.044.105-.07.09-.09.07-.105.044-.113.017-.113-.012-.106-.04-.09-.066-.07-.087-.043-.102-.015-.11v-1.706l-27.56.578-1.087.023v1.73l-.016.112-.045.105-.07.09-.094.072-.108.045-.117.018-.116-.013-.108-.04-.093-.067-.072-.088-.045-.103-.015-.112v-1.73l-15.24.32v-5.954l-1.825.033-.118-.013-.11-.042-.095-.067-.073-.09-.045-.103-.016-.113.016-.113.045-.106.073-.092.095-.07.11-.046.118-.017 1.825-.033V92.153l-1.825.02-.118-.013-.11-.043-.095-.068-.073-.09-.045-.104-.016-.113.016-.113.045-.105.073-.091.095-.07.11-.046.118-.016 1.825-.02V43.466l-1.825-.022-.118-.016-.11-.045-.095-.07-.073-.091-.045-.106-.016-.113.016-.113.045-.104.073-.09.095-.068.11-.042.118-.014 1.825.022V26.184l.608.012v86.523z"
       fill="#fff"
       id="path172" />
    <path
       d="M233.044 108.213l-217.71 4.506 1.335-.685 214.541-4.375z"
       fill="#666"
       id="path174" />
    <path
       d="M15.334 112.719V26.196l1.335.656v85.182z"
       fill="#787878"
       id="path176" />
    <path
       d="M12.783 43.427l.118.016 1.374.389-.116-.016z"
       fill="#686868"
       id="path178" />
    <path
       d="M12.901 43.443l1.825.022v.372l-.45-.005zM14.726 91.282l-1.825.02 1.374-.354.451-.005z"
       fill="#666"
       id="path180" />
    <path
       d="M12.901 91.301l-.118.016 1.376-.354.116-.015zM12.901 106.52l-.118.017 1.376-.59.116-.017z"
       fill="#686868"
       id="path182" />
    <path
       d="M14.726 106.487l-1.825.033 1.374-.59.451-.008z"
       fill="#666"
       id="path184" />
    <path
       d="M199.289 109.442v1.592l-1.388-.603v-.96z"
       fill="#787878"
       id="path186" />
    <path
       d="M199.34 111.232l.06.081-1.389-.607-.06-.08z"
       fill="#747474"
       id="path188" />
    <path
       d="M199.302 111.137l.038.095-1.388-.606-.038-.094z"
       fill="#767676"
       id="path190" />
    <path
       d="M199.289 111.034l.013.103-1.388-.605-.013-.1z"
       fill="#787878"
       id="path192" />
    <path
       d="M199.4 111.313l.079.062-1.39-.608-.078-.06z"
       fill="#717171"
       id="path194" />
    <path
       d="M172.403 111.822l.062.082-1.02-.624-.061-.081z"
       fill="#747474"
       id="path196" />
    <path
       d="M172.465 111.904l.081.062-1.021-.624-.08-.062z"
       fill="#717171"
       id="path198" />
    <path
       d="M172.35 110.007v1.614l-1.018-.62v-.972z"
       fill="#787878"
       id="path200" />
    <path
       d="M172.364 111.725l.04.097-1.02-.623-.039-.095z"
       fill="#767676"
       id="path202" />
    <path
       d="M172.35 111.621l.014.104-1.019-.621-.013-.103z"
       fill="#787878"
       id="path204" />
    <path
       d="M30.733 115.012l.071-.091 1.088-.714-.07.09z"
       fill="#747474"
       id="path206" />
    <path
       d="M30.865 114.703v-1.729l1.086-.023v1.041z"
       fill="#787878"
       id="path208" />
    <path
       d="M30.64 115.083l.093-.071 1.089-.716-.092.07z"
       fill="#717171"
       id="path210" />
    <path
       d="M30.804 114.92l.045-.104 1.087-.713-.044.104z"
       fill="#767676"
       id="path212" />
    <path
       d="M30.85 114.816l.015-.113 1.086-.71-.015.11z"
       fill="#787878"
       id="path214" />
    <path
       d="M60.258 114.365l.069-.09.625-.695-.069.088z"
       fill="#747474"
       id="path216" />
    <path
       d="M60.385 114.06v-1.705l.624-.013v1.027z"
       fill="#787878"
       id="path218" />
    <path
       d="M60.37 114.17l.015-.11.624-.691-.014.109z"
       fill="#787878"
       id="path220" />
    <path
       d="M60.327 114.275l.044-.104.624-.693-.043.102z"
       fill="#767676"
       id="path222" />
    <path
       d="M147.288 112.371l.064.084-.668-.64-.063-.082z"
       fill="#747474"
       id="path224" />
    <path
       d="M147.248 112.274l.04.097-.667-.638-.04-.096z"
       fill="#767676"
       id="path226" />
    <path
       d="M147.234 112.168l.014.106-.666-.637-.014-.104z"
       fill="#787878"
       id="path228" />
    <path
       d="M147.234 110.534v1.634l-.666-.635v-.985z"
       fill="#787878"
       id="path230" />
    <path
       d="M233.044 108.734v-.521M14.726 113.312l15.24-.32"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path232" />
    <path
       d="M233.044 108.213l-217.71 4.506M15.334 112.719V26.196"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path234" />
    <path
       d="M15.334 26.196l-.608-.012M14.726 113.312v-5.953M16.67 112.034V26.852M15.334 26.196l1.335.656"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path236" />
    <path
       d="M15.334 112.719l1.335-.685"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path238" />
    <path
       d="M231.21 107.66l-214.54 4.374M233.044 108.213l-1.834-.554"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path240" />
    <path
       d="M12.901 43.443l-.118-.016"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path242" />
    <path
       d="M12.783 43.427l-.11-.045M12.673 43.382l-.095-.07M12.578 43.312l-.073-.091M12.505 43.22l-.045-.105M12.46 43.115l-.016-.113M12.444 43.002l.016-.113M12.46 42.89l.045-.105M12.505 42.785l.073-.09M12.578 42.695l.095-.068M12.673 42.627l.11-.042M12.783 42.585l.118-.014M14.726 42.593V26.184M12.901 42.571l1.825.022"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path244" />
    <path
       d="M12.901 43.443l1.825.022"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path246" />
    <path
       d="M14.275 43.832l.451.005M14.275 43.832l-.116-.016"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path248" />
    <path
       d="M12.901 43.443l1.374.389"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path250" />
    <path
       d="M12.783 43.427l1.376.39M12.444 91.742l.016-.113M12.46 91.63l.045-.106M12.901 92.173l1.825-.02"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path252" />
    <path
       d="M12.901 91.301l1.825-.02M12.783 91.317l.118-.016"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path254" />
    <path
       d="M12.673 91.362l.11-.045M12.578 91.433l.095-.07M12.505 91.524l.073-.091M12.46 91.855l-.016-.113M12.505 91.96l-.045-.105M12.578 92.05l-.073-.09M12.673 92.117l-.095-.068M12.783 92.16l-.11-.043M12.901 92.173l-.118-.013"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path256" />
    <path
       d="M12.783 106.537l.118-.017M12.673 106.582l.11-.045"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path258" />
    <path
       d="M12.578 106.653l.095-.07M12.901 107.392l-.118-.013M12.444 106.964l.016-.113M12.901 107.392l1.825-.033M12.505 106.745l.073-.092M12.46 106.85l.045-.105"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path260" />
    <path
       d="M12.901 106.52l1.825-.033"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path262" />
    <path
       d="M12.46 107.077l-.016-.113M12.505 107.18l-.045-.103M12.578 107.27l-.073-.09M12.673 107.337l-.095-.067M12.783 107.38l-.11-.043M14.275 105.93l.451-.008M14.16 105.947l.115-.017M14.05 105.991l.11-.044"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path264" />
    <path
       d="M12.901 106.52l1.374-.59"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path266" />
    <path
       d="M12.673 106.582l1.378-.59"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path268" />
    <path
       d="M12.901 91.301l1.374-.353"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path270" />
    <path
       d="M12.783 91.317l1.376-.354M14.16 90.963l.115-.015M14.275 90.948l.451-.005"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path272" />
    <path
       d="M199.289 111.034v-1.592"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path274" />
    <path
       d="M199.67 111.424l-.1-.011M199.938 111.302l-.079.065"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path276" />
    <path
       d="M199.4 111.313l-.06-.08M199.34 111.232l-.038-.095"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path278" />
    <path
       d="M199.86 111.367l-.092.041M199.998 111.218l-.06.084"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path280" />
    <path
       d="M199.302 111.137l-.013-.103"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path282" />
    <path
       d="M200.05 111.018l-.014.103M200.05 111.018v-1.592M199.57 111.413l-.091-.038M199.768 111.408l-.099.016"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path284" />
    <path
       d="M199.479 111.375l-.079-.062"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path286" />
    <path
       d="M200.036 111.121l-.038.097M173.018 111.892l-.08.066"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path288" />
    <path
       d="M172.465 111.904l-.062-.082"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path290" />
    <path
       d="M172.64 112.005l-.094-.039"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path292" />
    <path
       d="M172.546 111.966l-.08-.062"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path294" />
    <path
       d="M173.12 111.709l-.04.098M172.843 112l-.101.016M173.08 111.807l-.062.085"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path296" />
    <path
       d="M172.35 111.621v-1.614"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path298" />
    <path
       d="M172.742 112.016l-.101-.011M173.133 111.604l-.014.105"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path300" />
    <path
       d="M172.403 111.822l-.039-.097"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path302" />
    <path
       d="M173.133 111.604v-1.613"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path304" />
    <path
       d="M172.364 111.725l-.013-.104"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path306" />
    <path
       d="M172.937 111.958l-.094.042M197.901 110.431v-.96M197.914 110.532l-.013-.1M197.952 110.626l-.038-.094M198.011 110.706l-.06-.08M198.09 110.767l-.079-.06"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path308" />
    <path
       d="M199.289 111.034l-1.388-.603"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path310" />
    <path
       d="M199.479 111.375l-1.39-.608M171.332 111.001v-.972M171.345 111.104l-.013-.103M171.384 111.199l-.039-.095M171.445 111.28l-.061-.081M171.525 111.342l-.08-.062"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path312" />
    <path
       d="M172.35 111.621l-1.018-.62"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path314" />
    <path
       d="M172.546 111.966l-1.021-.624"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path316" />
    <path
       d="M30.804 114.92l-.07.092M30.865 114.703v-1.729"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path318" />
    <path
       d="M29.966 114.723v-1.73M29.981 114.835l-.015-.112M30.026 114.938l-.045-.103M30.098 115.026l-.072-.088M30.19 115.093l-.092-.067M30.3 115.133l-.11-.04M30.415 115.146l-.116-.013M30.532 115.128l-.117.018M30.64 115.083l-.108.045"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path320" />
    <path
       d="M30.733 115.012l-.093.07M30.85 114.816l-.046.105M30.865 114.703l-.016.113"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path322" />
    <path
       d="M59.527 114.19l-.015-.11M59.64 114.378l-.07-.087M60.258 114.365l-.09.07"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path324" />
    <path
       d="M60.327 114.275l-.07.09"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path326" />
    <path
       d="M59.73 114.444l-.09-.066M60.167 114.434l-.105.045M59.57 114.291l-.043-.102"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path328" />
    <path
       d="M60.385 114.06v-1.705M60.385 114.06l-.014.11"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path330" />
    <path
       d="M59.512 114.08v-1.707"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path332" />
    <path
       d="M60.37 114.17l-.043.105"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path334" />
    <path
       d="M59.836 114.484l-.106-.04M59.949 114.496l-.113-.012M60.062 114.48l-.113.016"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path336" />
    <path
       d="M60.385 114.06l.624-.691"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path338" />
    <path
       d="M60.258 114.365l.625-.697M61.01 113.369v-1.027M61.01 113.369l-.015.109M60.995 113.478l-.043.102M60.952 113.58l-.069.088M31.951 113.992v-1.04M31.822 114.296l-.092.07M31.892 114.207l-.07.09M31.936 114.103l-.044.104M31.951 113.992l-.015.11"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path340" />
    <path
       d="M30.865 114.703l1.086-.71"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path342" />
    <path
       d="M30.64 115.083l1.09-.717M147.739 112.552l-.104.016"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path344" />
    <path
       d="M147.352 112.455l-.064-.084"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path346" />
    <path
       d="M147.919 112.442l-.083.067M147.435 112.518l-.083-.063"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path348" />
    <path
       d="M147.288 112.371l-.04-.097"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path350" />
    <path
       d="M148.036 112.15v-1.633M148.023 112.257l-.04.1M147.982 112.356l-.063.086M147.836 112.509l-.097.043M147.531 112.557l-.096-.04"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path352" />
    <path
       d="M147.248 112.274l-.014-.106"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path354" />
    <path
       d="M148.036 112.15l-.013.107"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path356" />
    <path
       d="M147.234 112.168v-1.634"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path358" />
    <path
       d="M147.635 112.568l-.104-.011M146.568 111.533v-.985M146.582 111.637l-.014-.104M146.621 111.733l-.04-.096M146.684 111.815l-.063-.082"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path360" />
    <path
       d="M147.234 112.168l-.666-.635"
       fill="none"
       stroke="#000"
       stroke-width=".0752"
       stroke-miterlimit="10"
       id="path362" />
    <path
       d="M147.352 112.455l-.668-.64M14.726 43.837v-.372M14.726 106.487v-.565M14.726 105.922V92.153M197.901 109.471l1.388-.029M60.385 112.355l.624-.013M148.036 110.517l23.296-.488M171.332 110.029l1.019-.022M14.726 91.282v-.34M14.726 90.943V43.837M61.01 112.342l85.558-1.794M59.512 112.373l-27.56.578M200.05 109.426l32.994-.692M173.133 109.99l24.768-.519M30.865 112.974l1.086-.023M147.234 110.534l-.666.014M16.67 112.034l14.84-.303"
       fill="none"
       stroke="#000"
       stroke-width=".1504"
       stroke-miterlimit="10"
       id="path364" />
  </g>
  <g
     id="g223">
    <path
       d="M19.242 53.265l11.886 53.081-1.813.375-11.829-53.018-.03-.225.03-.225.091-.21.145-.179.188-.137.22-.085.234-.028.236.032.219.088.188.14.144.18z"
       fill="#fff"
       id="phase1" />
    <path
       d="M31.128 106.346l-11.886-53.08 1.272.235 11.696 52.263z"
       fill="#777"
       id="path203" />
    <path
       d="M19.007 52.874l-.188-.14 1.279.244.185.137z"
       fill="#717171"
       id="path205" />
    <path
       d="M19.242 53.265l-.091-.21 1.274.239.089.207z"
       fill="#767676"
       id="path207" />
    <path
       d="M19.151 53.055l-.144-.181 1.276.241.142.179z"
       fill="#747474"
       id="path209" />
    <path
       d="M18.819 52.734l-.22-.088 1.283.245.216.087z"
       fill="#6d6d6d"
       id="path211" />
    <path
       d="M19.242 53.265l11.886 53.081M18.819 52.734l.188.14"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path213" />
    <path
       d="M17.486 53.703l11.829 53.018M29.315 106.721l1.813-.375"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path215" />
    <path
       d="M19.151 53.055l.09.21M19.007 52.874l.144.18M18.6 52.646l.219.088"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path217" />
    <path
       d="M18.364 52.614l.236.032M18.13 52.642l.234-.028M17.91 52.727l.22-.085M17.722 52.864l.188-.137M17.577 53.043l.145-.179M17.486 53.253l.091-.21M17.455 53.478l.031-.225M17.486 53.703l-.03-.225M20.514 53.5l11.696 52.264M19.882 52.891l.216.087M20.098 52.978l.185.137M20.283 53.115l.142.179M20.425 53.294l.089.207M18.6 52.646l1.282.245M31.128 106.346l1.082-.582"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path219" />
    <path
       d="M19.242 53.265l1.272.236"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path221" />
  </g>
  <g
     id="g243">
    <path
       d="M31.476 106.623l27.468-.493v1.706l-27.468.516z"
       fill="#fff"
       id="phase3" />
    <path
       d="M58.944 106.13l-27.468.493 1.077-.586 27.037-.479z"
       fill="#666"
       id="path227" />
    <path
       d="M59.59 107.239l-.646.597v-1.706l.646-.572z"
       fill="#787878"
       id="path229" />
    <path
       d="M31.476 106.623l27.468-.493"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path231" />
    <path
       d="M32.553 106.037l27.037-.479M58.944 107.836l.646-.597"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path233" />
    <path
       d="M58.944 106.13l.646-.572"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path235" />
    <path
       d="M31.476 106.623l1.077-.586"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path237" />
    <path
       d="M58.944 106.13v1.706"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path239" />
    <path
       d="M59.59 105.558v1.68M31.476 108.352l27.468-.516M31.476 106.623v1.73"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path241" />
  </g>
  <g
     id="g273">
    <path
       d="M75.085 91.063l71.552-.778.165 1.633-71.074.83-.045.002-.045.005-.045.008-.044.01-.043.014-.043.016-.041.02-.04.02-.021.014-.018.01-.037.027-.035.029-.033.03-13.963 13.896-1.235-1.183 14.436-14.349.178-.136.104-.044.104-.043z"
       fill="#fff"
       id="phase4" />
    <path
       d="M146.637 90.285l-71.552.778.397-.34 70.498-.755z"
       fill="#666"
       id="path247" />
    <path
       d="M75.085 91.063l-.223.03.401-.34.22-.03z"
       fill="#686868"
       id="path249" />
    <path
       d="M75.238 92.954l.033-.031.035-.029.037-.026.018-.011-13.476 13.41-.61.582z"
       fill="#727272"
       id="path251" />
    <path
       d="M74.758 91.137l-.104.044.404-.342.103-.043zM74.862 91.094l-.104.043.403-.341.102-.043z"
       fill="#6d6d6d"
       id="path253" />
    <path
       d="M74.654 91.18l-.178.137.407-.344.175-.134z"
       fill="#717171"
       id="path255" />
    <path
       d="M75.085 91.063l71.552-.778M74.862 91.094l.223-.031"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path257" />
    <path
       d="M75.271 92.923l-.033.03M146.802 91.918l-71.074.83"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path259" />
    <path
       d="M74.654 91.18l.104-.043M74.758 91.137l.104-.043M61.275 106.85l13.963-13.896"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path261" />
    <path
       d="M60.04 105.666l14.436-14.349"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path263" />
    <path
       d="M74.476 91.317l.178-.136"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path265" />
    <path
       d="M75.638 92.755l-.045.008M75.463 92.803l-.041.02M75.306 92.894l-.035.029M75.549 92.774l-.043.013M75.683 92.75l-.045.005M75.593 92.763l-.044.01M75.422 92.822l-.04.022M75.728 92.748l-.045.002M75.343 92.868l-.037.026M75.506 92.787l-.043.016M75.482 90.723l70.498-.755M75.263 90.753l.22-.03M75.16 90.796l.103-.043M75.058 90.839l.103-.043M74.883 90.973l.175-.134M61.885 106.268L75.36 92.857M146.637 90.285l-.657-.317"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path267" />
    <path
       d="M75.085 91.063l.397-.34"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path269" />
    <path
       d="M74.476 91.317l.407-.344M61.275 106.85l.61-.582M146.637 90.285l.165 1.633M60.04 105.666l1.235 1.183M75.382 92.844l-.021.013M75.361 92.857l-.018.01"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path271" />
  </g>
  <g
     id="g295">
    <path
       d="M172.824 57.923l-23.979 32.861-1.224-1.025 23.954-32.796z"
       fill="#fff"
       id="phase5" />
    <path
       d="M171.575 56.963L147.62 89.76l-.672-.31 23.618-32.32z"
       fill="#747474"
       id="path277" />
    <path
       d="M148.845 90.784l-.688-.324-1.208-1.01.672.309z"
       fill="#717171"
       id="path279" />
    <path
       d="M148.845 90.784l23.979-32.861"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path281" />
    <path
       d="M147.62 89.759l23.955-32.796"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path283" />
    <path
       d="M146.95 89.45l23.617-32.321M148.845 90.784l-.688-.324M171.575 56.963l-1.008.166"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path285" />
    <path
       d="M147.62 89.759l-.67-.31"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path287" />
    <path
       d="M171.575 56.963l1.249.96"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path289" />
    <path
       d="M147.62 89.759l1.225 1.025"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path291" />
    <path
       d="M146.95 89.45l1.207 1.01"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path293" />
  </g>
  <g
     id="g355">
    <path
       d="M172.66 55.473l7.358-10.072.123-.165.16-.126.188-.078.2-.025 18.105.216v1.592l-17.465-.193h-.042l-.042.006-.041.008-.04.012-.02.008-.02.007-.021.01-.017.008-.02.013-.016.009-.035.025-.032.027-.03.03-.028.033-.09.124-6.927 9.491z"
       fill="#fff"
       id="phase6" />
    <path
       d="M180.018 45.401l-7.357 10.072-1.023.188 7.255-9.93z"
       fill="#747474"
       id="path299" />
    <path
       d="M181.204 46.636l.041-.008-.102.028.02-.008z"
       fill="#696969"
       id="path301" />
    <path
       d="M180.983 46.755l.032-.027.035-.025.015-.01.264-.071 17.465.193-1.38.308-16.58-.181.091-.124.028-.033zM181.245 46.628l.042-.005-.184.05.021-.01.02-.007z"
       fill="#666"
       id="path303" />
    <path
       d="M180.141 45.236l-.123.165-1.125.33.122-.162z"
       fill="#747474"
       id="path305" />
    <path
       d="M180.489 45.032l-.187.078-1.129.335.185-.077z"
       fill="#6d6d6d"
       id="path307" />
    <path
       d="M180.302 45.11l-.161.126-1.126.333.158-.124z"
       fill="#717171"
       id="path309" />
    <path
       d="M181.287 46.623l.042-.001-.264.072.021-.013.017-.008z"
       fill="#666"
       id="path311" />
    <path
       d="M171.638 55.66l1.023-.187 1.247.96-1.04.174z"
       fill="#707070"
       id="path313" />
    <path
       d="M172.66 55.473l7.358-10.072M181.245 46.628l-.041.008"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path315" />
    <path
       d="M181.015 46.728l-.032.027"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path317" />
    <path
       d="M181.287 46.623l-.042.005M180.018 45.401l.123-.165"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path319" />
    <path
       d="M181.204 46.636l-.04.012"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path321" />
    <path
       d="M180.302 45.11l.187-.078"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path323" />
    <path
       d="M198.794 45.223l-18.104-.216"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path325" />
    <path
       d="M180.141 45.236l.16-.126"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path327" />
    <path
       d="M180.953 46.785l-.028.033"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path329" />
    <path
       d="M181.329 46.622l17.465.193"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path331" />
    <path
       d="M180.489 45.032l.2-.025M180.983 46.755l-.03.03M181.05 46.703l-.035.025"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path333" />
    <path
       d="M181.329 46.622h-.042"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path335" />
    <path
       d="M180.834 46.942l16.58.181M179.173 45.445l.185-.077M179.015 45.57l.158-.125M178.893 45.732l.122-.163M171.638 55.66l7.255-9.928M198.794 46.815l-1.38.308"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path337" />
    <path
       d="M181.329 46.622l-.264.072"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path339" />
    <path
       d="M180.489 45.032l-1.131.336"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path341" />
    <path
       d="M180.302 45.11l-1.129.335M180.141 45.236l-1.126.333M180.018 45.401l-1.125.33M172.66 55.473l-1.022.188"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path343" />
    <path
       d="M173.908 56.433l-1.04.174"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path345" />
    <path
       d="M181.245 46.628l-.102.028M181.287 46.623l-.184.05"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path347" />
    <path
       d="M198.794 45.223v1.592"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path349" />
    <path
       d="M172.66 55.473l1.248.96"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path351" />
    <path
       d="M171.638 55.66l1.23.947M181.163 46.648l-.02.008M181.143 46.656l-.019.007M173.908 56.433l6.926-9.491M180.834 46.942l.091-.124M181.086 46.681l-.02.013M181.065 46.694l-.015.009M181.124 46.663l-.021.01M181.103 46.673l-.017.008"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path353" />
  </g>
  <g
     id="g411">
    <path
       d="M205.51 46.925l.026.015-1.471.304-.026-.014z"
       fill="#6e6e6e"
       id="path357" />
    <path
       d="M205.536 46.94l-.026-.015-.028-.013-.028-.01-.03-.006-.029-.005-.03-.002-4.827-.053v-1.591l5.67.067.195.03.182.081.157.128.12.166.075.192 12.506 52.666.025.204-.025.204-.074.191-.119.165-.154.128-.18.082-.193.03-.193-.024-.18-.076-.155-.123-.119-.161-.074-.19-12.342-51.932-.016-.027-.018-.025-.02-.023-.021-.022-.024-.02z"
       fill="#fff"
       id="phase8" />
    <path
       d="M218.002 99.006l.074.189-1.638-.431-.073-.187z"
       fill="#767676"
       id="path361" />
    <path
       d="M200.538 46.836l4.827.053-1.468.306-4.763-.052zM205.365 46.89l.03.001-1.47.306-.028-.002z"
       fill="#666"
       id="path363" />
    <path
       d="M205.395 46.891l.03.005-1.47.305-.03-.004z"
       fill="#686868"
       id="path365" />
    <path
       d="M205.425 46.896l.029.007-1.47.305-.029-.007z"
       fill="#696969"
       id="path367" />
    <path
       d="M205.454 46.903l.028.01-1.47.305-.028-.01z"
       fill="#6b6b6b"
       id="path369" />
    <path
       d="M205.482 46.912l.028.013-1.471.305-.027-.012z"
       fill="#6d6d6d"
       id="path371" />
    <path
       d="M205.536 46.94l.025.017-1.471.304-.025-.017z"
       fill="#6f6f6f"
       id="path373" />
    <path
       d="M205.561 46.957l.024.02-1.472.303-.023-.019z"
       fill="#717171"
       id="path375" />
    <path
       d="M205.585 46.976l.021.022-1.472.304-.021-.022z"
       fill="#727272"
       id="path377" />
    <path
       d="M205.606 46.998l.02.023-1.472.304-.02-.023z"
       fill="#737373"
       id="path379" />
    <path
       d="M205.626 47.021l.018.025-1.472.303-.018-.024z"
       fill="#747474"
       id="path381" />
    <path
       d="M205.644 47.046l.016.027-1.472.303-.016-.027z"
       fill="#757575"
       id="path383" />
    <path
       d="M205.66 47.073l12.342 51.933-1.637-.429-12.177-51.201z"
       fill="#777"
       id="path385" />
    <path
       d="M218.076 99.195l.119.161-1.64-.433-.117-.16z"
       fill="#747474"
       id="path387" />
    <path
       d="M218.195 99.356l.154.123-1.641-.435-.153-.121z"
       fill="#717171"
       id="path389" />
    <path
       d="M218.35 99.479l.18.076-1.644-.436-.178-.075z"
       fill="#6d6d6d"
       id="path391" />
    <path
       d="M199.134 45.574l1.404-.33v1.592l-1.404.307z"
       fill="#787878"
       id="path393" />
    <path
       d="M205.51 46.925l.026.015M218.076 99.195l-.074-.19"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path395" />
    <path
       d="M206.208 45.312l-5.67-.067"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path397" />
    <path
       d="M200.538 46.836l4.827.053M205.365 46.89l.03.001M205.395 46.891l.03.005M205.425 46.896l.029.007M205.454 46.903l.028.01M205.482 46.912l.028.013M205.536 46.94l.025.017M205.561 46.957l.024.02M205.585 46.976l.021.022M205.606 46.998l.02.023M205.626 47.021l.018.025M205.644 47.046l.016.027M205.66 47.073l12.342 51.933M218.195 99.356l-.119-.161M218.35 99.479l-.155-.123M218.53 99.555l-.18-.076"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path399" />
    <path
       d="M218.723 99.579l-.193-.024M218.916 99.549l-.193.03M219.096 99.467l-.18.082M219.25 99.34l-.154.127M219.369 99.174l-.119.165M219.443 98.983l-.074.191M219.468 98.779l-.025.204M219.443 98.575l.025.204M206.862 45.717l.075.192M206.742 45.551l.12.166M206.585 45.423l.157.128M206.403 45.342l.182.081M206.208 45.312l.195.03M199.134 47.143l4.763.052M203.897 47.195l.029.002M203.926 47.197l.029.004M203.955 47.201l.029.007M203.984 47.208l.028.01M204.012 47.218l.027.012M204.039 47.23l.026.014M204.065 47.244l.025.017M204.09 47.261l.023.02M204.113 47.28l.021.022M204.134 47.302l.02.023M204.154 47.325l.018.024M204.172 47.35l.016.026M204.188 47.376l12.177 51.201M216.438 98.764l-.073-.187M216.555 98.923l-.117-.16M216.708 99.044l-.153-.121M216.886 99.119l-.178-.075M200.538 45.245l-1.404.33"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path401" />
    <path
       d="M200.538 46.836l-1.404.307M205.365 46.89l-1.468.305M205.66 47.073l-1.472.303M218.002 99.006l-1.637-.429"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path403" />
    <path
       d="M218.53 99.555l-1.644-.436"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path405" />
    <path
       d="M200.538 45.245v1.59"
       fill="none"
       stroke="#000"
       stroke-width=".0752094"
       stroke-miterlimit="10"
       id="path407" />
    <path
       d="M199.134 45.574v1.569M219.443 98.575L206.937 45.91"
       fill="none"
       stroke="#000"
       stroke-width=".1503906"
       stroke-miterlimit="10"
       id="path409" />
  </g>
  <path
     d="M 20.490212,52.690059 32.41795,105.56224"
     id="phase1-path" />
  <path
     d="m 31.538661,105.89897 27.40156,-0.53728"
     id="phase3-path" />
  <path
     d="M 59.853607,105.44228 74.575229,90.854981 75.145758,90.570739 146.51776,89.968458"
     id="phase4-path"
     sodipodi:nodetypes="cccc"
     style="fill:none" />
  <path
     d="M 146.95,89.449001 170.91571,56.626813"
     id="phase5-path" />
  <path
     d="m 171.98898,55.088142 7.18402,-9.643141 1.29449,-0.655537 0.28879,-0.03358 17.95877,0.228346"
     id="phase6-path"
     style="fill:none" />
  <path
     d="m 200.51213,45.422453 5.68527,0.05388 0.37543,0.09429 0.32182,0.242436 0.18771,0.350185 12.51026,52.97217"
     id="phase8-path"
     style="fill:none;stroke:none;stroke-width:1;stroke-opacity:1" />

<slot/>
</svg>