<svg width="274.045" height="365.197" viewBox="0 0 72.508 96.625" version="1.1" {...$$props} >
    <g transform="translate(157.962 60.408)">
        <g transform="matrix(.0713 0 0 .0713 -245.145 -38.756)">
            <path
                d="M1549.74 789.29l.503-8.463.814-7.534 1.105-6.383.27-.993 1.1-4.047 1.596-3.533 1.78-1.9 1.916-.185 1.993 1.565 2.012 3.296 1.234 3.107-.03 11.714-.255-.67-1.254-3.286-.29-.503-1.315-2.287-.299-.264-1.3-1.15-1.541-.015-1.442 1.367-1.298 2.691-1.121 3.92-.912 5.024-.679 5.974-.427 6.748-.167 7.327.098 7.699.361 7.854.614 7.788.85 7.503 1.063 7.002 1.248 6.297 1.398 5.403 1.51 4.339 1.578 3.135 1.598 1.82 1.572.431.19-.126-.03 11.606-1.23-.291-1.999-2.203-1.972-3.846-1.888-5.348-1.75-6.671-1.565-7.786-1.074-6.959-.263-1.707-1.075-9.296-.783-9.662-.472-9.76-.15-9.589z"
                fill="#2c2c2c" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1550.243 780.827l-.502 8.463-5.481.271 1.51-8.517z" fill="#5b5b5b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1552.162 766.91l-1.105 6.383-2.816.135 3.368-6.492z" fill="#4f4f4f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1551.057 773.293l-.814 7.534-4.473.217 2.47-7.616z" fill="#565656" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1549.74 789.29l-.178 9.153-5.817.294.515-9.176z" fill="#5e5e5e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1552.431 765.917l-.269.993-.553.026z" fill="#474747" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1550.967 827.454l1.074 9.296-.55.03-3.328-9.176z" fill="#585858" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1549.712 808.032l.472 9.76-4.46.234-1.486-9.712z" fill="#5f5f5f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1550.184 817.792l.783 9.662-2.804.15-2.44-9.578z" fill="#5d5d5d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1549.562 798.443l.15 9.59-5.474.281-.493-9.577z" fill="#606060" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1552.041 836.75l.264 1.707-.814-1.677z" fill="#525252" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path
                d="M1556.693 859.873l1.889 5.348 1.972 3.846 1.998 2.203 1.232.291-.24 92.005-8.803-9.322.202-101.042z"
                fill="#525252" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1564.063 764.22l-1.234-3.107 1.243-.058z" fill="#383838" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1562.829 761.113l-2.012-3.296 3.264-.15-.01 3.388z" fill="#2d2d2d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1563.623 860.081l-1.572-.431 1.764-.101v.406l-.191.126z" fill="#282828" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1557.366 850.356l-1.399-5.403 7.887-.437-.014 5.477z" fill="#434343" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1555.038 771.817l1.3-2.69 4.282-.203.299.264 1.315 2.287z" fill="#3d3d3d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1553.006 780.761l.911-5.023 9.86-.474.256.67-.011 4.292z" fill="#4e4e4e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1554.719 838.656l-1.063-7.002 10.233-.553-.018 7.054z" fill="#535353" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1558.823 756.252l-1.914.185-1.781 1.9.853-440.639 2.772-.118 6.492-6.49-1.16 444.92z"
                fill="#525252" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1552.806 824.151l-.614-7.788 11.737-.615-.02 7.812z" fill="#5d5d5d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1553.917 775.738l1.121-3.92 7.196-.343.29.503 1.253 3.286z" fill="#464646" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1551.733 800.81l.166-7.327 12.09-.604-.02 7.31z" fill="#5e5e5e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1560.817 757.817l-1.994-1.565 5.262-.242v1.656z" fill="#222" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1560.453 857.83l-1.578-3.135 4.953-.28-.01 3.223z" fill="#2e2e2e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1557.779 767.76l1.542.014 1.299 1.15-4.283.202z" fill="#323232" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1552.327 786.735l.679-5.974 11.016-.535-.015 5.935z" fill="#555" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1551.9 793.483l.427-6.748 11.68-.574-.018 6.718z" fill="#5b5b5b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1551.831 808.509l-.098-7.699 12.237-.62-.02 7.694z" fill="#606060" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1552.192 816.363l-.36-7.854 12.118-.625-.02 7.864z" fill="#5f5f5f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1553.656 831.654l-.85-7.503 11.103-.59-.02 7.54z" fill="#595959" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1555.967 844.953l-1.248-6.297 9.152-.5-.017 6.36z" fill="#4b4b4b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1558.875 854.695l-1.51-4.34 6.475-.362-.011 4.422z" fill="#393939" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1562.051 859.65l-1.598-1.82 3.367-.192v1.91z" fill="#232323" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path
                d="M1554.741 954.244l8.803 9.322-.08 30.554-266.604 19.074-.982-56.427 16.6-9.347 27.422-9.55 31.97-6.817 34.643-3.803 35.435-.645 34.362 2.539 31.447 5.625 26.722 8.49z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1339.9 937.87l-27.421 9.55-10.175-629.46 3.539.037 17.42.11 7.107 484.484.662 1.107 1.383 1.041 2.066.944 2.686.814.883.181z"
                fill="#777" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1339.9 937.87l-1.85-131.192 2.344.48 3.668.484 3.994.293 4.195.091 4.263-.113 4.196-.313 3.997-.504 3.674-.678 2.024-.519 1.466 125.154z"
                fill="#888" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1302.304 317.96l10.175 629.46-16.601 9.347-11.13-639.018 2.797.058z" fill="#616161"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1476.31 929.144l-34.362-2.539-3.939-608.247 22.864-.018 11.56-.028 1.913 476.002.269 1.104.984 1.074.193.116z"
                fill="#969696" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1438.01 318.358l3.938 608.247-35.435.645-1.414-155.024 1.877.126 3.894.079 3.934-.097 3.85-.269 3.646-.432 3.326-.581 2.904-.712 2.394-.82 1.813-.9 1.18-.955.512-.978-3.117-448.324z"
                fill="#989898" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1476.31 929.144l-.518-132.536 1.48.894 2.316.915 2.89.79 3.374.64 3.757.47 4.02.284 4.16.088 4.164-.11 4.038-.303 1.556-.201.21 134.694z"
                fill="#8d8d8d" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1405.1 772.226l1.413 155.024-34.642 3.803-1.466-125.154 1.21-.31 2.699-.955 2.081-1.05 1.404-1.112.689-1.14-5.366-483.033 12.201.027 4.591 448.464.065.978.743.95 1.402.894 2.022.809 2.582.699 3.064.566 3.452.415z"
                fill="#939393" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1507.757 934.77l-.21-134.695 2.23-.288 3.415-.657 2.939-.805 2.372-.926 1.738-1.018 1.05-1.079.34-1.105-.25-476.07 13.368-.101-.27 625.233z"
                fill="#7e7e7e" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1534.48 943.259l.27-625.233 5.278-.04 13.713-.192 2.24-.096-.853 440.64-1.597 3.532-1.1 4.047-.822 1.019-3.369 6.492-2.47 7.616-1.51 8.517-.515 9.176.493 9.577 1.486 9.712 2.439 9.578 3.328 9.176.814 1.677 1.073 6.959 1.565 7.786-.202 101.042z"
                fill="#6a6a6a" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1296.86 1013.194l266.604-19.074.08-30.554.24-92.005.03-11.606v-2.317l.01-3.223.01-4.422.015-5.477.016-6.36.018-7.055.02-7.54.02-7.813.02-7.864.02-7.695.02-7.31.017-6.718.015-5.935.01-4.292.03-11.714.01-3.165.01-3.389v-1.656l1.16-444.92.015-5.207v-1.034l.065-25.003v-.077l38.944.202-4.01 745.191-342.134 25.72-15.753-772.785 41.687.216.45 25.802v.055l.206 11.883.02 1.127.01.569 11.13 639.018.982 56.427z"
                fill="#979797" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1284.06 278.313l-41.686-.216 10.69-.633 34.888-1.064 46-.748 53.388-.35 55.97.09 53.388.518 46.085.887 35.18 1.163 22.132 1.326 4.176.685-38.944-.202v.077l-4.416-.474-21.046-1.028-30.83-.868-38.545-.627-43.218-.324-44.196.014-41.315.35-34.95.649-22.75.775z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1404.431 77.635l14.392-16.688 2.597-.64-14.35 16.641z" fill="#505b67" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1407.07 76.948l14.35-16.641 4.733 1.8-14.031 16.273z" fill="#667483" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1421.42 60.307l-2.597.64 16.428-14.512 2.55-.599z" fill="#4f5b66" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1484.234 129.782l-6.367 7.405.602-2.306 6.546-7.613z" fill="#384048" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1404.431 77.635l2.639-.687-12.057 18.51-2.672.739z" fill="#505b67" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1485.015 127.268l-6.546 7.613-1.939-4.212 6.99-8.127z" fill="#3f4952" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1483.52 122.542l-6.99 8.127-4.396-5.866 7.67-8.917z" fill="#576470" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1479.804 115.886l-7.67 8.917-6.587-7.133 8.546-9.932z" fill="#6c7c8b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1465.547 117.67l6.587 7.133-6.454 9.907-7.323-6.004z" fill="#6c7c8b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1479.804 115.886l-5.711-8.148 9.749-8.652 4.712 9.03z" fill="#6c7c8c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1474.093 107.738l-8.546 9.932-8.34-7.906 9.557-11.102z" fill="#7d8fa1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1472.134 124.803l4.396 5.866-5.882 9.027-4.968-4.986z" fill="#576470" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1483.52 122.542l-3.715-6.656 8.75-7.77 2.937 7.343z" fill="#586471" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1474.093 107.738l-7.329-9.076 10.903-9.668 6.175 10.092z" fill="#7d8fa1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1466.764 98.662l-9.556 11.102-9.504-8.11 10.63-12.345z" fill="#889cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1458.334 89.31l-10.63 12.344-9.976-7.72 11.687-13.568z" fill="#8ea2b7" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1437.728 93.933l9.976 7.721-8.94 13.723-10.864-6.358z" fill="#8ea2b7" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1458.334 89.31l-8.92-8.944 13.337-11.808 7.711 10.005z" fill="#8ea2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1449.415 80.366l-11.687 13.567-9.717-6.76 12.651-14.682z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1440.662 72.49l-12.651 14.682-8.745-5.31 13.452-15.604z" fill="#8599ac" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1440.662 72.49l-7.944-6.232 15.353-13.573 7.03 7.033z" fill="#8598ab" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1428.01 87.172l9.718 6.761-9.828 15.086-10.525-5.52z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1449.415 80.366l-8.753-7.875 14.438-12.773 7.651 8.84z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1432.718 66.258l-13.452 15.605-7.144-3.483 14.031-16.273z" fill="#78899b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1419.266 81.863l8.745 5.31-10.636 16.326-9.414-4.28z" fill="#8699ac" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1426.153 62.107l-4.733-1.8 16.38-14.47 4.368 2.118z" fill="#657482" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1478.469 134.881l-.602 2.306-5.36 8.225.452-2.074z" fill="#384048" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1484.234 129.782l.781-2.514 7.468-6.636-.985 2.693z" fill="#373f47" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1412.122 78.38l7.144 3.483-11.305 17.356-7.63-2.738z" fill="#78899b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1432.718 66.258l-6.565-4.151 16.015-14.152 5.903 4.73z" fill="#78899a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1466.764 98.662l-8.43-9.353 12.128-10.746 7.205 10.43z" fill="#899cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1407.07 76.948l5.052 1.432-11.79 18.1-5.32-1.021z" fill="#667583" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1447.705 101.654l9.503 8.11-8.04 12.34-10.404-6.727z" fill="#889cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1457.208 109.764l8.339 7.906-7.19 11.036-9.188-6.602z" fill="#7d8fa1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1485.015 127.268l-1.495-4.726 7.972-7.083.99 5.173z" fill="#404952" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1476.53 130.669l1.939 4.212-5.51 8.457-2.31-3.642z" fill="#3f4852" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1470.462 78.563l-7.71-10.005 14.742-9.837 6.376 10.883z" fill="#8ea2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1442.168 47.955l-4.368-2.119 18.109-12.042 3.963 2.382z" fill="#657381" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1448.07 52.685l-5.902-4.73 17.704-11.779 5.17 5.209z" fill="#789" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1483.842 99.086l-6.175-10.092 12.052-8.064 4.9 10.937z" fill="#7d8fa1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1437.8 45.836l-2.55.6 18.163-12.078 2.496-.564z" fill="#4e5a65" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1491.492 115.459l-2.938-7.342 9.673-6.487 2.08 7.913z" fill="#586572" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1488.554 108.117l-4.712-9.03 10.778-7.22 3.607 9.763z" fill="#6d7d8c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1477.667 88.994l-7.205-10.431 13.407-8.959 5.85 11.326z" fill="#899cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1492.483 120.632l-.991-5.173 8.815-5.916.433 5.545z" fill="#414a53" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1462.751 68.558l-7.65-8.84 15.96-10.638 6.433 9.64z" fill="#8ca0b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1491.498 123.325l.985-2.693 8.257-5.544-1.21 2.844z" fill="#363e46" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1455.1 59.718l-7.03-7.033 16.972-11.3 6.019 7.695z" fill="#8598ab" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1489.72 80.93l-5.85-11.326 14.444-7.014 4.391 12.02z" fill="#899cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1455.909 33.794l-2.497.564 19.562-9.43 2.439-.538z" fill="#4d5864" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1471.06 49.08l-6.018-7.695 18.28-8.83 4.93 8.208z" fill="#8497aa" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1477.494 58.72l-6.433-9.64 17.191-8.317 5.123 10.26z" fill="#8ca0b4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1494.62 91.867l-4.9-10.937 12.985-6.32 3.529 11.594z" fill="#7e90a2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1500.306 109.543l-2.079-7.913 10.425-5.091 1.154 8.358z" fill="#596673" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1500.74 115.088l-.433-5.545 9.5-4.646-.168 5.835z" fill="#424b55" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1465.042 41.385l-5.17-5.209 19.068-9.2 4.382 5.58z" fill="#768798" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1459.872 36.176l-3.963-2.382 19.504-9.404 3.527 2.586z" fill="#637280" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1498.227 101.63l-3.607-9.763 11.614-5.663 2.418 10.335z" fill="#6e7d8d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1483.87 69.604l-6.376-10.883 15.881-7.697 4.939 11.566z" fill="#8ea2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1499.53 117.932l1.21-2.844 8.899-4.356-1.452 2.961z" fill="#353d44" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1488.252 40.763l-4.93-8.208 19.255-6.21 3.786 8.563z" fill="#8396a9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1493.375 51.024l-5.123-10.26 18.111-5.856 3.745 10.69z" fill="#8b9fb3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1498.314 62.59l-4.939-11.566 16.733-5.426 3.427 12.04z" fill="#8da2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1475.413 24.39l-2.439.537 20.603-6.626 2.377-.517z" fill="#4c5762" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1478.94 26.976l-3.527-2.586 20.541-6.606 3.07 2.727z" fill="#62707e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1483.322 32.555l-4.382-5.579 20.083-6.465 3.554 5.835z" fill="#758696" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1502.705 74.61l-4.391-12.02 15.22-4.952 2.859 12.504z" fill="#899db0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1506.234 86.204l-3.529-11.594 13.688-4.468 2.083 12.053z" fill="#7f91a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1508.187 113.693l1.452-2.961 9.385-3.093-1.707 3.043z" fill="#343b42" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1508.652 96.539l-2.418-10.335 12.242-4.01 1.167 10.735z" fill="#6f7e8e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1509.806 104.897l-1.154-8.358 10.99-3.61.182 8.671z" fill="#5a6774" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1509.639 110.732l.167-5.835 10.018-3.297-.8 6.039z" fill="#434d57" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1502.577 26.346l-3.554-5.835 20.734-3.628 2.7 5.972z" fill="#748495" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1513.535 57.638l-3.427-12.04 17.282-3.067 1.87 12.298z" fill="#8da1b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1516.392 70.142l-2.857-12.504 15.725-2.81 1.276 12.77z" fill="#899db1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1517.317 110.682l1.707-3.043 9.705-1.78-1.97 3.089z" fill="#323940" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1518.476 82.195l-2.083-12.053 14.143-2.543.594 12.305z" fill="#7f91a4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1506.363 34.908l-3.786-8.562 19.88-3.49 2.608 8.753z" fill="#8295a7" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1510.108 45.598l-3.745-10.69 18.702-3.3 2.325 10.923z" fill="#8b9fb2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1519.024 107.639l.8-6.039 10.358-1.893-1.453 6.152z" fill="#454f59" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1519.824 101.6l-.181-8.67 11.363-2.07-.824 8.847z" fill="#5c6976" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1519.643 92.93l-1.167-10.735 12.654-2.29-.124 10.956z" fill="#708090" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1499.023 20.511l-3.069-2.727 21.207-3.705 2.596 2.804z" fill="#606e7c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1530.536 67.599l-1.276-12.77 15.95-.627.068 12.798z" fill="#899db1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1528.73 105.86l1.452-6.153 11.81-.518-1.974 6.168z" fill="#47515b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1526.76 108.948l1.97-3.089 11.288-.502-2.195 3.096z" fill="#30373e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1519.757 16.883l-2.597-2.804 20.045-.703 2.262 2.81z" fill="#5f6c7a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1531.13 79.904l-.594-12.305 14.742-.599-.55 12.336z" fill="#8092a5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1530.182 99.707l.824-8.846 12.596-.541-1.61 8.869z" fill="#5d6b78" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1522.457 22.855l-2.7-5.972 19.71-.697 2.072 5.983z" fill="#728293" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1531.006 90.86l.124-10.956 13.597-.568-1.125 10.984z" fill="#718191" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1525.065 31.609l-2.608-8.754 19.081-.686 1.729 8.768z" fill="#8193a6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1527.39 42.53l-2.325-10.921 18.202-.672 1.255 10.942z" fill="#8a9eb1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1529.26 54.829l-1.87-12.298 17.132-.652.687 12.323z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1541.992 99.189l1.61-8.87 70.85 1.805-2.255 8.786z" fill="#5e6c79" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1543.267 30.937l-1.729-8.768 73.32 2.402 1.66 8.698z" fill="#8092a5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1540.018 105.357l1.974-6.168 70.205 1.72-2.568 6.108z" fill="#48525c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1541.539 22.169l-2.072-5.983 73.213 2.445 2.178 5.94z" fill="#718191" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1544.522 41.88l-1.255-10.943 73.252 2.332 1.017 10.85z" fill="#899db1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1545.21 54.202l-.688-12.323 73.014 2.24.294 12.214z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1545.278 67l-.07-12.798 72.622 2.131-.45 12.684z" fill="#8a9db1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1544.727 79.336l.55-12.336 72.104 2.017-1.157 12.224z" fill="#8093a5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1539.467 16.186l-2.262-2.81 72.943 2.458 2.532 2.797z" fill="#5e6b78" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1543.602 90.32l1.125-10.984 71.497 1.905-1.773 10.883z" fill="#728292" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1537.823 108.453l2.195-3.096 69.61 1.66-2.7 3.06z" fill="#30363d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1395.013 95.459l5.319 1.022-9.336 19.598-5.528-.578z" fill="#667483" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1407.96 99.22l9.415 4.279-8.427 17.676-9.942-3.164z" fill="#8599ac" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1427.9 109.019l10.864 6.358-7.09 14.854-11.564-4.88z" fill="#8ea2b7" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1392.341 96.197l2.672-.738-9.545 20.042-2.698.794z" fill="#505b67" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1417.375 103.499l10.525 5.52-7.79 16.331-11.162-4.175z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1400.332 96.48l7.629 2.74-8.955 18.791-8.01-1.932z" fill="#78899b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1438.764 115.377l10.405 6.727-6.378 13.356-11.116-5.229z" fill="#889cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1458.357 128.706l7.323 6.004-5.124 10.72-7.906-4.779z" fill="#6c7c8b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1449.169 122.104l9.188 6.602-5.707 11.945-9.86-5.191z" fill="#7d8fa1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1465.68 134.71l4.969 4.986-4.672 9.77-5.422-4.035z" fill="#576470" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1472.959 143.338l-.452 2.074-4.256 8.9.332-1.824z" fill="#384048" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1470.649 139.696l2.31 3.642-4.376 9.15-2.606-3.022z" fill="#404952" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1371.466 247.983l-.754-67.493 2.72-.932.752 68.398z" fill="#4c5762" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1374.184 247.956l-.751-68.398 5.77-.722.73 69.063z" fill="#63717f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1373.433 179.558l-2.72.932 1.11-20.88 2.724-.914z" fill="#4d5863" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1379.933 247.9l-.73-69.064 8.459-.458.692 69.453-1.29-.004-7.131.071z" fill="#758697"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1388.354 247.831l-.692-69.453 10.57-.156.642 69.635z" fill="#8396a9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1398.232 178.222l-10.57.156 1.078-19.863 10.534.761z" fill="#8497aa" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1398.874 247.857l-.642-69.635 11.927.16.582 69.503z" fill="#8ca0b4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1410.742 247.885l-.583-69.503 12.412.466.518 69.067z" fill="#8da2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1379.203 178.836l-5.77.722 1.114-20.862 5.759-.377z" fill="#637280" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1387.662 178.378l-8.46.458 1.104-20.517 8.434.196z" fill="#768798" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1410.16 178.382l-11.928-.16 1.042-18.946 11.879 1.277z" fill="#8ca0b4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1462.823 183.935l.3 64.324-2.957-.043-.318-65.423z" fill="#434c56" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1462.823 183.935l-2.975-1.142.723-12.265 2.945 1.688-.023.383z" fill="#424c55" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1459.848 182.793l.318 65.423-5.952-.088-.351-66.495z" fill="#5a6774" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1453.863 181.633l.351 66.495-8.579-.127-.398-67.464z" fill="#6e7e8e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1445.237 180.537l.398 67.464-2.491-.037-8.135-.02-.455-68.36z" fill="#7e90a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1445.237 180.537l-10.683-.953.88-15.33 10.623 2.148z" fill="#7e90a2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1434.554 179.584l.455 68.36-11.92-.029-.518-69.067z" fill="#899db0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1453.863 181.633l-8.626-1.096.82-14.135 8.572 2.143z" fill="#6e7e8d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1434.554 179.584l-11.983-.736.938-16.593 11.924 1.999z" fill="#899cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1459.848 182.793l-5.985-1.16.766-13.088 5.942 1.983z" fill="#596673" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1422.57 178.848l-12.41-.466.993-17.829 12.356 1.702z" fill="#8ea2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1388.74 158.515l-8.434-.196 3.977-21.5 8.28 1.078z" fill="#789" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1423.51 162.255l-12.357-1.702 3.342-17.92 12.065 3.319z" fill="#8ea2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1374.547 158.696l-2.723.914 4.068-22.048 2.715-.852z" fill="#4e5a65" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1435.433 164.254l-11.924-2 3.051-16.302 11.625 3.642z" fill="#899cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1380.306 158.32l-5.76.376 4.061-21.986 5.676.108z" fill="#647381" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1399.274 159.276l-10.534-.761 3.822-20.618 10.317 1.984z" fill="#8497aa" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1411.153 160.553l-11.88-1.277 3.607-19.395 11.615 2.751z" fill="#8ca0b4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1463.516 172.216l-2.945-1.688 2.028-10.724 2.819 2.367-.298 1.57z" fill="#414a54" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1460.57 170.528l-5.941-1.983 2.222-11.769 5.748 3.028z" fill="#586572" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1454.629 168.545l-8.572-2.143 2.47-13.11 8.324 3.484z" fill="#6d7d8c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1446.057 166.402l-10.624-2.148 2.752-14.66 10.341 3.697z" fill="#7e90a2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1378.607 136.71l-2.715.852 6.878-21.267 2.698-.794z" fill="#4f5b66" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1384.283 136.818l-5.676-.108 6.861-21.209 5.528.578z" fill="#657482" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1448.526 153.291l-10.34-3.697 4.605-14.134 9.859 5.191z" fill="#7d8fa1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1465.418 162.171l-2.82-2.367 3.38-10.338 2.605 3.022z" fill="#404952" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1465.172 163.73l-.052.01.297-1.569z" fill="#414a54" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1438.186 149.594l-11.626-3.642 5.115-15.72 11.116 5.228z" fill="#899cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1414.495 142.632l-11.615-2.751 6.068-18.706 11.162 4.175z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1402.88 139.88l-10.318-1.983 6.444-19.886 9.942 3.164z" fill="#8598ab" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1426.56 145.952l-12.065-3.32 5.615-17.282 11.565 4.881z" fill="#8ea2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1465.172 163.73l.246-1.559 3.165-9.683-.332 1.824z" fill="#373f47" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1392.562 137.897l-8.28-1.079 6.714-20.739 8.01 1.932z" fill="#78899a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1462.599 159.804l-5.748-3.028 3.704-11.345 5.422 4.035z" fill="#586471" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1456.85 156.776l-8.324-3.485 4.124-12.64 7.905 4.78z" fill="#6d7c8c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1693.834 855.412l-2.039 4.752-86.624 5.02.026-4.68z" fill="#474747" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1691.795 860.164l-2.311 3.08-84.33 4.915.017-2.975z" fill="#3e3e3e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1689.484 863.245l-2.506 1.337-81.83 4.78.01-1.203z" fill="#333" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1680.302 750.667l2.65 1.513-77.16 3.561.01-1.649z" fill="#222" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1682.953 752.18l2.662 3.222-79.841 3.713.019-3.374z" fill="#2d2d2d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1699.411 840.657l-1.387 4.942-4.19 9.813z" fill="#2e2e2e" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1700.479 834.64l-1.068 6.017-3.88 8.464z" fill="#313131" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1701.189 827.734l-.71 6.905-3.65 6.84z" fill="#343434" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1701.517 820.163l-.328 7.571-3.512 4.991z" fill="#363636" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1701.451 812.171l.066 7.992-3.47 2.982z" fill="#373737" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1700.993 804.02l.458 8.151-3.528.883z" fill="#363636" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1700.159 795.977l.834 8.043-3.682-1.231z" fill="#353535" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1698.976 788.303l1.183 7.674-3.929-3.285z" fill="#333" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1697.487 781.245l1.49 7.058-4.256-5.206z" fill="#303030" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1695.74 775.025l1.747 6.22-4.652-6.93z" fill="#2c2c2c" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1693.794 769.832l1.946 5.193-5.102-8.4z" fill="#282828" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1691.714 765.818l2.08 4.014-5.59-9.573z" fill="#232323" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1693.834 855.412l-88.636 5.092.018-3.236-.48-2.992 90.796-5.155z" fill="#4f4f4f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1604.737 854.276l.479 2.992-.018 3.236-.026 4.68-.017 2.975-.01 1.203-.83 146.274-4.056 9.526 4.01-745.19 4.22.691-2.689 473.43-.01 1.648-.019 3.374-.028 5.025-.037 6.542-.045 7.871-.051 8.962-.029 5.056-.525 4.737-.823 10.296-.528 10.43-.215 10.213.105 9.653.42 8.772z"
                fill="#484848" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1605.059 797.308l.525-4.737.028-5.056 89.109-4.418 1.51 9.595z" fill="#585858" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1604.236 807.604l.823-10.296 91.171-4.616 1.081 10.097z" fill="#5d5d5d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1603.708 818.034l.528-10.43 93.075-4.815.612 10.265z" fill="#5f5f5f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1603.493 828.247l.215-10.213 94.215-4.98.124 10.09z" fill="#606060" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1603.597 837.9l-.104-9.653 94.554-5.102-.37 9.58z" fill="#5e5e5e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1604.018 846.672l-.42-8.772 94.079-5.175-.849 8.754z" fill="#5b5b5b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1604.737 854.276l-.72-7.604 92.811-5.193-1.296 7.642z" fill="#565656" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1696.23 792.692l-1.51-9.595 4.257 5.206z" fill="#333" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1697.311 802.789l-1.08-10.097 3.928 3.285z" fill="#353535" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1697.923 813.054l-.612-10.265 3.682 1.231z" fill="#373737" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1698.046 823.145l-.123-10.091 3.528-.883z" fill="#373737" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1697.677 832.725l.37-9.58 3.47-2.982z" fill="#363636" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1696.828 841.479l.849-8.754 3.512-4.99z" fill="#343434" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1695.532 849.12l1.296-7.641 3.65-6.84z" fill="#323232" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1693.834 855.412l1.698-6.291 3.88-8.464z" fill="#2e2e2e" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1605.774 759.115l79.841-3.713 2.59 4.857-82.46 3.88z" fill="#383838" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1605.745 764.14l82.46-3.88 2.433 6.365-84.93 4.057z" fill="#424242" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1690.638 766.625l-2.434-6.366 5.59 9.573z" fill="#272727" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1605.708 770.682l84.93-4.057 2.197 7.69-87.172 4.238z" fill="#4b4b4b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1692.835 774.315l-2.197-7.69 5.102 8.4z" fill="#2c2c2c" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1605.663 778.553l87.172-4.238 1.886 8.782-89.109 4.418z" fill="#525252" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1694.72 783.097l-1.885-8.782 4.652 6.93z" fill="#303030" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1608.492 280.663l-4.221-.692-4.176-.685.129-25.385 8.408 2.098z" fill="#484848" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1258.127 1050.883l-10.442-8.792-16.15-763.352 10.839-.642z" fill="#6a6a6a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1242.374 278.097l-10.84.642-.543-25.673 21.556-1.943.517 26.341z" fill="#6a6a6a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1231.535 278.739l16.15 763.352-7.488-18.811-16.12-743.172z" fill="#525252" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1231.535 278.739l-7.458 1.369-.54-24.955 7.454-2.087z" fill="#525252" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1443.31 275.392l-55.97-.09-.277-27.474 1.291.004 10.52.026 11.868.028 12.347.03 11.92.03 8.135.02.166 27.427z"
                fill="#989898" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1287.952 276.4l-34.888 1.064-.517-26.34 34.943-1.623z" fill="#7e7e7e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1333.952 275.652l-46 .748-.462-26.9 46.084-1.14z" fill="#8d8d8d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1600.095 279.286l-22.132-1.326.091-26.08 22.17 2.02z" fill="#616161" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1577.963 277.96l-35.18-1.163.027-26.69 35.245 1.772z" fill="#777" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1542.783 276.797l-46.085-.887-.062-27.157h.05l1.288.038 1.25.037 1.174.034 1.062.031.918.027.745.022.551.016.34.01.118.004 38.678 1.134-.027 26.69z"
                fill="#888" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1496.698 275.91l-53.388-.518-.166-27.428 2.491.037 8.58.127 5.951.087 2.957.044 26.25.387h.095l.317.005.53.008.726.01.901.014 1.049.016 1.164.017 1.244.018 1.236.018.062 27.157z"
                fill="#939393" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1387.34 275.303l-53.388.35-.378-27.292 6.25-.063.214-.004.437-.005.646-.006.837-.008 1.002-.01 1.135-.011 1.236-.012 1.297-.013 1.32-.013 1.3-.013 1.24-.012 1.145-.012 1.012-.01.85-.008.661-.007.454-.005.235-.004 16.621-.166 2.718-.027 5.749-.057 7.13-.071.277 27.475z"
                fill="#969696" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1284.06 278.313l22.751-.775.415 26.204-12.34.203-.615.007-.444.01-9.317.152z" fill="#7a7a7a"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1306.811 277.538l34.95-.65.353 26.543-16.593.148-.775.005-.653.008-16.867.15z" fill="#8a8a8a"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1341.76 276.889l41.316-.35.277 26.724-19.553.08h-.873l-.814.005-20 .08-.353-26.541z"
                fill="#959595" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1383.076 276.539l44.196-.014.193 26.732-20.842.004-.899-.004-.907.004-21.464.004-.277-26.725z"
                fill="#989898" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1427.272 276.525l43.218.324.11 26.563-20.31-.073-.845-.005h-.92l-21.06-.076-.193-26.732z"
                fill="#959595" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1470.49 276.849l38.545.627.037 26.236-18.057-.14-.72-.008-.854-.005-18.84-.147z" fill="#8b8b8b"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1509.035 277.476l30.83.868-.021 25.785-14.405-.195-.54-.01-.72-.007-15.108-.205z" fill="#7b7b7b"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1539.865 278.344l21.045 1.028-.058 25.25-9.815-.23-.324-.011-.535-.01-10.334-.243z" fill="#666"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1560.91 279.372l4.416.474-.065 25.003-4.409-.227z" fill="#4e4e4e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1307.226 303.742l16.867-.15-29.206.353z" fill="#626262" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1284.51 304.115l9.317-.153-9.316.208v-.055z" fill="#7a7a7a" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1383.353 303.263l21.464-.004-41.017.083 19.553-.08z" fill="#717171" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1342.113 303.43l20-.08-36.592.23z" fill="#6c6c6c" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1427.465 303.257l21.06.076-41.903-.073 20.843-.004z" fill="#717171" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1470.6 303.412l18.84.147-39.15-.22z" fill="#6c6c6c" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1560.852 304.622l4.41.227v.012l-14.225-.47z" fill="#464646" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1539.844 304.129l10.334.242-24.739-.437z" fill="#565656" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1509.071 303.712l15.11.205-33.166-.346z" fill="#636363" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1287.544 317.807l-2.796-.058-.01-.57 1.03-.024 1.15-.011z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1287.544 317.807l-.626-.663 17.949-.182h.133l.843 1.036-3.539-.037-14.76-.153z" fill="#3f3f3f"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1305.843 317.997l-.843-1.036 24.895-.137.195.306-2.628.019-2.126.022-1.416.024-.67.026.013.887z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1325.336 317.17l2.126-.021 7.018 488.534-2.065-.944z" fill="#919191" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1323.92 317.195l1.416-.024 7.08 487.568-1.384-1.041z" fill="#7d7d7d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1323.25 317.221l.67-.026 7.112 486.503-.662-1.107-7.107-484.484z" fill="#676767" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1330.09 317.13l-.195-.306 2.971-.016 7.878-.023v.312l-3.388.006-3.793.011-3.334.016h-.143z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1333.567 317.114l3.793-.012 6.702 490.54-3.668-.484z" fill="#bdbdbd" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1330.233 317.13l3.334-.016 6.827 490.044-2.344-.48-.883-.18z" fill="#b2b2b2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1385.323 318.326l-12.201-.027-.012-1.048-.727-.027-1.467-.026-2.17-.025-2.807-.022-3.362-.02-2.556-.01-.164-.39 7.946-.024 18.92-.017.01.863.235.049 2.333.047 3.913.04.049.265-.947.004-2.66.016-2.081.02-1.44.02-.757.022-.057.023v.267z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1362.578 317.131l3.362.02 5.676 488.438-1.211.31-2.024.52z" fill="#a1a1a1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1365.94 317.15l2.807.023 5.567 487.46-2.698.955z" fill="#8f8f8f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1368.747 317.173l2.17.025 5.478 486.386-2.081 1.05z" fill="#7b7b7b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1370.916 317.198l1.468.026 5.415 485.248-1.404 1.112z" fill="#656565" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1372.384 317.224l.726.027.012 1.048 5.366 483.034-.689 1.139z" fill="#4e4e4e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1389.655 317.974l2.66-.016 4.414 453.162-2.582-.7z" fill="#a3a3a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1387.574 317.993l2.08-.019 4.493 452.447-2.023-.81z" fill="#919191" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1386.135 318.014l1.439-.02 4.55 451.618-1.402-.894z" fill="#7d7d7d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1385.377 318.036l.758-.022 4.587 450.704-.743-.95z" fill="#676767" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1385.32 318.059l.057-.023 4.603 449.732-.065-.978-4.591-448.465v-.266z" fill="#676767"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1359.857 316.73l.165.39-1.26-.005-3.066-.008v-.365z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1393.262 317.954l-.049-.265.334.004 5.857.035 2.012.007v.197l-2.378.004-3.563.01-2.215.01z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1395.476 317.945l3.563-.01 4.205 454.166-3.451-.416z" fill="#bdbdbd" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1472.433 318.312l-11.56.028.481-1.6 22.806.06 5.404.025v.447l-1.353.004-3.889.011-3.493.015-2.99.019-2.394.021-1.728.024-1.015.025-.274.026v.897z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1484.323 317.286l3.89-.011 1.396 483.042-3.756-.47z" fill="#bdbdbd" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1480.83 317.3l3.494-.014 1.528 482.561-3.375-.64z" fill="#b2b2b2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1477.841 317.319l2.99-.019 1.647 481.906-2.89-.79z" fill="#a3a3a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1475.447 317.34l2.394-.02 1.747 481.097-2.315-.915z" fill="#919191" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1473.718 317.364l1.729-.023 1.826 480.16-1.48-.893-.194-.116z" fill="#7d7d7d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1472.703 317.39l1.015-.026 1.881 479.128-.984-1.074z" fill="#676767" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1472.43 317.415l.273-.026 1.912 478.029-.27-1.104-1.912-476.002z" fill="#505050" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1461.354 316.74l-.481 1.6-22.864.018-6.697.005v-.302l-.538-.023-1.225-.022-1.88-.021-.249-.004.014-.229 2.65-.004 6.969-.021 5.72-.032 4.082-.04 2.156-.046.057-.049v-.864l12.293.032z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1427.667 317.995l1.88.021 3.19 450.604-1.813.9z" fill="#7b7b7b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1429.547 318.016l1.225.023 3.144 449.626-1.179.955z" fill="#656565" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1430.772 318.039l.538.022v.302l3.117 448.324-.513.978-3.144-449.626z" fill="#4e4e4e"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1427.432 317.764l-.014.23-2.231-.018-3.007-.017-3.442-.013-3.523-.01v-.168l7.11.004 5.108-.006z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1418.738 317.946l3.442.013 3.446 453.093-3.326.58z" fill="#b0b0b0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1422.18 317.96l3.007.016 3.343 452.364-2.904.712z" fill="#a1a1a1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1553.741 317.794l-13.713.192.72-.829 13.415.2z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1540.748 317.157l-.72.83-5.279.039-13.367.1v-.708l-.352-.026-1.09-.025-.458-.006.272-.38 19.966.161z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1519.94 317.367l1.09.025.262 477.91-1.051 1.079z" fill="#656565" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1521.03 317.392l.351.026v.709l.25 476.07-.34 1.105z" fill="#4e4e4e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1519.754 316.981l-.272.38-1.34-.018-2.459-.022-3.043-.019-3.538-.015-3.922-.012h-.93v-.379l11.658.056 3.847.03z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1505.18 317.275l3.922.012.675 482.5-2.23.288-1.556.2z" fill="#bcbcbc" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1509.102 317.287l3.538.015.552 481.828-3.415.657z" fill="#b0b0b0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1512.64 317.302l3.043.02.448 481.003-2.939.805z" fill="#a1a1a1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1515.683 317.321l2.458.022.362 480.056-2.372.926z" fill="#8f8f8f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1553.741 317.794l.422-.437h.01l4.58.223-2.772.118z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1565.245 311.09l-6.492 6.49-4.58-.223 11.085-11.474z" fill="#343434" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1565.258 305.883l-11.085 11.474h-.01l-13.415-.2-1.028-.015 10.993-12.761.324.01 14.224.47v1.022z"
                fill="#464646" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1550.713 304.38l-10.993 12.762-19.966-.16-3.847-.032 8.993-13.026.54.01 24.738.438z"
                fill="#565656" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1524.9 303.924l-8.993 13.026-11.657-.055h-.118l-.338-.004-.548-.004-.741-.004-.914-.004-1.057-.005-1.168-.006-1.244-.006-1.282-.006-1.28-.006-1.237-.006-1.159-.005-1.043-.005-.898-.004-.722-.004-.527-.004-.315-.004h-.095l-5.404-.026 6.135-13.235.72.007 33.165.345.72.007z"
                fill="#636363" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1490.295 303.564l-6.135 13.235-22.806-.059-12.293-.031-2.074-.005h-.18l2.638-13.37.845.006 39.15.22.855.005z"
                fill="#6c6c6c" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1449.445 303.334l-2.639 13.37-3.91-.005-5.844-.005-7.203-.006-8.057-.006-8.336-.007-6.569-.005-1.163-13.413.899.004 41.902.072z"
                fill="#717171" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1405.724 303.258l1.163 13.413h-1.449l-7.126.007-5.732.005-3.948.004-1.909.004-18.92.018-4.876-13.363h.873l41.017-.083.907-.004z"
                fill="#717171" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1362.927 303.344l4.877 13.363-7.947.023-4.165.012h-.685l-.658.004-.846.004-1.008.004-1.138.004-1.236.004-1.293.004-1.313.004-1.291.004-1.23.004-1.13.004-.997.004-.833.004h-1.29l-7.878.023-8.12-13.224.775-.005 36.592-.229z"
                fill="#6c6c6c" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1324.746 303.584l8.12 13.224-2.97.016-24.896.137h-.133l-10.596-13.01.616-.007 29.206-.353.653-.008z"
                fill="#626262" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1294.271 303.952l10.596 13.01-17.949.182-1.15.011-1.05-1.102-.207-11.883 9.316-.208z" fill="#555"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1284.718 316.053l1.05 1.102-1.03.025z" fill="#454545" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1449.008 317.621l-2.155.046v-.144l.14.004 2.016.047v.05z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1446.853 317.667l-4.083.04v-.227h.133l3.95.042v.145z" fill="#6a6a6a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1442.77 317.707l-5.72.032v-.296h.01l5.712.036v.228z" fill="#7e7e7e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1437.05 317.74l-6.968.02v-.344l6.969.027v.296z" fill="#8d8d8d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1399.404 317.728l-5.857-.036v-.233l4.775-.028 1.082-.004v.3z" fill="#777" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1389.3 317.65l-2.333-.048v-.055l1.674-.041.658-.007v.15z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1449.06 316.709v.864h-.056l-2.016-.047v-.821l2.074.005z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1446.987 316.703v.822l-.14-.004-3.95-.043v-.78l3.91.004h.18z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1442.897 316.7v.78h-.133l-5.712-.036v-.748l5.844.005z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1437.052 316.695v.748h-.01l-6.969-.027h-.226l-.01-.726 7.203.006z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1429.849 316.69l.01.726-7.533-.015h-.523l-.01-.717 8.057.006z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1421.792 316.683l.01.717-7.52-.004h-.816l-.01-.72 8.336.006z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1413.456 316.677l.01.72-6.993.01h-1.024l-.01-.736h1.45z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1405.438 316.673l.01.735-6.043.02-1.082.003-.01-.75 7.126-.008z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1398.312 316.68l.01.75-4.774.029-.957.006-.01-.78z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1392.58 316.684l.01.78-3.289.035-.658.007-.01-.818z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1430.08 317.416v.345l-2.65.004-5.108.006v-.37l7.533.015h.225z" fill="#969696" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1422.321 317.4v.37l-7.11-.003H1414.278v-.371l7.52.004h.524z" fill="#989898" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1414.278 317.397v.371h-.935l-.901-.004-1.028-.004-1.124-.004-1.185-.004-1.209-.004-1.196-.004h-.232v-.347l6.993-.01h.817z"
                fill="#939393" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1406.468 317.407v.347l-.915-.004-1.062-.004-.945-.004-.8-.004-.628-.004-.44-.004h-.265l-2.012-.008v-.3l6.044-.02h1.023z"
                fill="#888" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1393.545 317.459v.233l-.334-.004-3.913-.04v-.15l3.289-.034.957-.006z" fill="#616161"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1386.732 317.553l-.01-.863 1.909-.004.01.818-1.674.041-.234.006z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1520.24 796.38l-1.736 1.02-.362-480.057 1.34.018.459.006z" fill="#7b7b7b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1505.991 800.276l-4.038.304-.957-483.312h4.184l.811 483z" fill="#c4c4c4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1501.953 800.58l-4.165.11-1.105-483.424H1500.996l.957 483.311z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1493.63 800.6l-4.02-.283-1.397-483.042 1.352-.004H1492.376l1.254 483.332z" fill="#c5c5c5"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1497.788 800.69l-4.158-.09-1.254-483.332h4.306l1.106 483.423z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1489.77 242.85l.529-.057.016 5.867-.53-.008z" fill="#7d7d7d" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1491.927 242.697l1.049-.036.015 6.039-1.049-.016z" fill="#b2b2b2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1494.14 242.635l1.245-.016.014 6.116-1.244-.018z" fill="#c5c5c5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1501.448 242.7l.918.046.012 6.176-.918-.027z" fill="#a1a1a1" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1502.366 242.746l.746.052.01 6.146-.745-.022z" fill="#8f8f8f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1499.21 242.637l1.175.027.012 6.2-1.174-.035z" fill="#bcbcbc" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1503.112 242.798l.55.058.012 6.104-.55-.016z" fill="#7b7b7b" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1503.674 248.96l-.011-6.104.34.06.01 6.054z" fill="#656565" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1504.013 248.97l-.01-6.053.118.063.01 5.993z" fill="#4e4e4e" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1503.246 316.89v.382h-.741v-.384l.741.004z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1497.96 242.62l1.25.017.013 6.192-1.25-.036z" fill="#c4c4c4" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1489.452 242.91l.317-.06.016 5.803-.316-.005z" fill="#676767" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1496.671 242.614l1.289.006.013 6.173-1.288-.038z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1492.976 242.66l1.165-.025.015 6.082-1.165-.017z" fill="#bdbdbd" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1490.299 242.793l.726-.052.016 5.93-.726-.01z" fill="#919191" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1489.357 242.973l.095-.063.016 5.738h-.095l-.016-5.673z" fill="#676767" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1500.385 242.664l1.063.037.012 6.194-1.063-.031z" fill="#b0b0b0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1491.025 242.741l.902-.044.016 5.987-.901-.013z" fill="#a3a3a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1502.505 316.887v.384h-.914v-.387l.914.004z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1495.385 242.619l1.286-.005.014 6.14h-.05l-1.235-.017-.014-6.117z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1501.591 316.882v.387h-1.057v-.39l1.057.004z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1500.534 316.877v.39h-1.168v-.395l1.168.006z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1499.366 316.871v.396h-1.244v-.4l1.244.005z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1498.122 316.866v.4h-1.282v-.406l1.281.006z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1490.502 317.27v-.44l.723.003v.437h-.723z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1491.225 317.27v-.437l.897.004v.431h-.897z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1492.122 317.268v-.43l1.043.004v.426H1492.122z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1493.165 317.268v-.426l1.159.005v.42h-1.159z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1494.324 317.267v-.42l1.238.006v.413h-1.238z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1495.562 317.266v-.413l1.28.006v.407H1495.563z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1337.167 806.497l-2.686-.814-7.02-488.534 2.629-.019h.143l6.934 489.368z" fill="#a3a3a3"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1348.056 807.935l-3.994-.293-6.702-490.54 3.388-.006h.747l6.56 490.84z" fill="#c5c5c5"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1356.514 807.913l-4.263.113-6.41-490.932h4.42l6.253 490.817z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1364.707 807.096l-3.997.504-6.096-490.497H1355.697l3.066.008 5.945 489.981z" fill="#bcbcbc"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1360.71 807.6l-4.196.313-6.253-490.817 1.1.004 1.139.004 1.008.004H1354.614l6.096 490.497z"
                fill="#c4c4c4" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1352.25 808.026l-4.194-.091-6.56-490.84h4.346l6.409 490.933z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1368.38 806.418l-3.673.678-5.944-489.98 1.259.005 2.556.01z" fill="#b0b0b0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1349.159 241.271l1.242.028.087 6.893-1.24.013z" fill="#bcbcbc" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1339.734 241.553l.213-.062.091 6.805-.214.004-.09-6.745z" fill="#676767" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1339.947 241.49l.436-.058.092 6.86-.436.005z" fill="#7d7d7d" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1345.24 241.252l1.298-.005.09 6.984-1.297.013z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1340.383 241.432l.646-.053.093 6.906-.647.006z" fill="#919191" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1341.121 248.285l-.092-6.906.837-.046.092 6.944z" fill="#a3a3a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1341.958 248.277l-.092-6.944 1.002-.037.092 6.971z" fill="#b2b2b2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1346.538 241.247l1.32.006.09 6.964-1.32.013z" fill="#c9c9c9" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1347.947 248.217l-.09-6.964 1.302.018.088 6.934z" fill="#c4c4c4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1342.868 241.296l1.136-.027.091 6.987-1.135.011z" fill="#bdbdbd" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1344.095 248.256l-.09-6.987 1.235-.017.092 6.991z" fill="#c5c5c5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1350.488 248.192l-.087-6.893 1.146.038.086 6.844z" fill="#b0b0b0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1351.547 241.337l1.013.047.085 6.787-1.012.01z" fill="#a1a1a1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1352.645 248.17l-.085-6.786.851.054.084 6.724z" fill="#8f8f8f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1353.495 248.162l-.084-6.724.662.06.083 6.658z" fill="#7b7b7b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1354.76 241.625v.065l.08 6.459-.234.004-.082-6.591.232.065z" fill="#4e4e4e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1354.074 241.497l.454.063.082 6.591-.453.005z" fill="#656565" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1342.038 317.095v-.314l.833-.004v.316h-.833z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1342.871 317.095v-.316l.997-.004v.318h-.997z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1343.868 317.094v-.318l1.13-.004v.321z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1344.999 317.094v-.321l1.23-.004v.324h-1.23z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1346.228 317.094v-.325l1.291-.004v.33h-1.291z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1347.52 317.095v-.33l1.312-.003v.333h-1.313z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1348.832 317.095v-.333l1.293-.004v.338h-1.293z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1350.126 317.096v-.338l1.235-.004v.343l-1.1-.004h-.135z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1351.36 317.098v-.343l1.14-.004v.348l-1.14-.004z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1352.5 317.1v-.349l1.007-.004v.354l-1.008-.004z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1353.508 317.102v-.353l.845-.004v.357h-.845z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1399.793 771.686l-3.064-.566-4.413-453.162.946-.004 2.214-.01z" fill="#b2b2b2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1410.87 772.43l-3.894-.078-4.082-454.423h4.024l3.952 454.503z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1418.655 772.065l-3.85.269-3.82-454.404h.433l1.028.004h2.521l3.688 454.13z" fill="#c4c4c4"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1422.3 771.633l-3.645.432-3.688-454.13.076.001h.172l3.523.01 3.562 453.687z" fill="#bcbcbc"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1414.804 772.334l-3.934.097-3.952-454.504h4.067l3.82 454.404z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1430.924 769.52l-2.394.82-3.343-452.364 2.231.017.25.004 3.256 451.526z" fill="#8f8f8f"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1402.894 317.929l4.082 454.423-1.877-.126-1.855-.125-4.204-454.166 2.378-.004h1.476z"
                fill="#c5c5c5" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1402.752 317.93v-.19l.799.004v.186h-.799z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1403.55 317.929v-.186l.946.004v.182h-.945z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1404.496 317.928v-.182l1.062.004v.178z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1405.558 317.928v-.178l.915.004h.232v.174h-1.147z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1414.094 317.768v.166h-.748v-.167h.748z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1413.346 317.766v.167h-.901v-.167l.901.004z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1412.445 317.765v.167l-1.029-.004v-.167l1.028.004z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1411.417 317.763v.167h-1.123v-.169l1.123.004z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1410.293 317.76v.17h-1.185v-.17l1.184.003z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1409.109 317.759v.17h-1.21v-.172l1.21.004z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1406.705 317.927v-.173l1.196.004v.172h-1.196z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1792.712 845.702l.385.254-54.66 3.118-3.216.88-7.062.403-5.175-1.575z" fill="#2e2e2e"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1793.097 845.956l-.385-.254-.489-.323-.573-.505-.815-.72-1.265-1.448-.517-.73-.722-1.022-1.204-2.042-.515-1.026-.646-1.288-1.108-2.568-.482-1.289-.566-1.512-.982-3.014-.428-1.513-.48-1.69-.827-3.37-.357-1.69-.384-1.82-.651-3.625-.273-1.82-.284-1.894-.457-3.778-.177-1.897-.179-1.916-.251-3.823-.074-1.92-.073-1.884-.04-3.76.036-1.89.033-1.799.174-3.59.146-1.807.134-1.662.384-3.32.258-1.673.227-1.477.584-2.955.293-1.186 20.337-.99 1.162 2.908 1.95 6.506 1.609 7.248 1.214 7.76.777 8.022.315 8.021-.16 7.76-.629 7.242-1.073 6.494-1.482 5.538-1.84 4.413-2.134 3.158-1.422 1.096z"
                fill="#8a8a8a" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1792.223 845.38l.49.322-69.729 3.08 68.666-3.908z" fill="#2e2e2e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1779.7 814.507l.652 3.626.384 1.819-67.396 3.646-1.177-7.326 67.265-3.585z" fill="#737373"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1778.687 807.015l.458 3.778.283 1.894-67.265 3.585-.834-7.63 67.18-3.524z" fill="#787878"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1784.858 834.03l1.108 2.568.646 1.288-68.037 3.817-1.984-5.198 67.785-3.764z" fill="#565656"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1781.093 821.642l.828 3.37.479 1.69-67.57 3.707-1.49-6.81 67.396-3.647z" fill="#6b6b6b"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1779.87 771.642l-.584 2.955-.227 1.477-67.335 3.318 1.02-5.957 17.991-.875.122.295 49.306-2.399z"
                fill="#6f6f6f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1787.127 838.912l1.204 2.042.722 1.021-68.326 3.865-2.152-4.137 68.037-3.817z" fill="#494949"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1778.801 777.746l-.384 3.32-.134 1.663-67.223 3.36.664-6.697 67.335-3.318z" fill="#767676"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1789.57 842.706l1.265 1.449.815.719-68.666 3.908-2.257-2.942 68.326-3.865z" fill="#3b3b3b"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1778.08 799.38l.251 3.822.179 1.916-67.181 3.525-.469-7.719 67.146-3.465z" fill="#7b7b7b"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1777.894 791.815l.04 3.76.072 1.884-67.145 3.465-.091-7.589 67.16-3.41z" fill="#7c7c7c"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1782.828 828.215l.982 3.014.566 1.512-67.785 3.764-1.761-6.096 67.57-3.707z" fill="#616161"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1778.137 784.535l-.175 3.591-.033 1.799-67.16 3.41.291-7.246 67.223-3.36z" fill="#7a7a7a"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1722.984 848.782l5.175 1.575 3.28 8.782z" fill="#494949" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1712.745 773.435l1.35-5.04 14.626-.704.05.086 1.965 4.783z" fill="#676767" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1714.095 768.394l1.645-3.97 10.817-.516 2.164 3.783z" fill="#5c5c5c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1722.312 754.013l2.39-3.507-7.068 11.143-1.894 2.774z" fill="#363636" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1715.74 764.423l1.894-2.774 6.656-.315 2.253 2.55.013.024z" fill="#505050" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1737.24 861.647l-2.886-.401-.99-10.784z" fill="#3b3b3b" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1724.703 750.506l2.643-1.907-7.623 11.568-2.09 1.482z" fill="#2d2d2d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1717.633 761.649l2.09-1.482 2.267-.107 2.256 1.224.044.05z" fill="#434343" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1727.346 748.6l2.821-.225-8.218 11.663-2.226.129z" fill="#2e2e2e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1734.354 861.246l-2.916-2.107-3.28-8.782z" fill="#353535" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1875.152 760.738l2.606 4.97-6.153-7.726-3.24-6.2 3.732 4.924.177.234z" fill="#393939"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1853.502 772.407l-.884 6.605-39.753 1.988-.574-2.552-1.2-3.952z" fill="#767676" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1869.21 852.77l-3.407 1.368-128.564 7.509 2.763-1.346z" fill="#434343" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1740.002 860.3l-2.763 1.347 1.198-12.573z" fill="#393939" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path
                d="M1740.002 860.3l-1.565-11.226 54.66-3.118 5.69-.324 8.59-.49 6.764-.386 53.207-3.035.054.315 1.807 10.735z"
                fill="#767676" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1857.74 742.51l3.58 1.438-128.236 5.918-2.917-1.491z" fill="#2d2d2d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1730.167 748.375l2.917 1.491-8.838 11.418-2.256-1.224-.04-.022z" fill="#373737" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1861.32 743.948l3.58 3.114-128.893 5.995-2.923-3.19z" fill="#3b3b3b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1733.084 749.866l2.923 3.191-9.464 10.828-2.253-2.551-.044-.05z" fill="#404040" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1868.365 751.781l3.24 6.2-130.095 6.214-2.664-6.319z" fill="#565656" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1738.846 757.876l2.664 6.32-10.653 8.66-.122-.296-1.964-4.783z" fill="#515151" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M1730.857 772.855l10.653-8.66 130.095-6.214 6.153 7.727-22.993 1.119-45.626 2.22-8.64.42-20.336.99z"
                fill="#6b6b6b" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1854.765 766.827l-1.263 5.58-42.412 2.09-.773-2.547-1.178-2.903z" fill="#6f6f6f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1852.618 779.012l-.415 7.14-37.904 1.926-.38-2.39-1.054-4.688z" fill="#7a7a7a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1852.203 786.153l.065 7.473-36.915 1.905-.205-2.09-.85-5.363z" fill="#7c7c7c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1852.268 793.626l.544 7.595-36.811 1.93-.067-1.697-.58-5.923z" fill="#7b7b7b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1852.812 801.22l1.006 7.503-37.596 2.004.027-1.26-.248-6.315z" fill="#787878" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1853.818 808.723l1.444 7.197-39.25 2.123.073-.824.138-6.492z" fill="#737373" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1855.262 815.92l1.841 6.685-41.732 2.289.074-.438.567-6.413z" fill="#6b6b6b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1857.103 822.605l2.19 5.976-44.975 2.497.036-.134 1.017-6.05z" fill="#616161" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1859.293 828.58l2.478 5.089-48.903 2.743 1.45-5.334z" fill="#565656" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1861.771 833.669l2.697 4.04-53.418 3.021 1.8-4.252.018-.066z" fill="#494949" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1864.468 837.709l2.838 2.86-58.381 3.322 2.059-3.002.067-.159z" fill="#3b3b3b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1867.306 840.568l.096 1.468-.053-.315-53.207 3.035-6.643.294 1.32-1.004.106-.155z" fill="#2e2e2e"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1872.36 756.936l-.263-.23-3.732-4.925-3.466-4.719z" fill="#393939" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1864.9 747.062l3.465 4.72-129.519 6.094-2.839-4.819z" fill="#494949" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1736.007 753.057l2.84 4.82-10.076 9.9-.05-.086-2.165-3.783-.013-.023z" fill="#494949"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1728.159 850.357l7.062-.403-1.858.508z" fill="#353535" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1728.159 850.357l5.204.105.991 10.784z" fill="#555" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1738.438 849.074l-1.199 12.573-3.876-11.185 1.858-.508z" fill="#595959" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1807.499 845.05l6.643-.294-6.765.386z" fill="#2e2e2e" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M1805.665 831.427l1.073-6.494 8.707-.477-.074.438-1.017 6.05z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1806.738 824.933l.628-7.243 8.718-.47-.073.823-.566 6.413z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1803.611 778.88l-1.949-6.506 8.655-.424.773 2.546 1.2 3.952z" fill="#f9f9f9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1805.22 786.128l-1.609-7.248 8.68-.432.574 2.552 1.055 4.688z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1807.212 801.91l-.777-8.022 8.713-.448.205 2.091.58 5.923z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1807.366 817.69l.16-7.759 8.723-.463-.027 1.259-.138 6.492z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1801.662 772.374l-1.162-2.907 8.64-.42 1.177 2.903z" fill="#dfdfdf" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1800.208 844.536l2.135-3.158 8.64-.49-2.058 3.003-.107.155z" fill="#d2d2d2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1798.787 845.632l1.421-1.096 8.61-.49-1.32 1.004-.121.092z" fill="#b3b3b3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1804.183 836.965l1.482-5.538 8.69-.483-.037.134-1.45 5.334-.018.066z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1802.343 841.378l1.84-4.413 8.667-.487-1.8 4.252-.067.159z" fill="#eee" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1807.526 809.931l-.314-8.021 8.722-.456.067 1.698.248 6.316z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1806.435 793.888l-1.214-7.76 8.699-.44.379 2.39.85 5.362z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M1549.74 789.29l.503-8.463" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1551.057 773.293l1.105-6.383" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1550.243 780.827l.814-7.534" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1549.562 798.443l.179-9.153" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1552.162 766.91l.27-.993" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1552.041 836.75l-1.074-9.296" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1550.184 817.792l-.472-9.76" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1550.967 827.454l-.783-9.662" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1549.712 808.032l-.15-9.589" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1552.305 838.457l-.264-1.707" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1605.059 797.308l.525-4.737" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1604.236 807.604l.823-10.296" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1603.708 818.034l.528-10.43" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1603.493 828.247l.215-10.213" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1603.597 837.9l-.104-9.653" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1604.018 846.672l-.42-8.772" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1604.737 854.276l-.72-7.604" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1605.216 857.268l-.479-2.992" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1242.374 278.097l41.687.216" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1565.327 279.77l38.944.201" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1333.567 317.114l3.793-.012" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1330.233 317.13l3.334-.016" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1362.578 317.131l3.362.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1325.336 317.17l2.126-.021" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1365.94 317.15l2.807.023" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1323.92 317.195l1.416-.024" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1368.747 317.173l2.17.025" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1323.25 317.221l.67-.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1370.916 317.198l1.468.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1372.384 317.224l.726.027" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1484.323 317.286l3.89-.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1505.18 317.275l3.922.012" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1480.83 317.3l3.494-.014" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1509.102 317.287l3.538.015" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1477.841 317.319l2.99-.019" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1512.64 317.302l3.043.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1475.447 317.34l2.394-.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1515.683 317.321l2.458.022" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1473.718 317.364l1.729-.023" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1472.703 317.39l1.015-.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1519.94 317.367l1.09.025" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1472.43 317.415l.273-.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1521.03 317.392l.351.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1395.476 317.945l3.563-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1418.738 317.946l3.442.013" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1389.655 317.974l2.66-.016" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1422.18 317.96l3.007.016" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1387.574 317.993l2.08-.019" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1386.135 318.014l1.439-.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1427.667 317.995l1.88.021" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1385.377 318.036l.758-.022" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1429.547 318.016l1.225.023" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1385.32 318.059l.057-.023" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1430.772 318.039l.538.022" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1404.431 77.635l14.392-16.688" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1477.867 137.187l6.367-7.405" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1484.234 129.782l7.264-6.457" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1418.823 60.947l16.428-14.512" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1435.25 46.435l18.163-12.077" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1491.498 123.325l8.032-5.393" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1453.412 34.358l19.562-9.43" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1499.53 117.932l8.657-4.239" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1472.974 24.927l20.603-6.626" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1495.954 17.784l-2.377.517" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1508.187 113.693l9.13-3.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1517.317 110.682l9.442-1.734" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1495.954 17.784l21.207-3.705" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1517.16 14.079l20.045-.703" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1526.76 108.948l11.063-.495" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1617.83 56.333l-.294-12.215" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1612.68 18.63l-2.532-2.796" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1614.858 24.571l-2.178-5.94" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1537.205 13.376l72.943 2.458" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1616.519 33.27l-1.66-8.699" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1617.536 44.118l-1.017-10.849" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1606.928 110.078l2.7-3.06" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1612.197 100.91l2.254-8.786" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1614.451 92.124l1.773-10.883" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1617.38 69.017l.45-12.684" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1537.823 108.453l69.105 1.625" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1609.629 107.017l2.568-6.107" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1616.224 81.24l1.157-12.223" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1392.341 96.197l12.09-18.562" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1472.507 145.412l5.36-8.225" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1371.466 247.983l-.754-67.493" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1463.123 248.259l-.3-64.324" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1370.712 180.49l1.112-20.88" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1371.824 159.61l4.068-22.048" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1463.494 172.599l1.678-8.869" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1465.172 163.73l3.08-9.418" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1375.892 137.562l6.878-21.267" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1468.251 154.312l4.256-8.9" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1382.77 116.295l9.571-20.098" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1556.693 859.873l1.889 5.348" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1564.063 764.22l-1.234-3.107" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1562.829 761.113l-2.012-3.296" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1558.582 865.221l1.972 3.846" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1554.943 853.202l1.75 6.671" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1560.554 869.067l1.998 2.203" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1562.552 871.27l1.232.291" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1562.051 859.65l1.572.431" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1555.967 844.953l1.398 5.403" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1556.337 769.126l-1.299 2.691" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1553.917 775.738l-.911 5.023" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1553.656 831.654l1.063 7.002" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1558.823 756.252l-1.914.185" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1556.909 756.437l-1.781 1.9" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1552.192 816.363l.614 7.788" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1555.038 771.817l-1.12 3.92" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1551.9 793.483l-.167 7.327" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1560.817 757.817l-1.994-1.565" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1558.875 854.695l1.578 3.135" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1563.623 860.081l.19-.126" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1559.32 767.774l-1.541-.015" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1557.779 767.76l-1.442 1.366" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1553.006 780.761l-.679 5.974" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1552.327 786.735l-.428 6.748" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1551.733 800.81l.098 7.699" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1551.831 808.509l.361 7.854" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1552.806 824.151l.85 7.503" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1554.719 838.656l1.248 6.297" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1557.366 850.356l1.51 4.339" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1560.453 857.83l1.598 1.82" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1691.795 860.164l2.04-4.752" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1689.484 863.245l2.311-3.081" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1686.978 864.582l2.506-1.337" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1682.953 752.18l-2.651-1.513" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1685.615 755.402l-2.662-3.222" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1688.204 760.26l-2.589-4.858" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1690.638 766.625l-2.434-6.366" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1692.835 774.315l-2.197-7.69" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1694.72 783.097l-1.885-8.782" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1696.23 792.692l-1.51-9.595" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1697.311 802.789l-1.08-10.097" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1697.923 813.054l-.612-10.265" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1698.046 823.145l-.123-10.091" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1697.677 832.725l.37-9.58" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1696.828 841.479l.849-8.754" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1695.532 849.12l1.296-7.641" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1693.834 855.412l1.698-6.291" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1698.024 845.6l1.387-4.943" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1699.411 840.657l1.068-6.018" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1700.479 834.64l.71-6.906" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1701.189 827.734l.328-7.57" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1701.517 820.163l-.066-7.992" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1701.451 812.171l-.458-8.151" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1700.993 804.02l-.834-8.043" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1700.159 795.977l-1.183-7.674" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1698.976 788.303l-1.49-7.058" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1697.487 781.245l-1.747-6.22" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1695.74 775.025l-1.946-5.193" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1693.794 769.832l-2.08-4.014" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1605.147 869.362l81.83-4.78" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1558.823 756.252l5.262-.242" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1605.802 754.092l74.5-3.425" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1698.024 845.6l-4.19 9.812" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1685.615 755.402l6.099 10.416" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1231.535 278.739l10.839-.642" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1604.27 279.971l4.222.692" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1224.077 280.108l7.458-1.37" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1339.9 937.87l-27.421 9.55" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1476.31 929.144l-34.362-2.539" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1312.479 947.42l-16.601 9.347" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1371.87 931.053l-31.97 6.817" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1406.513 927.25l-34.642 3.803" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1507.757 934.77l-31.446-5.626" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1441.948 926.605l-35.435.645" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1534.48 943.259l-26.723-8.49" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1563.544 963.566l-8.803-9.322" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1247.685 1042.09l10.442 8.793" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1240.197 1023.28l-16.12-743.172" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1240.197 1023.28l7.488 18.81" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1563.464 994.12l-266.603 19.074" fill="none" stroke="#000" stroke-width=".706"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1554.741 954.244l-20.262-10.985" fill="none" stroke="#000" stroke-width=".706"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1552.431 765.917l-.822 1.019" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1258.127 1050.883l342.135-25.72" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1600.262 1025.162l4.054-9.526" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1548.24 773.428l-2.47 7.616" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1544.238 808.314l1.486 9.712" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1545.77 781.044l-1.51 8.517" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1543.745 798.737l.493 9.577" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1551.609 766.936l-3.368 6.492" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1551.49 836.78l.815 1.677" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1544.26 789.561l-.515 9.176" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1545.724 818.026l2.439 9.578" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1548.163 827.604l3.328 9.176" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1600.262 1025.162l4.009-745.19" fill="none" stroke="#000" stroke-width=".706"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1242.374 278.097l15.753 772.786" fill="none" stroke="#000" stroke-width=".706"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1387.34 275.303l55.97.09" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1253.064 277.464l34.888-1.064" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1287.952 276.4l46-.748" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1577.963 277.96l22.132 1.326" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1542.783 276.797l35.18 1.163" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1496.698 275.91l46.085.887" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1443.31 275.392l53.388.518" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1333.952 275.652l53.388-.35" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1284.06 278.313l22.751-.775" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1306.811 277.538l34.95-.65" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1341.76 276.889l41.316-.35" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1383.076 276.539l44.196-.014" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1427.272 276.525l43.218.324" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1470.49 276.849l38.545.627" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1509.035 277.476l30.83.868" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1539.865 278.344l21.045 1.028" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1560.91 279.372l4.416.474" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1252.547 251.123l34.943-1.622" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1608.492 280.663l.14-24.664" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1578.054 251.88l22.17 2.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1223.536 255.153l7.455-2.087" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1287.49 249.5l46.084-1.14" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1230.991 253.066l21.556-1.943" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1224.077 280.108l-.54-24.955" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1600.224 253.9l8.408 2.1" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1542.81 250.106l35.245 1.773" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1307.226 303.742l16.867-.15" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1284.51 304.115l9.317-.153" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1383.353 303.263l21.464-.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1342.113 303.43l20-.08" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1427.465 303.257l21.06.076" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1470.6 303.412l18.84.147" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1560.852 304.622l4.41.227" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1539.844 304.129l10.334.242" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1509.071 303.712l15.11.205" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1287.544 317.807l-2.796-.058" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1305.843 317.997l17.42.11" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1385.323 318.326l-12.201-.027" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1472.433 318.312l-11.56.028" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1553.741 317.794l-13.713.192" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1558.753 317.58l6.492-6.49" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1558.753 317.58l-4.58-.223" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1550.713 304.38l-.535-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1524.9 303.924l-.72-.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1490.295 303.564l-.854-.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1449.445 303.334h-.92" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1405.724 303.258l-.907.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1362.927 303.344l-.814.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1324.746 303.584l-.653.008" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1294.271 303.952l-.444.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1284.738 317.18l1.03-.025" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1565.261 304.86l-14.224-.469" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1550.178 304.371l-24.739-.437" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1524.18 303.917l-33.165-.346" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1489.44 303.559l-39.15-.22" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1448.525 303.332l-41.903-.072" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1404.817 303.26l-41.017.083" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1362.113 303.35l-36.592.229" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1324.093 303.592l-29.206.353" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1293.827 303.962l-9.316.208" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1551.037 304.392l9.815.23" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1551.037 304.392l-.324-.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1525.44 303.934l14.404.195" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1525.44 303.934l-.54-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1491.015 303.571l18.056.141" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1491.015 303.571l-.72-.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1450.29 303.339l20.31.073" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1450.29 303.339l-.845-.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1406.622 303.26l20.843-.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1406.622 303.26l-.898-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1363.8 303.343l19.553-.08" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1363.8 303.343h-.873" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1325.52 303.58l16.594-.15" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1325.52 303.58l-.774.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1294.887 303.945l12.339-.203" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1294.887 303.945l-.616.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1449.008 317.621l.057-.048" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1446.853 317.667l2.155-.046" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1442.77 317.707l4.083-.04" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1437.05 317.74l5.72-.033" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1430.082 317.76l6.968-.02" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1393.547 317.692l5.857.036" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1386.967 317.602l2.333.047" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1386.732 317.553l.235.049" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1449.06 316.709v.864" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1386.723 316.69l.01.863" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1518.504 797.4l1.737-1.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1501.953 800.58l4.038-.304" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1520.24 796.38l1.052-1.078" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1497.788 800.69l4.165-.11" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1509.777 799.787l3.415-.657" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1489.61 800.317l4.02.284" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1474.346 794.314l.269 1.104" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1493.63 800.6l4.158.09" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1474.615 795.418l.984 1.074" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1477.273 797.502l2.315.915" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1482.477 799.206l3.375.641" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1521.292 795.302l.339-1.105" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1485.852 799.847l3.757.47" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1479.588 798.417l2.89.79" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1516.13 798.325l2.373-.926" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1513.192 799.13l2.939-.805" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1490.299 242.793l-.53.057" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1492.976 242.66l-1.049.037" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1495.385 242.619l-1.244.016" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1502.366 242.746l-.918-.045" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1503.112 242.798l-.746-.052" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1500.385 242.664l-1.175-.027" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1503.663 242.856l-.551-.058" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1504.003 242.917l-.34-.061" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1504.25 317.274v-.38" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1504.131 248.973l-.01-5.993" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1499.21 242.637l-1.25-.017" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1489.77 242.85l-.318.06" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1497.96 242.62l-1.288-.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1504.121 242.98l-.119-.063" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1494.14 242.635l-1.164.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1491.025 242.741l-.726.052" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1489.452 242.91l-.095.063" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1501.448 242.7l-1.063-.036" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1491.927 242.697l-.902.044" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1496.671 242.614l-1.286.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1489.565 317.272v-.447" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1489.373 248.646l-.016-5.673" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1332.415 804.74l2.065.943" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1334.48 805.683l2.687.814" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1340.394 807.158l3.668.484" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1344.062 807.642l3.994.293" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1352.25 808.026l4.264-.113" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1360.71 807.6l3.997-.504" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1371.616 805.588l2.698-.954" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1376.395 803.584l1.404-1.112" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1356.514 807.913l4.196-.313" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1377.8 802.472l.688-1.14" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1348.056 807.935l4.195.091" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1364.707 807.096l3.674-.678" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1374.314 804.634l2.081-1.05" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1331.032 803.698l1.383 1.041" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1330.37 802.591l.662 1.107" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1350.401 241.3l-1.242-.029" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1339.947 241.49l-.213.063" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1340.383 241.432l-.436.059" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1346.538 241.247l-1.298.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1341.03 241.379l-.647.053" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1341.866 241.333l-.836.046" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1347.858 241.253l-1.32-.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1344.004 241.269l-1.136.027" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1342.868 241.296l-1.002.037" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1349.159 241.271l-1.301-.018" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1352.56 241.384l-1.013-.047" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1353.411 241.438l-.85-.054" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1354.074 241.497l-.663-.06" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1354.765 241.69v-.065" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1351.547 241.337l-1.146-.038" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1354.76 241.625l-.232-.065" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1354.528 241.56l-.454-.063" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1345.24 241.252l-1.236.017" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1340.748 317.097v-.312" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1339.824 248.298l-.09-6.745" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1355.697 317.107v-.365" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1354.845 248.149l-.08-6.46" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1389.98 767.768l.742.95" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1390.722 768.718l1.402.894" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1394.147 770.42l2.582.7" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1396.728 771.12l3.065.566" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1389.914 766.79l.065.978" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1399.793 771.686l3.451.415" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1406.976 772.352l3.894.079" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1414.804 772.334l3.85-.269" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1418.655 772.065l3.645-.432" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1422.3 771.633l3.326-.581" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1410.87 772.43l3.935-.096" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1392.124 769.612l2.022.809" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1425.626 771.052l2.904-.712" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1428.53 770.34l2.394-.82" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1430.924 769.52l1.813-.9" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1433.916 767.665l.513-.978" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1432.737 768.62l1.18-.955" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1401.418 317.931v-.196" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1415.215 317.936v-.168" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1711.724 779.392l1.02-5.957" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1711.06 786.09l.664-6.698" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1728.159 850.357l-5.175-1.575" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1733.363 850.462l-5.204-.105" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1720.727 845.84l-2.152-4.137" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1715.74 764.423l1.894-2.774" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1717.633 761.649l2.09-1.482" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1713.34 823.598l-1.177-7.326" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1710.77 793.335l.29-7.246" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1712.745 773.435l1.35-5.04" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1716.59 836.505l-1.76-6.096" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1712.163 816.272l-.834-7.63" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1710.86 800.924l-.09-7.589" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1714.095 768.394l1.645-3.97" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1722.984 848.782l-2.257-2.942" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1711.329 808.643l-.469-7.719" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1718.575 841.703l-1.984-5.198" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1719.723 760.167l2.226-.129" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1714.83 830.409l-1.49-6.81" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1724.703 750.506l-2.391 3.507" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1734.354 861.246l2.885.4" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1738.846 757.876l-2.839-4.819" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1737.24 861.647l2.762-1.346" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1727.346 748.6l-2.643 1.906" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1730.857 772.855l10.653-8.66" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1741.51 764.195l-2.664-6.319" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1736.007 753.057l-2.923-3.19" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1733.084 749.866l-2.917-1.491" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1730.167 748.375l-2.82.224" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1731.438 859.14l2.916 2.106" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1738.438 849.074l1.564 11.227" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1875.152 760.738l2.606 4.97" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1868.365 751.781l-3.466-4.719" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1852.618 779.012l.884-6.605" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1865.803 854.138l3.406-1.367" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1861.32 743.948l-3.58-1.439" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1864.9 747.062l-3.58-3.114" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1871.605 757.981l-3.24-6.2" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1877.758 765.708l-6.153-7.726" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1872.36 756.936l2.792 3.802" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1872.097 756.706l.263.23" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1853.502 772.407l1.263-5.58" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1852.203 786.153l.415-7.14" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1852.268 793.626l-.065-7.473" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1852.812 801.22l-.544-7.594" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1853.818 808.723l-1.006-7.502" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1855.262 815.92l-1.444-7.197" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1857.103 822.605l-1.841-6.685" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1859.293 828.58l-2.19-5.975" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1861.771 833.669l-2.478-5.088" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1864.468 837.709l-2.697-4.04" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1867.306 840.568l-2.838-2.86" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1867.402 842.036l-.096-1.468" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1737.24 861.647l128.563-7.51" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1740.002 860.3l129.207-7.53" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1730.167 748.375l127.574-5.866" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1741.51 764.195l130.095-6.214" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1733.084 749.866l128.237-5.918" fill="none" stroke="#000" stroke-width=".706"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1722.984 848.782l68.666-3.908" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1808.925 843.89l58.381-3.322" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1722.312 754.013l-6.572 10.41" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1722.984 848.782l8.454 10.357" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1872.36 756.936l-7.46-9.874" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1806.738 824.933l-1.073 6.494" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1807.366 817.69l-.628 7.243" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1801.662 772.374l1.95 6.506" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1803.611 778.88l1.61 7.248" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1806.435 793.888l.777 8.022" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1807.526 809.931l-.16 7.76" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1800.5 769.467l1.162 2.907" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1802.343 841.378l-2.135 3.158" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1800.208 844.536l-1.42 1.096" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1805.665 831.427l-1.482 5.538" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1779.87 771.642l.293-1.186" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1804.183 836.965l-1.84 4.413" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1807.212 801.91l.314 8.021" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1805.22 786.128l1.215 7.76" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1809.14 769.047l1.177 2.903" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1350.261 317.096l1.1.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1354.614 317.103h.397" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1358.763 317.115l1.259.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1360.022 317.12l2.556.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1327.462 317.149l2.628-.019" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1330.09 317.13h.143" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1500.535 317.268h.461" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1518.141 317.343l1.34.018" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1519.482 317.36l.459.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1410.294 317.93h.691" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1410.985 317.93h.433" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1415.215 317.936l3.523.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1392.316 317.958l.946-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1393.262 317.954l2.214-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1425.187 317.976l2.231.017" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1427.418 317.993l.25.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1462.823 183.935l.67-11.336" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1463.494 172.599l.023-.383" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1552.305 838.457l1.073 6.96" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1553.531 761.87l-1.1 4.047" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1562.524 771.978l-.29-.503" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1562.234 771.475l-1.315-2.287" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1564.033 775.934l-.256-.67" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1563.777 775.264l-1.253-3.286" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1560.919 769.188l-.3-.264" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1560.62 768.924l-1.3-1.15" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1296.86 1013.194l-.982-56.427" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1605.583 792.571l.03-5.056" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1600.095 279.286l4.176.685" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1242.374 278.097l10.69-.633" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1496.636 248.754h.05" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1387.063 247.828l1.291.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1460.873 318.34l-22.864.018" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1438.01 318.358l-6.698.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1521.382 318.127l13.367-.101" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1534.75 318.026l5.278-.04" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1558.753 317.58l-2.772.118" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1555.98 317.698l-2.239.096" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1540.748 317.157l-1.028-.015" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1515.907 316.95l3.847.031" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1519.754 316.981l19.966.16" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1484.16 316.799l-22.805-.059" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1413.456 316.677l-6.569-.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1305 316.961h-.133" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1304.867 316.962l-17.949.182" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1286.918 317.144l-1.15.011" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1398.32 317.43l-4.775.029" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1393.545 317.459l-.957.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1405.445 317.408l-6.043.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1399.401 317.427l-1.082.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1413.461 317.397l-6.993.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1406.468 317.407h-1.023" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1421.798 317.4l-7.52-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1414.278 317.397h-.817" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1429.854 317.416l-7.533-.015" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1422.321 317.4h-.523" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1437.057 317.443h-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1442.901 317.48h-.133" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1442.768 317.48l-5.711-.037" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1446.992 317.525l-.14-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1446.852 317.523l-3.95-.043" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1449.065 317.573h-.057" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1449.008 317.571l-2.016-.046" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1422.324 317.77l5.108-.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1427.432 317.764l2.65-.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1415.214 317.768l7.11.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1406.472 317.754h.231" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1399.404 317.728l2.012.007" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1389.3 317.65l3.913.039" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1393.213 317.689l.334.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1388.64 317.506l-1.674.041" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1386.966 317.547l-.234.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1392.588 317.464l-3.29.035" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1389.299 317.499l-.659.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1287.544 317.807l14.76.154" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1302.304 317.96l3.539.037" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1505.991 800.276l1.556-.201" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1507.547 800.075l2.23-.288" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1475.599 796.492l.193.116" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1475.792 796.608l1.48.894" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1492.122 317.268h.254" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1492.376 317.268h.79" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1474.346 794.314l-1.913-476.002" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1472.433 318.312v-.897" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1521.63 794.197l-.248-476.07" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1521.382 318.127v-.71" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1368.38 806.418l2.025-.52" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1370.405 805.899l1.21-.31" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1337.167 806.497l.883.181" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1338.05 806.678l2.344.48" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1341.495 317.096h.543" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1341.395 317.096h.1" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1330.37 802.591l-7.107-484.483" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1323.263 318.108l-.013-.887" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1378.488 801.333l-5.366-483.034" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1373.122 318.3l-.012-1.05" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1403.244 772.101l1.855.125" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1405.1 772.226l1.876.126" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1434.43 766.687l-3.118-448.324" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1431.312 318.363v-.302" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1402.894 317.929h.657" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1399.04 317.935l2.378-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1389.914 766.79l-4.59-448.464" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1385.323 318.326v-.267" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1728.77 767.777l1.965 4.783" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1730.735 772.56l.122.295" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1724.246 761.284l.044.05" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1724.29 761.334l2.253 2.55" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1738.438 849.074l-3.217.88" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1735.22 849.954l-1.857.508" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1721.95 760.038l.04.022" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1721.99 760.06l2.256 1.224" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1728.72 767.69l.05.087" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1867.349 841.721l.053.315" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1867.402 842.036l1.807 10.735" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1738.438 849.074l54.66-3.118" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1777.894 791.815l.035-1.89" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1777.93 789.925l.032-1.799" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1787.127 838.912l-.515-1.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1786.612 837.886l-.646-1.288" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1778.687 807.015l-.177-1.897" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1778.51 805.118l-.179-1.916" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1778.801 777.746l.258-1.672" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1779.059 776.074l.227-1.477" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1778.137 784.535l.146-1.806" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1778.283 782.729l.134-1.662" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1792.223 845.38l-.573-.506" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1791.65 844.874l-.815-.72" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1784.858 834.03l-.482-1.289" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1784.376 832.741l-.566-1.512" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1781.093 821.642l-.357-1.69" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1780.736 819.952l-.384-1.82" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1779.7 814.507l-.272-1.82" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1779.428 812.687l-.284-1.894" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1778.08 799.38l-.074-1.921" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1778.006 797.459l-.072-1.884" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1789.57 842.706l-.517-.73" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1789.053 841.975l-.722-1.021" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1782.828 828.215l-.428-1.513" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1782.4 826.702l-.48-1.69" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1813.92 785.688l.379 2.39" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1814.299 788.078l.85 5.362" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1812.85 836.478l-1.8 4.252" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1811.05 840.73l-.067.159" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1812.29 778.448l.575 2.552" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1812.865 781l1.055 4.688" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1815.445 824.456l-.074.438" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1815.371 824.894l-1.017 6.05" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1810.984 840.889l-2.06 3.002" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1808.925 843.89l-.107.156" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1816.084 817.22l-.073.823" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1816.012 818.043l-.567 6.413" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1810.317 771.95l.773 2.546" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1811.09 774.496l1.2 3.952" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1815.148 793.44l.205 2.091" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1815.353 795.531l.58 5.923" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1815.934 801.454l.067 1.698" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1816 803.152l.25 6.316" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1816.25 809.468l-.028 1.259" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1816.222 810.727l-.138 6.492" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1812.868 836.412l-.018.066" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1808.818 844.046l-1.32 1.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1807.499 845.05l-.122.092" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1347.52 317.095h1.312" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1348.832 317.095h1.293" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1350.126 317.096h.135" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1355.011 317.105h.451" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1499.367 317.267h1.168" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1503.247 317.272h.548" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1503.795 317.273h.338" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1409.11 317.929h1.185" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1414.967 317.936h.076" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1415.043 317.936h.172" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1563.814 859.955l-.03 11.606" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1563.784 871.561l-.24 92.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1563.544 963.566l-.08 30.554" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1295.878 956.767l-11.13-639.018" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1605.612 787.515l.051-8.962" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1605.154 868.16l.017-2.976" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1605.171 865.184l.026-4.68" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1605.198 860.504l.018-3.236" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1460.166 248.216l2.957.043" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1463.123 248.259l26.25.387" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1371.466 247.983l2.718-.027" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1374.184 247.956l5.749-.057" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1379.933 247.9l7.13-.072" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1388.354 247.831l10.52.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1554.173 317.357h-.01" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1554.163 317.357l-13.415-.2" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1503.246 316.89l.548.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1503.794 316.893l.338.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1446.987 316.703h-.18" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1421.792 316.683l-8.336-.007" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1406.887 316.671h-1.449" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1405.438 316.673l-7.126.007" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1398.312 316.68l-5.732.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1342.867 316.779l-.833.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1342.034 316.781h-.643" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1332.866 316.808l-2.97.016" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1329.895 316.824l-24.895.137" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1437.049 317.443l-6.97-.027" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1430.08 317.416h-.226" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1415.042 317.768h.172" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1414.094 317.768h.187" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1488.213 317.275l1.352-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1495.562 317.266h1.12" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1342.038 317.095h.833" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1340.96 317.096h.435" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1726.543 763.885l.013.023" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1726.557 763.908l2.164 3.783" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1793.097 845.956l5.69-.324" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1730.857 772.855l49.306-2.399" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1780.163 770.456l20.337-.99" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1800.5 769.467l8.64-.42" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1814.354 830.944l-.036.134" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1814.318 831.078l-1.45 5.334" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1345.842 317.094h.386" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1346.228 317.094h1.291" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1351.36 317.098l1.14.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1352.5 317.1l1.008.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1353.508 317.102h.845" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1354.353 317.103h.261" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1355.462 317.106h.234" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1355.697 317.107l3.066.008" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1498.123 317.266h1.244" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1502.505 317.27h.742" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1504.133 317.274h.118" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1504.25 317.274h.93" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1406.918 317.927h.983" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1407.9 317.928h1.21" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1411.418 317.93l1.028.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1414.095 317.934h.57" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1414.666 317.935h.301" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1563.815 859.549v.406" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1284.748 317.749l-.01-.57" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1605.663 778.553l.045-7.871" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1604.316 1015.636l.831-146.274" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1605.147 869.362l.01-1.203" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1500.398 248.864l1.062.031" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1443.144 247.964l2.492.037" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1489.373 248.646h.095" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1398.874 247.857l11.867.028" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1502.505 316.887l.741.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1504.132 316.894h.118" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1504.25 316.895l11.657.055" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1461.354 316.74l-12.293-.031" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1449.06 316.709l-2.073-.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1429.849 316.69l-8.057-.006" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1386.723 316.69l-18.92.017" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1341.391 316.783h-.435" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1414.28 317.768h.385" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1414.665 317.768h.377" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1401.416 317.735h.028" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1401.444 317.735h.238" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1401.682 317.736l.44.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1493.165 317.268h1.159" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1494.324 317.267h1.238" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1344.999 317.094h.843" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1337.36 317.102l3.388-.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1340.748 317.097h.213" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1403.55 317.929h.946" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1404.496 317.928h1.062" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1405.558 317.928h1.147" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1406.705 317.927h.213" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1401.418 317.931h.028" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1401.446 317.931h.238" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1401.684 317.931h.44" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1798.787 845.632l8.59-.49" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1809.14 769.047l45.625-2.22" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1854.765 766.827l22.993-1.119" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1496.683 317.266h.158" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1496.841 317.266h1.282" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1500.996 317.268h.596" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1501.592 317.27h.913" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1412.446 317.932h.902" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1413.348 317.933h.747" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1284.51 304.115l-.45-25.802" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1605.802 754.092l2.69-473.429" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1499.224 248.83l1.174.034" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1503.123 248.944l.55.016" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1503.674 248.96l.34.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1445.636 248.001l8.578.127" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1454.214 248.128l5.952.087" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1489.468 248.648l.317.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1340.475 248.292l.647-.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1341.121 248.285l.837-.008" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1435.01 247.944l8.134.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1437.052 316.695l-7.203-.006" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1392.58 316.684l-3.948.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1388.632 316.688l-1.909.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1367.803 316.707l-7.946.023" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1344.994 316.773l-1.13.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1343.864 316.776l-.997.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1340.956 316.784h-.212" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1340.744 316.785l-7.878.023" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1412.445 317.765l.901.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1413.346 317.766h.748" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1402.121 317.738l.629.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1489.565 317.272h.095" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1490.502 317.27h.723" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1491.225 317.27h.897" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1342.871 317.095h.997" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1343.868 317.094h1.13" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1402.123 317.93h.629" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1402.752 317.93h.142" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1807.377 845.142l6.764-.386" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1814.141 844.756l53.207-3.035" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1563.854 844.516l-.014 5.477" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1563.84 849.993l-.011 4.422" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1284.738 317.18l-.02-1.127" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1605.793 755.741l.01-1.649" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1496.685 248.755l1.288.038" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1497.973 248.793l1.25.036" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1501.46 248.895l.918.027" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1502.378 248.922l.745.022" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1504.013 248.97l.118.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1504.131 248.973l38.678 1.133" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1340.039 248.296l.436-.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1410.742 247.885l12.347.03" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1423.09 247.915l11.92.03" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1492.12 316.837l1.044.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1446.806 316.703l-3.91-.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1442.897 316.7l-5.845-.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1409.109 317.759l1.184.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1410.293 317.76l1.124.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1411.417 317.763l1.028.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1402.75 317.74l.8.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1489.66 317.272h.315" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1489.975 317.272h.527" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1563.87 838.155l-.016 6.36" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1563.828 854.415l-.01 3.223" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1563.82 857.638v1.91" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1284.718 316.053l-.207-11.883" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1284.511 304.17v-.055" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1605.774 759.115l.019-3.374" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1491.943 248.684l1.048.016" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1495.4 248.736l1.235.018" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1333.574 248.36l6.25-.062" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1339.824 248.298l.214-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1341.958 248.277l1.002-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1342.96 248.267l1.135-.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1484.16 316.799l5.404.026" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1493.164 316.842l1.159.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1494.323 316.847l1.238.006" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1501.591 316.882l.914.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1350.121 316.758l-1.293.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1348.828 316.762l-1.313.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1406.703 317.754l1.196.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1407.9 317.756l1.208.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1405.556 317.75l.915.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1563.889 831.101l-.018 7.054" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1605.708 770.682l.037-6.542" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1605.745 764.14l.028-5.025" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1489.785 248.653l.53.008" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1492.991 248.7l1.164.017" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1494.156 248.717l1.244.019" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1346.628 248.23l1.32-.013" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1347.947 248.217l1.3-.012" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1500.534 316.877l1.057.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1351.357 316.755l-1.236.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1347.515 316.766l-1.291.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1346.224 316.77l-1.23.003" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1403.55 317.743l.944.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1404.494 317.746l1.062.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1563.909 823.56l-.02 7.541" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1490.315 248.66l.726.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1491.041 248.671l.901.013" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1344.095 248.256l1.236-.013" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1345.331 248.243l1.297-.012" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1349.247 248.205l1.241-.013" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1489.564 316.825h.095" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1490.5 316.83l.724.003" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1491.224 316.833l.897.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1499.366 316.871l1.168.006" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1489.659 316.825l.315.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1489.974 316.827l.527.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1498.122 316.866l1.244.006" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1354.349 316.746l-.846.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1353.503 316.749l-1.008.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1352.495 316.751l-1.138.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1563.97 800.19l-.02 7.694" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1563.95 807.884l-.02 7.864" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1563.93 815.748l-.022 7.812" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1350.488 248.192l1.145-.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1351.633 248.18l1.012-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1352.645 248.17l.85-.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1495.56 316.853l1.28.006" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1496.84 316.86l1.282.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1355.007 316.744l-.658.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1563.989 792.88l-.02 7.31" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1353.495 248.162l.661-.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1355.458 316.743h-.451" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1564.006 786.16l-.017 6.72" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1354.156 248.156l.454-.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1359.857 316.73l-4.165.012" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1355.692 316.742h-.234" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1564.022 780.226l-.015 5.935" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1354.61 248.151l.235-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1354.845 248.149l16.621-.166" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1564.033 775.934l-.011 4.292" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1564.063 764.22l-.03 11.714" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1565.327 279.77v.076" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1564.085 756.01v1.656" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1565.326 279.846l-.065 25.003" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M1564.08 757.666l-.01 3.39" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1564.072 761.055l-.01 3.165" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1565.245 311.09l-1.16 444.92" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1565.258 305.883l-.014 5.208" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1565.261 304.849v.012" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1565.261 304.86v1.023" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1553.378 845.416l1.565 7.786" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M1555.128 758.337l-1.597 3.533" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
        </g>
    </g>

<slot/>
</svg>