<svg width="274.048" height="365.196"
 viewBox="0 0 72.509 96.625" version="1.1" {...$$props} >
    <image width="44.583" height="20.192" preserveAspectRatio="none" xlink:href="/img/steam-cloud.png" x="27.926"
        y="10.731" image-rendering="optimizeQuality" id="steamer__steamCloud" />
    <g id="steamer__waterInlet" transform="translate(-166.125 -5.045)">
        <path opacity=".799" fill="#1f5cec" fill-opacity=".803" paint-order="fill markers stroke"
            d="M173.589 16.824h1.462v39.318h-1.462z" />
        <g fill="#302dd7" fill-opacity=".811">
            <path d="M176.297 13.51l-.381.513.151-.265.28-.375z" />
            <path d="M176.47 13.218l-.173.292.05-.127zm-.403.54l-.151.265-.51.397.122-.243z" />
            <path
                d="M174.23 5.902l.148.87.249.875.35.869.45.854.548.827.416.504.274.594.11.646-.065.656-.24.62-.124.166-.279.375-.54.42-.638.264-.691.09-.693-.095-.642-.271-.544-.425-.408-.547-.164-.42-.08-.206-.069-.66.109-.648.274-.592.417-.5.55-.82.448-.847.347-.864.246-.87.138-.824.007-.042.008-.14.039-.715z" />
            <path d="M175.528 14.177l-.122.243-.603.25.086-.228zm-2.638.233l-.513-.403-.058-.266.544.425z" />
            <path
                d="M172.89 14.41l-.027-.244.642.27-.009.23zm-.571-.67l.058.267-.386-.518-.08-.295zm-.409-.546l.081.295-.232-.594-.012-.121zm2.217-7.251l.012-.225.003.042-.008.141z" />
            <path
                d="M174.14 5.718l.04-.672-.038.714zm.75 8.724l-.087.229-.653.084.048-.224zm-3.143-1.668l.012.121-.093-.327z" />
            <path d="M174.15 14.755l-.654-.09.01-.228.692.094z" />
            <path
                d="M175.916 14.023l.381-.513m0 0l.173-.292m-2.24-7.316l.148.87m1.048 2.598l.55.827m-3.599 3.81l.514.403"
                stroke="#000" stroke-width=".021" stroke-miterlimit="10" />
            <path d="M172.319 13.74l.544.426" stroke="#000" stroke-width=".01" stroke-miterlimit="10" />
            <path d="M174.627 7.647l.35.869m-.837-2.798l-.013.225m.013-.225l.04-.672m.623 9.624l.603-.25" stroke="#000"
                stroke-width=".021" stroke-miterlimit="10" />
            <path d="M174.89 14.442l.638-.265m-3.781-1.403l.012.121" stroke="#000" stroke-width=".01"
                stroke-miterlimit="10" />
            <path d="M171.76 12.895l-.094-.327m-.068-.66l.068.66m.094.327l.231.594" stroke="#000" stroke-width=".021"
                stroke-miterlimit="10" />
            <path d="M174.14 5.718l.002.042m2.155 7.75l.05-.127" stroke="#000" stroke-width=".01"
                stroke-miterlimit="10" />
            <path d="M175.406 14.42l.51-.397m-2.42.643l.654.09m-2.159-1.266l.386.517m4.288-2.712l-.274-.594"
                stroke="#000" stroke-width=".021" stroke-miterlimit="10" />
            <path d="M173.505 14.437l.693.094" stroke="#000" stroke-width=".01" stroke-miterlimit="10" />
            <path d="M171.707 11.26l-.109.648" stroke="#000" stroke-width=".021" stroke-miterlimit="10" />
            <path d="M172.863 14.166l.642.27" stroke="#000" stroke-width=".01" stroke-miterlimit="10" />
            <path
                d="M176.391 10.701l-.416-.504m.495 3.021l.24-.621m-4.73-1.929l-.273.592m2.036-3.623l-.347.864m0-.001l-.449.848m2.029-.832l.45.854"
                stroke="#000" stroke-width=".021" stroke-miterlimit="10" />
            <path d="M175.528 14.177l.54-.419" stroke="#000" stroke-width=".01" stroke-miterlimit="10" />
            <path d="M173.989 6.768l-.246.87m-1.345 2.53l-.417.5m.966-1.32l-.549.82" stroke="#000" stroke-width=".021"
                stroke-miterlimit="10" />
            <path d="M174.198 14.531l.691-.089" stroke="#000" stroke-width=".01" stroke-miterlimit="10" />
            <path d="M174.18 5.046l.05.856m.148.87l.249.875" stroke="#000" stroke-width=".021" stroke-miterlimit="10" />
            <path d="M171.91 13.194l.409.547" stroke="#000" stroke-width=".01" stroke-miterlimit="10" />
            <path d="M176.71 12.597l.065-.656m0-.001l-.11-.645m-2.515 3.46l.653-.084m-1.913-.261l.606.256" stroke="#000"
                stroke-width=".021" stroke-miterlimit="10" />
            <path
                d="M176.067 13.758l.28-.375m-.001 0l.124-.165m-2.29-8.172l-.038.714m0 0l-.008.141m-2.468 6.667l.08.206m.001 0l.164.42"
                stroke="#000" stroke-width=".01" stroke-miterlimit="10" />
            <path d="M174.134 5.901l-.007.042m0 0l-.138.825" stroke="#000" stroke-width=".021" stroke-miterlimit="10" />
        </g>
    </g>
    <image width="29.788" height="29.788" preserveAspectRatio="none" xlink:href="/img/steam-power.png" x="-.515"
        y="57.383" image-rendering="optimizeQuality" id="steamer__power" />

<slot/>
</svg>