<script lang="ts">
  export let run: boolean = true;
</script>

<!--
  Src: docs/Design/Grafiske elementerSVG/proofingStatus.svg
  inkscape:label="proofFan"
--->

<svg class:run viewBox="0 0 745.15871 745.1587" width="150" {...$$restProps}>
  <g transform="translate(-8805.3181,-188.06586)">
    <path
      d="m 9165.2255,621.02472 -3.3577,-0.44239 -3.4551,-1.43086 0.038,-0.0706 z"
      style="fill:#616161;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9180.6066,755.42281 -7.0901,-32.55892 193.3225,164.60046 z"
      style="fill:#5d5d5d;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="M 9366.839,887.46435 9173.5165,722.86389 9169.135,690.05098 Z"
      style="fill:#5e5e5e;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9366.839,887.46435 -197.704,-197.41337 -1.6729,-32.85349 z"
      style="fill:#5e5e5e;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9180.6066,755.42281 186.2324,132.04154 -89.1091,45.76022 z"
      style="fill:#585858;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9180.6066,755.42281 97.1233,177.80176 -22.3132,-26.46363 -19.9944,-28.01443 -17.562,-29.35711 -15.0365,-30.48494 -12.4361,-31.39545 z"
      style="fill:#5f5f5f;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9266.4096,593.35565 27.4828,14.5034 142.8355,212.06847 z"
      style="fill:#5f5f5f;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="M 9266.4096,593.35565 9436.7279,819.92752 9237.7866,580.66235 Z"
      style="fill:#5f5f5f;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="M 9237.7866,580.66235 9436.7279,819.92752 9236.4245,580.16564 Z"
      style="fill:#5f5f5f;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9236.4245,580.16564 200.3034,239.76188 -200.4713,-239.48636 0.092,-0.32244 z"
      style="fill:#5f5f5f;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9236.2566,580.44116 200.4713,239.48636 -202.0214,-234.31217 0.1252,-0.20849 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9236.4245,580.16564 -0.075,-0.0469 1.9551,-6.81425 -0.4487,3.40642 z"
      style="fill:#616161;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9234.7065,585.61535 202.0214,234.31217 -210.2933,-220.58523 0.05,-0.049 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9436.7279,819.92752 -142.8355,-212.06847 26.229,16.22883 z"
      style="fill:#5e5e5e;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9436.7279,819.92752 -116.6065,-195.83964 24.8708,17.86396 z"
      style="fill:#5e5e5e;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9436.7279,819.92752 -91.7357,-177.97568 115.8963,135.81274 z"
      style="fill:#585858;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9366.839,887.46435 -199.3769,-230.26686 1.0185,-32.6831 z"
      style="fill:#5f5f5f;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="M 9226.4346,599.34229 9436.7279,819.92752 9214.9001,610.47807 Z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9460.8885,777.76458 -115.8963,-135.81274 23.416,19.40278 21.8729,20.83999 20.2509,22.17138 18.5582,23.39411 16.8032,24.50429 z"
      style="fill:#5f5f5f;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9366.839,887.46435 -198.3584,-262.94996 0.2822,-2.48214 0.4646,0.008 z"
      style="fill:#5f5f5f;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9366.839,887.46435 -197.6116,-265.42399 15.7089,0.27658 0.267,-0.0667 z"
      style="fill:#616161;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9366.839,887.46435 -181.6357,-265.21409 15.4541,-3.85092 0.088,-0.0487 z"
      style="fill:#616161;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="M 9366.839,887.46435 9200.7456,618.35066 9214.77,610.57932 Z"
      style="fill:#616161;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9366.839,887.46435 -152.069,-276.88503 0.059,-0.0328 0.071,-0.0684 221.8278,209.44945 z"
      style="fill:#595959;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9117.996,544.59471 1.4312,-3.45511 0.062,0.0434 -1.929,6.72254 z"
      style="fill:#616161;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9193.9839,500.72291 3.4548,1.43122 -0.027,0.0737 -6.7874,-1.94733 z"
      style="fill:#616161;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9045.099,480.27591 -21.5304,-23.11329 -19.9506,-24.48983 -18.2831,-25.75913 -16.5346,-26.91447 -14.7141,-27.95129 -12.828,-28.86569 -10.8864,-29.65273 z"
      style="fill:#616161;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9078.065,188.06586 22.3189,26.46504 20,28.01619 17.5676,29.35852 15.0421,30.48635 12.4422,31.39687 9.7867,32.08796 7.0958,32.55997 4.3871,32.81504 1.6785,32.85454 -1.0131,32.68451 -0.2696,2.47897 -0.2628,-0.005 z"
      style="fill:#5f5f5f;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="M 9118.19,540.37196 9092.5141,521.97248 8951.9738,263.48481 Z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="M 9119.4272,541.1396 9118.19,540.37196 9009.4216,217.19225 Z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 8930.3718,293.52948 21.602,-30.04467 93.1252,216.7911 z"
      style="fill:#5d5d5d;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 8951.9738,263.48481 57.4478,-46.29256 108.7684,323.17971 z"
      style="fill:#5d5d5d;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9068.1146,501.91107 -23.0156,-21.63516 -93.1252,-216.7911 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="M 9092.5141,521.97248 9068.1146,501.91107 8951.9738,263.48481 Z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9009.4216,217.19225 68.6434,-29.12639 108.7734,311.19937 -0.1527,-0.004 -177.2641,-282.07052 z"
      style="fill:#5c5c5c;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9119.4889,541.18299 -0.062,-0.0434 -110.0056,-323.94735 110.2765,323.26155 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9119.6981,540.4538 -110.2765,-323.26155 111.8534,318.25458 -0.2691,0.44803 z"
      style="fill:#5f5f5f;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9121.275,535.44683 -111.8534,-318.25458 120.1458,304.60843 -0.2148,0.20778 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9129.5674,521.80068 -120.1458,-304.60843 131.6937,293.50404 -0.1066,0.0589 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9141.1153,510.69629 -131.6937,-293.50404 145.7176,285.7327 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9155.1392,502.92495 -145.7176,-285.7327 161.2691,281.84509 -15.5102,3.86503 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9009.4216,217.19225 177.2641,282.07052 -15.784,-0.27799 -0.211,0.0526 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9395.057,277.72327 42.1556,24.14834 -177.9796,91.72822 z"
      style="fill:#585858;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9259.233,393.59983 177.9796,-91.72822 -195.8457,116.59552 z"
      style="fill:#5e5e5e;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9395.057,277.72327 -135.824,115.87656 19.4046,-23.41245 20.8417,-21.8694 22.1735,-20.24768 23.3959,-18.5547 24.506,-16.80033 z"
      style="fill:#5f5f5f;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9241.7874,570.09772 -2.4877,-0.26459 0.01,-0.47801 265.4254,-197.62293 z"
      style="fill:#5f5f5f;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9340.131,565.05123 -32.8112,4.38538 197.4137,-197.70442 z"
      style="fill:#5e5e5e;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9504.7335,371.73219 -197.4137,197.70442 -32.8514,1.6764 z"
      style="fill:#5e5e5e;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="M 9340.131,565.05123 9504.7335,371.73219 9372.6874,557.95758 Z"
      style="fill:#5d5d5d;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="M 9241.3669,418.46713 9437.2126,301.87161 9225.136,444.69298 Z"
      style="fill:#5e5e5e;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="M 9225.136,444.69298 9437.2126,301.87161 9210.6308,472.17226 Z"
      style="fill:#5f5f5f;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="M 9210.6308,472.17226 9437.2126,301.87161 9197.9354,500.79206 Z"
      style="fill:#5f5f5f;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="M 9197.9354,500.79206 9437.2126,301.87161 9197.4387,502.15413 Z"
      style="fill:#5f5f5f;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9504.7335,371.73219 45.7433,89.08062 -177.7894,97.14477 z"
      style="fill:#585858;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9504.7335,371.73219 -230.2651,199.38082 -32.681,-1.01529 z"
      style="fill:#5f5f5f;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9504.7335,371.73219 -265.4254,197.62293 0.2766,-15.72154 -0.064,-0.25682 z"
      style="fill:#616161;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9372.6874,557.95758 177.7894,-97.14477 -26.4615,22.31672 -28.0127,19.99792 -29.3546,17.56586 -30.4828,15.03962 -31.3934,12.44 z"
      style="fill:#5f5f5f;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9504.7335,371.73219 -265.2127,181.64457 -3.8533,-15.46437 -0.044,-0.0794 z"
      style="fill:#616161;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9504.7335,371.73219 -269.1101,166.10083 -7.7714,-14.02433 z"
      style="fill:#616161;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9504.7335,371.73219 -276.8815,152.0765 -0.037,-0.0677 -0.062,-0.0642 209.4601,-221.80514 z"
      style="fill:#595959;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9437.2126,301.87161 -209.4601,221.80514 -11.1354,-11.53442 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9437.2126,301.87161 -220.5955,210.27072 -0.056,-0.0579 -13.6691,-8.21584 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9437.2126,301.87161 -234.3203,201.99702 -0.2177,-0.13053 -4.5942,-1.31833 -0.3231,-0.1337 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9437.2126,301.87161 -239.4553,200.41446 -0.3186,-0.13194 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 8983.1417,563.34202 32.5621,-7.09224 32.8168,-4.38362 32.8567,-1.67499 32.6863,1.01636 2.474,0.28151 v 0.25259 l -311.2149,108.73599 26.4668,-22.31531 28.0187,-19.99651 29.3602,-17.5641 30.4889,-15.03821 31.3989,-12.43859 z"
      style="fill:#5f5f5f;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 8969.3206,784.49205 -28.8653,12.82841 -29.6528,10.88637 186.7465,-114.72756 -23.1133,21.53073 -24.4902,19.95029 -25.7588,18.28306 -26.9145,16.53505 z"
      style="fill:#616161;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9119.1841,670.4637 -21.6351,23.01557 -216.795,93.11851 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9157.645,620.38866 -18.3998,25.67587 -258.4912,140.53325 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9158.4511,619.08091 -0.038,0.0706 -323.9597,109.98411 323.2851,-110.25928 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9157.7381,618.8763 -323.2851,110.25928 318.2729,-111.83443 0.4371,0.26282 z"
      style="fill:#5f5f5f;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9152.7259,617.30115 -318.2729,111.83443 304.6232,-120.12542 0.2,0.20708 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="M 9139.2452,646.06453 9119.1841,670.4637 8880.754,786.59778 Z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 8880.754,786.59778 -46.301,-57.4622 323.192,-108.74692 z"
      style="fill:#5d5d5d;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9139.0762,609.01016 -304.6232,120.12542 293.516,-131.67219 0.054,0.0977 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 8880.754,786.59778 216.795,-93.11851 -186.7465,114.72756 z"
      style="fill:#5d5d5d;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 8834.453,729.13558 323.9597,-109.98411 -0.7335,1.1818 -0.013,0.0201 -0.021,0.0353 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 8834.453,729.13558 -29.1349,-68.65796 311.2149,-108.73599 v 0.15452 l -282.0776,177.23943 z"
      style="fill:#5c5c5c;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="M 9127.969,597.46339 8834.453,729.13558 9120.198,583.43907 Z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 8834.453,729.13558 282.0775,-177.23943 -0.2776,15.77199 0.055,0.22084 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 8834.453,729.13558 281.8549,-161.2466 3.8622,15.50035 0.028,0.0497 z"
      style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
    <path
      d="m 9186.8384,499.26523 0.2628,0.005 3.5229,1.01071 6.7874,1.94733 0.6689,0.19191 4.5942,1.31833 0.2177,0.13053 13.6691,8.21584 0.056,0.0579 11.1354,11.53442 0.062,0.0642 0.037,0.0677 7.7714,14.02433 0.044,0.0794 3.8533,15.46437 0.064,0.25682 -0.2766,15.72154 -0.01,0.47801 -0.9956,3.47134 -1.9551,6.81425 -0.092,0.32244 -1.4249,4.9657 -0.1252,0.20849 -8.2215,13.6779 -0.05,0.049 -11.5345,11.13578 -0.071,0.0684 -0.059,0.0328 -14.0244,7.77134 -0.088,0.0487 -15.4541,3.85092 -0.267,0.0667 -15.7089,-0.27658 -0.4646,-0.008 -0.026,-3.5e-4 -3.5112,-1.00718 -6.7744,-1.94381 -0.713,-0.20461 -4.5751,-1.31233 -0.4371,-0.26282 -13.4497,-8.08391 -0.2,-0.20708 -11.0529,-11.44905 -0.054,-0.0977 -7.771,-14.02432 -0.028,-0.0497 -3.8622,-15.50035 -0.055,-0.22084 0.2776,-15.77199 v -0.15452 -0.25259 -0.0205 l 1.022,-3.56305 1.929,-6.72254 0.2092,-0.72919 1.3078,-4.55894 0.2691,-0.44803 8.0776,-13.43837 0.2148,-0.20778 11.4413,-11.04547 0.1066,-0.0589 14.0239,-7.77134 0.041,-0.0226 15.5102,-3.86503 0.211,-0.0526 15.784,0.27799 0.1527,0.004 z"
      style="fill:#616161;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352778"
    />
  </g>
</svg>

<style>
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  svg {
    position: absolute;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-name: rotate;
    animation-duration: 1s;
    animation-play-state: paused;
  }
  svg.run {
    animation-play-state: running;
  }
</style>
