<script lang="ts">
  import { onMount } from "svelte";
  import { readable } from "svelte/store";
  import { tweened } from "svelte/motion";
  import { _, time, json } from "svelte-i18n";
  import AutoGraphSVG from "./auto-graph.svg";
  import hmi, { config } from "@/hmi";
  import { numFmt } from "@/utils";

  let s_curTemp = hmi.getValueStore("cur.T");
  let s_curHumidity = hmi.getValueStore("cur.H");

  let curTempText: any, curHumidityText: any;
  $: {
    let v = $s_curTemp;
    curTempText = v !== null ? $numFmt(v, $config?.tempDecimals) + $config?.tempUnit || "" : "-";
  }
  $: {
    let v = $s_curHumidity;
    curHumidityText = v !== null ? $numFmt(v, 0) + "%" : "-";
  }

  // FIXME: Remove the fixed autoConfig when we can receive it from the server
  const autoConfig = {
    freezing_temp: -5,
    freezing_secs: 8400, // 2t 20m - in secs
    storage_temp: 2,
    thawing_start: 81300, // 22:35 - in secs from 00:00
    thawing_secs: 18900, // 5t 15m - in secs
    proofing_start: 13800, // 03:50 - in secs from 00:00
    proofing_temp: 35,
    proofing_humidity: 85,
    proofing_secs: 2400, // 40m - in secs
    baking_start: 16200, // 04:30 - in secs from 00:00
    yeast_stop_secs: 1200, // 20m - in secs
  };
  $: {
    // FIXME: Remove the conversion when we can receive it from the server
    let f = $json("use_farenheight_temps");
    autoConfig.freezing_temp = f ? 23 : -5;
    autoConfig.storage_temp = f ? 35.6 : 2;
    autoConfig.proofing_temp = f ? 95 : 35;
  }

  $: startFmt = readable((secs: number) => {
    let now = new Date();
    now.setHours(Math.floor(secs / (60 * 60)), Math.floor((secs / 60) % 60));
    return $time(now);
  });

  $: durFmt = readable((secs: number) => {
    const h = Math.floor(secs / (60 * 60));
    const m = Math.floor((secs / 60) % 60);
    const s = secs % 60;
    return `${h ? h + $_("hours")[0] + " " : ""}${h || m ? m + $_("minutes")[0] + " " : ""}${s ? s + $_("seconds")[0] + " " : ""}`;
  });

  interface Phases {
    pathEL: SVGPathElement | undefined;
  }

  let div: HTMLDivElement;
  let phases: Phases[];
  let currentPhase = 0;
  let progress: number = 0;
  let progressTweened = tweened(progress);
  let door: any, clock: any;

  onMount(() => {
    phases = [];
    const svg = div.getElementsByTagName("svg")[0];
    console.assert(svg, "svg element not found in AutoGraph.svelte");
    for (let i = 1; i <= 8; i++) {
      let id = "phase" + i;
      let e = svg.getElementById(id) as SVGPathElement;
      phases.push({ pathEL: e });
    }

    door = svg.getElementById("door");
    console.assert(door, "Cant find door svg elemenr");
    clock = svg.getElementById("clock");
    console.assert(clock, "Cant find clock svg elemenr");

    phases[0].pathEL?.classList.add("active-phase");

    let interval = setInterval(() => {
      progress++;
      if (progress === 101) {
        progress = 0;
        currentPhase = (currentPhase + 1) % phases.length;
        progressTweened.set(0, { duration: 0 });
      } else {
        $progressTweened = progress;
      }
    }, 100);
    return () => clearInterval(interval);
  });

  $: {
    if (phases) {
      door.classList.value = currentPhase === 1 ? "blink" : "";
      clock.classList.value = currentPhase === 6 ? "blink" : "";

      console.log("Switched to phase " + (currentPhase + 1));
      phases.forEach((e, i) => {
        if (!e.pathEL) return;
        e.pathEL.removeAttribute("class");
        if (i < currentPhase) e.pathEL.classList.add("completed-phase");
      });
      if (currentPhase >= 0) phases[currentPhase].pathEL?.classList.add("active-phase");
    }
  }
</script>

<div bind:this={div} class="auto-graph">
  <AutoGraphSVG>
    {#if phases && phases[currentPhase].pathEL}
      <text overflow="show">
        <textPath
          href={`#phase${currentPhase + 1}-path`}
          startOffset={$progressTweened.toFixed(3) + "%"}
          stroke="orange"
          font-size="6"
          overflow="show"
          text-anchor="middle"
        >
          v
        </textPath>
      </text>
    {/if}

    <defs>
      <style>
        #clock,
        #door,
        #phase1,
        #phase3,
        #phase4,
        #phase5,
        #phase6,
        #phase7 {
          opacity: 1;
          transition: fill 1s ease;
        }

        .completed-phase {
          fill: lightgreen !important;
        }
        .active-phase {
          fill: orange !important;
        }
      </style>
    </defs>
  </AutoGraphSVG>

  <div class="abs large" style="top:20px; right:400px">
    {curTempText}
    <br />
    {curHumidityText}
  </div>

  <div class="abs normal" style="top:40px; left:750px" class:active-phase={currentPhase === 6} class:completed-phase={currentPhase > 6}>
    {$_("auto_mode.Baking")}<br />{$startFmt(autoConfig.baking_start)}
  </div>

  <div class="abs small" style="top:152px; right:885px">
    {$numFmt(autoConfig.proofing_temp, 0) + $config?.tempUnit}
    <br />
    {$numFmt(autoConfig.proofing_humidity, 0)}%
  </div>
  <div class="abs small" style="top:336px; right:885px">
    {$numFmt(autoConfig.storage_temp, 0) + $config?.tempUnit}
  </div>
  <div class="abs small" style="top:394px; right:885px">
    {$numFmt(autoConfig.freezing_temp, 0) + $config?.tempUnit}
  </div>

  <div class="abs small" style="top:440px; left:70px" class:active-phase={currentPhase === 0} class:completed-phase={currentPhase > 0}>
    {$_("auto_mode.Pre-cooling")}
  </div>
  <div class="abs small" style="top:440px; left:150px" class:active-phase={currentPhase === 2} class:completed-phase={currentPhase > 2}>
    {$_("auto_mode.Freezing")}
    <br />
    {$numFmt(autoConfig.freezing_temp, 0) + $config?.tempUnit} / {$durFmt(autoConfig.freezing_secs)}
  </div>
  <div class="abs small" style="top:440px; left:270px" class:active-phase={currentPhase === 3} class:completed-phase={currentPhase > 3}>
    {$_("auto_mode.Storage")}
    <br />
    {$numFmt(autoConfig.storage_temp, 0) + $config?.tempUnit}
  </div>
  <div class="abs small" style="top:420px; left:590px" class:active-phase={currentPhase === 4} class:completed-phase={currentPhase > 4}>
    {$startFmt(autoConfig.thawing_start)}
    <br />
    {$_("auto_mode.Thawing")}
    <br />
    {$durFmt(autoConfig.thawing_secs)}
  </div>
  <div class="abs small" style="top:420px; left:680px" class:active-phase={currentPhase === 5} class:completed-phase={currentPhase > 5}>
    {$startFmt(autoConfig.proofing_start)}
    <br />
    {$_("auto_mode.Proofing")}
    <br />
    {$numFmt(autoConfig.proofing_temp, 0) + $config?.tempUnit} / {$numFmt(autoConfig.proofing_humidity, 0)}%
    <br />
    {$durFmt(autoConfig.proofing_secs)}
  </div>
  <div class="abs small" style="top:420px; left:820px" class:active-phase={currentPhase === 7}>
    + {$durFmt(autoConfig.yeast_stop_secs)}
    <br />
    {$_("auto_mode.Yeast-stop")}
  </div>
</div>

<style>
  .auto-graph {
    position: relative;
  }
  .abs {
    position: absolute;
    transition: color 1s ease;
  }
  .normal {
    text-align: left;
    font-size: 20px;
    line-height: 25px;
  }
  .small {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }
  .large {
    text-align: center;
    font-size: 50px;
    line-height: 60px;
    font-weight: bold;
  }
  .completed-phase {
    color: lightgreen;
  }
  .active-phase {
    color: orange;
  }
</style>
