<svg width="274.045" height="365.197" viewBox="0 0 72.508 96.625" version="1.1" {...$$props} >
    <g transform="translate(118.999 71.017)">
        <g transform="matrix(.0713 0 0 .0713 -569.79 -49.366)">
            <path d="M6705.059 797.308l.524-4.737.03-5.056 89.108-4.418 1.51 9.595z" fill="#585858" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6705.583 792.571l-.524 4.737-.823 10.296-.528 10.43-.216 10.213.105 9.653.42 8.772.72 7.604.479 2.992-.018 3.236-.026 4.68-.017 2.975-.01 1.203-.831 146.274-4.055 9.526 4.01-745.19 4.22.691-2.69 473.43-.01 1.648-.018 3.374-.03 5.025-.036 6.542-.045 7.871-.051 8.962z"
                fill="#484848" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6704.236 807.604l.823-10.296 91.171-4.616 1.08 10.097z" fill="#5d5d5d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6703.708 818.034l.528-10.43 93.075-4.815.612 10.265z" fill="#5f5f5f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6703.492 828.247l.215-10.213 94.216-4.98.123 10.09z" fill="#606060" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6703.597 837.9l-.105-9.653 94.554-5.102-.37 9.58z" fill="#5e5e5e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6704.017 846.672l-.42-8.772 94.08-5.175-.849 8.754z" fill="#5b5b5b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6704.737 854.276l-.72-7.604 92.811-5.193-1.296 7.642z" fill="#565656" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6705.216 857.268l-.48-2.992 90.796-5.155-1.698 6.291-88.637 5.092z" fill="#4f4f4f"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6796.23 792.692l-1.51-9.595 4.256 5.206z" fill="#333" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6797.31 802.789l-1.08-10.097 3.928 3.285z" fill="#353535" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6797.923 813.054l-.612-10.265 3.682 1.231z" fill="#373737" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6798.046 823.145l-.123-10.091 3.528-.883z" fill="#373737" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6797.676 832.725l.37-9.58 3.47-2.982z" fill="#363636" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6796.828 841.479l.848-8.754 3.512-4.99z" fill="#343434" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6795.532 849.12l1.296-7.641 3.65-6.84z" fill="#323232" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6793.834 855.412l1.698-6.291 3.88-8.464z" fill="#2e2e2e" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6705.197 860.504l88.637-5.092-2.039 4.752-86.624 5.02z" fill="#474747" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6504.431 77.635l14.392-16.688 2.597-.64-14.35 16.641z" fill="#505b67" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6507.07 76.948l14.35-16.641 4.733 1.8-14.031 16.273z" fill="#667483" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6521.42 60.307l-2.597.64 16.427-14.512 2.55-.599z" fill="#4f5b66" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6584.234 129.782l-6.368 7.405.603-2.306 6.546-7.613z" fill="#384048" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6504.431 77.635l2.639-.687-12.057 18.51-2.672.739z" fill="#505b67" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6585.015 127.268l-6.546 7.613-1.939-4.212 6.99-8.127z" fill="#3f4952" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6583.52 122.542l-6.99 8.127-4.396-5.866 7.67-8.917z" fill="#576470" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6579.804 115.886l-7.67 8.917-6.587-7.133 8.546-9.932z" fill="#6c7c8b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6565.547 117.67l6.587 7.133-6.454 9.907-7.324-6.004z" fill="#6c7c8b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6579.804 115.886l-5.711-8.148 9.749-8.652 4.712 9.03z" fill="#6c7c8c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6574.093 107.738l-8.546 9.932-8.34-7.906 9.557-11.102z" fill="#7d8fa1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6572.134 124.803l4.396 5.866-5.882 9.027-4.968-4.986z" fill="#576470" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6583.52 122.542l-3.716-6.656 8.75-7.77 2.938 7.343z" fill="#586471" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6574.093 107.738l-7.33-9.076 10.903-9.668 6.176 10.092z" fill="#7d8fa1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6566.764 98.662l-9.557 11.102-9.503-8.11 10.63-12.345z" fill="#889cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6558.334 89.31l-10.63 12.344-9.977-7.72 11.688-13.568z" fill="#8ea2b7" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6537.727 93.933l9.977 7.721-8.94 13.723-10.864-6.358z" fill="#8ea2b7" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6558.334 89.31l-8.92-8.944 13.337-11.808 7.71 10.005z" fill="#8ea2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6549.414 80.366l-11.687 13.567-9.717-6.76 12.652-14.682z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6540.662 72.49l-12.652 14.682-8.744-5.31 13.452-15.604z" fill="#8599ac" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6540.662 72.49l-7.944-6.232 15.352-13.573 7.03 7.033z" fill="#8598ab" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6528.01 87.172l9.717 6.761-9.827 15.086-10.525-5.52z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6549.414 80.366l-8.752-7.875 14.438-12.773 7.65 8.84z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6532.718 66.258l-13.452 15.605-7.144-3.483 14.03-16.273z" fill="#78899b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6519.266 81.863l8.744 5.31-10.635 16.326-9.415-4.28z" fill="#8699ac" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6526.153 62.107l-4.733-1.8 16.38-14.47 4.367 2.118z" fill="#657482" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6578.468 134.881l-.602 2.306-5.36 8.225.452-2.074z" fill="#384048" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6584.234 129.782l.781-2.514 7.468-6.636-.985 2.693z" fill="#373f47" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6512.122 78.38l7.144 3.483-11.306 17.356-7.629-2.738z" fill="#78899b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6532.718 66.258l-6.565-4.151 16.014-14.152 5.903 4.73z" fill="#78899a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6566.764 98.662l-8.43-9.353 12.128-10.746 7.204 10.43z" fill="#899cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6507.07 76.948l5.052 1.432-11.79 18.1-5.32-1.021z" fill="#667583" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6547.704 101.654l9.503 8.11-8.038 12.34-10.405-6.727z" fill="#889cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6557.207 109.764l8.34 7.906-7.19 11.036-9.188-6.602z" fill="#7d8fa1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6585.015 127.268l-1.496-4.726 7.973-7.083.99 5.173z" fill="#404952" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6576.53 130.669l1.938 4.212-5.51 8.457-2.31-3.642z" fill="#3f4852" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6570.462 78.563l-7.711-10.005 14.742-9.837 6.376 10.883z" fill="#8ea2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6542.167 47.955l-4.367-2.119 18.108-12.042 3.963 2.382z" fill="#657381" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6548.07 52.685l-5.903-4.73 17.704-11.779 5.17 5.209z" fill="#789" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6583.842 99.086l-6.176-10.092 12.053-8.064 4.9 10.937z" fill="#7d8fa1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6537.8 45.836l-2.55.6 18.162-12.078 2.496-.564z" fill="#4e5a65" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6591.492 115.459l-2.938-7.342 9.673-6.487 2.08 7.913z" fill="#586572" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6588.554 108.117l-4.712-9.03 10.777-7.22 3.608 9.763z" fill="#6d7d8c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6577.666 88.994l-7.204-10.431 13.407-8.959 5.85 11.326z" fill="#899cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6592.483 120.632l-.991-5.173 8.814-5.916.433 5.545z" fill="#414a53" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6562.75 68.558l-7.65-8.84 15.96-10.638 6.433 9.64z" fill="#8ca0b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6591.498 123.325l.985-2.693 8.256-5.544-1.21 2.844z" fill="#363e46" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6555.1 59.718l-7.03-7.033 16.972-11.3 6.018 7.695z" fill="#8598ab" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6589.719 80.93l-5.85-11.326 14.444-7.014 4.392 12.02z" fill="#899cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6555.908 33.794l-2.496.564 19.562-9.43 2.438-.538z" fill="#4d5864" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6571.06 49.08l-6.018-7.695 18.28-8.83 4.93 8.208z" fill="#8497aa" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6577.493 58.72l-6.433-9.64 17.192-8.317 5.123 10.26z" fill="#8ca0b4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6594.62 91.867l-4.901-10.937 12.986-6.32 3.528 11.594z" fill="#7e90a2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6600.306 109.543l-2.08-7.913 10.426-5.091 1.154 8.358z" fill="#596673" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6600.74 115.088l-.434-5.545 9.5-4.646-.167 5.835z" fill="#424b55" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6565.042 41.385l-5.17-5.209 19.067-9.2 4.383 5.58z" fill="#768798" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6559.872 36.176l-3.964-2.382 19.504-9.404 3.527 2.586z" fill="#637280" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6598.227 101.63l-3.608-9.763 11.614-5.663 2.418 10.335z" fill="#6e7d8d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6583.87 69.604l-6.377-10.883 15.882-7.697 4.938 11.566z" fill="#8ea2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6599.53 117.932l1.21-2.844 8.899-4.356-1.452 2.961z" fill="#353d44" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6588.252 40.763l-4.93-8.208 19.254-6.21 3.787 8.563z" fill="#8396a9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6593.375 51.024l-5.123-10.26 18.11-5.856 3.745 10.69z" fill="#8b9fb3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6598.313 62.59l-4.938-11.566 16.732-5.426 3.427 12.04z" fill="#8da2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6575.412 24.39l-2.438.537 20.603-6.626 2.377-.517z" fill="#4c5762" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6578.94 26.976l-3.528-2.586 20.542-6.606 3.07 2.727z" fill="#62707e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6583.322 32.555l-4.382-5.579 20.083-6.465 3.553 5.835z" fill="#758696" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6602.705 74.61l-4.392-12.02 15.221-4.952 2.858 12.504z" fill="#899db0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6606.233 86.204l-3.528-11.594 13.687-4.468 2.084 12.053z" fill="#7f91a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6608.187 113.693l1.452-2.961 9.384-3.093-1.706 3.043z" fill="#343b42" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6608.651 96.539l-2.418-10.335 12.243-4.01 1.166 10.735z" fill="#6f7e8e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6609.806 104.897l-1.154-8.358 10.99-3.61.181 8.671z" fill="#5a6774" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6609.639 110.732l.167-5.835 10.017-3.297-.8 6.039z" fill="#434d57" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6602.576 26.346l-3.553-5.835 20.734-3.628 2.7 5.972z" fill="#748495" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6613.534 57.638l-3.427-12.04 17.283-3.067 1.87 12.298z" fill="#8da1b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6616.392 70.142l-2.858-12.504 15.725-2.81 1.277 12.77z" fill="#899db1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6617.317 110.682l1.707-3.043 9.705-1.78-1.97 3.089z" fill="#323940" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6618.476 82.195l-2.084-12.053 14.144-2.543.594 12.305z" fill="#7f91a4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6606.363 34.908l-3.787-8.562 19.88-3.49 2.609 8.753z" fill="#8295a7" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6610.107 45.598l-3.744-10.69 18.702-3.3 2.325 10.923z" fill="#8b9fb2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6619.024 107.639l.8-6.039 10.358-1.893-1.453 6.152z" fill="#454f59" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6619.823 101.6l-.18-8.67 11.362-2.07-.823 8.847z" fill="#5c6976" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6619.642 92.93l-1.166-10.735 12.654-2.29-.125 10.956z" fill="#708090" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6599.023 20.511l-3.069-2.727 21.206-3.705 2.597 2.804z" fill="#606e7c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6630.536 67.599l-1.277-12.77 15.95-.627.069 12.798z" fill="#899db1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6628.729 105.86l1.452-6.153 11.81-.518-1.973 6.168z" fill="#47515b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6626.76 108.948l1.969-3.089 11.289-.502-2.195 3.096z" fill="#30373e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6619.757 16.883l-2.597-2.804 20.045-.703 2.262 2.81z" fill="#5f6c7a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6631.13 79.904l-.594-12.305 14.742-.599-.551 12.336z" fill="#8092a5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6630.181 99.707l.824-8.846 12.597-.541-1.61 8.869z" fill="#5d6b78" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6622.457 22.855l-2.7-5.972 19.71-.697 2.071 5.983z" fill="#728293" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6631.005 90.86l.125-10.956 13.597-.568-1.125 10.984z" fill="#718191" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6625.065 31.609l-2.608-8.754 19.081-.686 1.728 8.768z" fill="#8193a6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6627.39 42.53l-2.325-10.921 18.201-.672 1.256 10.942z" fill="#8a9eb1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6629.26 54.829l-1.87-12.298 17.132-.652.687 12.323z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6641.992 99.189l1.61-8.87 70.849 1.805-2.254 8.786z" fill="#5e6c79" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6643.266 30.937l-1.728-8.768 73.32 2.402 1.66 8.698z" fill="#8092a5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6640.018 105.357l1.974-6.168 70.205 1.72-2.568 6.108z" fill="#48525c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6641.538 22.169l-2.071-5.983 73.213 2.445 2.178 5.94z" fill="#718191" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6644.522 41.88l-1.256-10.943 73.253 2.332 1.016 10.85z" fill="#899db1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6645.209 54.202l-.687-12.323 73.013 2.24.295 12.214z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6645.278 67l-.07-12.798 72.622 2.131-.45 12.684z" fill="#8a9db1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6644.727 79.336l.55-12.336 72.103 2.017-1.156 12.224z" fill="#8093a5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6639.467 16.186l-2.262-2.81 72.942 2.458 2.533 2.797z" fill="#5e6b78" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6643.602 90.32l1.125-10.984 71.497 1.905-1.773 10.883z" fill="#728292" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6637.823 108.453l2.195-3.096 69.61 1.66-2.7 3.06z" fill="#30363d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6495.013 95.459l5.318 1.022-9.335 19.598-5.528-.578z" fill="#667483" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6507.96 99.22l9.415 4.279-8.427 17.676-9.942-3.164z" fill="#8599ac" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6527.9 109.019l10.864 6.358-7.09 14.854-11.564-4.88z" fill="#8ea2b7" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6492.34 96.197l2.673-.738-9.545 20.042-2.698.794z" fill="#505b67" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6517.375 103.499l10.525 5.52-7.79 16.331-11.162-4.175z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6500.331 96.48l7.63 2.74-8.955 18.791-8.01-1.932z" fill="#78899b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6538.764 115.377l10.405 6.727-6.379 13.356-11.116-5.229z" fill="#889cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6558.356 128.706l7.324 6.004-5.125 10.72-7.906-4.779z" fill="#6c7c8b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6549.169 122.104l9.187 6.602-5.707 11.945-9.859-5.191z" fill="#7d8fa1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6565.68 134.71l4.968 4.986-4.671 9.77-5.422-4.035z" fill="#576470" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6572.958 143.338l-.451 2.074-4.256 8.9.332-1.824z" fill="#384048" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6570.648 139.696l2.31 3.642-4.375 9.15-2.606-3.022z" fill="#404952" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6471.466 247.983l-.754-67.493 2.72-.932.751 68.398z" fill="#4c5762" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6474.184 247.956l-.752-68.398 5.77-.722.73 69.063z" fill="#63717f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6473.432 179.558l-2.72.932 1.111-20.88 2.724-.914z" fill="#4d5863" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6479.932 247.9l-.73-69.064 8.46-.458.692 69.453-1.29-.004-7.132.071z" fill="#758697"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6488.354 247.831l-.693-69.453 10.57-.156.643 69.635z" fill="#8396a9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6498.232 178.222l-10.57.156 1.078-19.863 10.533.761z" fill="#8497aa" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6498.874 247.857l-.642-69.635 11.927.16.582 69.503z" fill="#8ca0b4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6510.741 247.885l-.582-69.503 12.412.466.518 69.067z" fill="#8da2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6479.203 178.836l-5.77.722 1.113-20.862 5.76-.377z" fill="#637280" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6487.661 178.378l-8.459.458 1.104-20.517 8.434.196z" fill="#768798" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6510.159 178.382l-11.927-.16 1.041-18.946 11.88 1.277z" fill="#8ca0b4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6562.823 183.935l.3 64.324-2.957-.043-.319-65.423z" fill="#434c56" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6562.823 183.935l-2.976-1.142.723-12.265 2.946 1.688-.023.383z" fill="#424c55" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6559.847 182.793l.32 65.423-5.953-.088-.352-66.495z" fill="#5a6774" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6553.862 181.633l.352 66.495-8.579-.127-.398-67.464z" fill="#6e7e8e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6545.237 180.537l.398 67.464-2.492-.037-8.134-.02-.455-68.36z" fill="#7e90a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6545.237 180.537l-10.683-.953.879-15.33 10.624 2.148z" fill="#7e90a2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6534.554 179.584l.455 68.36-11.92-.029-.518-69.067z" fill="#899db0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6553.862 181.633l-8.625-1.096.82-14.135 8.572 2.143z" fill="#6e7e8d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6534.554 179.584l-11.983-.736.938-16.593 11.924 1.999z" fill="#899cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6559.847 182.793l-5.985-1.16.766-13.088 5.942 1.983z" fill="#596673" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6522.57 178.848l-12.411-.466.994-17.829 12.356 1.702z" fill="#8ea2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6488.74 158.515l-8.434-.196 3.976-21.5 8.28 1.078z" fill="#789" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6523.509 162.255l-12.357-1.702 3.343-17.92 12.065 3.319z" fill="#8ea2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6474.547 158.696l-2.723.914 4.068-22.048 2.715-.852z" fill="#4e5a65" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6535.433 164.254l-11.924-2 3.05-16.302 11.626 3.642z" fill="#899cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6480.306 158.32l-5.76.376 4.06-21.986 5.676.108z" fill="#647381" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6499.273 159.276l-10.533-.761 3.822-20.618 10.317 1.984z" fill="#8497aa" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6511.153 160.553l-11.88-1.277 3.606-19.395 11.616 2.751z" fill="#8ca0b4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6563.516 172.216l-2.946-1.688 2.028-10.724 2.82 2.367-.298 1.57z" fill="#414a54" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6560.57 170.528l-5.941-1.983 2.221-11.769 5.749 3.028z" fill="#586572" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6554.628 168.545l-8.571-2.143 2.469-13.11 8.324 3.484z" fill="#6d7d8c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6546.057 166.402l-10.624-2.148 2.752-14.66 10.34 3.697z" fill="#7e90a2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6478.607 136.71l-2.715.852 6.878-21.267 2.698-.794z" fill="#4f5b66" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6484.282 136.818l-5.675-.108 6.86-21.209 5.529.578z" fill="#657482" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6548.526 153.291l-10.34-3.697 4.604-14.134 9.86 5.191z" fill="#7d8fa1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6565.417 162.171l-2.819-2.367 3.379-10.338 2.606 3.022z" fill="#404952" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6565.172 163.73l-.052.01.297-1.569z" fill="#414a54" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6538.185 149.594l-11.625-3.642 5.114-15.72 11.116 5.228z" fill="#899cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6514.495 142.632l-11.616-2.751 6.069-18.706 11.162 4.175z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6502.88 139.88l-10.318-1.983 6.444-19.886 9.942 3.164z" fill="#8598ab" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6526.56 145.952l-12.065-3.32 5.615-17.282 11.564 4.881z" fill="#8ea2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6565.172 163.73l.245-1.559 3.166-9.683-.332 1.824z" fill="#373f47" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6492.562 137.897l-8.28-1.079 6.714-20.739 8.01 1.932z" fill="#78899a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6562.599 159.804l-5.749-3.028 3.705-11.345 5.422 4.035z" fill="#586471" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6556.85 156.776l-8.324-3.485 4.123-12.64 7.906 4.78z" fill="#6d7c8c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6791.795 860.164l-2.311 3.08-84.33 4.915.017-2.975z" fill="#3e3e3e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6789.484 863.245l-2.507 1.337-81.83 4.78.01-1.203z" fill="#333" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6780.301 750.667l2.652 1.513-77.16 3.561.01-1.649z" fill="#222" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6782.953 752.18l2.662 3.222-79.842 3.713.02-3.374z" fill="#2d2d2d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6798.976 788.303l1.182 7.674-3.928-3.285z" fill="#333" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6797.486 781.245l1.49 7.058-4.255-5.206z" fill="#303030" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6795.74 775.025l1.746 6.22-4.651-6.93z" fill="#2c2c2c" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6793.794 769.832l1.946 5.193-5.102-8.4z" fill="#282828" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6791.713 765.818l2.081 4.014-5.59-9.573z" fill="#232323" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6705.773 759.115l79.842-3.713 2.59 4.857-82.46 3.88z" fill="#383838" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6705.745 764.14l82.46-3.88 2.432 6.365-84.93 4.057z" fill="#424242" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6790.637 766.625l-2.433-6.366 5.59 9.573z" fill="#272727" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6705.708 770.682l84.93-4.057 2.197 7.69-87.172 4.238z" fill="#4b4b4b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6792.835 774.315l-2.197-7.69 5.102 8.4z" fill="#2c2c2c" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6705.663 778.553l87.172-4.238 1.886 8.782-89.109 4.418z" fill="#525252" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6794.72 783.097l-1.885-8.782 4.651 6.93z" fill="#303030" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6797.31 802.789l2.848-6.812.203 1.95.632 6.093z" fill="#353535" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6797.923 813.054l3.07-9.034.458 8.151z" fill="#363636" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path
                d="M6801.45 812.171l-.457-8.151-.632-6.093 10.467.324 32.421 1.002 8.447.261-.077 4.685 1.071 2.136 2.421 2.118 3.714 2.035 4.907 1.888 5.957 1.677 6.827 1.412 7.484 1.099 7.902.747 8.063.37 7.962-.021 7.598-.41 6.988-.786 6.153-1.134 5.125-1.442 3.943-1.703 2.647-1.906 1.284-2.047.138-5.933 11.2.346 72.693 2.247 31.589 20.93-255.782-7.3z"
                fill="#1e4850" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6798.046 823.145l3.405-10.974.052 6.351v.321l.01 1.32-3.47 2.982z" fill="#373737" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6797.676 832.725l3.84-12.562-.242 5.591-.086 1.98z" fill="#363636" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6796.828 841.479l4.36-13.745-.473 4.6-.02.192-.217 2.113z" fill="#343434" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6795.532 849.12l4.946-14.48-.678 3.825-.023.129-.366 2.063z" fill="#313131" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6793.834 855.412l5.577-14.755-.842 3-.018.064-.527 1.878-.905 2.119-1.173 2.748z" fill="#252525"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6708.492 280.663l-4.222-.692-4.176-.685.13-25.385 8.408 2.098z" fill="#484848" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6358.127 1050.883l-10.442-8.792-16.15-763.352 10.838-.642z" fill="#6a6a6a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6342.373 278.097l-10.839.642-.543-25.673 21.556-1.943.517 26.341z" fill="#6a6a6a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6331.534 278.739l16.15 763.352-7.487-18.811-16.12-743.172z" fill="#525252" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6331.534 278.739l-7.457 1.369-.541-24.955 7.455-2.087z" fill="#525252" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6700.261 1025.162l-342.134 25.72-15.754-772.785 41.687.216.45 25.802v.055l.207 11.883.019 1.127.01.569 5.794 332.651.516 29.659 5.803 333.135 266.603-19.074.845-324.1.043-16.053.893-342.876.015-5.208v-1.034l.065-25.003v-.077l38.944.202-4.01 745.191z"
                fill="#979797" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6384.06 278.313l-41.687-.216 10.69-.633 34.888-1.064 46.001-.748 53.388-.35 55.97.09 53.387.518 46.085.887 35.18 1.163 22.132 1.326 4.177.685-38.945-.202v.077l-4.416-.474-21.045-1.028-30.83-.868-38.545-.627-43.218-.324-44.196.014-41.316.35-34.948.649-22.751.775z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6663.464 994.12l-266.604 19.074-5.802-333.135 273.25-10.04z" fill="#49afc3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6543.31 275.392l-55.97-.09-.277-27.474 1.29.004 10.521.026 11.867.028 12.348.03 11.92.03 8.135.02.166 27.427z"
                fill="#989898" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6387.951 276.4l-34.887 1.064-.517-26.34 34.943-1.623z" fill="#7e7e7e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6433.952 275.652l-46 .748-.462-26.9 46.084-1.14z" fill="#8d8d8d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6700.094 279.286l-22.131-1.326.09-26.08 22.17 2.02z" fill="#616161" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6677.963 277.96l-35.18-1.163.026-26.69 35.245 1.772z" fill="#777" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6642.782 276.797l-46.085-.887-.062-27.157h.05l1.288.038 1.25.037 1.175.034 1.062.031.918.027.745.022.55.016.34.01.118.004 38.679 1.134-.027 26.69z"
                fill="#888" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6596.697 275.91l-53.387-.518-.167-27.428 2.492.037 8.579.127 5.952.087 2.957.044 26.25.387h.095l.317.005.53.008.726.01.901.014 1.049.016 1.164.017 1.244.018 1.236.018.062 27.157z"
                fill="#939393" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6487.34 275.303l-53.388.35-.378-27.292 6.25-.063.214-.004.437-.005.646-.006.837-.008 1.002-.01 1.135-.011 1.236-.012 1.297-.013 1.32-.013 1.3-.013 1.24-.012 1.145-.012 1.012-.01.85-.008.661-.007.453-.005.235-.004 16.622-.166 2.718-.027 5.748-.057 7.131-.071.277 27.475z"
                fill="#969696" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6384.06 278.313l22.751-.775.414 26.204-12.339.203-.615.007-.444.01-9.317.152z" fill="#7a7a7a"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6406.811 277.538l34.95-.65.352 26.543-16.593.148-.774.005-.653.008-16.868.15z" fill="#8a8a8a"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6441.76 276.889l41.315-.35.277 26.724-19.552.08h-.874l-.814.005-19.999.08-.353-26.541z"
                fill="#959595" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6483.075 276.539l44.197-.014.193 26.732-20.843.004-.898-.004-.907.004-21.465.004-.277-26.725z"
                fill="#989898" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6527.272 276.525l43.217.324.11 26.563-20.31-.073-.844-.005h-.92l-21.06-.076-.193-26.732z"
                fill="#959595" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6570.49 276.849l38.544.627.037 26.236-18.056-.14-.72-.008-.854-.005-18.841-.147z" fill="#8b8b8b"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6609.034 277.476l30.83.868-.02 25.785-14.404-.195-.54-.01-.72-.007-15.109-.205z" fill="#7b7b7b"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6639.865 278.344l21.045 1.028-.058 25.25-9.815-.23-.325-.011-.534-.01-10.334-.243z" fill="#666"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6660.91 279.372l4.416.474-.065 25.003-4.41-.227z" fill="#4e4e4e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6407.225 303.742l16.868-.15-29.207.353z" fill="#626262" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6384.51 304.115l9.317-.153-9.316.208v-.055z" fill="#7a7a7a" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6483.352 303.263l21.465-.004-41.017.083 19.552-.08z" fill="#717171" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6442.113 303.43l20-.08-36.593.23z" fill="#6c6c6c" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6527.465 303.257l21.06.076-41.903-.073 20.843-.004z" fill="#717171" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6570.6 303.412l18.84.147-39.15-.22z" fill="#6c6c6c" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6660.852 304.622l4.409.227v.012l-14.224-.47z" fill="#464646" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6639.844 304.129l10.334.242-24.739-.437z" fill="#565656" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6609.07 303.712l15.11.205-33.166-.346z" fill="#636363" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6650.178 304.371l.535.01-10.993 12.761-19.966-.16-3.847-.031 8.993-13.026.54.01z" fill="#565656"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6639.72 317.142l10.992-12.761.325.01 14.224.47v1.022l-11.085 11.475h-.01l-13.415-.2-1.028-.016z"
                fill="#464646" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6654.173 317.357l11.085-11.474-.014 5.208-6.492 6.49z" fill="#343434" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6658.753 317.58l6.491-6.49-.894 342.877-9.015-4.89.645-331.379z" fill="#525252" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6655.335 649.077l9.015 4.89-.042 16.053-273.25 10.039-.517-29.659 17.057-4.901 20.413-3.634.45 30.635.664.811 1.393.763 2.08.692 2.71.597 3.255.484 3.702.355 4.032.215 4.236.067 4.305-.083 4.238-.23 4.038-.37 3.71-.496 3.27-.608 2.727-.7 2.105-.77 1.421-.815.699-.835-.39-35.114 11.917-.67.107 10.488.063.715.747.696 1.412.654 2.037.592 2.604.511 3.09.415 3.482.304 3.764.184 3.93.057 3.97-.07 3.885-.197 3.679-.317 3.357-.425 2.932-.521 2.417-.6 1.83-.66 1.192-.698.52-.716-.08-11.547 6.546-.06 33.651 1.273.123 30.564.27.809.993.786 1.688.74 2.338.67 2.915.58 3.407.469 3.792.344 4.06.208 4.197.065 4.205-.08 4.077-.223 3.823-.358 3.447-.482 2.967-.59 2.396-.678 1.754-.746 1.061-.79.342-.81-.013-25.157 13.058 2.127z"
                fill="#1e4a52" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6428.012 641.865l-20.414 3.634-5.294-327.538 3.539.036 17.42.11z" fill="#777" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6573.708 635.87l-33.651-1.273-2.048-316.24 22.864-.017 11.56-.028z" fill="#969696"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6407.598 645.499l-17.057 4.901-5.794-332.651 2.797.058 14.76.154z" fill="#616161" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6540.057 634.597l-6.546.061-2.2-316.295 6.698-.005z" fill="#989898" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6488.573 635.793l-11.917.67-3.534-318.164 12.2.027z" fill="#939393" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6634.609 643.318l-13.059-2.126-.168-323.065 13.367-.101z" fill="#7e7e7e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6655.335 649.077l-20.727-5.758.14-325.293 5.28-.04 13.713-.192 2.24-.096z" fill="#6a6a6a"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6579.12 669.44l-2.337-.67-1.337-351.43 2.395-.02z" fill="#919191" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6577.84 317.319l-2.394.022-1.728.023-1.015.025-.274.026v.897l-11.56.027.482-1.599 22.805.059 5.404.026v.447l-1.353.004-3.889.011-3.493.015-2.989.018z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6584.323 317.286l3.89-.011 1.022 353.558-3.793-.344z" fill="#bdbdbd" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6580.83 317.3l3.493-.014 1.12 353.203-3.408-.47z" fill="#b2b2b2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6577.84 317.319l2.99-.019 1.206 352.72-2.916-.58z" fill="#a3a3a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6573.718 317.364l1.728-.023 1.337 351.43-1.689-.74z" fill="#7d7d7d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6572.703 317.39l1.015-.026 1.376 350.666-.992-.786z" fill="#676767" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6572.429 317.415l.274-.026 1.399 349.855-.27-.81-.124-30.563-1.275-317.559z" fill="#505050"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6609.596 670.445l-3.822.358-.595-353.528 3.923.012z" fill="#bcbcbc" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6609.102 317.287l-3.923-.012h-.928v-.379l11.656.055 3.848.031-.273.38-1.34-.018-2.458-.021-3.044-.02-3.538-.015z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6609.102 317.287l3.537.015.405 352.661-3.448.482z" fill="#b0b0b0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6612.64 317.302l3.043.02.327 352.052-2.966.59z" fill="#a1a1a1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6615.683 317.321l2.458.022.265 351.352-2.396.679z" fill="#8f8f8f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6597.491 671.106l-4.198-.065-.917-353.773h4.306l.81 353.84z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6601.696 671.026l-4.205.08-.809-353.84H6600.995l.7 353.757z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6593.293 671.041l-4.058-.208-1.023-353.558 1.353-.004H6592.376l.918 353.773z" fill="#c5c5c5"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6605.774 670.803l-4.078.223-.7-353.758h4.183l.594 353.528z" fill="#c4c4c4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6621.221 667.159l-1.061.79-.22-350.582 1.09.025z" fill="#656565" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6621.03 317.392l-1.09-.025-.459-.006.273-.38 19.966.16 1.028.016-.72.83-5.28.04-13.366.1v-.71z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6621.03 317.392l.351.026v.709l.17 323.065.012 25.157-.342.81z" fill="#4e4e4e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6620.16 667.95l-1.754.745-.265-351.352 1.34.018.46.006z" fill="#7b7b7b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6435.309 675.363l-2.71-.597-5.138-357.617 2.628-.019h.143l5.076 358.234z" fill="#a3a3a3"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6429.126 673.311l-.665-.811-.45-30.635-4.748-323.757-.013-.887.67-.026z" fill="#676767"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6423.92 317.195l-.67.026.013.887-17.42-.11-.843-1.037 24.895-.137.194.306-2.628.019-2.125.022z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6425.336 317.17l2.125-.021 5.138 357.617-2.08-.692z" fill="#919191" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6423.92 317.195l1.416-.024 5.182 356.903-1.392-.763z" fill="#7d7d7d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6474.926 673.227l-2.104.77-4.075-356.824 2.169.025z" fill="#7b7b7b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6470.916 317.198l-2.17-.025-2.806-.022-3.363-.02-2.556-.01-.164-.39 7.946-.024 18.92-.017.01.863.235.049 2.333.047 3.913.04.049.265-.947.004-2.66.016-2.081.02-1.44.02-.757.022-.057.023v.267l-12.201-.027-.012-1.048-.727-.027-1.468-.026z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6462.577 317.131l3.363.02 4.154 357.546-3.269.608z" fill="#a1a1a1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6465.94 317.15l2.807.023 4.075 356.824-2.728.7z" fill="#8f8f8f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6470.916 317.198l1.467.026 3.964 355.188-1.42.815z" fill="#656565" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6472.383 317.224l.727.027.012 1.048 3.534 318.164.39 35.114-.698.835z" fill="#4e4e4e"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6489.654 317.974l2.661-.016 3.229 331.491-2.604-.511z" fill="#a3a3a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6487.574 317.993l2.08-.019 3.286 330.964-2.038-.592z" fill="#919191" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6486.134 318.014l1.44-.02 3.328 330.352-1.412-.654z" fill="#7d7d7d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6485.377 318.036l.757-.022 3.356 329.678-.747-.696z" fill="#676767" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6485.32 318.059l.057-.023 3.367 328.96-.063-.715-.108-10.488-3.25-317.468v-.266z" fill="#676767"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6463.114 675.802l-4.037.369-4.463-359.068h1.082l3.067.008 4.351 358.687z" fill="#bcbcbc"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6450.533 676.484l-4.236-.067-4.802-359.321h4.346l4.692 359.39z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6438.564 675.847l-3.256-.484-5.076-358.234 3.334-.015z" fill="#b2b2b2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6433.566 317.114l-3.334.015h-.143l-.194-.306 2.97-.017 7.878-.022v.312l-3.387.006-3.793.011z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6433.566 317.114l3.794-.012 4.905 359.1-3.701-.355z" fill="#bdbdbd" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6446.297 676.417l-4.032-.215-4.905-359.1 3.387-.006h.748l4.802 359.321z" fill="#c5c5c5"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6459.077 676.17l-4.239.23-4.577-359.304 1.1.004 1.138.004 1.008.004H6454.613l4.463 359.068z"
                fill="#c4c4c4" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6466.825 675.305l-3.71.497-4.352-358.687 1.258.006 2.556.01z" fill="#b0b0b0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6454.838 676.4l-4.305.084-4.692-359.39h4.42l4.577 359.304z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6531.88 647.62l-1.83.66-2.383-330.285 1.88.021z" fill="#7b7b7b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6529.547 318.016l-1.88-.02-.25-.005.015-.229 2.65-.004 6.968-.021 5.72-.032 4.083-.04 2.155-.046.057-.049v-.864l12.294.032-.482 1.6-22.864.017-6.697.005v-.301l-.537-.023-1.226-.022z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6529.547 318.016l1.225.023 2.3 328.882-1.192.699z" fill="#656565" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6530.772 318.039l.537.022v.302l2.2 316.295.08 11.547-.52.716-2.3-328.882z" fill="#4e4e4e"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6527.632 648.879l-2.931.52-2.521-331.44 3.006.017z" fill="#a1a1a1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6525.186 317.976l-3.006-.017-3.442-.013-3.523-.01v-.168l7.11.004 5.108-.006-.014.229-2.231-.017z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6518.738 317.946l3.442.013 2.52 331.44-3.357.426z" fill="#b0b0b0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6530.05 648.28l-2.418.599-2.446-330.903 2.232.017.249.004 2.382 330.284z" fill="#8f8f8f"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6521.343 649.825l-3.678.317-2.698-332.206h.248l3.523.01 2.605 331.88z" fill="#bcbcbc"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6513.779 650.338l-3.97.071-2.891-332.482h4.067l2.794 332.409z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6505.88 650.352l-3.765-.184-3.076-332.233 2.379-.004h1.475l2.986 332.422z" fill="#c5c5c5"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6498.633 649.864l-3.09-.415-3.228-331.491.947-.004 2.214-.01z" fill="#b2b2b2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6509.809 650.409l-3.93-.057-2.985-332.423h4.024l2.89 332.481z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6502.115 650.168l-3.481-.304-3.158-331.92 3.563-.01z" fill="#bdbdbd" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6499.04 317.935l-3.564.01-2.214.01-.05-.265.335.004 5.857.035 2.012.007v.197l-2.378.004z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6517.664 650.142l-3.885.196-2.794-332.408h.432l1.029.004h2.521l2.698 332.206z" fill="#c4c4c4"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6387.544 317.807l-2.797-.058-.01-.57 1.03-.024 1.15-.011z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6387.544 317.807l-.626-.663 17.948-.182h.134l.843 1.036-3.54-.037-14.759-.153z" fill="#3f3f3f"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6459.857 316.73l.164.39-1.258-.005-3.067-.008v-.365z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6640.028 317.986l.72-.829 13.415.2-.422.437z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6653.741 317.794l.422-.437h.01l4.58.223-2.772.118z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6624.9 303.924l-8.993 13.026-11.657-.055h-.118l-.338-.004-.548-.004-.742-.004-.913-.004-1.057-.005-1.168-.006-1.245-.006-1.28-.006-1.28-.006-1.239-.006-1.158-.005-1.044-.005-.897-.004-.723-.004-.526-.004-.316-.004h-.095l-5.404-.026 6.136-13.235.72.007 33.165.345.72.007z"
                fill="#636363" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6590.295 303.564l-6.136 13.235-22.805-.059-12.293-.031-2.074-.005h-.181l2.639-13.37.844.006 39.152.22.854.005z"
                fill="#6c6c6c" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6549.445 303.334l-2.639 13.37-3.91-.005-5.844-.005-7.203-.006-8.057-.006-8.337-.007-6.568-.005-1.163-13.413.898.004 41.903.072z"
                fill="#717171" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6505.724 303.258l1.163 13.413h-1.45l-7.125.007-5.732.005-3.948.004-1.91.004-18.919.018-4.877-13.363h.874l41.017-.083.907-.004z"
                fill="#717171" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6462.926 303.344l4.877 13.363-7.946.023-4.165.012h-.685l-.659.004-.845.004-1.008.004-1.139.004-1.235.004-1.294.004-1.312.004-1.292.004-1.229.004-1.13.004-.998.004-.832.004H6440.743l-7.877.023-8.12-13.224.774-.005 36.593-.229z"
                fill="#6c6c6c" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6424.746 303.584l8.12 13.224-2.971.016-24.895.137h-.134l-10.595-13.01.615-.007 29.207-.353.653-.008z"
                fill="#626262" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6394.27 303.952l10.596 13.01-17.948.182-1.15.011-1.05-1.102-.207-11.883 9.316-.208z" fill="#555"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6384.718 316.053l1.05 1.102-1.03.025z" fill="#454545" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6549.008 317.621l-2.155.046v-.144l.14.004 2.015.047v.05z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6546.853 317.667l-4.083.04v-.227h.132l3.951.042v.145z" fill="#6a6a6a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6542.77 317.707l-5.72.032v-.296h.01l5.712.036v.228z" fill="#7e7e7e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6537.05 317.74l-6.969.02v-.344l6.97.027v.296z" fill="#8d8d8d" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6499.404 317.728l-5.857-.036v-.233l4.774-.028 1.082-.004v.3z" fill="#777" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6489.3 317.65l-2.334-.048v-.055l1.675-.041.658-.007v.15z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6549.06 316.709v.864h-.056l-2.016-.047-.01-.821 2.073.005z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6546.987 316.703l.01.822-.14-.004-3.95-.043v-.78l3.909.004h.18z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6542.896 316.7v.78h-.133l-5.712-.036v-.748l5.845.005z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6537.052 316.695v.748h-.01l-6.97-.027h-.225v-.726l7.203.006z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6529.849 316.69v.726l-7.533-.015h-.524l-.01-.717 8.057.006z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6521.792 316.683l.01.717-7.52-.004h-.817l-.01-.72 8.337.006z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6513.456 316.677l.01.72-6.994.01h-1.023l-.01-.736h1.449z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6505.438 316.673l.01.735-6.043.02-1.082.003-.01-.75 7.126-.008z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6498.312 316.68l.01.75-4.774.029-.958.006-.01-.78z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6492.58 316.684l.01.78-3.29.035-.657.007-.01-.818z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6530.08 317.416v.345l-2.65.004-5.108.006v-.37l7.532.015h.226z" fill="#969696" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6522.321 317.4v.37l-7.11-.003H6514.278v-.371l7.52.004h.524z" fill="#989898" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6514.277 317.397v.371H6513.343l-.902-.004-1.028-.004-1.124-.004-1.184-.004-1.209-.004-1.196-.004h-.232v-.347l6.993-.01h.816z"
                fill="#939393" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6506.468 317.407v.347l-.915-.004-1.062-.004-.945-.004-.8-.004-.628-.004-.44-.004h-.265l-2.012-.008v-.3l6.043-.02h1.024z"
                fill="#888" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6493.545 317.459v.233l-.334-.004-3.913-.04v-.15l3.289-.034.957-.006z" fill="#616161"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6486.731 317.553l-.01-.863 1.91-.004.01.818-1.675.041-.234.006z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6589.77 242.85l.529-.057.016 5.867-.53-.008z" fill="#7d7d7d" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6591.927 242.697l1.049-.036.015 6.039-1.048-.016z" fill="#b2b2b2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6594.14 242.635l1.245-.016.015 6.116-1.244-.018z" fill="#c5c5c5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6601.447 242.7l.919.046.012 6.176-.918-.027z" fill="#a1a1a1" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6602.366 242.746l.746.052.01 6.146-.745-.022z" fill="#8f8f8f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6599.21 242.637l1.175.027.012 6.2-1.174-.035z" fill="#bcbcbc" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6603.111 242.798l.552.058.01 6.104-.55-.016z" fill="#7b7b7b" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6603.673 248.96l-.01-6.104.34.06.01 6.054z" fill="#656565" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6604.013 248.97l-.011-6.053.118.063.01 5.993z" fill="#4e4e4e" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6603.246 316.89v.382h-.742v-.384l.742.004z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6597.96 242.62l1.25.017.013 6.192-1.25-.036z" fill="#c4c4c4" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6589.452 242.91l.317-.06.016 5.803-.317-.005z" fill="#676767" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6596.671 242.614l1.288.006.014 6.173-1.289-.038z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6592.975 242.66l1.165-.025.015 6.082-1.164-.017z" fill="#bdbdbd" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6590.299 242.793l.726-.052.016 5.93-.727-.01z" fill="#919191" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6589.357 242.973l.095-.063.016 5.738h-.095l-.016-5.673z" fill="#676767" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6600.385 242.664l1.062.037.012 6.194-1.062-.031z" fill="#b0b0b0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6591.025 242.741l.902-.044.016 5.987-.902-.013z" fill="#a3a3a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6602.504 316.887v.384h-.913v-.387l.913.004z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6595.385 242.619l1.286-.005.014 6.14h-.05l-1.236-.017-.015-6.117z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6601.591 316.882v.387h-1.056v-.39l1.056.004z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6600.534 316.877v.39h-1.168v-.395l1.168.006z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6599.366 316.871v.396h-1.244v-.4l1.244.005z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6598.121 316.866v.4h-1.281v-.406l1.281.006z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6590.502 317.27v-.44l.723.003v.437h-.723z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6591.224 317.27v-.437l.897.004v.431h-.897z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6592.121 317.268v-.43l1.044.004v.426h-1.044z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6593.165 317.268v-.426l1.158.005v.42h-1.158z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6594.323 317.267v-.42l1.239.006v.413h-1.239z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6595.562 317.266v-.413l1.28.006v.407h-1.28z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6449.158 241.271l1.243.028.087 6.893-1.241.013z" fill="#bcbcbc" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6439.734 241.553l.213-.062.09 6.805-.213.004-.09-6.745z" fill="#676767" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6439.947 241.49l.436-.058.092 6.86-.437.005z" fill="#7d7d7d" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6445.24 241.252l1.298-.005.09 6.984-1.297.013z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6440.383 241.432l.646-.053.092 6.906-.646.006z" fill="#919191" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6441.121 248.285l-.092-6.906.837-.046.092 6.944z" fill="#a3a3a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6441.958 248.277l-.092-6.944 1.002-.037.091 6.971z" fill="#b2b2b2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6446.538 241.247l1.32.006.09 6.964-1.32.013z" fill="#c9c9c9" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6447.947 248.217l-.09-6.964 1.301.018.09 6.934z" fill="#c4c4c4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6442.868 241.296l1.136-.027.09 6.987-1.135.011z" fill="#bdbdbd" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6444.095 248.256l-.09-6.987 1.235-.017.091 6.991z" fill="#c5c5c5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6450.488 248.192l-.087-6.893 1.146.038.086 6.844z" fill="#b0b0b0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6451.547 241.337l1.013.047.085 6.787-1.012.01z" fill="#a1a1a1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6452.645 248.17l-.085-6.786.85.054.085 6.724z" fill="#8f8f8f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6453.494 248.162l-.083-6.724.662.06.083 6.658z" fill="#7b7b7b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6454.76 241.625v.065l.08 6.459-.235.004-.082-6.591.233.065z" fill="#4e4e4e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6454.073 241.497l.455.063.082 6.591-.453.005z" fill="#656565" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6442.038 317.095v-.314l.833-.004v.316h-.833z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6442.87 317.095v-.316l.998-.004v.318h-.998z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6443.868 317.094v-.318l1.13-.004v.321z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6444.998 317.094v-.321l1.23-.004v.324h-1.23z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6446.228 317.094v-.325l1.29-.004v.33h-1.29z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6447.519 317.095v-.33l1.312-.003v.333h-1.312z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6448.832 317.095v-.333l1.293-.004v.338h-1.294z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6450.125 317.096v-.338l1.236-.004v.343l-1.1-.004h-.136z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6451.36 317.098v-.343l1.14-.004v.348l-1.14-.004z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6452.5 317.1v-.349l1.007-.004v.354l-1.008-.004z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6453.507 317.102v-.353l.846-.004v.357h-.846z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6502.752 317.93v-.19l.798.004v.186h-.798z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6503.55 317.929v-.186l.946.004v.182h-.945z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6504.495 317.928v-.182l1.062.004v.178z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6505.557 317.928v-.178l.916.004h.231v.174h-1.147z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6514.094 317.768v.166h-.748v-.167h.748z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6513.346 317.766v.167h-.902v-.167l.902.004z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6512.444 317.765v.167l-1.028-.004v-.167l1.028.004z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6511.416 317.763v.167H6510.294v-.169l1.123.004z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6510.292 317.76v.17h-1.184v-.17l1.184.003z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6509.108 317.759v.17h-1.209v-.172l1.21.004z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6506.704 317.927v-.173l1.197.004v.172h-1.197z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6811.723 779.392l1.021-5.957 17.991-.875.121.295 123.909-6.028-1.263 5.58z" fill="#6f6f6f"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6811.06 786.09l.663-6.698 141.779-6.985-.884 6.605z" fill="#767676" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6810.77 793.335l.29-7.246 141.558-7.077-.415 7.14-31.234 1.587-5.131-.982-7.232-.988-7.559-.666-7.672-.328-6.053.014-10.119.51-5.384.56-6.018 1.02-5.128 1.31-4.081 1.568-2.227 1.364z"
                fill="#7a7a7a" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6877.203 785.298l10.12-.509 6.051-.014 7.673.328 7.56.666 7.231.988 5.131.982 1.565.3 5.962 1.542 5.05 1.76 3.976 1.93 1.417 1.043 1.356.999 1.469 2.096.103 2.086-1.283 2.01-2.649 1.873-3.944 1.673-5.128 1.417-6.158 1.114-6.992.772-7.603.403-7.966.02-8.07-.363-7.906-.733-7.49-1.08-6.832-1.387-5.961-1.648-4.91-1.854-3.717-2-2.424-2.081-1.072-2.098.292-2.051 1.632-1.944.68-.417 2.226-1.364 4.081-1.567 5.128-1.311 6.018-1.02z"
                fill="#7f7f7f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6812.744 773.435l1.35-5.04 14.627-.704.05.086 1.964 4.783z" fill="#676767" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6810.828 798.25l-.06-4.915 43.597-2.214-.68.417-1.631 1.944-.293 2.05-.054 3.284-8.458.437z"
                fill="#7c7c7c" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6814.095 768.394l1.644-3.97 10.817-.516 2.165 3.783z" fill="#5c5c5c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6822.311 754.013l2.392-3.507-7.07 11.143-1.894 2.774z" fill="#363636" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6815.74 764.423l1.893-2.774 6.656-.315 2.254 2.55.013.024z" fill="#505050" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6837.239 861.647l-2.885-.401-.991-10.784z" fill="#3b3b3b" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6824.703 750.506l2.643-1.907-7.623 11.568-2.09 1.482z" fill="#2d2d2d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6817.633 761.649l2.09-1.482 2.266-.107 2.256 1.224.044.05z" fill="#434343" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6827.346 748.6l2.82-.225-8.217 11.663-2.226.129z" fill="#2e2e2e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6834.354 861.246l-2.916-2.107-3.28-8.782z" fill="#353535" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6975.152 760.738l2.606 4.97-6.153-7.726-3.24-6.2 3.731 4.924.178.234z" fill="#393939"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6969.21 852.77l-3.407 1.368-128.564 7.509 2.763-1.346z" fill="#434343" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6840.002 860.3l-2.763 1.347 1.198-12.573z" fill="#393939" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6840.002 860.3l-1.565-11.226 75.704-4.318 39.654-2.262 13.553-.773.015.088.038.227 1.808 10.735z"
                fill="#767676" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6957.74 742.51l3.58 1.438-128.237 5.918-2.916-1.491z" fill="#2d2d2d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6830.167 748.375l2.916 1.491-8.838 11.418-2.256-1.224-.04-.022z" fill="#373737" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6961.32 743.948l3.579 3.114-128.892 5.995-2.924-3.19z" fill="#3b3b3b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6833.083 749.866l2.924 3.191-9.464 10.828-2.254-2.551-.044-.05z" fill="#404040" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6968.364 751.781l3.241 6.2-130.095 6.214-2.664-6.319z" fill="#565656" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6838.846 757.876l2.664 6.32-10.654 8.66-.121-.296-1.965-4.783z" fill="#515151" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6830.856 772.855l10.654-8.66 130.095-6.214 6.153 7.727-22.993 1.119z" fill="#6b6b6b"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6952.203 786.153l.065 7.473-13.33.688-1.416-1.044-3.977-1.929-5.05-1.76-5.962-1.542-1.564-.3z"
                fill="#7c7c7c" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6952.268 793.626l.543 7.595-10.997.577.054-2.303-.104-2.086-1.469-2.096-1.356-.999z"
                fill="#7b7b7b" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6952.811 801.22l.191 1.425-11.2-.346.012-.501z" fill="#787878" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6972.36 756.936l-.263-.23-3.732-4.925-3.466-4.719z" fill="#393939" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6964.899 747.062l3.465 4.72-129.518 6.094-2.84-4.819z" fill="#494949" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6836.007 753.057l2.839 4.82-10.076 9.9-.05-.086-2.164-3.783-.013-.023z" fill="#494949"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6843.25 799.253l8.458-.437-.012.698z" fill="#7b7b7b" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6838.437 849.074l-1.198 12.573-3.876-11.185 1.857-.508z" fill="#595959" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6833.363 850.462l.991 10.784-6.196-10.889z" fill="#555" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6833.363 850.462l-5.205-.105 7.062-.403z" fill="#353535" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6835.22 849.954l-7.062.403-4.275-1.302 49.433-2.496 40.825-1.803-75.704 4.318z" fill="#2e2e2e"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6823.883 849.055l4.275 1.302 3.28 8.782-8.203-10.05z" fill="#494949" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6914.141 844.756l-40.825 1.803 80.48-4.065z" fill="#2e2e2e" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6928.666 815.33l-6.153 1.134.192-8.882 6.158-1.114z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6922.513 816.464l-6.989.786.19-8.896 6.991-.772z" fill="#fff" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6858.825 810.488l-3.714-2.035.147-8.741 3.717 2z" fill="#acacac" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6876.516 815.465l-6.828-1.412.158-8.84 6.832 1.388z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6884 816.564l-7.484-1.099.162-8.864 7.49 1.08z" fill="#fff" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6899.965 817.68l-8.063-.369.173-8.897 8.069.364z" fill="#fff" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6915.524 817.25l-7.598.41.184-8.903 7.604-.403z" fill="#fff" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6869.688 814.053l-5.956-1.677.153-8.81 5.961 1.648z" fill="#e9e9e9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6940.381 810.279l-2.647 1.906.201-8.807 2.649-1.872z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6941.665 808.232l-1.284 2.047.203-8.773 1.283-2.011-.054 2.303-.01.501z" fill="#a9a9a9"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6933.791 813.888l-5.125 1.442.197-8.862 5.128-1.417z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6855.111 808.453l-2.42-2.118.143-8.704 2.424 2.08z" fill="#8a8a8a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6863.732 812.376l-4.907-1.888.15-8.777 4.91 1.855z" fill="#ccc" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6937.734 812.185l-3.943 1.703.2-8.837 3.944-1.673z" fill="#e6e6e6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6907.926 817.66l-7.96.02.178-8.902 7.966-.02z" fill="#fff" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M6891.902 817.311l-7.902-.747.168-8.884 7.907.734z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6852.69 806.335l-1.071-2.136.077-4.685.012-.698.054-3.283 1.072 2.098z" fill="#676767"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6801.503 818.522l255.782 7.3-2.29 4.948-253.49-11.927v-.32z" fill="#3d93a4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7054.995 830.77l2.29-4.947 10.246.124-2.902 4.934z" fill="#3d93a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7057.285 825.823l-31.59-20.93 9.853.142 31.983 20.912z" fill="#1e4851" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7059.104 839.262l-6.698-1.001 2.184.023 8.217 1.229z" fill="#214f58" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7062.807 839.513l-8.217-1.23 3.463-1.148 8.637 1.294z" fill="#29636e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7059.104 839.262l3.703.251 7.88 2.344-3.96-.328-.182-.053z" fill="#22525c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7067.53 825.947l-31.982-20.912 10.263 1.576 32.387 20.951z" fill="#1d454d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7066.69 838.429l-8.637-1.294 3.382-2.489 9.198 1.382z" fill="#317583" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7062.807 839.513l3.883-1.084 8.28 2.467-4.283.961z" fill="#2a6672" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7058.053 837.135l-3.463 1.149-2.184-.023-6.148-.066 3.131-1.153z" fill="#28606b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7049.39 837.042l-3.132 1.153-247.707 5.526.018-.063z" fill="#27606a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6798.551 843.721l247.707-5.526-30.191 1.154-218.947 8.369.904-2.118z" fill="#347d8b"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7070.633 836.028l-9.198-1.382 3.194-3.765 9.881 1.49z" fill="#378695" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7064.63 830.881l-3.195 3.765-9.102-.1 2.662-3.776z" fill="#378493" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7054.995 830.77l-2.662 3.776-251.637-2.02.02-.193z" fill="#378493" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6800.696 832.526l251.637 2.02-252.533 3.919.678-3.826z" fill="#3b8f9f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7066.69 838.429l3.943-2.401 8.815 2.63-4.477 2.238z" fill="#327886" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7061.435 834.646l-3.382 2.489-8.664-.093 2.944-2.496z" fill="#307380" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7052.333 834.546l-2.944 2.496-249.612 1.552.023-.13z" fill="#2f7380" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6799.777 838.594l249.612-1.552-250.82 6.616.842-3.001z" fill="#388696" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7074.51 832.37l-9.88-1.489 2.9-4.934 10.668 1.615z" fill="#3d94a5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7070.633 836.028l3.877-3.657 9.47 2.831-4.532 3.456z" fill="#388898" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7074.97 840.896l4.478-2.238 8.284 3.829-4.979 2z" fill="#337b89" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7066.726 841.53l3.96.327 7.407 3.416-4.2-.44-3.679-1.696z" fill="#23565f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7078.198 827.562l-32.388-20.952 9.837 2.966 32.772 21.051z" fill="#1b424a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7078.198 827.562l10.221 3.065-4.44 4.575-9.469-2.831z" fill="#3e96a8" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7088.42 830.627l-32.773-21.051 9.244 4.298 33.127 21.208z" fill="#1a3f46" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7070.687 841.857l4.284-.961 7.782 3.592-4.66.785z" fill="#2b6975" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7079.448 838.658l4.531-3.456 8.896 4.12-5.143 3.165z" fill="#398b9b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7083.98 835.202l4.44-4.575 9.598 4.455-5.143 4.24z" fill="#3f98aa" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7098.018 835.082l8.816 5.762-5.787 3.809-8.172-5.331z" fill="#409aac" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7073.892 844.832l4.2.44 6.81 4.425-4.421-.586z" fill="#255963" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7084.901 849.697l-6.808-4.424 4.66-.785 7.153 4.652z" fill="#2d6c78" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7080.48 849.11l4.422.587 6.096 5.354-4.617-.762z" fill="#265c67" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7073.892 844.832l6.589 4.279-3.707-1.697-6.499-4.22z" fill="#1c454d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7087.732 842.487l5.143-3.165 8.172 5.331-5.703 2.792z" fill="#3a8d9d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7082.753 844.488l4.98-2.001 7.611 4.958-5.438 1.695z" fill="#347e8c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7089.906 849.14l5.438-1.695 6.813 5.997-5.847 1.328z" fill="#35808f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7084.901 849.697l5.005-.557 6.404 5.63-5.312.28z" fill="#2e6f7c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7101.047 844.653l5.787-3.809 7.885 6.966-6.36 3.29z" fill="#419cae" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7095.344 847.445l5.703-2.792 7.312 6.447-6.202 2.342z" fill="#3b90a0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7086.381 854.289l4.617.762 5.283 6.19-4.786-.966z" fill="#275f6a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7096.28 861.24l-5.282-6.19 5.312-.28 5.547 6.507z" fill="#2f727f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7080.48 849.11l5.901 5.179-3.787-1.77-2.148-1.884-3.672-3.221z" fill="#1e4851" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7086.381 854.289l5.114 5.986-3.856-1.852-5.045-5.903z" fill="#1f4c54" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7101.857 861.277l-5.547-6.507 5.847-1.328 5.9 6.931z" fill="#368392" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7096.28 861.24l5.577.037 4.601 7.271-5.795-.39z" fill="#307582" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7101.857 861.277l6.2-.904 4.892 7.745-6.491.43z" fill="#378695" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7108.057 860.373l-5.9-6.93 6.202-2.343 6.33 7.45z" fill="#3c92a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7114.69 858.55l-6.33-7.45 6.359-3.29 6.824 8.046z" fill="#419eb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7091.495 860.275l4.786.965 4.382 6.917-4.925-1.192z" fill="#29636e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7108.057 860.373l6.633-1.823 5.246 8.32-6.987 1.248z" fill="#3d94a5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7121.543 855.856l5.653 8.985-7.26 2.03-5.246-8.321z" fill="#42a0b2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7091.495 860.275l4.243 6.69-3.913-1.945-4.186-6.597z" fill="#214f58" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7100.663 868.157l5.795.391 3.579 7.91-5.964-.775z" fill="#327886" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7087.639 858.423l4.186 6.597-2.793-2.63-.483-.761-3.73-5.878z" fill="#183c42" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7087.639 858.423l-2.82-2.672-4.373-5.116 2.148 1.885z" fill="#1a3f46" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7089.032 862.39l2.793 2.63 3.26 7.178-2.772-2.587z" fill="#193e45" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7106.458 868.548l6.491-.43 3.804 8.423-6.716-.082z" fill="#388898" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7112.95 868.118l6.986-1.247 4.077 9.048-7.26.622z" fill="#3e96a8" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7119.936 866.87l7.26-2.029 4.39 9.768-7.574 1.31z" fill="#43a2b4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7095.738 866.965l4.925 1.192 3.41 7.526-5.032-1.438z" fill="#2a6671" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7116.753 876.54l7.26-.62 2.843 9.621-7.449-.042z" fill="#3f98aa" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7110.037 876.459l6.716.082 2.654 8.958-6.87-.627z" fill="#398b9b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7112.537 884.872l6.87.627 1.466 9.34-6.954-1.192z" fill="#3a8d9d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7110.037 876.459l2.5 8.413-6.08-1.184-2.384-8.005z" fill="#337a89" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7099.04 874.245l5.033 1.438 2.384 8.005-5.106-1.7z" fill="#2b6874" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7095.738 866.965l3.303 7.28-3.956-2.047-3.26-7.178z" fill="#22525b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7126.856 885.541l-2.844-9.622 7.575-1.31 3.06 10.385z" fill="#44a3b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7101.35 881.989l5.107 1.699 1.32 8.349-5.146-1.972z" fill="#2c6b77" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7119.407 885.499l7.449.042 1.567 10.032-7.55-.734z" fill="#409aac" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7090.795 866.571l-2.245-4.942.482.76 3.281 7.222z" fill="#193e45" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7126.856 885.541l7.79-.547 1.682 10.826-7.905-.247z" fill="#44a5b8" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7090.795 866.571l1.518 3.04 2.297 7.682-1.46-2.842z" fill="#1f4a53" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7092.313 869.611l2.772 2.587 2.28 7.635-2.755-2.54z" fill="#1b4148" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7106.457 883.688l6.08 1.184 1.382 8.775-6.142-1.61z" fill="#347d8b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7095.085 872.198l3.956 2.047 2.31 7.744-3.985-2.156z" fill="#23555f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7094.61 877.293l2.756 2.54 1.265 7.963-2.747-2.491z" fill="#1c434b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7098.631 887.796l-1.265-7.963 3.985 2.156 1.28 8.076z" fill="#245762" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7095.885 885.305l2.746 2.491.232 8.157-2.744-2.463z" fill="#1d454d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7094.61 877.293l1.275 8.012-1.425-2.636-1.31-8.218z" fill="#1d4850" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7098.631 887.796l4 2.269.232 8.251-4-2.363z" fill="#255a64" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M7128.423 895.573l7.905.247.263 10.39-7.915-.86z" fill="#45a6b9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7102.863 898.316l-.232-8.251 5.146 1.972.234 8.468z" fill="#2d6d7a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7108.011 900.505l-.234-8.468 6.142 1.61.239 8.802z" fill="#357f8d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7102.863 898.316l5.148 2.19-2.248 62.98-5.113-2.487z" fill="#2e6e7b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7114.158 902.449l-.24-8.802 6.955 1.193.246 9.243z" fill="#3b8f9f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7108.011 900.505l6.147 1.944-2.29 63.296-6.105-2.26z" fill="#35808e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7121.119 904.083l-.246-9.243 7.55.733.253 9.778z" fill="#419cae" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7094.46 882.669l1.425 2.636.234 8.185-1.42-2.487-.07-2.435z" fill="#1d454d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7093.955 955.667l-1.406-2.68 2.15-61.984 1.42 2.487z" fill="#1c444c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7093.955 955.667l2.164-62.177 2.744 2.463-2.185 62.412z" fill="#1d464f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7128.676 905.35l7.915.86-2.432 64.254-7.865-1.17z" fill="#45a7ba" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7098.863 895.953l4 2.363L7100.65 961l-3.972-2.634z" fill="#255b65" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7111.869 965.745l2.289-63.296 6.96 1.634-2.334 63.62z" fill="#3b90a0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7121.119 904.083l7.557 1.268-2.382 63.943-7.51-1.59z" fill="#419daf" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M7094.629 888.568l.07 2.435-2.15 61.984-.064-2.58z" fill="#255963" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6801.506 818.843l253.489 11.927-253.72-5.016.242-5.59z" fill="#419eb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6801.274 825.754l253.72 5.016-254.279 1.563.473-4.599z" fill="#3e96a8" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M6797.12 847.718l218.947-8.369-48.68 2.459h-.024l-.015-.087-13.553.773-80.48 4.065-49.432 2.496-.648.033-27.29 1.378z"
                fill="#2f717e" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M6705.059 797.308l.524-4.737" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6704.236 807.604l.823-10.296" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6703.708 818.034l.528-10.43" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6703.492 828.247l.215-10.213" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6703.597 837.9l-.105-9.653" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6704.017 846.672l-.42-8.772" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6704.737 854.276l-.72-7.604" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6705.216 857.268l-.48-2.992" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6407.598 645.499l20.414-3.634" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6540.057 634.597l33.652 1.274" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6390.541 650.4l17.057-4.901" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6533.51 634.658l6.547-.06" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6476.656 636.463l11.917-.67" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6621.55 641.192l13.059 2.127" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6634.609 643.318l20.726 5.759" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6655.335 649.077l9.015 4.89" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6342.373 278.097l41.688.216" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6665.326 279.77l38.944.201" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6433.566 317.114l3.794-.012" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6430.232 317.13l3.334-.016" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6462.577 317.131l3.363.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6425.336 317.17l2.125-.021" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6465.94 317.15l2.807.023" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6423.92 317.195l1.416-.024" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6468.747 317.173l2.169.025" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6423.25 317.221l.67-.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6470.916 317.198l1.467.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6472.383 317.224l.727.027" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6584.323 317.286l3.89-.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6605.18 317.275l3.922.012" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6580.83 317.3l3.493-.014" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6609.102 317.287l3.537.015" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6577.84 317.319l2.99-.019" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6612.64 317.302l3.043.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6575.446 317.34l2.395-.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6615.683 317.321l2.458.022" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6573.718 317.364l1.728-.023" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6572.703 317.39l1.015-.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6619.94 317.367l1.09.025" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6572.429 317.415l.274-.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6621.03 317.392l.351.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6495.476 317.945l3.563-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6518.738 317.946l3.442.013" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6489.654 317.974l2.661-.016" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6522.18 317.96l3.006.016" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6487.574 317.993l2.08-.019" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6486.134 318.014l1.44-.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6527.667 317.995l1.88.021" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6485.377 318.036l.757-.022" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6529.547 318.016l1.225.023" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6485.32 318.059l.057-.023" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6530.772 318.039l.537.022" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6504.431 77.635l14.392-16.688" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6577.866 137.187l6.368-7.405" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6584.234 129.782l7.264-6.457" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6518.823 60.947l16.427-14.512" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6535.25 46.435l18.162-12.077" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6591.498 123.325l8.032-5.393" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6553.412 34.358l19.562-9.43" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6599.53 117.932l8.657-4.239" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6572.974 24.927l20.603-6.626" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6595.954 17.784l-2.377.517" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6608.187 113.693l9.13-3.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6617.317 110.682l9.442-1.734" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6595.954 17.784l21.206-3.705" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6617.16 14.079l20.045-.703" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6626.76 108.948l11.063-.495" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6717.83 56.333l-.295-12.215" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6712.68 18.63l-2.533-2.796" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6714.858 24.571l-2.178-5.94" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6637.205 13.376l72.942 2.458" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6716.519 33.27l-1.66-8.699" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6717.535 44.118l-1.016-10.849" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6706.928 110.078l2.7-3.06" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6712.197 100.91l2.254-8.786" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6714.45 92.124l1.774-10.883" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6717.38 69.017l.45-12.684" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6637.823 108.453l69.105 1.625" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6709.628 107.017l2.57-6.107" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6716.224 81.24l1.156-12.223" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6492.34 96.197l12.091-18.562" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6572.507 145.412l5.36-8.225" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6471.466 247.983l-.754-67.493" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6563.123 248.259l-.3-64.324" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6470.712 180.49l1.111-20.88" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6471.824 159.61l4.068-22.048" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6563.493 172.599l1.679-8.869" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6565.172 163.73l3.079-9.418" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6475.892 137.562l6.878-21.267" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6568.251 154.312l4.256-8.9" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6482.77 116.295l9.57-20.098" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6791.795 860.164l2.04-4.752" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6789.484 863.245l2.311-3.081" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6786.977 864.582l2.507-1.337" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6782.953 752.18l-2.652-1.513" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6785.615 755.402l-2.662-3.222" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6788.204 760.26l-2.59-4.858" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6790.637 766.625l-2.433-6.366" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6792.835 774.315l-2.197-7.69" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6794.72 783.097l-1.885-8.782" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6796.23 792.692l-1.51-9.595" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6797.31 802.789l-1.08-10.097" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6797.923 813.054l-.612-10.265" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6798.046 823.145l-.123-10.091" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6797.676 832.725l.37-9.58" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6796.828 841.479l.848-8.754" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6795.532 849.12l1.296-7.641" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6793.834 855.412l1.698-6.291" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6801.45 812.171l-.457-8.151" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6800.158 795.977l-1.182-7.674" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6798.976 788.303l-1.49-7.058" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6797.486 781.245l-1.746-6.22" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6795.74 775.025l-1.946-5.193" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6793.794 769.832l-2.08-4.014" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6705.147 869.362l81.83-4.78" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6705.802 754.092l74.5-3.425" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6785.615 755.402l6.099 10.416" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6331.534 278.739l10.84-.642" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6704.27 279.971l4.221.692" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6324.077 280.108l7.457-1.37" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6347.685 1042.09l10.442 8.793" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6340.197 1023.28l-16.12-743.172" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6340.197 1023.28l7.488 18.81" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6663.464 994.12l-266.604 19.074" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6358.127 1050.883l342.134-25.72" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6700.261 1025.162l4.055-9.526" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6700.261 1025.162l4.01-745.19" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6342.373 278.097l15.754 772.786" fill="none" stroke="#000" stroke-width=".706"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6487.34 275.303l55.97.09" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6353.064 277.464l34.887-1.064" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6387.951 276.4l46.001-.748" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6677.963 277.96l22.131 1.326" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6642.782 276.797l35.18 1.163" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6596.697 275.91l46.085.887" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6543.31 275.392l53.387.518" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6433.952 275.652l53.388-.35" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6384.06 278.313l22.751-.775" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6406.811 277.538l34.95-.65" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6441.76 276.889l41.315-.35" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6483.075 276.539l44.197-.014" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6527.272 276.525l43.217.324" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6570.49 276.849l38.544.627" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6609.034 277.476l30.83.868" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6639.865 278.344l21.045 1.028" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6660.91 279.372l4.416.474" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6352.547 251.123l34.943-1.622" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6708.492 280.663l.14-24.664" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6678.054 251.88l22.17 2.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6323.536 255.153l7.455-2.087" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6387.49 249.5l46.084-1.14" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6330.991 253.066l21.556-1.943" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6324.077 280.108l-.541-24.955" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6700.223 253.9l8.408 2.1" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6642.81 250.106l35.244 1.773" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6407.225 303.742l16.868-.15" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6384.51 304.115l9.317-.153" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6483.352 303.263l21.465-.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6442.113 303.43l20-.08" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6527.465 303.257l21.06.076" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6570.6 303.412l18.84.147" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6660.852 304.622l4.409.227" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6639.844 304.129l10.334.242" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6609.07 303.712l15.11.205" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6387.544 317.807l-2.797-.058" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6405.843 317.997l17.42.11" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6485.323 318.326l-12.201-.027" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6572.432 318.312l-11.56.028" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6653.741 317.794l-13.713.192" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6658.753 317.58l6.491-6.49" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6658.753 317.58l-4.58-.223" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6650.712 304.38l-.534-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6624.9 303.924l-.72-.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6590.295 303.564l-.854-.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6549.445 303.334h-.92" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6505.724 303.258l-.907.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6462.926 303.344l-.814.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6424.746 303.584l-.653.008" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6394.27 303.952l-.443.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6384.737 317.18l1.03-.025" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6665.261 304.86l-14.224-.469" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6650.178 304.371l-24.739-.437" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6624.18 303.917l-33.166-.346" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6589.44 303.559l-39.15-.22" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6548.525 303.332l-41.903-.072" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6504.817 303.26l-41.017.083" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6462.113 303.35l-36.593.229" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6424.093 303.592l-29.207.353" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6393.827 303.962l-9.316.208" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6651.037 304.392l9.815.23" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6651.037 304.392l-.325-.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6625.44 303.934l14.404.195" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6625.44 303.934l-.54-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6591.014 303.571l18.057.141" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6591.014 303.571l-.719-.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6550.29 303.339l20.31.073" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6550.29 303.339l-.845-.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6506.622 303.26l20.843-.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6506.622 303.26l-.898-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6463.8 303.343l19.552-.08" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6463.8 303.343h-.874" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6425.52 303.58l16.593-.15" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6425.52 303.58l-.774.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6394.886 303.945l12.34-.203" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6394.886 303.945l-.615.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6549.008 317.621l.057-.048" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6546.853 317.667l2.155-.046" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6542.77 317.707l4.083-.04" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6537.05 317.74l5.72-.033" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6530.082 317.76l6.968-.02" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6493.547 317.692l5.857.036" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6486.966 317.602l2.334.047" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6486.731 317.553l.235.049" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6549.06 316.709v.864" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6486.723 316.69l.01.863" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6590.299 242.793l-.53.057" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6592.975 242.66l-1.048.037" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6595.385 242.619l-1.245.016" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6602.366 242.746l-.919-.045" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6603.111 242.798l-.745-.052" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6600.385 242.664l-1.175-.027" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6603.663 242.856l-.551-.058" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6604.002 242.917l-.34-.061" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6604.25 317.274v-.38" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6604.131 248.973l-.01-5.993" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6599.21 242.637l-1.25-.017" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6589.77 242.85l-.318.06" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6597.96 242.62l-1.289-.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6604.12 242.98l-.118-.063" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6594.14 242.635l-1.165.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6591.025 242.741l-.726.052" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6589.452 242.91l-.095.063" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6601.447 242.7l-1.062-.036" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6591.927 242.697l-.902.044" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6596.671 242.614l-1.286.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6589.565 317.272v-.447" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6589.373 248.646l-.016-5.673" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6450.401 241.3l-1.242-.029" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6439.947 241.49l-.213.063" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6440.383 241.432l-.436.059" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6446.538 241.247l-1.298.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6441.03 241.379l-.647.053" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6441.866 241.333l-.837.046" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6447.858 241.253l-1.32-.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6444.004 241.269l-1.136.027" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6442.868 241.296l-1.002.037" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6449.158 241.271l-1.3-.018" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6452.56 241.384l-1.014-.047" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6453.41 241.438l-.85-.054" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6454.073 241.497l-.662-.06" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6454.765 241.69v-.065" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6451.547 241.337l-1.146-.038" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6454.76 241.625l-.232-.065" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6454.528 241.56l-.455-.063" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6445.24 241.252l-1.236.017" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6440.747 317.097v-.312" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6439.824 248.298l-.09-6.745" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6455.696 317.107v-.365" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6454.844 248.149l-.08-6.46" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6501.418 317.931v-.196" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6515.215 317.936v-.168" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6396.86 1013.194l-5.802-333.135" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6391.058 680.059l273.25-10.04" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6664.309 670.02l-.845 324.1" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6811.723 779.392l1.021-5.957" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6811.06 786.09l.663-6.698" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6828.158 850.357l-4.275-1.302" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6833.363 850.462l-5.205-.105" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6815.74 764.423l1.893-2.774" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6817.633 761.649l2.09-1.482" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6810.77 793.335l.29-7.246" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6812.744 773.435l1.35-5.04" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6810.828 798.25l-.06-4.915" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6814.095 768.394l1.644-3.97" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6819.723 760.167l2.226-.129" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6824.703 750.506l-2.392 3.507" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6834.354 861.246l2.885.4" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6838.846 757.876l-2.84-4.819" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6837.239 861.647l2.763-1.346" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6827.346 748.6l-2.643 1.906" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6830.856 772.855l10.654-8.66" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6841.51 764.195l-2.664-6.319" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6836.007 753.057l-2.924-3.19" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6833.083 749.866l-2.916-1.491" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6830.167 748.375l-2.821.224" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6831.438 859.14l2.916 2.106" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6838.437 849.074l1.565 11.227" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6975.152 760.738l2.606 4.97" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6968.364 751.781l-3.465-4.719" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6952.618 779.012l.884-6.605" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6965.803 854.138l3.406-1.367" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6961.32 743.948l-3.58-1.439" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6964.899 747.062l-3.579-3.114" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6971.605 757.981l-3.24-6.2" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6977.758 765.708l-6.153-7.726" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6972.36 756.936l2.792 3.802" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6972.096 756.706l.263.23" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6953.502 772.407l1.263-5.58" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6952.203 786.153l.415-7.14" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6952.268 793.626l-.065-7.473" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6952.811 801.22l-.543-7.594" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6953.002 802.645l-.19-1.424" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6967.401 842.036l-.015-.228" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6837.239 861.647l128.564-7.51" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6840.002 860.3l129.207-7.53" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6830.167 748.375l127.573-5.866" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6841.51 764.195l130.095-6.214" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6833.083 749.866l128.237-5.918" fill="none" stroke="#000" stroke-width=".706"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6822.311 754.013l-6.572 10.41" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6823.235 849.088l8.203 10.051" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6972.36 756.936l-7.461-9.874" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6922.513 816.464l6.153-1.134" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6915.524 817.25l6.989-.786" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6855.111 808.453l3.714 2.035" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6869.688 814.053l6.828 1.412" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6876.516 815.465l7.484 1.099" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6891.902 817.311l8.063.37" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6907.926 817.66l7.598-.41" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6863.732 812.376l5.956 1.677" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6937.734 812.185l2.647-1.906" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6940.381 810.279l1.284-2.047" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6928.666 815.33l5.125-1.442" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6852.69 806.335l2.421 2.118" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6858.825 810.488l4.907 1.888" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6933.791 813.888l3.943-1.703" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6899.965 817.68l7.961-.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6884 816.564l7.902.747" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6851.619 804.199l1.071 2.136" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6855.258 799.712l3.717 2" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6884.168 807.68l7.907.734" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6853.686 791.538l-1.632 1.944" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6851.762 795.533l1.072 2.098" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6865.8 786.879l-5.127 1.31" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6852.054 793.482l-.292 2.05" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6933.99 805.051l3.945-1.673" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6860.673 788.19l-4.081 1.567" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6852.834 797.63l2.424 2.082" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6863.885 803.566l5.961 1.648" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6876.678 806.601l7.49 1.08" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6941.764 797.409l-1.47-2.096" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6893.374 784.775l-6.052.014" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6922.706 807.582l6.157-1.114" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6877.203 785.298l-5.384.561" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6937.935 803.378l2.649-1.872" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6915.713 808.354l6.993-.772" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6871.819 785.86l-6.018 1.019" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6858.975 801.711l4.91 1.855" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6869.846 805.214l6.832 1.387" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6892.075 808.414l8.069.364" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6900.144 808.778l7.966-.02" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6908.11 808.757l7.603-.403" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6928.863 806.468l5.128-1.417" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6940.584 801.506l1.283-2.011" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6941.867 799.495l-.103-2.086" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6937.522 793.27l-3.977-1.929" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6933.545 791.341l-5.049-1.76" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6928.496 789.58l-5.962-1.541" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6915.838 786.757l-7.232-.988" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6908.606 785.77l-7.559-.667" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6901.047 785.103l-7.672-.328" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6801.503 818.522l255.782 7.3" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7059.104 839.262l-6.698-1.001" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M7025.696 804.892l9.852.143" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7067.53 825.947l10.668 1.615" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7057.285 825.823l10.246.124" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7035.548 805.035l10.262 1.576" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M7078.198 827.562l10.221 3.065" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7045.81 806.61l9.837 2.966" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7098.018 835.082l8.816 5.762" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7055.647 809.576l9.244 4.298" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7088.42 830.627l9.598 4.455" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7064.891 813.874l33.127 21.208" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M7066.545 841.476l3.73 1.719" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7106.834 840.844l7.885 6.966" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7070.275 843.195l6.499 4.219" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7121.543 855.856l5.653 8.985" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7087.639 858.423l4.186 6.597" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7114.719 847.81l6.824 8.046" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7082.594 852.52l5.045 5.903" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7080.445 850.635l4.373 5.116" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7088.55 861.629l2.245 4.942" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7131.587 874.61l3.06 10.384" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7127.196 864.841l4.39 9.768" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7091.825 865.02l3.26 7.178" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7097.366 879.833l1.265 7.963" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7090.795 866.571l2.356 7.88" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7095.085 872.198l2.28 7.635" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7134.646 884.994l1.682 10.826" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M7093.15 874.451l1.31 8.218" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7093.955 955.667l-1.406-2.68" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7094.46 882.669l.169 5.9" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7136.328 895.82l.263 10.39" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7098.631 887.796l.232 8.157" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7105.763 963.485L7100.65 961" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7094.7 891.003l-.07-2.435" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7118.784 967.704l-6.915-1.959" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M7134.16 970.464l-7.866-1.17" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7136.592 906.21l-2.432 64.254" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M7094.629 888.568l-2.144 61.838" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M7092.549 952.987l-.064-2.58" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7126.294 969.294l-7.51-1.59" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7111.869 965.745l-6.106-2.26" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7100.65 960.999l-3.972-2.634" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7096.677 958.365l-2.722-2.698" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M7098.863 895.953l-2.185 62.412" fill="none" stroke="#000" stroke-width=".706"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6450.261 317.096l1.1.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6454.614 317.103h.397" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6458.763 317.115l1.258.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6460.021 317.12l2.556.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6427.461 317.149l2.628-.019" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6430.09 317.13h.142" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6600.535 317.268h.46" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6618.141 317.343l1.34.018" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6619.481 317.36l.46.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6510.294 317.93h.691" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6510.985 317.93h.432" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6515.215 317.936l3.523.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6492.315 317.958l.947-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6493.262 317.954l2.214-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6525.186 317.976l2.232.017" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6527.418 317.993l.249.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6562.823 183.935l.67-11.336" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6563.493 172.599l.023-.383" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6798.024 845.6l.527-1.879" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6799.411 840.657l.366-2.063" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6800.478 834.64l.218-2.114" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6801.188 827.734l.086-1.98" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6801.274 825.754l.242-5.59" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6801.503 818.522l-.052-6.35" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6800.993 804.02l-.632-6.093" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6800.36 797.927l-.202-1.95" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6795.946 850.466l-2.112 4.946" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6705.583 792.571l.03-5.056" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6700.094 279.286l4.176.685" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6342.373 278.097l10.69-.633" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6596.635 248.754h.05" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6433.574 248.36l6.25-.062" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6487.063 247.828l1.29.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6560.873 318.34l-22.864.018" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6538.009 318.358l-6.698.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6621.382 318.127l13.367-.101" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6634.749 318.026l5.279-.04" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6658.753 317.58l-2.772.118" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6655.98 317.698l-2.239.096" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6654.173 317.357h-.01" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6615.907 316.95l3.847.031" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6619.754 316.981l19.966.16" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6584.16 316.799l5.403.026" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6546.987 316.703h-.181" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6513.456 316.677l-6.569-.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6486.723 316.69l-18.92.017" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6405 316.961h-.134" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6404.866 316.962l-17.948.182" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6386.918 317.144l-1.15.011" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6498.32 317.43l-4.775.029" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6493.545 317.459l-.958.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6505.444 317.408l-6.043.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6499.401 317.427l-1.082.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6513.461 317.397l-6.993.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6506.468 317.407h-1.024" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6521.797 317.4l-7.52-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6514.277 317.397h-.816" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6529.854 317.416l-7.533-.015" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6522.321 317.4h-.524" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6537.056 317.443h-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6542.901 317.48h-.133" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6542.768 317.48l-5.712-.037" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6546.992 317.525l-.14-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6546.852 317.523l-3.95-.043" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6549.065 317.573h-.057" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6549.008 317.571l-2.016-.046" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6522.324 317.77l5.108-.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6527.431 317.764l2.65-.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6515.213 317.768l7.11.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6506.471 317.754h.232" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6499.404 317.728l2.012.007" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6489.3 317.65l3.913.039" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6493.213 317.689l.334.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6488.64 317.506l-1.674.041" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6486.966 317.547l-.235.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6492.587 317.464l-3.289.035" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6489.298 317.499l-.658.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6387.544 317.807l14.76.154" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6402.304 317.96l3.539.037" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6592.121 317.268h.255" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6592.376 317.268h.789" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6573.831 666.435l-.123-30.564" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6621.564 666.349l-.014-25.157" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6441.495 317.096h.543" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6441.395 317.096h.1" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6428.461 672.5l-.45-30.635" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6477.046 671.577l-.39-35.114" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6533.591 646.205l-.08-11.547" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6502.894 317.929h.657" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6499.04 317.935l2.378-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6488.68 646.281l-.107-10.488" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6828.77 767.777l1.965 4.783" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6830.735 772.56l.121.295" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6824.245 761.284l.044.05" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6824.29 761.334l2.253 2.55" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6838.437 849.074l-3.216.88" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6835.22 849.954l-1.857.508" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6821.949 760.038l.04.022" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6821.99 760.06l2.255 1.224" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6828.72 767.69l.05.087" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6967.401 842.036l1.808 10.735" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6838.437 849.074l75.704-4.318" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6830.856 772.855l123.909-6.028" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6954.765 766.827l22.993-1.119" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6856.592 789.757l-2.227 1.364" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6854.365 791.121l-.679.417" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6851.619 804.199l.077-4.685" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6940.295 795.313l-1.356-.999" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6938.939 794.314l-1.417-1.044" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6922.534 788.039l-1.564-.3" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6920.97 787.739l-5.132-.982" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6941.814 801.798l.054-2.303" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6800.36 797.927l10.468.324" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6941.802 802.3l11.2.345" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6953.002 802.645l72.694 2.247" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M7046.258 838.195l6.148.066" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7052.406 838.26l2.184.024" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7066.726 841.53l-.18-.054" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7066.545 841.476l-7.441-2.214" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M7076.774 847.414l3.671 3.221" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7080.445 850.635l2.149 1.885" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7084.818 855.751l3.731 5.878" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7088.55 861.629l.482.76" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M7046.258 838.195l-30.191 1.154" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M7016.067 839.35l-218.948 8.368" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M7016.067 839.35l-48.68 2.458" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6967.386 841.808h-.023" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6447.519 317.095h1.312" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6448.832 317.095h1.293" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6450.125 317.096h.136" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6455.011 317.105h.451" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6599.366 317.267h1.169" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6603.247 317.272h.548" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6603.795 317.273h.338" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6509.11 317.929h1.184" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6514.967 317.936h.076" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6515.043 317.936h.172" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6798.551 843.721l.018-.063" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6798.569 843.658l.842-3.001" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6799.777 838.594l.023-.13" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6799.8 838.465l.678-3.826" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6800.696 832.526l.02-.193" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6800.715 832.333l.473-4.599" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6801.516 820.163l-.01-1.32" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6801.506 818.843v-.32" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6798.024 845.6l-.904 2.118" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6797.12 847.718l-1.174 2.748" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6665.326 279.77v.076" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6664.35 653.967l-.042 16.053" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6391.058 680.059l-.517-29.659" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6384.51 304.115l-.45-25.802" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6705.802 754.092l2.69-473.429" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6705.154 868.16l.017-2.976" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6705.17 865.184l.027-4.68" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6705.197 860.504l.018-3.236" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6560.166 248.216l2.957.043" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6563.123 248.259l26.25.387" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6488.354 247.831l10.52.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6654.163 317.357l-13.415-.2" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6640.748 317.157l-1.028-.015" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6589.563 316.825h.095" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6584.16 316.799l-22.806-.059" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6521.792 316.683l-8.337-.007" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6488.632 316.688l-1.91.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6442.034 316.781h-.643" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6441.39 316.783h-.434" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6432.866 316.808l-2.971.016" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6429.895 316.824l-24.895.137" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6537.048 317.443l-6.969-.027" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6530.08 317.416h-.226" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6515.041 317.768h.172" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6514.094 317.768h.187" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6588.212 317.275l1.353-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6595.562 317.266h1.12" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6573.708 635.87l-1.275-317.558" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6572.432 318.312v-.897" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6621.55 641.192l-.168-323.065" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6621.382 318.127v-.71" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6442.038 317.095h.833" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6440.96 317.096h.435" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6428.012 641.865l-4.75-323.758" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6423.263 318.108l-.013-.887" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6476.656 636.463l-3.534-318.164" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6473.122 318.3l-.012-1.05" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6533.51 634.658l-2.199-316.295" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6531.311 318.363v-.302" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6488.573 635.793l-3.25-317.467" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6485.323 318.326v-.267" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6826.543 763.885l.013.023" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6826.556 763.908l2.165 3.783" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6967.348 841.721l.015.088" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6967.363 841.809l.038.227" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6914.141 844.756l39.654-2.262" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6953.795 842.494l13.553-.773" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6851.696 799.514l.012-.698" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6851.708 798.816l.054-3.283" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6941.665 808.232l.137-5.933" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6941.802 802.3l.012-.502" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6810.828 798.25l32.421 1.003" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6843.25 799.253l8.446.261" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6953.795 842.494l-80.48 4.065" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6873.316 846.559l-49.433 2.496" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6823.883 849.055l-.648.033" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6823.235 849.088l-27.29 1.378" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6445.841 317.094h.387" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6446.228 317.094h1.29" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6451.36 317.098l1.14.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6452.5 317.1l1.007.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6453.507 317.102h.846" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6454.353 317.103h.26" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6455.462 317.106h.234" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6455.696 317.107l3.067.008" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6598.123 317.266h1.243" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6602.505 317.27h.742" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6604.133 317.274h.118" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6604.25 317.274h.93" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6506.918 317.927h.983" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6507.9 317.928h1.21" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6511.417 317.93l1.029.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6514.095 317.934h.57" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6514.666 317.935h.3" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6665.326 279.846l-.065 25.003" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6390.541 650.4l-5.794-332.651" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6705.792 755.741l.01-1.649" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6704.316 1015.636l.83-146.274" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6705.147 869.362l.01-1.203" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6600.397 248.864l1.062.031" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6543.144 247.964l2.491.037" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6589.373 248.646h.095" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6440.475 248.292l.646-.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6441.121 248.285l.837-.008" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6498.874 247.857l11.867.028" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6589.658 316.825l.316.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6561.354 316.74l-12.293-.031" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6549.06 316.709l-2.073-.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6529.849 316.69l-8.057-.006" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6492.58 316.684l-3.948.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6442.866 316.779l-.832.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6440.956 316.784h-.213" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6440.743 316.785l-7.877.023" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6514.28 317.768h.384" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6514.664 317.768h.377" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6501.416 317.735h.028" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6501.444 317.735h.237" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6501.681 317.736l.44.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6593.165 317.268h1.158" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6594.323 317.267h1.239" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6444.998 317.094h.843" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6437.36 317.102l3.387-.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6440.747 317.097h.213" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6503.55 317.929h.945" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6504.495 317.928h1.062" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6505.557 317.928h1.147" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6506.704 317.927h.214" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6501.418 317.931h.028" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6501.446 317.931h.237" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6501.683 317.931h.44" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6596.682 317.266h.159" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6596.84 317.266h1.282" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6600.996 317.268h.596" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6601.592 317.27h.913" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6512.446 317.932h.902" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6513.347 317.933h.748" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6665.244 311.09l-.894 342.877" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6705.773 759.115l.02-3.374" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6599.223 248.83l1.174.034" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6603.123 248.944l.55.016" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6603.673 248.96l.34.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6545.635 248.001l8.579.127" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6554.214 248.128l5.952.087" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6589.468 248.648l.317.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6439.824 248.298l.214-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6440.038 248.296l.437-.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6510.741 247.885l12.348.03" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6589.974 316.827l.526.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6537.052 316.695l-7.203-.006" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6498.312 316.68l-5.732.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6512.444 317.765l.902.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6513.346 317.766h.748" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6502.121 317.738l.629.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6589.565 317.272h.095" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6590.502 317.27h.722" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6591.224 317.27h.897" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6442.87 317.095h.998" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6443.868 317.094h1.13" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6502.123 317.93h.629" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6502.752 317.93h.142" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6665.258 305.883l-.014 5.208" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6384.747 317.749l-.01-.57" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6384.737 317.18l-.019-1.127" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6384.718 316.053l-.207-11.883" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6384.511 304.17v-.055" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6705.745 764.14l.029-5.025" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6596.685 248.755l1.288.038" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6597.973 248.793l1.25.036" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6601.46 248.895l.917.027" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6602.377 248.922l.746.022" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6604.013 248.97l.118.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6604.131 248.973l38.678 1.133" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M6441.958 248.277l1.002-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6442.96 248.267l1.135-.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6444.095 248.256l1.236-.013" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6523.089 247.915l11.92.03" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6535.01 247.944l8.133.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6546.806 316.703l-3.91-.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6542.896 316.7l-5.844-.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6506.887 316.671h-1.45" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6505.438 316.673l-7.126.007" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6467.803 316.707l-7.946.023" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6444.994 316.773l-1.13.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6443.863 316.776l-.997.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6509.108 317.759l1.184.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6510.292 317.76l1.124.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6511.416 317.763l1.028.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6502.75 317.74l.799.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6589.66 317.272h.315" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6589.975 317.272h.527" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6665.261 304.849v.012" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6665.261 304.86v1.023" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6705.708 770.682l.037-6.542" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6591.942 248.684l1.049.016" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6595.4 248.736l1.235.018" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6592.12 316.837l1.044.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6506.703 317.754l1.196.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6507.9 317.756l1.208.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6505.556 317.75l.915.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6705.612 787.515l.051-8.962" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6705.663 778.553l.045-7.871" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6589.785 248.653l.53.008" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6592.991 248.7l1.164.017" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6594.155 248.717l1.244.019" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6471.466 247.983l2.717-.027" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6474.184 247.956l5.748-.057" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6479.932 247.9l7.131-.072" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6590.5 316.83l.723.003" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6591.223 316.833l.897.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6593.164 316.842l1.158.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6594.322 316.847l1.239.006" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6450.12 316.758l-1.293.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6448.827 316.762l-1.312.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6503.549 317.743l.945.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6504.494 317.746l1.062.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6590.314 248.66l.727.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6591.04 248.671l.902.013" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6603.246 316.89l.548.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6603.794 316.893l.338.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6451.356 316.755l-1.235.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6447.515 316.766l-1.292.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6446.223 316.77l-1.229.003" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6445.33 248.243l1.298-.012" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6446.628 248.23l1.32-.013" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6447.947 248.217l1.3-.012" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6602.504 316.887l.742.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6604.132 316.894h.118" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6604.25 316.895l11.657.055" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6449.247 248.205l1.241-.013" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6601.591 316.882l.913.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6454.348 316.746l-.845.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6453.503 316.749l-1.008.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6452.495 316.751l-1.139.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6600.534 316.877l1.057.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6455.007 316.744l-.659.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6450.488 248.192l1.145-.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6451.633 248.18l1.012-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6452.645 248.17l.85-.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6599.366 316.871l1.168.006" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6455.457 316.743h-.45" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6453.494 248.162l.662-.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6598.121 316.866l1.245.006" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6459.857 316.73l-4.165.012" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6455.692 316.742h-.235" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6454.156 248.156l.453-.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6595.56 316.853l1.28.006" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6596.84 316.86l1.281.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6454.61 248.151l.234-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M6454.844 248.149l16.622-.166" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
        </g>
    </g>

<slot/>
</svg>