<script>
  import { fade } from "svelte/transition";

  const raw = `
    <g transform="translate(-102.9 -100.545)">
        <g transform="translate(-2.895 89.079)">
            <path
                d="M106.001 54.376V13.873l.672-.098v40.523l.044.008zm2.869-40.923l10.96-1.604v41.07l.085.016.185-.018.264-.004.177.033-11.671 1.148zm9.164 4.82l-3.66.51v18.408l.858-.101 2.802-.33V18.613z"
                fill="#bebebe" />
            <path d="M106.001 54.376l.716-.07-.044-.008 2.154-.212.043.008 11.671-1.148.853.157-14.527 1.425z"
                fill="#434343" />
            <path d="M121.394 53.103l-.853-.157-.177-.033-.264.004-.185.018-.086-.016.148-.014.405-.008 1.082.199z"
                fill="#b8c8d2" />
            <path d="M108.87 13.453v40.64l-.043-.007V13.442z" fill="#bbb" />
            <path
                d="M106.673 13.757l2.154-.315v40.644l-2.154.212V13.775zm.031 8.914l2.089-.283v-.722l-2.089.285zm13.273 30.234l-.148.014V11.706l.148-.022z"
                fill="#5b5b5b" />
            <path d="M120.382 52.897l-.405.008V11.684l.405-.012z" fill="#606060" />
            <path d="M121.464 53.096l-1.082-.2V11.673l1.082.296z" fill="#3b3b3b" />
            <path d="M114.374 18.783l3.66-.51v.34l-2.802.39z" fill="#434343" />
            <path d="M114.374 37.19V18.784l.858.22V37.09z" fill="#7c7c7c" />
            <path d="M108.793 22.388l-2.089.283v-.72l2.089-.285z" fill="#5b5b5b" />
            <path d="M106.001 13.873v40.503M119.83 11.85l-10.96 1.603" fill="none" stroke="#000" stroke-width=".411"
                stroke-miterlimit="10" />
            <path d="M106.001 54.376l.716-.07" fill="none" stroke="#000" stroke-width=".206" stroke-miterlimit="10" />
            <path d="M106.867 54.528l14.527-1.425m-15.393 1.273l.866.152" fill="none" stroke="#000" stroke-width=".411"
                stroke-miterlimit="10" />
            <path d="M120.541 52.946l.853.157m-3.36-34.829l-3.66.51m0-.001v18.408" fill="none" stroke="#000"
                stroke-width=".206" stroke-miterlimit="10" />
            <path d="M118.034 18.613l-2.802.39m0-.001V37.09" fill="none" stroke="#000" stroke-width=".411"
                stroke-miterlimit="10" />
            <path d="M114.374 18.783l.858.22m-6.362 35.091l11.671-1.148M108.87 13.453v40.64" fill="none" stroke="#000"
                stroke-width=".206" stroke-miterlimit="10" />
            <path d="M106.673 13.775l-.672.098m2.826-.431l-2.154.315" fill="none" stroke="#000" stroke-width=".411"
                stroke-miterlimit="10" />
            <path d="M106.673 54.298l2.154-.212m0-40.644v40.644" fill="none" stroke="#000" stroke-width=".206"
                stroke-miterlimit="10" />
            <path d="M108.87 13.453l-.043-.011m-2.11 40.864l-.044-.008" fill="none" stroke="#000" stroke-width=".411"
                stroke-miterlimit="10" />
            <path d="M108.87 54.094l-.043-.008" fill="none" stroke="#000" stroke-width=".206" stroke-miterlimit="10" />
            <path d="M120.541 52.946l-.177-.033m-.449.022l-.086-.016" fill="none" stroke="#000" stroke-width=".411"
                stroke-miterlimit="10" />
            <path d="M119.83 52.92l.147-.015m0 0l.405-.008" fill="none" stroke="#000" stroke-width=".206"
                stroke-miterlimit="10" />
            <path d="M120.1 52.917l-.185.018m.185-.018l.264-.004" fill="none" stroke="#000" stroke-width=".411"
                stroke-miterlimit="10" />
            <path d="M120.382 52.897l1.082.199" fill="none" stroke="#000" stroke-width=".206" stroke-miterlimit="10" />
            <path
                d="M121.464 53.096l-.07.007m-1.012-41.431l1.082.296m-1.487-.284l.405-.012m-.552.034l.147-.022m1.487 41.412V11.968"
                fill="none" stroke="#000" stroke-width=".411" stroke-miterlimit="10" />
            <path d="M120.382 52.897V11.672m-.405 41.233V11.684" fill="none" stroke="#000" stroke-width=".206"
                stroke-miterlimit="10" />
            <path
                d="M114.374 37.19l.858-.1m0 0l2.802-.33m0 0V18.613m0 0v-.34m-11.361 36.025V13.775m0 0v-.018M119.83 52.92V11.85m0 0v-.144"
                fill="none" stroke="#000" stroke-width=".411" stroke-miterlimit="10" />
        </g>
    </g>
`;
</script>

<svg width="59.997" height="163.526" viewBox="0 0 15.874 43.266" transition:fade {...$$restProps}>
  {@html raw}
</svg>

<style>
  svg {
    position: absolute;
  }
</style>
