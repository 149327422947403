<script lang="ts">
  import { onMount } from "svelte";
  import DefrostSVG from "./defrost.svg";

  export let wave: boolean = true;
  export let coil: boolean = true;

  let div: HTMLDivElement;
  let waveEL: SVGElement | null;
  let coilEL: SVGElement | null;

  $: if (waveEL) waveEL.style.visibility = wave ? "visible" : "hidden";
  $: if (coilEL) coilEL.style.visibility = coil ? "visible" : "hidden";

  onMount(() => {
    let svg = div.getElementsByTagName("svg")[0];
    waveEL = svg.getElementById("defrost__wave") as SVGElement | null;
    coilEL = svg.getElementById("defrost__coil") as SVGElement | null;
  });
</script>

<div bind:this={div} {...$$restProps}>
  <DefrostSVG />
</div>

<style>
  div {
    position: absolute;
  }
</style>
