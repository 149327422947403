<script lang="ts">
  import { onMount } from "svelte";
  import SteamerOverlaySVG from "./steamer-overlay.svg";
  import SteamerEmptySVG from "./steamer-empty.svg";
  import SteamerFullSVG from "./steamer-full.svg";
  import SteamerWaterDrainSVG from "./steamer-water-drain.svg";

  export let power: boolean = true;
  export let steam: boolean = true;
  export let water: boolean = true;

  let mode = 2;
  const steamerModes = [SteamerEmptySVG, SteamerWaterDrainSVG, SteamerFullSVG];

  let div: HTMLDivElement;
  let powerEL: SVGImageElement | null;
  let steamEL: SVGImageElement | null;
  let waterEL: SVGElement | null;

  $: if (powerEL) powerEL.style.visibility = power ? "visible" : "hidden";
  $: if (steamEL) steamEL.style.visibility = steam ? "visible" : "hidden";
  $: if (waterEL) waterEL.style.visibility = water ? "visible" : "hidden";

  onMount(() => {
    const svgs = div.getElementsByTagName("svg");
    let svg = svgs[svgs.length - 1]; // Get the overlay svg
    powerEL = svg.getElementById("steamer__power") as SVGImageElement | null;
    steamEL = svg.getElementById("steamer__steamCloud") as SVGImageElement | null;
    waterEL = svg.getElementById("steamer__waterInlet") as SVGElement | null;

    let interval = setInterval(() => {
      mode = (mode + 1) % 3;
    }, 5000);
    return () => clearInterval(interval);
  });
</script>

<div class="steamer" bind:this={div} {...$$restProps}>
  <svelte:component this={steamerModes[mode]} />
  <!-- This must be last! -->
  <SteamerOverlaySVG />
</div>

<style>
  .steamer {
    position: absolute;
  }
  :global(.steamer > *) {
    position: absolute;
  }
</style>
