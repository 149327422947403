<svg width="274.045" height="365.198" viewBox="0 0 72.508 96.625" version="1.1" {...$$props} >
    <g transform="translate(166.309 104.967)">
        <g transform="matrix(.0713 0 0 .0713 -435.296 -83.315)">
            <path d="M4155.059 797.308l.524-4.737.03-5.056 89.108-4.418 1.51 9.595z" fill="#585858" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M4155.583 792.571l-.524 4.737-.823 10.296-.528 10.43-.215 10.213.104 9.653.42 8.772.72 7.604.479 2.992-.018 3.236-.026 4.68-.017 2.975-.01 1.203-.831 146.274-4.055 9.526 4.01-745.19 4.22.691-2.69 473.43-.01 1.648-.018 3.374-.03 5.025-.036 6.542-.045 7.871-.051 8.962z"
                fill="#484848" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4154.236 807.604l.823-10.296 91.171-4.616 1.08 10.097z" fill="#5d5d5d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4153.708 818.034l.528-10.43 93.075-4.815.612 10.265z" fill="#5f5f5f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4153.493 828.247l.215-10.213 94.215-4.98.124 10.09z" fill="#606060" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4153.597 837.9l-.104-9.653 94.553-5.102-.37 9.58z" fill="#5e5e5e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4154.018 846.672l-.42-8.772 94.079-5.175-.849 8.754z" fill="#5b5b5b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4154.737 854.276l-.72-7.604 92.811-5.193-1.296 7.642z" fill="#565656" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4155.216 857.268l-.479-2.992 90.795-5.155-1.698 6.291-88.637 5.092z" fill="#4f4f4f"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4246.23 792.692l-1.51-9.595 4.256 5.206z" fill="#333" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4247.31 802.789l-1.08-10.097 3.929 3.285z" fill="#353535" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4247.923 813.054l-.612-10.265 3.682 1.231z" fill="#373737" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4248.047 823.145l-.124-10.091 3.528-.883z" fill="#373737" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4247.677 832.725l.37-9.58 3.47-2.982z" fill="#363636" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4246.828 841.479l.849-8.754 3.512-4.99z" fill="#343434" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4245.532 849.12l1.296-7.641 3.65-6.84z" fill="#323232" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4243.834 855.412l1.698-6.291 3.88-8.464z" fill="#2e2e2e" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4155.198 860.504l88.636-5.092-2.039 4.752-86.624 5.02z" fill="#474747" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3954.431 77.635l14.392-16.688 2.597-.64-14.35 16.641z" fill="#505b67" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3957.07 76.948l14.35-16.641 4.733 1.8-14.031 16.273z" fill="#667483" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3971.42 60.307l-2.597.64 16.428-14.512 2.55-.599z" fill="#4f5b66" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4034.234 129.782l-6.367 7.405.602-2.306 6.546-7.613z" fill="#384048" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3954.431 77.635l2.639-.687-12.057 18.51-2.672.739z" fill="#505b67" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4035.015 127.268l-6.546 7.613-1.939-4.212 6.99-8.127z" fill="#3f4952" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4033.52 122.542l-6.99 8.127-4.396-5.866 7.67-8.917z" fill="#576470" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4029.804 115.886l-7.67 8.917-6.587-7.133 8.546-9.932z" fill="#6c7c8b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4015.547 117.67l6.587 7.133-6.454 9.907-7.323-6.004z" fill="#6c7c8b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4029.804 115.886l-5.711-8.148 9.749-8.652 4.712 9.03z" fill="#6c7c8c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4024.093 107.738l-8.546 9.932-8.34-7.906 9.557-11.102z" fill="#7d8fa1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4022.134 124.803l4.396 5.866-5.882 9.027-4.968-4.986z" fill="#576470" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4033.52 122.542l-3.716-6.656 8.75-7.77 2.938 7.343z" fill="#586471" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4024.093 107.738l-7.329-9.076 10.903-9.668 6.175 10.092z" fill="#7d8fa1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4016.764 98.662l-9.556 11.102-9.504-8.11 10.63-12.345z" fill="#889cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4008.334 89.31l-10.63 12.344-9.977-7.72 11.688-13.568z" fill="#8ea2b7" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3987.727 93.933l9.977 7.721-8.94 13.723-10.864-6.358z" fill="#8ea2b7" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4008.334 89.31l-8.92-8.944 13.337-11.808 7.711 10.005z" fill="#8ea2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3999.415 80.366l-11.688 13.567-9.716-6.76 12.651-14.682z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3990.662 72.49l-12.651 14.682-8.745-5.31 13.452-15.604z" fill="#8599ac" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3990.662 72.49l-7.944-6.232 15.353-13.573 7.03 7.033z" fill="#8598ab" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3978.01 87.172l9.717 6.761-9.827 15.086-10.525-5.52z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3999.415 80.366l-8.753-7.875 14.438-12.773 7.651 8.84z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3982.718 66.258l-13.452 15.605-7.144-3.483 14.03-16.273z" fill="#78899b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3969.266 81.863l8.745 5.31-10.636 16.326-9.414-4.28z" fill="#8699ac" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3976.153 62.107l-4.733-1.8 16.38-14.47 4.368 2.118z" fill="#657482" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4028.469 134.881l-.602 2.306-5.36 8.225.452-2.074z" fill="#384048" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4034.234 129.782l.781-2.514 7.468-6.636-.985 2.693z" fill="#373f47" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3962.122 78.38l7.144 3.483-11.306 17.356-7.628-2.738z" fill="#78899b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3982.718 66.258l-6.565-4.151 16.015-14.152 5.903 4.73z" fill="#78899a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4016.764 98.662l-8.43-9.353 12.128-10.746 7.205 10.43z" fill="#899cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3957.07 76.948l5.052 1.432-11.79 18.1-5.32-1.021z" fill="#667583" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3997.704 101.654l9.504 8.11-8.04 12.34-10.404-6.727z" fill="#889cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4007.208 109.764l8.339 7.906-7.19 11.036-9.188-6.602z" fill="#7d8fa1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4035.015 127.268l-1.495-4.726 7.972-7.083.99 5.173z" fill="#404952" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4026.53 130.669l1.939 4.212-5.51 8.457-2.31-3.642z" fill="#3f4852" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4020.462 78.563l-7.71-10.005 14.742-9.837 6.375 10.883z" fill="#8ea2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3992.168 47.955l-4.368-2.119 18.109-12.042 3.963 2.382z" fill="#657381" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3998.07 52.685l-5.902-4.73 17.704-11.779 5.17 5.209z" fill="#789" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4033.842 99.086l-6.175-10.092 12.052-8.064 4.9 10.937z" fill="#7d8fa1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3987.8 45.836l-2.55.6 18.162-12.078 2.497-.564z" fill="#4e5a65" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4041.492 115.459l-2.938-7.342 9.673-6.487 2.08 7.913z" fill="#586572" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4038.554 108.117l-4.712-9.03 10.778-7.22 3.607 9.763z" fill="#6d7d8c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4027.667 88.994l-7.205-10.431 13.407-8.959 5.85 11.326z" fill="#899cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4042.483 120.632l-.991-5.173 8.814-5.916.434 5.545z" fill="#414a53" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4012.751 68.558l-7.65-8.84 15.96-10.638 6.433 9.64z" fill="#8ca0b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4041.498 123.325l.985-2.693 8.257-5.544-1.21 2.844z" fill="#363e46" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4005.1 59.718l-7.03-7.033 16.972-11.3 6.019 7.695z" fill="#8598ab" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4039.72 80.93l-5.85-11.326 14.444-7.014 4.391 12.02z" fill="#899cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4005.909 33.794l-2.497.564 19.562-9.43 2.439-.538z" fill="#4d5864" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4021.06 49.08l-6.018-7.695 18.28-8.83 4.93 8.208z" fill="#8497aa" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4027.494 58.72l-6.433-9.64 17.191-8.317 5.123 10.26z" fill="#8ca0b4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4044.62 91.867l-4.9-10.937 12.985-6.32 3.528 11.594z" fill="#7e90a2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4050.306 109.543l-2.079-7.913 10.425-5.091 1.154 8.358z" fill="#596673" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4050.74 115.088l-.434-5.545 9.5-4.646-.167 5.835z" fill="#424b55" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4015.042 41.385l-5.17-5.209 19.068-9.2 4.382 5.58z" fill="#768798" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4009.872 36.176l-3.963-2.382 19.504-9.404 3.527 2.586z" fill="#637280" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4048.227 101.63l-3.607-9.763 11.613-5.663 2.419 10.335z" fill="#6e7d8d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4033.87 69.604l-6.376-10.883 15.881-7.697 4.939 11.566z" fill="#8ea2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4049.53 117.932l1.21-2.844 8.899-4.356-1.452 2.961z" fill="#353d44" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4038.252 40.763l-4.93-8.208 19.254-6.21 3.787 8.563z" fill="#8396a9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4043.375 51.024l-5.123-10.26 18.11-5.856 3.746 10.69z" fill="#8b9fb3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4048.314 62.59l-4.939-11.566 16.733-5.426 3.426 12.04z" fill="#8da2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4025.413 24.39l-2.44.537 20.604-6.626 2.377-.517z" fill="#4c5762" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4028.94 26.976l-3.527-2.586 20.541-6.606 3.07 2.727z" fill="#62707e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4033.322 32.555l-4.382-5.579 20.083-6.465 3.553 5.835z" fill="#758696" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4052.705 74.61l-4.391-12.02 15.22-4.952 2.858 12.504z" fill="#899db0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4056.233 86.204l-3.528-11.594 13.687-4.468 2.084 12.053z" fill="#7f91a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4058.187 113.693l1.452-2.961 9.385-3.093-1.707 3.043z" fill="#343b42" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4058.652 96.539l-2.419-10.335 12.243-4.01 1.166 10.735z" fill="#6f7e8e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4059.806 104.897l-1.154-8.358 10.99-3.61.182 8.671z" fill="#5a6774" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4059.639 110.732l.167-5.835 10.018-3.297-.8 6.039z" fill="#434d57" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4052.577 26.346l-3.554-5.835 20.734-3.628 2.7 5.972z" fill="#748495" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4063.535 57.638l-3.427-12.04 17.282-3.067 1.87 12.298z" fill="#8da1b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4066.392 70.142l-2.857-12.504 15.725-2.81 1.276 12.77z" fill="#899db1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4067.317 110.682l1.707-3.043 9.705-1.78-1.97 3.089z" fill="#323940" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4068.476 82.195l-2.084-12.053 14.144-2.543.594 12.305z" fill="#7f91a4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4056.363 34.908l-3.786-8.562 19.88-3.49 2.608 8.753z" fill="#8295a7" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4060.108 45.598l-3.745-10.69 18.702-3.3 2.325 10.923z" fill="#8b9fb2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4069.024 107.639l.8-6.039 10.358-1.893-1.453 6.152z" fill="#454f59" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4069.823 101.6l-.18-8.67 11.362-2.07-.823 8.847z" fill="#5c6976" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4069.642 92.93l-1.166-10.735 12.654-2.29-.124 10.956z" fill="#708090" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4049.023 20.511l-3.069-2.727 21.206-3.705 2.597 2.804z" fill="#606e7c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4080.536 67.599l-1.276-12.77 15.949-.627.069 12.798z" fill="#899db1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4078.729 105.86l1.453-6.153 11.81-.518-1.974 6.168z" fill="#47515b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4076.76 108.948l1.969-3.089 11.289-.502-2.195 3.096z" fill="#30373e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4069.757 16.883l-2.597-2.804 20.045-.703 2.262 2.81z" fill="#5f6c7a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4081.13 79.904l-.594-12.305 14.742-.599-.55 12.336z" fill="#8092a5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4080.182 99.707l.824-8.846 12.596-.541-1.61 8.869z" fill="#5d6b78" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4072.457 22.855l-2.7-5.972 19.71-.697 2.071 5.983z" fill="#728293" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4081.006 90.86l.124-10.956 13.597-.568-1.125 10.984z" fill="#718191" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4075.065 31.609l-2.608-8.754 19.081-.686 1.729 8.768z" fill="#8193a6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4077.39 42.53l-2.325-10.921 18.202-.672 1.255 10.942z" fill="#8a9eb1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4079.26 54.829l-1.87-12.298 17.132-.652.687 12.323z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4091.992 99.189l1.61-8.87 70.85 1.805-2.255 8.786z" fill="#5e6c79" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4093.267 30.937l-1.729-8.768 73.32 2.402 1.66 8.698z" fill="#8092a5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4090.018 105.357l1.974-6.168 70.205 1.72-2.568 6.108z" fill="#48525c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4091.538 22.169l-2.071-5.983 73.213 2.445 2.178 5.94z" fill="#718191" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4094.522 41.88l-1.255-10.943 73.252 2.332 1.016 10.85z" fill="#899db1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4095.209 54.202l-.687-12.323 73.014 2.24.294 12.214z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4095.278 67l-.07-12.798 72.622 2.131-.45 12.684z" fill="#8a9db1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4094.727 79.336l.55-12.336 72.104 2.017-1.157 12.224z" fill="#8093a5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4089.467 16.186l-2.262-2.81 72.943 2.458 2.532 2.797z" fill="#5e6b78" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4093.602 90.32l1.125-10.984 71.497 1.905-1.773 10.883z" fill="#728292" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4087.823 108.453l2.195-3.096 69.61 1.66-2.7 3.06z" fill="#30363d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3945.013 95.459l5.319 1.022-9.336 19.598-5.528-.578z" fill="#667483" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3957.96 99.22l9.415 4.279-8.427 17.676-9.942-3.164z" fill="#8599ac" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3977.9 109.019l10.864 6.358-7.09 14.854-11.564-4.88z" fill="#8ea2b7" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3942.34 96.197l2.673-.738-9.545 20.042-2.698.794z" fill="#505b67" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3967.375 103.499l10.525 5.52-7.79 16.331-11.162-4.175z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3950.332 96.48l7.629 2.74-8.955 18.791-8.01-1.932z" fill="#78899b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3988.764 115.377l10.405 6.727-6.378 13.356-11.116-5.229z" fill="#889cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4008.357 128.706l7.323 6.004-5.125 10.72-7.905-4.779z" fill="#6c7c8b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3999.169 122.104l9.188 6.602-5.707 11.945-9.86-5.191z" fill="#7d8fa1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4015.68 134.71l4.968 4.986-4.67 9.77-5.423-4.035z" fill="#576470" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4022.959 143.338l-.452 2.074-4.256 8.9.332-1.824z" fill="#384048" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4020.648 139.696l2.31 3.642-4.375 9.15-2.606-3.022z" fill="#404952" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3921.466 247.983l-.754-67.493 2.72-.932.752 68.398z" fill="#4c5762" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3924.184 247.956l-.751-68.398 5.77-.722.73 69.063z" fill="#63717f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3923.433 179.558l-2.72.932 1.11-20.88 2.724-.914z" fill="#4d5863" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3929.933 247.9l-.73-69.064 8.459-.458.692 69.453-1.29-.004-7.131.071z" fill="#758697"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3938.354 247.831l-.692-69.453 10.57-.156.642 69.635z" fill="#8396a9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3948.232 178.222l-10.57.156 1.078-19.863 10.534.761z" fill="#8497aa" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3948.874 247.857l-.642-69.635 11.927.16.582 69.503z" fill="#8ca0b4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3960.741 247.885l-.582-69.503 12.412.466.518 69.067z" fill="#8da2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3929.203 178.836l-5.77.722 1.114-20.862 5.759-.377z" fill="#637280" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3937.662 178.378l-8.46.458 1.104-20.517 8.434.196z" fill="#768798" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3960.159 178.382l-11.927-.16 1.042-18.946 11.879 1.277z" fill="#8ca0b4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4012.823 183.935l.3 64.324-2.957-.043-.318-65.423z" fill="#434c56" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4012.823 183.935l-2.975-1.142.723-12.265 2.945 1.688-.023.383z" fill="#424c55" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4009.848 182.793l.318 65.423-5.952-.088-.352-66.495z" fill="#5a6774" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4003.863 181.633l.351 66.495-8.579-.127-.398-67.464z" fill="#6e7e8e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3995.237 180.537l.398 67.464-2.491-.037-8.135-.02-.455-68.36z" fill="#7e90a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3995.237 180.537l-10.683-.953.88-15.33 10.623 2.148z" fill="#7e90a2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3984.554 179.584l.455 68.36-11.92-.029-.518-69.067z" fill="#899db0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4003.863 181.633l-8.626-1.096.82-14.135 8.572 2.143z" fill="#6e7e8d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3984.554 179.584l-11.983-.736.938-16.593 11.924 1.999z" fill="#899cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4009.848 182.793l-5.986-1.16.767-13.088 5.942 1.983z" fill="#596673" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3972.57 178.848l-12.411-.466.994-17.829 12.356 1.702z" fill="#8ea2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3938.74 158.515l-8.434-.196 3.976-21.5 8.28 1.078z" fill="#789" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3973.51 162.255l-12.357-1.702 3.342-17.92 12.065 3.319z" fill="#8ea2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3924.547 158.696l-2.723.914 4.068-22.048 2.715-.852z" fill="#4e5a65" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3985.433 164.254l-11.924-2 3.051-16.302 11.625 3.642z" fill="#899cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3930.306 158.32l-5.76.376 4.06-21.986 5.676.108z" fill="#647381" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3949.274 159.276l-10.534-.761 3.822-20.618 10.317 1.984z" fill="#8497aa" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3961.153 160.553l-11.88-1.277 3.606-19.395 11.616 2.751z" fill="#8ca0b4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4013.516 172.216l-2.945-1.688 2.028-10.724 2.818 2.367-.297 1.57z" fill="#414a54" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4010.57 170.528l-5.941-1.983 2.222-11.769 5.748 3.028z" fill="#586572" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4004.629 168.545l-8.572-2.143 2.47-13.11 8.323 3.484z" fill="#6d7d8c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3996.057 166.402l-10.624-2.148 2.752-14.66 10.341 3.697z" fill="#7e90a2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3928.607 136.71l-2.715.852 6.878-21.267 2.698-.794z" fill="#4f5b66" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3934.282 136.818l-5.675-.108 6.861-21.209 5.528.578z" fill="#657482" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3998.526 153.291l-10.34-3.697 4.605-14.134 9.859 5.191z" fill="#7d8fa1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4015.417 162.171l-2.818-2.367 3.378-10.338 2.606 3.022z" fill="#404952" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4015.172 163.73l-.052.01.297-1.569z" fill="#414a54" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3988.185 149.594l-11.625-3.642 5.115-15.72 11.116 5.228z" fill="#899cb0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3964.495 142.632l-11.616-2.751 6.069-18.706 11.162 4.175z" fill="#8da1b5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3952.88 139.88l-10.318-1.983 6.444-19.886 9.942 3.164z" fill="#8598ab" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3976.56 145.952l-12.065-3.32 5.615-17.282 11.565 4.881z" fill="#8ea2b6" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4015.172 163.73l.245-1.559 3.166-9.683-.332 1.824z" fill="#373f47" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3942.562 137.897l-8.28-1.079 6.714-20.739 8.01 1.932z" fill="#78899a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4012.599 159.804l-5.749-3.028 3.705-11.345 5.422 4.035z" fill="#586471" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4006.85 156.776l-8.324-3.485 4.124-12.64 7.905 4.78z" fill="#6d7c8c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4241.795 860.164l-2.311 3.08-84.33 4.915.017-2.975z" fill="#3e3e3e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4239.484 863.245l-2.506 1.337-81.831 4.78.01-1.203z" fill="#333" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4230.301 750.667l2.652 1.513-77.16 3.561.01-1.649z" fill="#222" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4232.953 752.18l2.662 3.222-79.841 3.713.019-3.374z" fill="#2d2d2d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4155.774 759.115l79.84-3.713 2.59 4.857-82.459 3.88z" fill="#383838" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4238.204 760.26l-2.59-4.858 3.528 6.024.381.65 1.165 1.99 1.026 1.752z" fill="#1e4a52"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4155.745 764.14l82.46-3.88 2.433 6.365-84.93 4.057z" fill="#424242" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4240.638 766.625l-2.434-6.366 5.59 9.573z" fill="#272727" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4155.708 770.682l84.93-4.057 2.197 7.69-87.172 4.238z" fill="#4b4b4b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4242.835 774.315l-2.197-7.69 5.102 8.4z" fill="#2c2c2c" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4155.663 778.553l87.172-4.238 1.886 8.782-89.109 4.418z" fill="#525252" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4244.72 783.097l-1.885-8.782 4.652 6.93z" fill="#303030" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4238.204 760.26l3.51 5.558.437.843.352.697.686 1.305.605 1.169z" fill="#232323" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4242.503 767.358l-.352-.697-.437-.843-1.026-1.752 34.844-1.654.434.82.577.653.557.972.704.812z"
                fill="#275e69" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M4243.19 768.663l-.687-1.305 35.3-1.69.408 1.131.56.978.52 1.269.679 1.124-35.435 1.713-.332-.96-.409-1.091z"
                fill="#2f717e" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4244.203 770.922l.332.961.639 1.63.566 1.512-5.102-8.4 3.156 3.207z" fill="#282828"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M4245.174 773.513l-.64-1.63 35.436-1.713.363 1.41.524 1.275 69.643-3.388 1.162 2.907-105.258 5.155-.297-1.197-.367-1.307z"
                fill="#368291" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4246.107 776.332l.297 1.197.575 1.906.508 1.81-4.652-6.93 2.905.71z" fill="#2c2c2c"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4246.979 779.435l-.575-1.906 105.258-5.155 1.95 6.506-105.563 5.247-.25-1.398-.312-1.484z"
                fill="#3c91a2" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4247.8 782.729l.249 1.398.494 2.124.433 2.052-4.255-5.206 2.766-1.852z" fill="#303030"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4248.543 786.25l-.494-2.123 105.562-5.247 1.61 7.248-105.806 5.345-.19-1.556-.249-1.614z"
                fill="#419eb0" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4249.225 789.917l.19 1.556.4 2.273.344 2.23-3.929-3.284 2.746-4.389z" fill="#333" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4249.815 793.746l-.4-2.273 105.806-5.345 1.214 7.76-105.98 5.445-.12-1.663-.176-1.693z"
                fill="#45a7ba" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4250.334 797.67l.12 1.663.297 2.35.242 2.337-3.682-1.231 2.848-6.812z" fill="#353535"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4250.75 801.683l-.295-2.35 105.98-5.445.777 8.022-106.079 5.544-.044-1.717-.097-1.717z"
                fill="#48adc1" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4251.09 805.737l.043 1.717.185 2.35.133 2.367-3.528.883 3.07-9.034z" fill="#363636"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4251.318 809.805l-.185-2.35 106.08-5.545.314 8.021-106.1 5.64.038-1.716-.014-1.684z"
                fill="#49b0c4" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4251.465 813.855l-.037 1.716.07 2.273.019 2.32-3.47 2.98 3.404-10.973z" fill="#373737"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4251.498 817.844l-.07-2.273 106.099-5.64-.16 7.76-106.04 5.726.12-1.658.07-1.596z" fill="#49afc3"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4251.448 821.76l-.12 1.657-.044 2.121-.095 2.196-3.512 4.991 3.84-12.562z" fill="#363636"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4251.284 825.538l.043-2.12 106.038-5.728-.627 7.243-105.902 5.804.202-1.546.15-1.457z"
                fill="#47abbf" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4251.039 829.191l-.202 1.546-.153 1.9-.206 2.002-3.65 6.84 4.36-13.745z" fill="#343434"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4250.684 832.638l.153-1.901 105.901-5.804-1.074 6.494-105.691 5.867.28-1.385.225-1.27z"
                fill="#44a3b6" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4250.253 835.91l-.28 1.384-.253 1.62-.309 1.743-3.88 8.464 4.948-14.482z" fill="#323232"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4249.72 838.913l.253-1.619 105.692-5.867-1.483 5.538-105.416 5.915.352-1.18.293-1.043z"
                fill="#3f98aa" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M4249.118 841.7l-.352 1.18-.34 1.288-.402 1.432-.736 1.723-1.344 3.149-.746 1.745-.13.306-1.234 2.889 5.577-14.755z"
                fill="#313131" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4248.426 844.168l.34-1.288 105.417-5.915-1.84 4.413-105.055 5.945.736-1.723z" fill="#398b9b"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4279.97 770.17l-.678-1.124-.521-1.269 10.075-9.9 2.664 6.318-10.653 8.66-.524-1.275z"
                fill="#515151" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4277.804 765.669l-.704-.812-.557-.972 9.464-10.828 2.84 4.82-10.076 9.9-.56-.978z" fill="#494949"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4275.966 763.231l-.434-.82-.713-.478-.574-.65 8.839-11.417 2.923 3.191-9.464 10.828z"
                fill="#404040" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4274.82 761.933l.712.479-34.844 1.654-1.165-1.99 33.702-1.593.444.488.576.313z" fill="#1e4a52"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4273.669 760.97l-.444-.487-.703-.134-.573-.31 8.218-11.664 2.917 1.491-8.838 11.418z"
                fill="#373737" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M4272.523 760.349l.702.134-33.702 1.593-.38-.65 29.363-1.386.152-.007 1.19-.056 1.107-.052.435.146.56-.033z"
                fill="#1b4148" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4271.39 760.07l-.435-.145-1.107.052 7.498-11.378 2.82-.224-8.217 11.663z" fill="#2e2e2e"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4158.492 280.663l-4.221-.692-4.176-.685.129-25.385 8.408 2.098z" fill="#484848" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3808.127 1050.883l-10.442-8.792-16.15-763.352 10.839-.642z" fill="#6a6a6a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3792.374 278.097l-10.84.642-.543-25.673 21.556-1.943.517 26.341z" fill="#6a6a6a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3781.535 278.739l16.15 763.352-7.488-18.811-16.12-743.172z" fill="#525252" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3781.535 278.739l-7.458 1.369-.541-24.955 7.455-2.087z" fill="#525252" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M4150.261 1025.162l-342.134 25.72-15.753-772.785 41.687.216.45 25.802v.055l.206 11.883.02 1.127.01.569 5.793 332.651.517 29.659 5.802 333.135 266.604-19.074.845-324.1.042-16.053.894-342.876.014-5.208v-1.034l.065-25.003v-.077l38.944.202-4.01 745.191z"
                fill="#979797" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M3834.06 278.313l-41.686-.216 10.69-.633 34.888-1.064 46-.748 53.388-.35 55.97.09 53.387.518 46.086.887 35.18 1.163 22.131 1.326 4.177.685-38.944-.202v.077l-4.417-.474-21.045-1.028-30.83-.868-38.545-.627-43.218-.324-44.196.014-41.315.35-34.95.649-22.75.775z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4113.464 994.12l-266.604 19.074-5.802-333.135 273.251-10.04z" fill="#49afc3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M3993.31 275.392l-55.97-.09-.277-27.474 1.291.004 10.52.026 11.867.028 12.348.03 11.92.03 8.135.02.166 27.427z"
                fill="#989898" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3837.952 276.4l-34.888 1.064-.517-26.34 34.943-1.623z" fill="#7e7e7e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3883.952 275.652l-46 .748-.462-26.9 46.084-1.14z" fill="#8d8d8d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4150.095 279.286l-22.132-1.326.091-26.08 22.17 2.02z" fill="#616161" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4127.963 277.96l-35.18-1.163.027-26.69 35.245 1.772z" fill="#777" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M4092.783 276.797l-46.085-.887-.062-27.157h.05l1.287.038 1.25.037 1.175.034 1.062.031.918.027.745.022.551.016.34.01.117.004 38.679 1.134-.027 26.69z"
                fill="#888" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M4046.698 275.91l-53.388-.518-.166-27.428 2.491.037 8.58.127 5.951.087 2.957.044 26.25.387h.095l.317.005.53.008.726.01.901.014 1.049.016 1.164.017 1.244.018 1.236.018.062 27.157z"
                fill="#939393" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M3937.34 275.303l-53.388.35-.378-27.292 6.25-.063.214-.004.437-.005.646-.006.837-.008 1.002-.01 1.135-.011 1.236-.012 1.297-.013 1.32-.013 1.3-.013 1.24-.012 1.145-.012 1.012-.01.85-.008.661-.007.454-.005.235-.004 16.621-.166 2.718-.027 5.749-.057 7.13-.071.277 27.475z"
                fill="#969696" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3834.06 278.313l22.751-.775.414 26.204-12.339.203-.615.007-.444.01-9.317.152z" fill="#7a7a7a"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3856.811 277.538l34.95-.65.352 26.543-16.592.148-.775.005-.653.008-16.867.15z" fill="#8a8a8a"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3891.76 276.889l41.315-.35.278 26.724-19.553.08h-.873l-.814.005-20 .08-.353-26.541z"
                fill="#959595" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3933.075 276.539l44.197-.014.193 26.732-20.843.004-.898-.004-.907.004-21.465.004-.276-26.725z"
                fill="#989898" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3977.272 276.525l43.218.324.11 26.563-20.31-.073-.845-.005h-.92l-21.06-.076-.193-26.732z"
                fill="#959595" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4020.49 276.849l38.545.627.036 26.236-18.056-.14-.72-.008-.854-.005-18.841-.147z" fill="#8b8b8b"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4059.035 277.476l30.83.868-.02 25.785-14.405-.195-.54-.01-.72-.007-15.108-.205z" fill="#7b7b7b"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4089.865 278.344l21.045 1.028-.058 25.25-9.815-.23-.325-.011-.534-.01-10.334-.243z" fill="#666"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4110.91 279.372l4.416.474-.065 25.003-4.409-.227z" fill="#4e4e4e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3857.226 303.742l16.867-.15-29.207.353z" fill="#626262" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3834.51 304.115l9.317-.153-9.316.208v-.055z" fill="#7a7a7a" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3933.352 303.263l21.465-.004-41.017.083 19.552-.08z" fill="#717171" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3892.113 303.43l20-.08-36.592.23z" fill="#6c6c6c" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3977.465 303.257l21.06.076-41.903-.073 20.843-.004z" fill="#717171" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4020.6 303.412l18.84.147-39.15-.22z" fill="#6c6c6c" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4110.852 304.622l4.41.227v.012l-14.225-.47z" fill="#464646" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4089.844 304.129l10.334.242-24.739-.437z" fill="#565656" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4059.071 303.712l15.11.205-33.166-.346z" fill="#636363" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4100.178 304.371l.535.01-10.993 12.761-19.966-.16-3.847-.031 8.993-13.026.54.01z" fill="#565656"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4089.72 317.142l10.993-12.761.324.01 14.224.47v1.022l-11.085 11.475h-.01l-13.415-.2-1.028-.016z"
                fill="#464646" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4104.173 317.357l11.085-11.474-.014 5.208-6.491 6.49z" fill="#343434" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4108.753 317.58l6.492-6.49-.894 342.877-9.015-4.89.645-331.379z" fill="#525252" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M4105.336 649.077l9.015 4.89-.042 16.053-273.251 10.039-.517-29.659 17.057-4.901 20.414-3.634.449 30.635.665.811 1.392.763 2.081.692 2.71.597 3.255.484 3.701.355 4.032.215 4.236.067 4.305-.083 4.238-.23 4.038-.37 3.711-.496 3.27-.608 2.727-.7 2.104-.77 1.421-.815.699-.835-.39-35.114 11.917-.67.107 10.488.063.715.747.696 1.412.654 2.038.592 2.603.511 3.09.415 3.482.304 3.764.184 3.93.057 3.97-.07 3.885-.197 3.68-.317 3.356-.425 2.932-.521 2.417-.6 1.83-.66 1.192-.698.52-.716-.08-11.547 6.545-.06 33.652 1.273.123 30.564.27.809.992.786 1.689.74 2.337.67 2.916.58 3.407.469 3.792.344 4.059.208 4.198.065 4.204-.08 4.078-.223 3.822-.358 3.448-.482 2.967-.59 2.396-.678 1.753-.746 1.062-.79.342-.81-.013-25.157 13.058 2.127z"
                fill="#1e4a52" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3878.012 641.865l-20.414 3.634-5.294-327.538 3.539.036 17.42.11z" fill="#777" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4023.709 635.87l-33.652-1.273-2.048-316.24 22.864-.017 11.56-.028z" fill="#969696"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3857.598 645.499l-17.056 4.901-5.794-332.651 2.796.058 14.76.154z" fill="#616161" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3990.057 634.597l-6.546.061-2.2-316.295 6.698-.005z" fill="#989898" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3938.573 635.793l-11.917.67-3.534-318.164 12.201.027z" fill="#939393" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4084.609 643.318l-13.058-2.126-.17-323.065 13.368-.101z" fill="#7e7e7e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4105.336 649.077l-20.727-5.758.14-325.293 5.28-.04 13.712-.192 2.24-.096z" fill="#6a6a6a"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4029.12 669.44l-2.337-.67-1.336-351.43 2.394-.02z" fill="#919191" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M4027.841 317.319l-2.395.022-1.728.023-1.015.025-.274.026v.897l-11.56.027.482-1.599 22.805.059 5.404.026v.447l-1.352.004-3.89.011-3.493.015-2.989.018z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4034.323 317.286l3.89-.011 1.022 353.558-3.792-.344z" fill="#bdbdbd" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4030.83 317.3l3.494-.014 1.118 353.203-3.406-.47z" fill="#b2b2b2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4027.841 317.319l2.99-.019 1.205 352.72-2.916-.58z" fill="#a3a3a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4023.718 317.364l1.729-.023 1.336 351.43-1.689-.74z" fill="#7d7d7d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4022.703 317.39l1.015-.026 1.376 350.666-.992-.786z" fill="#676767" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4022.43 317.415l.273-.026 1.4 349.855-.272-.81-.122-30.563-1.276-317.559z" fill="#505050"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4059.596 670.445l-3.822.358-.594-353.528 3.922.012z" fill="#bcbcbc" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M4059.102 317.287l-3.922-.012h-.93v-.379l11.658.055 3.847.031-.273.38-1.34-.018-2.458-.021-3.044-.02-3.537-.015z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4059.102 317.287l3.538.015.404 352.661-3.448.482z" fill="#b0b0b0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4062.64 317.302l3.043.02.328 352.052-2.967.59z" fill="#a1a1a1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4065.683 317.321l2.459.022.264 351.352-2.395.679z" fill="#8f8f8f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4047.492 671.106l-4.199-.065-.917-353.773h4.306l.81 353.84z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4051.696 671.026l-4.204.08-.81-353.84h4.314l.7 353.757z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4043.294 671.041l-4.06-.208-1.021-353.558 1.352-.004H4042.376l.918 353.773z" fill="#c5c5c5"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4055.774 670.803l-4.078.223-.7-353.758h4.184l.594 353.528z" fill="#c4c4c4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4071.222 667.159l-1.062.79-.22-350.582 1.09.025z" fill="#656565" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4071.03 317.392l-1.09-.025-.458-.006.272-.38 19.966.16 1.028.016-.72.83-5.279.04-13.367.1v-.71z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4071.03 317.392l.351.026v.709l.17 323.065.013 25.157-.342.81z" fill="#4e4e4e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4070.16 667.95l-1.754.745-.265-351.352 1.34.018.46.006z" fill="#7b7b7b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3885.309 675.363l-2.71-.597-5.138-357.617 2.629-.019h.143l5.076 358.234z" fill="#a3a3a3"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3879.126 673.311l-.665-.811-.45-30.635-4.748-323.757-.013-.887.67-.026z" fill="#676767"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3873.92 317.195l-.67.026.013.887-17.42-.11-.843-1.037 24.895-.137.195.306-2.629.019-2.125.022z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3875.336 317.17l2.126-.021 5.137 357.617-2.08-.692z" fill="#919191" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3873.92 317.195l1.416-.024 5.182 356.903-1.392-.763z" fill="#7d7d7d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3924.927 673.227l-2.105.77-4.075-356.824 2.169.025z" fill="#7b7b7b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M3920.916 317.198l-2.169-.025-2.807-.022-3.363-.02-2.556-.01-.164-.39 7.946-.024 18.92-.017.01.863.235.049 2.333.047 3.913.04.05.265-.947.004-2.661.016-2.081.02-1.44.02-.757.022-.057.023v.267l-12.2-.027-.013-1.048-.727-.027-1.467-.026z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3912.577 317.131l3.363.02 4.154 357.546-3.269.608z" fill="#a1a1a1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3915.94 317.15l2.807.023 4.075 356.824-2.728.7z" fill="#8f8f8f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3920.916 317.198l1.468.026 3.964 355.188-1.421.815z" fill="#656565" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3922.384 317.224l.726.027.012 1.048 3.534 318.164.39 35.114-.698.835z" fill="#4e4e4e"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3939.655 317.974l2.66-.016 3.229 331.491-2.604-.511z" fill="#a3a3a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3937.574 317.993l2.08-.019 3.286 330.964-2.037-.592z" fill="#919191" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3936.135 318.014l1.439-.02 3.329 330.352-1.412-.654z" fill="#7d7d7d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3935.377 318.036l.758-.022 3.355 329.678-.746-.696z" fill="#676767" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3935.32 318.059l.057-.023 3.367 328.96-.063-.715-.108-10.488-3.25-317.468v-.266z" fill="#676767"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3913.115 675.802l-4.038.369-4.463-359.068H3905.697l3.066.008 4.352 358.687z" fill="#bcbcbc"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3900.534 676.484l-4.236-.067-4.803-359.321H3895.842l4.692 359.39z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3888.564 675.847l-3.255-.484-5.076-358.234 3.334-.015z" fill="#b2b2b2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3883.567 317.114l-3.334.015h-.143l-.195-.306 2.971-.017 7.878-.022v.312l-3.388.006-3.793.011z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3883.567 317.114l3.793-.012 4.905 359.1-3.7-.355z" fill="#bdbdbd" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3896.298 676.417l-4.032-.215-4.906-359.1 3.387-.006h.748l4.803 359.321z" fill="#c5c5c5"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3909.077 676.17l-4.239.23-4.577-359.304 1.1.004 1.139.004 1.008.004H3904.614l4.463 359.068z"
                fill="#c4c4c4" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3916.825 675.305l-3.71.497-4.352-358.687 1.259.006 2.555.01z" fill="#b0b0b0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3904.838 676.4l-4.304.084-4.692-359.39H3900.26l4.577 359.304z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3981.88 647.62l-1.83.66-2.383-330.285 1.88.021z" fill="#7b7b7b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M3979.547 318.016l-1.88-.02-.25-.005.015-.229 2.65-.004 6.968-.021 5.72-.032 4.083-.04 2.155-.046.058-.049v-.864l12.293.032-.482 1.6-22.863.017-6.698.005v-.301l-.537-.023-1.225-.022z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3979.547 318.016l1.225.023 2.3 328.882-1.192.699z" fill="#656565" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3980.772 318.039l.537.022v.302l2.2 316.295.08 11.547-.52.716-2.298-328.882z" fill="#4e4e4e"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3977.632 648.879l-2.931.52-2.521-331.44 3.007.017z" fill="#a1a1a1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3975.187 317.976l-3.007-.017-3.442-.013-3.523-.01v-.168l7.11.004 5.108-.006-.014.229-2.231-.017z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3968.738 317.946l3.442.013 2.52 331.44-3.356.426z" fill="#b0b0b0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3980.05 648.28l-2.418.599-2.445-330.903 2.23.017.25.004 2.383 330.284z" fill="#8f8f8f"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3971.343 649.825l-3.678.317-2.698-332.206h.248l3.523.01 2.606 331.88z" fill="#bcbcbc"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3963.78 650.338l-3.971.071-2.891-332.482h4.067l2.794 332.409z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3955.88 650.352l-3.765-.184-3.076-332.233 2.379-.004H3952.894l2.986 332.422z" fill="#c5c5c5"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3948.634 649.864l-3.09-.415-3.228-331.491.946-.004 2.214-.01z" fill="#b2b2b2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3959.809 650.409l-3.93-.057-2.985-332.423h4.024l2.891 332.481z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3952.115 650.168l-3.481-.304-3.158-331.92 3.563-.01z" fill="#bdbdbd" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3949.04 317.935l-3.564.01-2.214.01-.049-.265.334.004 5.857.035 2.012.007v.197l-2.378.004z"
                fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3967.665 650.142l-3.886.196-2.794-332.408h.433l1.028.004h2.521l2.698 332.206z" fill="#c4c4c4"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3837.544 317.807l-2.796-.058-.01-.57 1.03-.024 1.15-.011z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3837.544 317.807l-.626-.663 17.948-.182h.134l.843 1.036-3.54-.037-14.759-.153z" fill="#3f3f3f"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3909.857 316.73l.165.39-1.26-.005-3.065-.008v-.365z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4090.028 317.986l.72-.829 13.415.2-.422.437z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4103.741 317.794l.422-.437h.01l4.58.223-2.772.118z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M4074.9 303.924l-8.993 13.026-11.657-.055h-.118l-.338-.004-.548-.004-.741-.004-.914-.004-1.057-.005-1.168-.006-1.244-.006-1.282-.006-1.28-.006-1.237-.006-1.159-.005-1.043-.005-.898-.004-.722-.004-.527-.004-.316-.004h-.095l-5.403-.026 6.135-13.235.72.007 33.165.345.72.007z"
                fill="#636363" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M4040.295 303.564l-6.135 13.235-22.805-.059-12.294-.031-2.074-.005h-.18l2.638-13.37.845.006 39.15.22.855.005z"
                fill="#6c6c6c" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M3999.445 303.334l-2.639 13.37-3.91-.005-5.844-.005-7.203-.006-8.057-.006-8.336-.007-6.569-.005-1.163-13.413.898.004 41.903.072z"
                fill="#717171" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M3955.724 303.258l1.163 13.413h-1.449l-7.126.007-5.732.005-3.948.004-1.91.004-18.919.018-4.876-13.363h.873l41.017-.083.907-.004z"
                fill="#717171" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M3912.927 303.344l4.876 13.363-7.946.023-4.165.012h-.685l-.659.004-.845.004-1.008.004-1.138.004-1.236.004-1.294.004-1.312.004-1.291.004-1.23.004-1.13.004-.997.004-.833.004h-1.29l-7.878.023-8.12-13.224.775-.005 36.592-.229z"
                fill="#6c6c6c" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M3874.746 303.584l8.12 13.224-2.971.016-24.895.137h-.134l-10.595-13.01.615-.007 29.207-.353.653-.008z"
                fill="#626262" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3844.271 303.952l10.595 13.01-17.948.182-1.15.011-1.05-1.102-.207-11.883 9.316-.208z" fill="#555"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3834.718 316.053l1.05 1.102-1.03.025z" fill="#454545" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3999.008 317.621l-2.155.046v-.144l.14.004 2.016.047v.05z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3996.853 317.667l-4.083.04v-.227h.133l3.95.042v.145z" fill="#6a6a6a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3992.77 317.707l-5.72.032v-.296h.01l5.712.036v.228z" fill="#7e7e7e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3987.05 317.74l-6.968.02v-.344l6.969.027v.296z" fill="#8d8d8d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3949.404 317.728l-5.857-.036v-.233l4.774-.028 1.083-.004v.3z" fill="#777" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3939.3 317.65l-2.333-.048v-.055l1.674-.041.658-.007v.15z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3999.06 316.709v.864h-.056l-2.016-.047v-.821l2.074.005z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3996.987 316.703v.822l-.14-.004-3.95-.043-.01-.78 3.91.004h.18z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3992.896 316.7l.01.78h-.133l-5.711-.036v-.748l5.844.005z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3987.052 316.695v.748h-.01l-6.969-.027h-.225l-.01-.726 7.203.006z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3979.849 316.69l.01.726-7.533-.015h-.523l-.01-.717 8.057.006z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3971.792 316.683l.01.717-7.52-.004h-.816l-.01-.72 8.336.006z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3963.456 316.677l.01.72-6.993.01h-1.024l-.01-.736h1.449z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3955.438 316.673l.01.735-6.043.02-1.082.003-.01-.75 7.126-.008z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3948.312 316.68l.01.75-4.774.029-.957.006-.01-.78z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3942.58 316.684l.01.78-3.289.035-.658.007-.01-.818z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3980.08 317.416v.345l-2.65.004-5.108.006v-.37l7.533.015h.225z" fill="#969696" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3972.321 317.4v.37l-7.11-.003H3964.278v-.371l7.52.004h.524z" fill="#989898" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M3964.278 317.397v.371h-.935l-.901-.004-1.028-.004-1.124-.004-1.185-.004-1.209-.004-1.196-.004h-.232v-.347l6.994-.01h.816z"
                fill="#939393" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path
                d="M3956.468 317.407v.347l-.915-.004-1.062-.004-.945-.004-.8-.004-.628-.004-.44-.004h-.265l-2.012-.008v-.3l6.043-.02h1.024z"
                fill="#888" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3943.545 317.459v.233l-.334-.004-3.913-.04v-.15l3.289-.034.957-.006z" fill="#616161"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3936.732 317.553l-.01-.863 1.909-.004.01.818-1.674.041-.235.006z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4039.77 242.85l.529-.057.016 5.867-.53-.008z" fill="#7d7d7d" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4041.927 242.697l1.049-.036.015 6.039-1.05-.016z" fill="#b2b2b2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4044.14 242.635l1.245-.016.014 6.116-1.244-.018z" fill="#c5c5c5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4051.448 242.7l.918.046.012 6.176-.918-.027z" fill="#a1a1a1" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4052.366 242.746l.746.052.01 6.146-.745-.022z" fill="#8f8f8f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4049.21 242.637l1.175.027.012 6.2-1.174-.035z" fill="#bcbcbc" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4053.112 242.798l.55.058.012 6.104-.551-.016z" fill="#7b7b7b" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4053.674 248.96l-.011-6.104.34.06.01 6.054z" fill="#656565" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4054.013 248.97l-.01-6.053.118.063.01 5.993z" fill="#4e4e4e" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4053.247 316.89v.382h-.742v-.384l.742.004z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4047.96 242.62l1.25.017.013 6.192-1.25-.036z" fill="#c4c4c4" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4039.452 242.91l.317-.06.016 5.803-.316-.005z" fill="#676767" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4046.671 242.614l1.289.006.013 6.173-1.288-.038z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4042.976 242.66l1.165-.025.015 6.082-1.165-.017z" fill="#bdbdbd" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4040.299 242.793l.726-.052.016 5.93-.726-.01z" fill="#919191" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4039.357 242.973l.095-.063.016 5.738h-.095l-.016-5.673z" fill="#676767" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4050.385 242.664l1.063.037.012 6.194-1.062-.031z" fill="#b0b0b0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4041.025 242.741l.902-.044.015 5.987-.901-.013z" fill="#a3a3a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4052.505 316.887v.384h-.914v-.387l.914.004z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4045.385 242.619l1.286-.005.014 6.14h-.05l-1.235-.017-.014-6.117z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4051.591 316.882v.387h-1.056v-.39l1.056.004z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4050.534 316.877v.39h-1.168v-.395l1.168.006z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4049.366 316.871v.396h-1.244v-.4l1.244.005z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4048.122 316.866v.4h-1.282v-.406l1.281.006z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4040.502 317.27v-.44l.723.003v.437h-.723z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4041.224 317.27v-.437l.898.004v.431h-.897z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4042.122 317.268v-.43l1.043.004v.426H4042.122z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4043.165 317.268v-.426l1.159.005v.42h-1.159z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4044.324 317.267v-.42l1.238.006v.413h-1.238z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4045.562 317.266v-.413l1.28.006v.407H4045.562z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3899.159 241.271l1.242.028.087 6.893-1.24.013z" fill="#bcbcbc" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3889.734 241.553l.213-.062.091 6.805-.214.004-.09-6.745z" fill="#676767" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3889.947 241.49l.436-.058.092 6.86-.436.005z" fill="#7d7d7d" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3895.24 241.252l1.298-.005.09 6.984-1.297.013z" fill="#c9c9c9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3890.383 241.432l.646-.053.092 6.906-.646.006z" fill="#919191" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3891.121 248.285l-.092-6.906.837-.046.092 6.944z" fill="#a3a3a3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3891.958 248.277l-.092-6.944 1.002-.037.092 6.971z" fill="#b2b2b2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3896.538 241.247l1.32.006.09 6.964-1.32.013z" fill="#c9c9c9" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3897.947 248.217l-.09-6.964 1.301.018.088 6.934z" fill="#c4c4c4" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3892.868 241.296l1.136-.027.091 6.987-1.136.011z" fill="#bdbdbd" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3894.095 248.256l-.09-6.987 1.236-.017.09 6.991z" fill="#c5c5c5" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3900.488 248.192l-.087-6.893 1.146.038.086 6.844z" fill="#b0b0b0" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3901.547 241.337l1.013.047.085 6.787-1.012.01z" fill="#a1a1a1" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3902.645 248.17l-.085-6.786.851.054.084 6.724z" fill="#8f8f8f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3903.495 248.162l-.084-6.724.662.06.082 6.658z" fill="#7b7b7b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3904.76 241.625v.065l.08 6.459-.234.004-.081-6.591.232.065z" fill="#4e4e4e" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3904.074 241.497l.454.063.081 6.591-.453.005z" fill="#656565" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3892.038 317.095v-.314l.833-.004v.316h-.833z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3892.87 317.095v-.316l.998-.004v.318h-.997z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3893.868 317.094v-.318l1.13-.004v.321z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3894.999 317.094v-.321l1.229-.004v.324h-1.229z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3896.228 317.094v-.325l1.291-.004v.33h-1.291z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3897.52 317.095v-.33l1.312-.003v.333h-1.313z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3898.832 317.095v-.333l1.294-.004v.338h-1.294z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3900.125 317.096v-.338l1.236-.004v.343l-1.1-.004h-.136z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3901.36 317.098v-.343l1.14-.004v.348l-1.14-.004z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3902.5 317.1v-.349l1.007-.004v.354l-1.008-.004z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3903.508 317.102v-.353l.845-.004v.357h-.845z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3952.752 317.93v-.19l.799.004v.186h-.799z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3953.55 317.929v-.186l.946.004v.182h-.945z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3954.495 317.928v-.182l1.063.004v.178z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3955.558 317.928v-.178l.915.004h.232v.174h-1.147z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3964.094 317.768v.166h-.748v-.167h.748z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3963.346 317.766v.167h-.901v-.167l.901.004z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3962.445 317.765v.167l-1.029-.004v-.167l1.028.004z" fill="#3f3f3f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M3961.417 317.763v.167h-1.123v-.169l1.123.004z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3960.293 317.76v.17h-1.185v-.17l1.184.003z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3959.108 317.759v.17h-1.209v-.172l1.21.004z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M3956.705 317.927v-.173l1.196.004v.172h-1.196z" fill="#3f3f3f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4272.312 754.013l2.39-3.507-6.044 9.527-.152.007z" fill="#363636" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4287.239 861.647l-2.885-.401-.99-10.784z" fill="#3b3b3b" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4288.846 757.876l-2.839-4.819 128.892-5.995 3.466 4.72z" fill="#494949" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4274.703 750.506l2.643-1.907-7.498 11.378-1.19.056z" fill="#2d2d2d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4280.857 772.855l10.653-8.66 130.095-6.214 6.153 7.727-22.993 1.119-45.626 2.22-8.64.42z"
                fill="#6b6b6b" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4291.51 764.195l-2.664-6.319 129.519-6.095 3.24 6.2z" fill="#565656" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4286.007 753.057l-2.923-3.19 128.237-5.919 3.578 3.114z" fill="#3b3b3b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4283.084 749.866l-2.917-1.491 127.573-5.866 3.58 1.439z" fill="#2d2d2d" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4284.354 861.246l-2.916-2.107-3.179-8.512.051-.003z" fill="#353535" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4425.152 760.738l2.606 4.97-6.153-7.726-3.24-6.2 3.732 4.924.177.234z" fill="#393939"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4403.502 772.407l-.884 6.605-39.753 1.988-.574-2.552-1.2-3.952z" fill="#767676" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4419.21 852.77l-3.407 1.368-128.564 7.509 2.763-1.346z" fill="#434343" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4290.002 860.3l-2.763 1.347 1.198-12.573z" fill="#393939" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4290.002 860.3l-1.565-11.226 60.35-3.442 8.59-.49 6.764-.386 53.207-3.035.053.315 1.808 10.735z"
                fill="#767676" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4404.765 766.827l-1.263 5.58-42.412 2.09-.773-2.547-1.178-2.903z" fill="#6f6f6f" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4402.618 779.012l-.415 7.14-37.905 1.926-.378-2.39-1.055-4.688z" fill="#7a7a7a" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4402.203 786.153l.065 7.473-36.915 1.905-.205-2.09-.85-5.363z" fill="#7c7c7c" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4402.268 793.626l.544 7.595-36.811 1.93-.067-1.697-.58-5.923z" fill="#7b7b7b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4402.811 801.22l1.007 7.503-37.596 2.004.027-1.26-.249-6.315z" fill="#787878" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4403.818 808.723l1.443 7.197-39.25 2.123.073-.824.139-6.492z" fill="#737373" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4405.261 815.92l1.842 6.685-41.732 2.289.074-.438.567-6.413z" fill="#6b6b6b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4407.103 822.605l2.19 5.976-44.975 2.497.036-.134 1.017-6.05z" fill="#616161" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4409.293 828.58l2.478 5.089-48.903 2.743 1.45-5.334z" fill="#565656" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4411.771 833.669l2.697 4.04-53.418 3.021 1.8-4.252.018-.066z" fill="#494949" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4414.468 837.709l2.838 2.86-58.381 3.322 2.059-3.002.067-.159z" fill="#3b3b3b" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4417.306 840.568l.096 1.468-.053-.315-53.207 3.035-6.643.294 1.32-1.004.107-.155z" fill="#2e2e2e"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4288.437 849.074l-1.198 12.573-3.876-11.185.733-.2 1.01-.277.845-.095 1.007-.411z" fill="#595959"
                fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4285.95 849.89l-.845.095-1.009.276-2.786.16-.51.06-2.49.142-.05.003-3.602.206-29.59 1.69.13-.305z"
                fill="#23555f" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4286.958 849.479l-1.007.411-40.752 2.327.745-1.745 104.264-5.936-1.42 1.096-60.35 3.442z"
                fill="#2b6874" fill-opacity="1" fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4280.8 850.481l.51-.06 2.053.04.991 10.785-6.044-10.623z" fill="#555" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4281.31 850.42l2.786-.159-.733.2z" fill="#23555f" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4278.26 850.627l3.178 8.512-6.78-8.307z" fill="#494949" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4357.499 845.05l6.642-.294-6.764.386z" fill="#2e2e2e" fill-opacity="1" fill-rule="nonzero"
                stroke="none" stroke-width=".353" />
            <path d="M4355.664 831.427l1.074-6.494 8.707-.477-.074.438-1.017 6.05z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4356.738 824.933l.628-7.243 8.718-.47-.073.823-.566 6.413z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4353.611 778.88l-1.95-6.506 8.656-.424.773 2.546 1.2 3.952z" fill="#f9f9f9" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4355.22 786.128l-1.609-7.248 8.68-.432.574 2.552 1.055 4.688z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4357.212 801.91l-.777-8.022 8.713-.448.205 2.091.58 5.923z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4357.366 817.69l.16-7.759 8.723-.463-.027 1.259-.138 6.492z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4351.662 772.374l-1.163-2.907 8.64-.42 1.178 2.903z" fill="#dfdfdf" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4348.787 845.632l1.421-1.096 8.61-.49-1.32 1.004-.121.092z" fill="#b3b3b3" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4354.182 836.965l1.483-5.538 8.69-.483-.037.134-1.45 5.334-.018.066z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4352.343 841.378l1.84-4.413 8.666-.487-1.799 4.252-.067.159z" fill="#eee" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4357.526 809.931l-.314-8.021 8.721-.456.068 1.698.248 6.316z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4356.435 793.888l-1.214-7.76 8.699-.44.378 2.39.85 5.362z" fill="#fff" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4358.818 844.046l-8.61.49 2.135-3.158 8.64-.49-2.058 3.003z" fill="#d2d2d2" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4352.343 841.378l-2.135 3.158-104.264 5.936 1.344-3.149z" fill="#337b89" fill-opacity="1"
                fill-rule="nonzero" stroke="none" stroke-width=".353" />
            <path d="M4155.059 797.308l.524-4.737" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4154.236 807.604l.823-10.296" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4153.708 818.034l.528-10.43" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4153.493 828.247l.215-10.213" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4153.597 837.9l-.104-9.653" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4154.018 846.672l-.42-8.772" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4154.737 854.276l-.72-7.604" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4155.216 857.268l-.479-2.992" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3857.598 645.499l20.414-3.634" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M3990.057 634.597l33.652 1.274" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M3840.542 650.4l17.056-4.901" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3983.511 634.658l6.546-.06" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3926.656 636.463l11.917-.67" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4071.55 641.192l13.059 2.127" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4084.609 643.318l20.727 5.759" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4105.336 649.077l9.015 4.89" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3792.374 278.097l41.687.216" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4115.327 279.77l38.944.201" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3883.567 317.114l3.793-.012" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3880.233 317.13l3.334-.016" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3912.577 317.131l3.363.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3875.336 317.17l2.126-.021" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3915.94 317.15l2.807.023" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3873.92 317.195l1.416-.024" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3918.747 317.173l2.169.025" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3873.25 317.221l.67-.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3920.916 317.198l1.468.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3922.384 317.224l.726.027" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4034.323 317.286l3.89-.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4055.18 317.275l3.922.012" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4030.83 317.3l3.494-.014" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4059.102 317.287l3.538.015" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4027.841 317.319l2.99-.019" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4062.64 317.302l3.043.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4025.447 317.34l2.394-.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4065.683 317.321l2.459.022" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4023.718 317.364l1.729-.023" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4022.703 317.39l1.015-.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4069.94 317.367l1.09.025" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4022.43 317.415l.273-.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4071.03 317.392l.351.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3945.476 317.945l3.563-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3968.738 317.946l3.442.013" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3939.655 317.974l2.66-.016" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3972.18 317.96l3.007.016" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3937.574 317.993l2.08-.019" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3936.135 318.014l1.439-.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3977.667 317.995l1.88.021" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3935.377 318.036l.758-.022" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3979.547 318.016l1.225.023" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3935.32 318.059l.057-.023" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3980.772 318.039l.537.022" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3954.431 77.635l14.392-16.688" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4027.867 137.187l6.367-7.405" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4034.234 129.782l7.264-6.457" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3968.823 60.947l16.428-14.512" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M3985.25 46.435l18.162-12.077" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4041.498 123.325l8.032-5.393" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4003.412 34.358l19.562-9.43" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4049.53 117.932l8.657-4.239" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4022.974 24.927l20.603-6.626" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4045.954 17.784l-2.377.517" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4058.187 113.693l9.13-3.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4067.317 110.682l9.442-1.734" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4045.954 17.784l21.206-3.705" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4067.16 14.079l20.045-.703" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4076.76 108.948l11.063-.495" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4167.83 56.333l-.294-12.215" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4162.68 18.63l-2.532-2.796" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4164.858 24.571l-2.178-5.94" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4087.205 13.376l72.943 2.458" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4166.519 33.27l-1.66-8.699" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4167.535 44.118l-1.016-10.849" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4156.928 110.078l2.7-3.06" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4162.197 100.91l2.254-8.786" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4164.451 92.124l1.773-10.883" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4167.38 69.017l.45-12.684" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4087.823 108.453l69.105 1.625" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4159.629 107.017l2.568-6.107" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4166.224 81.24l1.157-12.223" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3942.34 96.197l12.091-18.562" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4022.507 145.412l5.36-8.225" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3921.466 247.983l-.754-67.493" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4013.123 248.259l-.3-64.324" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3920.712 180.49l1.112-20.88" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3921.824 159.61l4.068-22.048" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4013.494 172.599l1.678-8.869" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4015.172 163.73l3.08-9.418" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3925.892 137.562l6.878-21.267" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4018.251 154.312l4.256-8.9" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3932.77 116.295l9.57-20.098" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4241.795 860.164l2.04-4.752" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4239.484 863.245l2.311-3.081" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4236.978 864.582l2.506-1.337" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4232.953 752.18l-2.652-1.513" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4235.615 755.402l-2.662-3.222" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4238.204 760.26l-2.59-4.858" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4240.638 766.625l-2.434-6.366" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4242.835 774.315l-2.197-7.69" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4244.72 783.097l-1.885-8.782" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4246.23 792.692l-1.51-9.595" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4247.31 802.789l-1.08-10.097" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4247.923 813.054l-.612-10.265" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4248.047 823.145l-.124-10.091" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4247.677 832.725l.37-9.58" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4246.828 841.479l.849-8.754" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4245.532 849.12l1.296-7.641" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4243.834 855.412l1.698-6.291" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4248.024 845.6l.402-1.432" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4249.118 841.7l.293-1.043" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4249.411 840.657l.31-1.744" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4250.253 835.91l.226-1.27" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4250.479 834.64l.205-2.002" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4251.039 829.191l.15-1.457" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4251.189 827.734l.095-2.196" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4251.448 821.76l.069-1.597" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4251.517 820.163l-.02-2.319" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4251.465 813.855l-.014-1.684" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4251.451 812.171l-.133-2.366" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4251.09 805.737l-.098-1.717" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4250.993 804.02l-.243-2.337" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4250.334 797.67l-.175-1.693" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4250.158 795.977l-.343-2.23" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4249.225 789.917l-.249-1.614" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4248.976 788.303l-.433-2.052" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4247.8 782.729l-.313-1.484" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4247.487 781.245l-.508-1.81" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4246.107 776.332l-.367-1.307" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4245.74 775.025l-.566-1.512" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4244.203 770.922l-.409-1.09" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4243.794 769.832l-.605-1.169" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4242.15 766.66l-.436-.842" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4155.147 869.362l81.83-4.78" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4155.802 754.092l74.5-3.425" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3781.535 278.739l10.839-.642" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4154.27 279.971l4.222.692" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3774.077 280.108l7.458-1.37" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3797.685 1042.09l10.442 8.793" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M3790.197 1023.28l-16.12-743.172" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M3790.197 1023.28l7.488 18.81" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4113.464 994.12l-266.604 19.074" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M3808.127 1050.883l342.134-25.72" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4150.261 1025.162l4.055-9.526" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4150.261 1025.162l4.01-745.19" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3792.374 278.097l15.753 772.786" fill="none" stroke="#000" stroke-width=".706"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M3937.34 275.303l55.97.09" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3803.064 277.464l34.888-1.064" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3837.952 276.4l46-.748" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4127.963 277.96l22.131 1.326" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4092.783 276.797l35.18 1.163" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4046.698 275.91l46.085.887" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3993.31 275.392l53.387.518" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3883.952 275.652l53.388-.35" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3834.06 278.313l22.751-.775" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3856.811 277.538l34.95-.65" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3891.76 276.889l41.315-.35" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3933.075 276.539l44.197-.014" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3977.272 276.525l43.218.324" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4020.49 276.849l38.545.627" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4059.035 277.476l30.83.868" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4089.865 278.344l21.045 1.028" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4110.91 279.372l4.416.474" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3802.547 251.123l34.943-1.622" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4158.492 280.663l.14-24.664" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4128.054 251.88l22.17 2.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3773.536 255.153l7.455-2.087" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3837.49 249.5l46.084-1.14" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3780.991 253.066l21.556-1.943" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M3774.077 280.108l-.541-24.955" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4150.224 253.9l8.408 2.1" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4092.81 250.106l35.244 1.773" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3857.226 303.742l16.867-.15" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3834.51 304.115l9.317-.153" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3933.352 303.263l21.465-.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3892.113 303.43l20-.08" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3977.465 303.257l21.06.076" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4020.6 303.412l18.84.147" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4110.852 304.622l4.41.227" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4089.844 304.129l10.334.242" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4059.071 303.712l15.11.205" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3837.544 317.807l-2.796-.058" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3855.843 317.997l17.42.11" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3935.323 318.326l-12.201-.027" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4022.433 318.312l-11.56.028" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4103.741 317.794l-13.713.192" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4108.753 317.58l6.492-6.49" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4108.753 317.58l-4.58-.223" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4100.713 304.38l-.535-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4074.9 303.924l-.72-.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4040.295 303.564l-.854-.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3999.445 303.334h-.92" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3955.724 303.258l-.907.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3912.927 303.344l-.814.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3874.746 303.584l-.653.008" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3844.271 303.952l-.444.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3834.738 317.18l1.03-.025" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4115.261 304.86l-14.224-.469" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4100.178 304.371l-24.739-.437" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4074.18 303.917l-33.165-.346" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4039.44 303.559l-39.15-.22" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3998.525 303.332l-41.903-.072" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3954.817 303.26l-41.017.083" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3912.113 303.35l-36.592.229" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3874.093 303.592l-29.207.353" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3843.827 303.962l-9.316.208" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4101.037 304.392l9.815.23" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4101.037 304.392l-.324-.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4075.44 303.934l14.404.195" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4075.44 303.934l-.54-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4041.015 303.571l18.056.141" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4041.015 303.571l-.72-.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4000.29 303.339l20.31.073" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4000.29 303.339l-.845-.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3956.622 303.26l20.843-.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3956.622 303.26l-.898-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3913.8 303.343l19.552-.08" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3913.8 303.343h-.873" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3875.52 303.58l16.593-.15" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3875.52 303.58l-.774.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3844.886 303.945l12.34-.203" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3844.886 303.945l-.615.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3999.008 317.621l.057-.048" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3996.853 317.667l2.155-.046" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3992.77 317.707l4.083-.04" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3987.05 317.74l5.72-.033" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3980.082 317.76l6.968-.02" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3943.547 317.692l5.857.036" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3936.967 317.602l2.333.047" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3936.732 317.553l.235.049" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3999.06 316.709v.864" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3936.723 316.69l.01.863" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4040.299 242.793l-.53.057" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4042.976 242.66l-1.05.037" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4045.385 242.619l-1.244.016" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4052.366 242.746l-.918-.045" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4053.112 242.798l-.746-.052" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4050.385 242.664l-1.175-.027" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4053.663 242.856l-.551-.058" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4054.003 242.917l-.34-.061" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4054.25 317.274v-.38" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4054.131 248.973l-.01-5.993" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4049.21 242.637l-1.25-.017" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4039.77 242.85l-.318.06" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4047.96 242.62l-1.289-.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4054.121 242.98l-.118-.063" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4044.14 242.635l-1.164.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4041.025 242.741l-.726.052" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4039.452 242.91l-.095.063" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4051.448 242.7l-1.063-.036" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4041.927 242.697l-.902.044" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4046.671 242.614l-1.286.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4039.565 317.272v-.447" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4039.373 248.646l-.016-5.673" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3900.401 241.3l-1.242-.029" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3889.947 241.49l-.213.063" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3890.383 241.432l-.436.059" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3896.538 241.247l-1.298.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3891.03 241.379l-.647.053" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3891.866 241.333l-.837.046" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3897.858 241.253l-1.32-.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3894.004 241.269l-1.136.027" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3892.868 241.296l-1.002.037" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3899.159 241.271l-1.301-.018" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3902.56 241.384l-1.013-.047" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3903.411 241.438l-.85-.054" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3904.074 241.497l-.663-.06" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3904.765 241.69v-.065" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3901.547 241.337l-1.146-.038" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3904.76 241.625l-.232-.065" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3904.528 241.56l-.454-.063" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3895.24 241.252l-1.236.017" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3890.747 317.097v-.312" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3889.824 248.298l-.09-6.745" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3905.697 317.107v-.365" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3904.845 248.149l-.08-6.46" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3951.418 317.931v-.196" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3965.215 317.936v-.168" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3846.86 1013.194l-5.802-333.135" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M3841.058 680.059l273.251-10.04" fill="none" stroke="#000" stroke-width=".706"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4114.31 670.02l-.846 324.1" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4283.363 850.462l-2.053-.041" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4278.77 767.777l.522 1.269" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4280.333 771.58l.524 1.275" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4274.245 761.284l.574.65" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4275.966 763.231l.577.654" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4288.437 849.074l-1.48.405" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4273.669 760.97l.577.314" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4271.39 760.07l.56-.032" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4276.543 763.885l.557.972" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4278.211 766.8l.56.977" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4274.703 750.506l-2.391 3.507" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4284.354 861.246l2.885.4" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4288.846 757.876l-2.839-4.819" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4287.239 861.647l2.763-1.346" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4277.346 748.6l-2.643 1.906" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4280.857 772.855l10.653-8.66" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4291.51 764.195l-2.664-6.319" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4286.007 753.057l-2.923-3.19" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4283.084 749.866l-2.917-1.491" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4280.167 748.375l-2.82.224" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4281.438 859.14l2.916 2.106" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4288.437 849.074l1.565 11.227" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4425.152 760.738l2.606 4.97" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4418.365 751.781l-3.466-4.719" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4402.618 779.012l.884-6.605" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4415.803 854.138l3.406-1.367" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4411.32 743.948l-3.58-1.439" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4414.9 747.062l-3.58-3.114" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4421.605 757.981l-3.24-6.2" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4427.758 765.708l-6.153-7.726" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4422.36 756.936l2.792 3.802" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4422.097 756.706l.263.23" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4403.502 772.407l1.263-5.58" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4402.203 786.153l.415-7.14" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4402.268 793.626l-.065-7.473" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4402.811 801.22l-.543-7.594" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4403.818 808.723l-1.007-7.502" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4405.261 815.92l-1.443-7.197" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4407.103 822.605l-1.841-6.685" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4409.293 828.58l-2.19-5.975" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4411.771 833.669l-2.478-5.088" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4414.468 837.709l-2.697-4.04" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4417.306 840.568l-2.838-2.86" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4417.402 842.036l-.096-1.468" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4287.239 861.647l128.564-7.51" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4290.002 860.3l129.207-7.53" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4280.167 748.375l127.573-5.866" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4291.51 764.195l130.095-6.214" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4283.084 749.866l128.237-5.918" fill="none" stroke="#000" stroke-width=".706"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4358.925 843.89l58.381-3.322" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4272.312 754.013l-3.805 6.027" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4274.658 850.832l6.78 8.307" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4422.36 756.936l-7.46-9.874" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4356.738 824.933l-1.073 6.494" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4357.366 817.69l-.628 7.243" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4351.662 772.374l1.95 6.506" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4353.611 778.88l1.61 7.248" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4356.435 793.888l.777 8.022" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4357.526 809.931l-.16 7.76" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4350.5 769.467l1.162 2.907" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4352.343 841.378l-2.135 3.158" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4350.208 844.536l-1.42 1.096" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4355.664 831.427l-1.481 5.538" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4354.182 836.965l-1.839 4.413" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4357.212 801.91l.314 8.021" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4355.22 786.128l1.215 7.76" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4359.139 769.047l1.178 2.903" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3900.261 317.096l1.1.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3904.614 317.103h.397" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3908.763 317.115l1.259.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3910.021 317.12l2.556.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3877.461 317.149l2.629-.019" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3880.09 317.13h.143" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4050.535 317.268h.461" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4068.142 317.343l1.34.018" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4069.482 317.36l.459.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3960.294 317.93h.691" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3960.985 317.93h.433" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3965.215 317.936l3.523.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3942.316 317.958l.946-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3943.262 317.954l2.214-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3975.187 317.976l2.23.017" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3977.418 317.993l.249.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4012.823 183.935l.67-11.336" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4013.494 172.599l.023-.383" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4150.095 279.286l4.176.685" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3792.374 278.097l10.69-.633" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4046.635 248.754h.05" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3883.574 248.36l6.25-.062" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3937.063 247.828l1.291.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4010.873 318.34l-22.864.018" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3988.009 318.358l-6.697.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4071.382 318.127l13.367-.101" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4084.75 318.026l5.278-.04" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4108.753 317.58l-2.772.118" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4105.98 317.698l-2.239.096" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4104.173 317.357h-.01" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4065.907 316.95l3.847.031" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4069.754 316.981l19.966.16" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4034.16 316.799l5.404.026" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3996.987 316.703h-.18" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3963.456 316.677l-6.569-.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3936.723 316.69l-18.92.017" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3855 316.961h-.133" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3854.867 316.962l-17.949.182" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3836.918 317.144l-1.15.011" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3948.32 317.43l-4.775.029" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3943.545 317.459l-.958.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3955.445 317.408l-6.044.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3949.401 317.427l-1.082.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3963.461 317.397l-6.993.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3956.468 317.407h-1.024" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3971.798 317.4l-7.52-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3964.278 317.397h-.817" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3979.854 317.416l-7.533-.015" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3972.321 317.4h-.524" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3987.057 317.443h-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3992.901 317.48h-.133" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3992.768 317.48l-5.712-.037" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3996.992 317.525l-.14-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3996.852 317.523l-3.95-.043" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3999.065 317.573h-.057" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3999.008 317.571l-2.016-.046" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3972.324 317.77l5.108-.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3977.432 317.764l2.65-.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3965.214 317.768l7.11.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3956.471 317.754h.232" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3949.404 317.728l2.012.007" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3939.3 317.65l3.913.039" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3943.213 317.689l.334.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3938.64 317.506l-1.674.041" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3936.966 317.547l-.234.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3942.588 317.464l-3.29.035" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3939.298 317.499l-.658.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3837.544 317.807l14.76.154" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3852.304 317.96l3.539.037" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4042.122 317.268h.254" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4042.376 317.268h.79" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4023.831 666.435l-.122-30.564" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4071.564 666.349l-.013-25.157" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M3891.495 317.096h.543" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3891.395 317.096h.1" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3878.461 672.5l-.45-30.635" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3927.046 671.577l-.39-35.114" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3983.592 646.205l-.081-11.547" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M3952.894 317.929h.657" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3949.04 317.935l2.378-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3938.68 646.281l-.107-10.488" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4285.105 849.985l-1.009.276" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4284.096 850.261l-.733.2" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4417.348 841.721l.053.315" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4417.402 842.036l1.807 10.735" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4288.437 849.074l60.35-3.442" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4280.857 772.855l69.643-3.388" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4363.92 785.688l.378 2.39" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4364.298 788.078l.85 5.362" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4362.85 836.478l-1.8 4.252" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4361.05 840.73l-.067.159" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4362.29 778.448l.575 2.552" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4362.865 781l1.055 4.688" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4365.445 824.456l-.074.438" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4365.371 824.894l-1.017 6.05" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4360.984 840.889l-2.06 3.002" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4358.925 843.89l-.107.156" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4366.084 817.22l-.073.823" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4366.011 818.043l-.566 6.413" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4360.317 771.95l.773 2.546" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4361.09 774.496l1.2 3.952" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4365.148 793.44l.205 2.091" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4365.353 795.531l.58 5.923" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4365.934 801.454l.066 1.698" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4366 803.152l.25 6.316" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4366.25 809.468l-.028 1.259" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4366.222 810.727l-.138 6.492" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4362.868 836.412l-.018.066" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4358.818 844.046l-1.32 1.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4357.499 845.05l-.122.092" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4280.8 850.481l-2.49.142" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4270.955 759.925l-1.107.052" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3897.52 317.095h1.312" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3898.832 317.095h1.293" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3900.125 317.096h.136" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3905.011 317.105h.451" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4049.367 317.267h1.168" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4053.247 317.272h.548" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4053.795 317.273h.338" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3959.11 317.929h1.185" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3964.967 317.936h.076" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3965.043 317.936h.172" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4245.944 850.472l-.746 1.745" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4245.199 852.217l-.131.306" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4245.068 852.523l-1.234 2.889" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4235.615 755.402l3.527 6.024" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4239.142 761.426l.381.65" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4239.523 762.076l1.165 1.99" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4240.688 764.066l1.026 1.752" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4115.327 279.77v.076" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4114.35 653.967l-.041 16.053" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3841.058 680.059l-.516-29.659" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M3834.51 304.115l-.45-25.802" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4155.663 778.553l.045-7.871" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4155.802 754.092l2.69-473.429" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4155.154 868.16l.017-2.976" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4155.171 865.184l.026-4.68" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4155.198 860.504l.017-3.236" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4010.166 248.216l2.957.043" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4013.123 248.259l26.25.387" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3938.354 247.831l10.52.026" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4104.163 317.357l-13.415-.2" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4090.748 317.157l-1.028-.015" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4039.564 316.825h.095" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4034.16 316.799l-22.805-.059" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3971.792 316.683l-8.336-.007" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3938.632 316.688l-1.91.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3892.034 316.781h-.643" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3891.391 316.783h-.435" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3882.866 316.808l-2.971.016" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3879.895 316.824l-24.895.137" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3987.048 317.443l-6.968-.027" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3980.08 317.416h-.226" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3965.041 317.768h.173" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3964.094 317.768h.187" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4038.213 317.275l1.352-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4045.562 317.266h1.12" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4023.709 635.87l-1.276-317.558" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4022.433 318.312v-.897" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4071.55 641.192l-.168-323.065" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4071.382 318.127v-.71" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3892.038 317.095h.833" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3890.96 317.096h.435" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3878.012 641.865l-4.749-323.758" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M3873.263 318.108l-.013-.887" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3926.656 636.463l-3.534-318.164" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M3923.122 318.3l-.012-1.05" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3983.511 634.658l-2.2-316.295" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M3981.312 318.363v-.302" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3938.573 635.793l-3.25-317.467" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M3935.323 318.326v-.267" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4348.787 845.632l8.59-.49" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4350.5 769.467l8.639-.42" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4364.354 830.944l-.036.134" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4364.318 831.078l-1.45 5.334" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4278.31 850.623l-.05.003" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4269.848 759.977l-1.19.056" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3895.842 317.094h.386" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3896.228 317.094h1.291" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3901.36 317.098l1.14.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3902.5 317.1l1.008.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3903.508 317.102h.845" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3904.353 317.103h.261" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3905.462 317.106h.234" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3905.697 317.107l3.066.008" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4048.123 317.266h1.244" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4052.505 317.27h.742" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4054.133 317.274h.118" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4054.25 317.274h.93" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3956.918 317.927h.983" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3957.9 317.928h1.21" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3961.418 317.93l1.028.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3964.095 317.934h.57" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3964.666 317.935h.301" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4248.024 845.6l-.736 1.723" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4247.288 847.323l-1.344 3.149" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4115.326 279.846l-.065 25.003" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M3840.542 650.4l-5.794-332.651" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4155.583 792.571l.03-5.056" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4155.612 787.515l.051-8.962" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4155.793 755.741l.01-1.649" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4154.316 1015.636l.831-146.274" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4155.147 869.362l.01-1.203" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4050.397 248.864l1.063.031" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3993.144 247.964l2.491.037" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4039.373 248.646h.095" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3890.475 248.292l.646-.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3891.121 248.285l.837-.008" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3948.874 247.857l11.867.028" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4039.659 316.825l.315.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4011.354 316.74l-12.293-.031" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3999.06 316.709l-2.073-.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3979.849 316.69l-8.057-.006" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3942.58 316.684l-3.948.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3892.867 316.779l-.833.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3890.956 316.784h-.212" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3890.744 316.785l-7.878.023" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3964.28 317.768h.385" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3964.665 317.768h.376" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3951.416 317.735h.028" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3951.444 317.735h.238" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3951.682 317.736l.44.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4043.165 317.268h1.159" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4044.324 317.267h1.238" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3894.999 317.094h.843" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3887.36 317.102l3.387-.006" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3890.747 317.097h.214" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3953.55 317.929h.945" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3954.495 317.928h1.063" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3955.558 317.928h1.147" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3956.705 317.927h.213" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3951.418 317.931h.028" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3951.446 317.931h.238" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3951.684 317.931h.44" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4357.377 845.142l6.764-.386" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4364.141 844.756l53.207-3.035" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4359.139 769.047l45.626-2.22" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4404.765 766.827l22.993-1.119" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4278.26 850.627l-3.602.205" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4274.658 850.832l-29.59 1.691" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4268.658 760.033l-.152.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4268.507 760.04l-29.365 1.386" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4046.683 317.266h.158" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4046.841 317.266h1.282" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4050.996 317.268h.596" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4051.592 317.27h.913" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3962.446 317.932h.902" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3963.348 317.933h.747" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4115.245 311.09l-.894 342.877" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M4155.774 759.115l.019-3.374" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4049.223 248.83l1.174.034" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4053.123 248.944l.55.016" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4053.674 248.96l.34.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3995.635 248.001l8.58.127" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4004.214 248.128l5.952.087" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4039.468 248.648l.317.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3889.824 248.298l.214-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3890.038 248.296l.437-.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3960.741 247.885l12.348.03" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4039.974 316.827l.527.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3987.052 316.695l-7.203-.006" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3948.312 316.68l-5.732.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3962.445 317.765l.901.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3963.346 317.766h.748" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3952.121 317.738l.629.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4039.565 317.272h.095" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4040.502 317.27h.723" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4041.224 317.27h.898" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3892.87 317.095h.998" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3893.868 317.094h1.13" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3952.123 317.93h.629" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3952.752 317.93h.142" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4115.258 305.883l-.014 5.208" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3834.748 317.749l-.01-.57" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3834.738 317.18l-.02-1.127" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3834.718 316.053l-.207-11.883" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M3834.511 304.17v-.055" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4155.708 770.682l.037-6.542" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4155.745 764.14l.029-5.025" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4046.685 248.755l1.288.038" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4047.973 248.793l1.25.036" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4051.46 248.895l.918.027" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4052.378 248.922l.745.022" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4054.013 248.97l.118.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4054.131 248.973l38.678 1.133" fill="none" stroke="#000" stroke-width="1.411"
                stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none"
                stroke-opacity="1" />
            <path d="M3891.958 248.277l1.002-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3892.96 248.267l1.135-.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3894.095 248.256l1.236-.013" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3973.089 247.915l11.92.03" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3985.01 247.944l8.134.02" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3996.806 316.703l-3.91-.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3992.896 316.7l-5.844-.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3956.887 316.671h-1.449" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3955.438 316.673l-7.126.007" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3917.803 316.707l-7.946.023" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3894.994 316.773l-1.13.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3893.864 316.776l-.997.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3959.108 317.759l1.185.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3960.293 317.76l1.124.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3961.417 317.763l1.028.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3952.75 317.74l.799.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4039.66 317.272h.315" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4039.975 317.272h.527" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4115.261 304.849v.012" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4115.261 304.86v1.023" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4041.942 248.684l1.05.016" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4045.4 248.736l1.235.018" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4042.12 316.837l1.044.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3956.703 317.754l1.196.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3957.9 317.756l1.208.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3955.556 317.75l.915.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4039.785 248.653l.53.008" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4042.991 248.7l1.164.017" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4044.155 248.717l1.245.019" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3921.466 247.983l2.718-.027" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3924.184 247.956l5.749-.057" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3929.933 247.9l7.13-.072" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4040.5 316.83l.723.003" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4041.223 316.833l.898.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4043.164 316.842l1.159.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4044.323 316.847l1.238.006" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3900.121 316.758l-1.294.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3898.827 316.762l-1.312.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3953.549 317.743l.945.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3954.494 317.746l1.062.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4040.315 248.66l.726.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4041.041 248.671l.901.013" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4053.247 316.89l.547.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4053.794 316.893l.338.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3901.357 316.755l-1.236.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3897.515 316.766l-1.291.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3896.224 316.77l-1.23.003" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3895.331 248.243l1.297-.012" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3896.628 248.23l1.32-.013" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3897.947 248.217l1.3-.012" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4052.505 316.887l.742.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4054.132 316.894h.118" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4054.25 316.895l11.657.055" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3899.247 248.205l1.241-.013" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4051.591 316.882l.914.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3904.349 316.746l-.846.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3903.503 316.749l-1.008.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3902.495 316.751l-1.138.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4050.534 316.877l1.057.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3905.007 316.744l-.658.004" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3900.488 248.192l1.145-.011" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3901.633 248.18l1.012-.01" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3902.645 248.17l.85-.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4049.366 316.871l1.168.006" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3905.458 316.743h-.451" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3903.495 248.162l.661-.007" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4048.122 316.866l1.244.006" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3909.857 316.73l-4.165.012" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3905.692 316.742h-.234" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3904.156 248.156l.454-.005" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4045.56 316.853l1.28.006" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4046.84 316.86l1.282.005" fill="none" stroke="#000" stroke-width=".706" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3904.61 248.151l.234-.004" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M3904.845 248.149l16.621-.166" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
            <path d="M4271.95 760.038l.573.311" fill="none" stroke="#000" stroke-width="1.411" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="1" />
        </g>
    </g>

<slot/>
</svg>