<script>
  import { fade } from "svelte/transition";

  const raw = `
    <g transform="translate(-98.677 -136.744)">
        <g transform="translate(-36.855 117.69)">
            <path d="M142.243 19.141l.016-.014v.176l-.016.001z" fill="#2a2a2a" />
            <path d="M142.26 19.127l.045-.013v.185l-.046.004z" fill="#3a3a3a" />
            <path d="M142.305 19.114l.072-.011v.19l-.029.001-.043.005z" fill="#484848" />
            <path d="M142.377 19.103l.093-.01v.195l-.093.005z" fill="#545454" />
            <path d="M142.47 19.094l.108-.005v.196l-.068.001-.04.002z" fill="#5c5c5c" />
            <path d="M142.578 19.089l.116-.002v.197h-.01l-.106.001z" fill="#606060" />
            <path d="M142.694 19.087l.116.002v.196l-.116-.001z" fill="#606060" />
            <path d="M142.81 19.09l.107.005v.194l-.058-.003h-.049z" fill="#5b5b5b" />
            <path d="M142.917 19.095l.092.01v.189l-.092-.005z" fill="#535353" />
            <path d="M143.009 19.104l.07.012v.184l-.058-.006h-.012z" fill="#464646" />
            <path d="M143.078 19.116l.043.013v.175l-.043-.004z" fill="#383838" />
            <path d="M143.12 19.13l.014.014v.161l-.013-.001z" fill="#272727" />
            <path d="M143.16 19.308l.107.016 1.107.982-.287-.025z" fill="#7f7f7f" />
            <path
                d="M143.267 19.324l.067.02 1.183.977-.052-.007-.091-.008zm-1.227.02l.065-.02-1.113.98-.104.01-.031.004z"
                fill="#6a6a6a" />
            <path d="M142.105 19.324l.105-.016-.937.973-.281.024z" fill="#7f7f7f" />
            <path d="M145.172 20.467l.068.052-.25-.069-.062-.05z" fill="#959595" />
            <path d="M144.99 20.45l.25.069.052.068-.255-.07z" fill="#a4a4a4" />
            <path d="M144.99 20.45l.047.068-.422-.06-.04-.068z" fill="#c8c8c8" />
            <path d="M145.037 20.518l.255.07.032.08-.257-.07z" fill="#aeaeae" />
            <path d="M145.292 20.587l-.052-.068.071.072.053.07z" fill="#7b7b7b" />
            <path d="M145.037 20.518l.03.08-.428-.06-.024-.08z" fill="#d8d8d8" />
            <path d="M145.067 20.598l.257.07.011.086-.258-.069zm.01.087l.258.07-.01.087-.258-.068z" fill="#b3b3b3" />
            <path d="M145.077 20.685l-.01.089-.428-.06.009-.09z" fill="#e1e1e1" />
            <path d="M145.067 20.774l.257.068-.033.082-.254-.067z" fill="#aeaeae" />
            <path d="M145.037 20.857l.254.067-.052.071-.25-.065z" fill="#a4a4a4" />
            <path d="M145.037 20.857l-.047.073-.415-.056.04-.075z" fill="#c8c8c8" />
            <path d="M144.99 20.93l.25.065-.069.056-.243-.063z" fill="#959595" />
            <path d="M145.24 20.995l.051-.07.073.07-.053.069z" fill="#7b7b7b" />
            <path d="M145.324 20.842l.011-.088.074.073-.011.087z" fill="gray" />
            <path d="M145.324 20.668l-.032-.08.072.072.034.081zm-.033.256l.033-.082.074.072-.034.08z" fill="#7e7e7e" />
            <path d="M144.928 20.988l.243.063-.078.035-.237-.06z" fill="#848484" />
            <path d="M145.171 21.05l.068-.055.072.069-.069.053z" fill="#767676" />
            <path d="M145.067 20.598l.01.087-.43-.06-.008-.088z" fill="#e1e1e1" />
            <path d="M145.335 20.754l-.01-.086.073.073.011.086z" fill="gray" />
            <path d="M144.99 20.93l-.062.058-.404-.055.051-.059z" fill="#b1b1b1" />
            <path d="M145.093 21.086l.078-.035.071.066-.08.033z" fill="#707070" />
            <path d="M145.067 20.774l-.03.083-.423-.058.025-.085z" fill="#d8d8d8" />
            <path d="M144.928 20.4l.062.05-.414-.06-.051-.049z" fill="#b2b2b2" />
            <path d="M145.172 20.467l-.244-.067-.072-.03.237.066z" fill="#848484" />
            <path d="M144.928 20.988l-.072.038-.39-.053.058-.04z" fill="#959595" />
            <path d="M144.856 21.026l.237.06-.085.014-.229-.058z" fill="#707070" />
            <path d="M144.78 21.042l.228.058v.438l-.152-.037-.076-.01z" fill="#777" />
            <path d="M145.093 21.558l.079.032-.244-.058-.072-.03.152.036.067.016z" fill="#848484" />
            <path d="M144.928 21.532l.244.058.068.052-.25-.06zm-.072-.031l.072.03-.403-.05-.06-.029.314.04z"
                fill="#959595" />
            <path d="M144.928 21.532l.062.05-.414-.052-.051-.05z" fill="#b2b2b2" />
            <path d="M144.99 21.582l.25.06.052.068-.255-.06z" fill="#a4a4a4" />
            <path d="M144.856 21.026l-.077.016-.377-.05.063-.019z" fill="#767676" />
            <path d="M145.008 21.1l.085-.014.068.064-.086.011z" fill="#696969" />
            <path d="M145.008 21.538V21.1l.067.061v.393z" fill="#555" />
            <path d="M144.78 21.042v.45l-.314-.04-.064-.006v-.455z" fill="#959595" />
            <path d="M145.037 21.65l.255.06.032.08-.257-.06z" fill="#aeaeae" />
            <path d="M144.856 20.37l.072.03-.403-.059-.06-.027.052.007z" fill="#959595" />
            <path d="M141.273 20.28l.937-.972.033-.004.016-.001.046-.004.043-.005-.676.962-.223.01-.046.003z"
                fill="#909090" />
            <path d="M140.197 23.835l.079-.032.026-.005.059-.01.143-.025-.072.032z" fill="#848484" />
            <path d="M140.197 23.835l.235-.04-.062.051-.241.041z" fill="#959595" />
            <path d="M140.129 23.887l.241-.04-.048.068-.246.041z" fill="#a4a4a4" />
            <path d="M140.129 23.887l-.053.07-.063.043.053-.07z" fill="#7b7b7b" />
            <path d="M140.076 23.956l.246-.041-.03.08-.248.042z" fill="#aeaeae" />
            <path d="M140.322 23.915l.048-.069.407-.036-.04.069z" fill="#c8c8c8" />
            <path d="M140.044 24.037l.248-.041-.01.087-.25.041z" fill="#b3b3b3" />
            <path d="M140.282 24.083l.01-.087.42-.036-.008.088z" fill="#e1e1e1" />
            <path d="M140.044 24.037l-.012.087-.064.043.011-.086z" fill="gray" />
            <path d="M140.032 24.124l.25-.04.01.088-.248.04z" fill="#b3b3b3" />
            <path d="M140.292 24.172l-.01-.089.422-.035.008.089z" fill="#e1e1e1" />
            <path d="M140.292 23.996l.03-.081.415-.036-.025.08z" fill="#d8d8d8" />
            <path d="M140.432 23.795l.072-.032.056-.005.328-.03-.06.031z" fill="#959595" />
            <path d="M140.076 23.956l-.032.081-.065.044.034-.08z" fill="#7e7e7e" />
            <path d="M140.032 24.124l.012.088-.065.042-.011-.087z" fill="gray" />
            <path d="M140.044 24.212l.248-.04.03.083-.245.038z" fill="#aeaeae" />
            <path d="M140.077 24.293l.245-.038.048.072-.241.037z" fill="#a4a4a4" />
            <path d="M140.077 24.293l.052.07-.063.04-.053-.069z" fill="#7b7b7b" />
            <path d="M140.129 24.364l.241-.037.062.056-.235.035z" fill="#959595" />
            <path d="M140.197 24.418l.235-.035.073.036-.229.034z" fill="#848484" />
            <path d="M140.197 24.418l.08.035-.061.037-.08-.034z" fill="#707070" />
            <path d="M140.276 24.453l.229-.034.077.014-.22.033z" fill="#707070" />
            <path d="M140.361 24.466l.221-.033v.85l-.22.028z" fill="#777" />
            <path d="M140.582 24.433l-.077-.014.384-.03.064.015z" fill="#767676" />
            <path d="M140.505 24.419l-.073-.036.397-.032.06.038z" fill="#959595" />
            <path d="M140.37 24.327l-.048-.072.415-.034.04.073z" fill="#c8c8c8" />
            <path d="M140.361 24.466v.845l-.059.028v-.838z" fill="#555" />
            <path
                d="M140.361 25.31l.221-.026.371-.023.496-.019.588-.011.636-.004.636.004.591.011.502.019.377.023.229.027.067.028-.041.012-.046-.018-.09-.02-.218-.026-.36-.023-.478-.017-.562-.01-.607-.005-.605.004-.56.011-.472.017-.354.023-.211.025-.09.02-.041.017-.038-.01z"
                fill="#666" />
            <path d="M140.953 25.26l-.37.024v-.851l.37-.03z" fill="#959595" />
            <path d="M140.432 24.383l-.062-.056.407-.033.052.057z" fill="#b1b1b1" />
            <path d="M140.276 24.453l.085.013-.059.035-.086-.011z" fill="#696969" />
            <path d="M140.37 23.846l.062-.051.396-.036-.051.051z" fill="#b2b2b2" />
            <path d="M140.044 24.212l.033.081-.064.041-.034-.08z" fill="#7e7e7e" />
            <path d="M140.322 24.255l-.03-.083.42-.035.025.084z" fill="#d8d8d8" />
            <path d="M140.197 23.835l-.068.052-.063.044.069-.053zm-.068.529l.068.054-.062.038-.07-.053z"
                fill="#767676" />
            <path d="M140.828 23.76l.06-.031.515-.027-.043.03z" fill="#a4a4a4" />
            <path d="M140.777 23.81l.051-.05.532-.028-.037.05z" fill="#c9c9c9" />
            <path d="M140.737 23.879l.04-.069.546-.027-.028.068z" fill="#e6e6e6" />
            <path d="M140.704 24.048l.008-.088.565-.028-.006.088z" fill="#fff" />
            <path d="M140.953 25.26v-.856l.496-.022v.86z" fill="#aeaeae" />
            <path d="M140.737 24.221l-.025-.084.565-.027.018.085zm-.025-.261l.025-.081.558-.028-.018.08z"
                fill="#fbfbfb" />
            <path d="M140.953 24.404l-.064-.015.514-.024.046.017z" fill="#7b7b7b" />
            <path d="M140.712 24.137l-.008-.09.567-.027.006.09z" fill="#fff" />
            <path d="M141.403 20.27l.046-.004.223-.01-.001.001z" fill="#909090" />
            <path d="M140.777 24.294l-.04-.073.558-.026.028.074z" fill="#e6e6e6" />
            <path d="M140.889 24.389l-.06-.038.531-.024.043.038z" fill="#a4a4a4" />
            <path d="M140.829 24.351l-.052-.057.546-.025.037.058z" fill="#c8c8c8" />
            <path d="M141.403 23.702l-.515.027.065-.008.496-.025z" fill="#7b7b7b" />
            <path d="M140.582 23.755l-.022.003-.056.005z" fill="#959595" />
            <path d="M140.582 23.755l-.078.008-.143.025v-.444l.221-.042z" fill="#7e7e7e" />
            <path d="M140.361 23.344v.444l-.06.01.001-.386v-.024l.06-.044z" fill="#5a5a5a" />
            <path d="M140.582 23.755v-.453l.371-.036v.455z" fill="#9e9e9e" />
            <path d="M140.582 23.302l-.22.042-.086-.013.229-.043z" fill="#707070" />
            <path d="M140.276 23.33l.085.014-.059.044-.086-.012z" fill="#696969" />
            <path d="M140.582 23.302l-.077-.014.384-.038.064.016z" fill="#767676" />
            <path d="M140.505 23.288l-.229.043-.079-.035.235-.045z" fill="#848484" />
            <path d="M140.953 23.266l-.064-.016.514-.029.046.017z" fill="#7b7b7b" />
            <path d="M140.889 23.25l-.384.038-.073-.037.397-.039z" fill="#959595" />
            <path d="M140.889 23.25l-.06-.038.531-.03.043.04z" fill="#a4a4a4" />
            <path d="M140.953 23.721v-.455l.497-.028v.458z" fill="#b9b9b9" />
            <path d="M140.829 23.212l-.397.039-.062-.056.407-.04z" fill="#b1b1b1" />
            <path d="M140.777 23.154l-.407.04-.048-.072.415-.042zm.052.058l-.052-.058.546-.031.037.059z"
                fill="#c8c8c8" />
            <path d="M140.737 23.08l-.415.042-.03-.083.42-.043z" fill="#d8d8d8" />
            <path d="M140.712 22.996l-.42.043-.01-.088.422-.044z" fill="#e1e1e1" />
            <path d="M140.282 22.95l.01.09-.248.048-.012-.087z" fill="#b3b3b3" />
            <path d="M140.712 22.996l-.008-.09.567-.033.006.09z" fill="#fff" />
            <path d="M140.704 22.907l-.422.044.01-.088.42-.044z" fill="#e1e1e1" />
            <path d="M140.292 22.863l-.01.088-.25.05.012-.087z" fill="#b3b3b3" />
            <path d="M140.704 22.907l.008-.088.565-.034-.006.088z" fill="#fff" />
            <path d="M140.712 22.819l-.42.044.03-.08.415-.045z" fill="#d8d8d8" />
            <path d="M140.737 22.738l-.415.045.048-.069.407-.044z" fill="#c8c8c8" />
            <path d="M140.777 22.67l-.407.044.062-.051.396-.043z" fill="#b2b2b2" />
            <path d="M140.828 22.62l-.396.043.072-.03.078-.01.306-.033z" fill="#959595" />
            <path d="M140.504 22.632l-.072.031-.235.05.079-.033.026-.005.059-.012z" fill="#848484" />
            <path d="M140.828 22.62l.06-.03.515-.032-.043.029z" fill="#a4a4a4" />
            <path d="M140.777 22.67l.051-.05.532-.033-.037.05z" fill="#c9c9c9" />
            <path d="M140.737 22.738l.04-.068.546-.034-.028.068z" fill="#e6e6e6" />
            <path d="M140.37 22.714l-.048.069-.246.05.053-.068z" fill="#a4a4a4" />
            <path d="M140.712 22.819l.025-.08.558-.035-.018.08zm.025.261l-.025-.084.565-.033.018.085z" fill="#fbfbfb" />
            <path d="M140.432 22.663l-.062.051-.241.05.068-.052z" fill="#959595" />
            <path d="M140.322 22.783l-.03.08-.248.05.032-.08zm-.03.257l.03.082-.245.048-.033-.082z" fill="#aeaeae" />
            <path d="M140.37 23.195l.062.056-.235.045-.068-.055z" fill="#959595" />
            <path d="M140.777 23.154l-.04-.074.558-.032.028.075z" fill="#e6e6e6" />
            <path d="M140.322 23.122l.048.073-.241.046-.052-.07z" fill="#a4a4a4" />
            <path d="M141.403 22.558l-.515.032.065-.006.496-.032z" fill="#aeaeae" />
            <path d="M140.953 22.584l-.065.006-.306.034v-.452l.371-.043z" fill="#959595" />
            <path d="M140.953 22.129l-.37.043-.078-.015.384-.045z" fill="#767676" />
            <path d="M140.889 22.112l-.384.045-.073-.038.397-.046z" fill="#959595" />
            <path d="M140.889 22.112l-.06-.04.531-.036.043.04z" fill="#a4a4a4" />
            <path d="M140.829 22.073l-.397.046-.062-.056.407-.049z" fill="#b1b1b1" />
            <path d="M140.777 22.014l-.407.049-.048-.073.415-.05z" fill="#c8c8c8" />
            <path d="M140.737 21.94l-.415.05-.03-.084.42-.05z" fill="#d8d8d8" />
            <path d="M140.292 21.906l.03.084-.245.057-.033-.082z" fill="#aeaeae" />
            <path d="M140.737 21.94l-.025-.085.565-.04.018.086z" fill="#fbfbfb" />
            <path d="M140.712 21.855l-.42.051-.01-.088.422-.052z" fill="#e1e1e1" />
            <path d="M140.712 21.855l-.008-.09.567-.04.006.09z" fill="#fff" />
            <path d="M140.282 21.818l.01.088-.248.06-.012-.088z" fill="#b3b3b3" />
            <path d="M140.704 21.766l-.422.052.01-.087.42-.053z" fill="#e1e1e1" />
            <path d="M140.712 21.678l-.42.053.03-.08.415-.053z" fill="#d8d8d8" />
            <path d="M140.737 21.598l-.415.052.048-.068.407-.052z" fill="#c8c8c8" />
            <path d="M140.37 21.582l-.048.068-.246.06.053-.068z" fill="#a4a4a4" />
            <path d="M140.737 21.598l.04-.068.546-.04-.028.067z" fill="#e6e6e6" />
            <path d="M140.777 21.53l-.407.052.062-.05.396-.051z" fill="#b2b2b2" />
            <path d="M140.704 21.766l.008-.088.565-.04-.006.087z" fill="#fff" />
            <path d="M140.828 21.48l-.396.052.072-.03.078-.01.306-.04z" fill="#959595" />
            <path d="M140.828 21.48l.06-.028.047-.003.468-.035-.043.027z" fill="#a4a4a4" />
            <path d="M140.504 21.501l-.072.03-.235.059.079-.032.026-.006.059-.015z" fill="#848484" />
            <path d="M140.322 21.65l-.03.08-.248.06.032-.08z" fill="#aeaeae" />
            <path d="M140.712 21.678l.025-.08.558-.041-.018.08z" fill="#fbfbfb" />
            <path d="M140.777 21.53l.051-.05.532-.039-.037.05z" fill="#c9c9c9" />
            <path d="M140.432 21.532l-.062.05-.241.06.068-.052z" fill="#959595" />
            <path d="M140.777 22.014l-.04-.074.558-.039.028.075z" fill="#e6e6e6" />
            <path d="M140.322 21.99l.048.073-.241.055-.052-.07z" fill="#a4a4a4" />
            <path d="M140.292 21.73l-.01.088-.25.06.012-.087z" fill="#b3b3b3" />
            <path d="M140.37 22.063l.062.056-.235.054-.068-.055z" fill="#959595" />
            <path d="M140.829 22.073l-.052-.059.546-.038.037.06z" fill="#c8c8c8" />
            <path d="M140.432 22.12l.073.037-.229.052-.079-.036z" fill="#848484" />
            <path d="M140.505 22.157l.077.015-.22.05-.086-.013z" fill="#707070" />
            <path d="M140.953 22.129l-.064-.017.514-.035.046.018z" fill="#7b7b7b" />
            <path d="M140.953 22.584v-.455l.497-.034v.457z" fill="#aeaeae" />
            <path d="M140.582 22.172v.452l-.078.008-.143.03v-.44z" fill="#777" />
            <path d="M141.277 21.815l-.006-.09.671-.025.004.09z" fill="#fff" />
            <path d="M141.403 22.077l-.043-.04.63-.024.023.042zm-.043-.636l.043-.027.61-.025-.023.027z"
                fill="#aeaeae" />
            <path d="M141.323 21.49l.037-.049.63-.025-.02.049z" fill="#d9d9d9" />
            <path d="M141.295 21.557l.028-.067.647-.025-.015.066z" fill="#fcfcfc" />
            <path d="M141.277 21.637l.018-.08.66-.026-.01.08z" fill="#fff" />
            <path d="M141.323 21.976l-.028-.075.66-.025.015.077z" fill="#fbfbfb" />
            <path d="M141.36 22.036l-.037-.06.647-.023.02.06z" fill="#d9d9d9" />
            <path d="M141.45 22.552v-.457l.587-.021v.459l-.588.02z" fill="silver" />
            <path d="M141.295 21.901l-.018-.086.669-.025.01.086z" fill="#fff" />
            <path d="M141.45 22.095l-.047-.018.61-.022.024.019zm-.047.463l.046-.006.588-.02-.025.005z" fill="#7e7e7e" />
            <path d="M141.27 21.725l.007-.088.669-.026-.004.089z" fill="#fff" />
            <path d="M141.45 23.696v-.458l.587-.017v.458l-.588.017z" fill="#cbcbcb" />
            <path d="M141.403 23.702l.046-.006.588-.017-.025.006z" fill="#7e7e7e" />
            <path d="M141.403 23.221l-.043-.04.63-.018.023.04zm-.043.511l.043-.03.61-.017-.023.03z" fill="#aeaeae" />
            <path d="M141.323 23.123l-.028-.075.66-.02.015.075z" fill="#fbfbfb" />
            <path d="M141.277 22.963l-.006-.09.671-.022.004.09z" fill="#fff" />
            <path d="M141.295 23.048l-.018-.085.669-.021.01.085z" fill="#fff" />
            <path d="M141.323 23.783l.037-.051.63-.017-.02.05z" fill="#d9d9d9" />
            <path d="M141.277 22.785l.018-.081.66-.022-.01.08z" fill="#fff" />
            <path d="M141.36 23.182l-.037-.06.647-.02.02.06z" fill="#d9d9d9" />
            <path d="M141.36 22.587l.043-.029.61-.02-.023.027z" fill="#aeaeae" />
            <path
                d="M141.277 24.11l-.006-.09.671-.017.004.09zm0-.178l.018-.081.66-.018-.01.081zm.018.263l-.018-.085.669-.017.01.085z"
                fill="#fff" />
            <path d="M141.323 24.269l-.028-.074.66-.017.015.075z" fill="#fbfbfb" />
            <path d="M141.27 24.02l.007-.088.669-.018-.004.089z" fill="#fff" />
            <path d="M141.36 24.327l-.037-.058.647-.016.02.059z" fill="#d9d9d9" />
            <path d="M141.45 23.238l-.047-.017.61-.018.024.018z" fill="#7e7e7e" />
            <path d="M141.295 23.85l.028-.067.647-.018-.015.068z" fill="#fcfcfc" />
            <path d="M141.323 22.636l.037-.05.63-.02-.02.049z" fill="#d9d9d9" />
            <path d="M141.295 22.704l.028-.068.647-.021-.015.067z" fill="#fcfcfc" />
            <path d="M141.27 22.873l.007-.088.669-.022-.004.088z" fill="#fff" />
            <path d="M141.403 24.365l-.043-.038.63-.015.023.039z" fill="#aeaeae" />
            <path d="M142.037 25.23l-.588.012v-.86l.588-.015z" fill="silver" />
            <path d="M141.45 24.382l-.047-.017.61-.014.024.016z" fill="#7e7e7e" />
            <path d="M141.97 22.615l.02-.05.681-.007v.05zm0-1.15l.02-.049.681-.008v.048z" fill="#e1e1e1" />
            <path d="M141.955 21.531l.015-.066.7-.01v.067z" fill="#fff" />
            <path d="M142.012 22.537l.025-.004.636-.007-.001.004z" fill="gray" />
            <path d="M141.946 21.611l.01-.08.715-.009v.08z" fill="#fff" />
            <path d="M141.942 21.7l.004-.089.724-.009v.089l-.727.009zm.028.253l-.015-.077.716-.008v.076z" fill="#fff" />
            <path d="M141.99 22.013l-.02-.06.7-.009.001.062z" fill="#e1e1e1" />
            <path d="M142.012 21.39l-.609.024.046-.005.588-.023z" fill="silver" />
            <path d="M141.45 21.41l-.047.004-.468.035.018-.003z" fill="#a4a4a4" />
            <path d="M140.953 21.446l-.018.003-.047.003z" fill="#a4a4a4" />
            <path d="M140.953 21.446l-.065.006-.306.04v-.45l.371-.05z" fill="#959595" />
            <path d="M140.953 20.991l-.37.05-.078-.015.384-.053z" fill="#767676" />
            <path d="M140.953 20.991l-.064-.018.514-.04.046.02z" fill="#7b7b7b" />
            <path d="M140.889 20.973l-.384.053-.073-.038.397-.055z" fill="#959595" />
            <path d="M140.889 20.973l-.06-.04.531-.042.043.042z" fill="#a4a4a4" />
            <path d="M140.432 20.988l.073.038-.229.06-.079-.035z" fill="#848484" />
            <path d="M140.829 20.933l-.397.055-.062-.058.407-.056z" fill="#b1b1b1" />
            <path d="M140.777 20.874l-.407.056-.048-.073.415-.058z" fill="#c8c8c8" />
            <path d="M140.737 20.8l-.415.057-.03-.083.42-.06z" fill="#d8d8d8" />
            <path d="M140.777 20.874l-.04-.075.558-.045.028.076z" fill="#e6e6e6" />
            <path d="M140.737 20.8l-.025-.086.565-.046.018.086z" fill="#fbfbfb" />
            <path d="M140.712 20.714l-.42.06-.01-.089.422-.06z" fill="#e1e1e1" />
            <path d="M140.704 20.625l-.422.06.01-.087.42-.06z" fill="#e1e1e1" />
            <path d="M140.292 20.598l-.01.087-.25.07.012-.087z" fill="#b3b3b3" />
            <path d="M140.704 20.625l.008-.088.565-.047-.006.088z" fill="#fff" />
            <path d="M140.712 20.537l-.42.06.03-.08.415-.06z" fill="#d8d8d8" />
            <path d="M140.322 20.518l-.03.08-.248.07.032-.08z" fill="#aeaeae" />
            <path d="M140.712 20.537l.025-.08.558-.047-.018.08z" fill="#fbfbfb" />
            <path d="M140.737 20.457l-.415.06.048-.067.407-.06z" fill="#c8c8c8" />
            <path d="M140.737 20.457l.04-.067.546-.046-.028.066z" fill="#e6e6e6" />
            <path d="M140.777 20.39l-.407.06.062-.05.396-.059z" fill="#b2b2b2" />
            <path d="M140.777 20.39l.051-.049.532-.045-.037.048z" fill="#c9c9c9" />
            <path d="M140.828 20.341l-.396.059.072-.03.353-.052.031-.004zm-.396.059l-.062.05-.241.069.068-.052z"
                fill="#959595" />
            <path d="M140.712 20.714l-.008-.09.567-.046.006.09z" fill="#fff" />
            <path d="M140.829 20.933l-.052-.059.546-.044.037.061z" fill="#c8c8c8" />
            <path d="M140.292 20.774l.03.083-.245.067-.033-.082z" fill="#aeaeae" />
            <path d="M140.505 21.026l.077.016-.22.058-.086-.014z" fill="#707070" />
            <path d="M140.37 20.45l-.048.068-.246.07.053-.07z" fill="#a4a4a4" />
            <path d="M140.282 20.685l.01.089-.248.068-.012-.088z" fill="#b3b3b3" />
            <path d="M140.582 21.042v.45l-.078.01-.143.035V21.1z" fill="#777" />
            <path d="M140.953 21.446v-.455l.497-.039v.457z" fill="#aeaeae" />
            <path d="M140.504 20.37l-.072.03-.235.067.079-.031z" fill="#848484" />
            <path d="M140.828 20.341l.06-.027.104-.01.28-.023.131-.012-.043.027z" fill="#a4a4a4" />
            <path d="M140.37 20.93l.062.058-.235.063-.068-.056z" fill="#959595" />
            <path d="M140.322 20.857l.048.073-.241.065-.052-.07z" fill="#a4a4a4" />
            <path d="M141.36 20.296l.043-.027.268-.012.341-.016-.022.026z" fill="#aeaeae" />
            <path d="M141.295 20.41l.028-.066.647-.03-.015.066z" fill="#fcfcfc" />
            <path d="M141.277 20.49l.018-.08.66-.03-.01.08zm-.007.088l.007-.088.669-.03-.004.088z" fill="#fff" />
            <path d="M141.323 20.344l.037-.048.63-.029-.02.047z" fill="#d9d9d9" />
            <path d="M141.45 20.952l-.047-.02.61-.025.024.02z" fill="#7e7e7e" />
            <path d="M141.45 21.41v-.458l.587-.025v.459l-.588.023z" fill="silver" />
            <path d="M141.36 20.891l-.037-.06.647-.029.02.062z" fill="#d9d9d9" />
            <path d="M141.277 20.668l-.006-.09.671-.03.004.09zm.018.086l-.018-.086.669-.03.01.087z" fill="#fff" />
            <path d="M141.323 20.83l-.028-.076.66-.029.015.077z" fill="#fbfbfb" />
            <path d="M141.403 20.933l-.043-.042.63-.027.023.043z" fill="#aeaeae" />
            <path d="M141.99 20.267l.022-.026.66-.01v.026z" fill="#b4b4b4" />
            <path d="M141.946 20.46l.01-.08.715-.01v.08zm.009-.08l.015-.066.7-.01v.066z" fill="#fff" />
            <path d="M141.942 20.548l.004-.088.724-.01v.088l-.727.01zm.004.091l-.004-.09.728-.011v.09l-.724.01z"
                fill="#fff" />
            <path d="M141.97 20.314l.02-.047.681-.01v.047z" fill="#e1e1e1" />
            <path d="M141.955 20.725l-.01-.086.725-.01v.087z" fill="#fff" />
            <path d="M141.97 20.802l-.015-.077.716-.01v.078z" fill="#fff" />
            <path d="M141.99 20.864l-.02-.062.7-.01.001.063z" fill="#e1e1e1" />
            <path d="M142.013 20.907l-.023-.043.681-.009v.043z" fill="#b3b3b3" />
            <path d="M142.037 20.927l-.024-.02.659-.009v.02z" fill="gray" />
            <path d="M142.037 21.386v-.459l.635-.008v.459z" fill="#c9c9c9" />
            <path d="M142.012 21.39l.025-.004.636-.008-.001.003z" fill="gray" />
            <path d="M142.037 22.533v-.459l.635-.007v.459z" fill="#c9c9c9" />
            <path d="M141.955 21.876l-.01-.086.725-.009v.087z" fill="#fff" />
            <path d="M142.013 22.055l-.023-.042.681-.007v.041z" fill="#b3b3b3" />
            <path d="M141.99 22.565l.022-.028.66-.007v.028z" fill="#b4b4b4" />
            <path d="M141.955 22.682l.015-.067.7-.008v.068zm-.009-.892l-.004-.09.728-.01v.091l-.724.01z" fill="#fff" />
            <path d="M141.99 21.416l.022-.027.66-.008v.027z" fill="#b4b4b4" />
            <path d="M142.037 22.074l-.024-.02.659-.007v.02z" fill="gray" />
            <path d="M141.946 22.763l.01-.081.715-.007v.08z" fill="#fff" />
            <path d="M141.942 22.851l.004-.088.724-.008v.089l-.727.007zm.004.091l-.004-.09.728-.008v.09z" fill="#fff" />
            <path d="M141.99 23.163l-.02-.06.7-.007.001.06z" fill="#e1e1e1" />
            <path d="M141.97 23.103l-.015-.076.716-.007v.076z" fill="#fff" />
            <path d="M142.012 23.685l.025-.006.636-.005-.001.006zm.025-.465l-.024-.017.659-.007v.019z" fill="gray" />
            <path d="M141.955 23.833l.015-.068.7-.006v.068zm0 .345l-.01-.085.725-.006v.086z" fill="#fff" />
            <path d="M142.037 23.68v-.46l.635-.005v.459l-.635.005z" fill="#d5d5d5" />
            <path d="M141.99 23.715l.022-.03.66-.005v.029z" fill="#b4b4b4" />
            <path d="M141.97 24.253l-.015-.075.716-.005v.074z" fill="#fff" />
            <path d="M141.99 24.312l-.02-.06.7-.005.001.06z" fill="#e1e1e1" />
            <path d="M142.013 23.203l-.023-.04.681-.007v.04z" fill="#b3b3b3" />
            <path d="M141.955 23.027l-.01-.085.725-.008v.086zm-.009.887l.01-.081.715-.006v.081z" fill="#fff" />
            <path d="M142.037 24.367l-.024-.016.659-.005v.017z" fill="gray" />
            <path d="M142.037 25.23v-.863l.636-.004v.864z" fill="#c9c9c9" />
            <path d="M141.942 24.003l.004-.089.724-.006v.089l-.727.006z" fill="#fff" />
            <path d="M141.97 23.765l.02-.05.681-.006v.05z" fill="#e1e1e1" />
            <path d="M141.946 24.093l-.004-.09.728-.006v.09z" fill="#fff" />
            <path d="M142.013 24.35l-.023-.038.681-.006v.04z" fill="#b3b3b3" />
            <path d="M142.67 20.304l.001-.047.683.01.019.047z" fill="#e1e1e1" />
            <path d="M142.671 20.257v-.025l.662.01.021.025z" fill="#b4b4b4" />
            <path d="M142.672 20.232l-.66.01.025-.003.116-.001.52-.008z" fill="gray" />
            <path d="M142.037 20.24l-.025.001-.341.016v-.001z" fill="#aeaeae" />
            <path d="M142.672 20.232v-.002h.003l.524.008.11.001.024.002-.661-.01z" fill="gray" />
            <path d="M142.67 20.538v-.088l.726.01.003.088zm0 .255v-.077l.717.01-.014.076z" fill="#fff" />
            <path d="M142.671 21.408v-.027l.662.008.021.027z" fill="#b4b4b4" />
            <path d="M142.67 21.456l.001-.048.683.008.019.049z" fill="#e1e1e1" />
            <path d="M142.67 20.37l.001-.066.702.01.014.066zm0 .346v-.087l.726.01-.009.086z" fill="#fff" />
            <path d="M142.672 20.898v-.043l.682.01-.021.042z" fill="#b3b3b3" />
            <path d="M142.672 21.381v-.003l.637.008.024.003z" fill="gray" />
            <path d="M142.67 20.45v-.08l.717.01.009.08z" fill="#fff" />
            <path d="M142.673 20.919l-.001-.021.66.009-.023.02z" fill="gray" />
            <path d="M142.67 20.629v-.091l.73.01-.004.09-.726-.01z" fill="#fff" />
            <path d="M142.673 21.378v-.46l.636.01v.458l-.637-.008z" fill="#c9c9c9" />
            <path d="M142.67 21.522l.001-.066.702.009.014.066z" fill="#fff" />
            <path d="M142.671 20.855v-.062l.702.01-.019.061z" fill="#e1e1e1" />
            <path d="M142.67 22.675l.001-.068.702.008.014.067zm0 .08v-.08l.717.007.009.08z" fill="#fff" />
            <path d="M142.673 23.674v-.46l.636.007v.458l-.637-.005z" fill="#d5d5d5" />
            <path d="M142.67 22.607l.001-.049.683.007.019.05z" fill="#e1e1e1" />
            <path d="M142.673 22.526v-.46l.636.008v.459l-.637-.007z" fill="#c9c9c9" />
            <path d="M142.67 21.868v-.087l.726.01-.009.085z" fill="#fff" />
            <path d="M142.672 22.53v-.004l.637.007.024.004z" fill="gray" />
            <path d="M142.671 22.558v-.028l.662.007.021.028z" fill="#b4b4b4" />
            <path d="M142.67 23.02v-.086l.726.008-.009.085zm0-1.33v-.088l.726.01.003.088z" fill="#fff" />
            <path d="M142.67 21.602v-.08l.717.01.009.08z" fill="#fff" />
            <path d="M142.672 22.047v-.041l.682.007-.021.042z" fill="#b3b3b3" />
            <path
                d="M142.67 21.944v-.076l.717.008-.014.077zm0-.163v-.09l.73.009-.004.09zm0 1.315v-.076l.717.007-.014.076z"
                fill="#fff" />
            <path d="M142.671 23.156v-.06l.702.007-.019.06zm0-1.15v-.062l.702.009-.019.06z" fill="#e1e1e1" />
            <path d="M142.67 22.844v-.089l.726.008.003.088z" fill="#fff" />
            <path d="M142.67 22.934v-.09l.73.007-.004.09z" fill="#fff" />
            <path d="M142.673 23.215l-.001-.019.66.007-.023.018zm0-1.148l-.001-.02.66.008-.023.019z" fill="gray" />
            <path d="M142.672 23.196v-.04l.682.007-.021.04z" fill="#b3b3b3" />
            <path d="M142.67 23.908v-.081l.717.006.009.081z" fill="#fff" />
            <path d="M142.67 23.997v-.089l.726.006.003.089zm0 .25v-.074l.717.005-.014.075z" fill="#fff" />
            <path d="M142.671 24.306v-.059l.702.006-.019.059z" fill="#e1e1e1" />
            <path d="M142.67 24.087v-.09l.73.006-.004.09z" fill="#fff" />
            <path d="M142.671 23.709v-.03l.662.006.021.03z" fill="#b4b4b4" />
            <path d="M142.672 24.346v-.04l.682.006-.021.039z" fill="#b3b3b3" />
            <path d="M142.673 25.227v-.864l.636.004v.864z" fill="#c9c9c9" />
            <path d="M142.673 24.363l-.001-.017.66.005-.023.016z" fill="gray" />
            <path d="M142.67 23.759l.001-.05.683.006.019.05z" fill="#e1e1e1" />
            <path d="M142.67 23.827l.001-.068.702.006.014.068z" fill="#fff" />
            <path d="M142.672 23.68v-.006l.637.005.024.006z" fill="gray" />
            <path d="M143.333 20.241l-.024-.002.374.017.001.002z" fill="#9c9c9c" />
            <path d="M142.67 24.173v-.086l.726.006-.009.085z" fill="#fff" />
            <path d="M143.354 20.267l-.021-.026.351.017.261.011.042.027z" fill="#aeaeae" />
            <path d="M143.387 21.531l-.014-.066.65.025.028.067z" fill="#fcfcfc" />
            <path d="M143.396 21.611l-.009-.08.664.026.018.08z" fill="#fff" />
            <path d="M143.333 21.39l-.024-.004.591.023.045.005zm-.023-.463l.023-.02.612.026-.045.02z" fill="#7e7e7e" />
            <path d="M143.31 21.386v-.459l.59.025v.457z" fill="silver" />
            <path d="M143.396 21.79l.003-.09.676.025-.006.09z" fill="#fff" />
            <path d="M143.373 20.314l-.019-.047.633.029.037.048z" fill="#d9d9d9" />
            <path d="M143.387 20.725l.01-.086.672.029-.018.086z" fill="#fff" />
            <path d="M143.354 20.864l.019-.062.65.028-.036.061z" fill="#d9d9d9" />
            <path d="M143.373 20.802l.014-.077.664.03-.027.075z" fill="#fbfbfb" />
            <path d="M143.4 20.548l-.004-.088.673.03.006.088z" fill="#fff" />
            <path d="M143.387 20.38l-.014-.066.65.03.028.066z" fill="#fcfcfc" />
            <path d="M143.4 21.7l-.004-.089.673.026.006.088z" fill="#fff" />
            <path d="M143.354 21.416l-.021-.027.612.025.042.027zm-.021-.509l.021-.043.633.027-.042.042z"
                fill="#aeaeae" />
            <path d="M143.373 21.953l.014-.077.664.025-.027.075z" fill="#fbfbfb" />
            <path d="M143.354 22.013l.019-.06.65.023-.036.06z" fill="#d9d9d9" />
            <path d="M143.396 20.639l.003-.09.676.029-.006.09zm0-.179l-.009-.08.664.03.018.08z" fill="#fff" />
            <path d="M143.373 21.465l-.019-.049.633.025.037.05z" fill="#d9d9d9" />
            <path d="M143.387 21.876l.01-.086.672.025-.018.086zm.009.887l-.009-.081.664.022.018.08z" fill="#fff" />
            <path d="M143.31 22.074l.023-.02.612.023-.045.018z" fill="#7e7e7e" />
            <path d="M143.333 22.055l.021-.042.633.023-.042.04z" fill="#aeaeae" />
            <path d="M143.333 22.537l-.024-.004.591.02.045.005z" fill="#7e7e7e" />
            <path d="M143.354 22.565l-.021-.028.612.021.042.029z" fill="#aeaeae" />
            <path d="M143.396 22.942l.003-.09.676.02-.006.09z" fill="#fff" />
            <path d="M143.373 23.103l.014-.076.664.02-.027.076z" fill="#fbfbfb" />
            <path d="M143.373 22.615l-.019-.05.633.022.037.05z" fill="#d9d9d9" />
            <path d="M143.387 22.682l-.014-.067.65.021.028.068z" fill="#fcfcfc" />
            <path d="M143.31 22.533v-.459l.59.021v.457l-.59-.02z" fill="silver" />
            <path d="M143.4 22.851l-.004-.088.673.022.006.088z" fill="#fff" />
            <path d="M143.354 23.163l.019-.06.65.02-.036.059z" fill="#d9d9d9" />
            <path d="M143.333 23.203l.021-.04.633.019-.042.04z" fill="#aeaeae" />
            <path d="M143.333 23.685l-.024-.006.591.017.045.006z" fill="#7e7e7e" />
            <path d="M143.354 23.715l-.021-.03.612.017.042.03z" fill="#aeaeae" />
            <path d="M143.387 23.027l.01-.085.672.02-.018.086z" fill="#fff" />
            <path d="M143.31 23.22l.023-.017.612.018-.045.017z" fill="#7e7e7e" />
            <path d="M143.373 23.765l-.019-.05.633.017.037.05z" fill="#d9d9d9" />
            <path d="M143.31 23.68v-.46l.59.018v.458l-.59-.017z" fill="#cbcbcb" />
            <path d="M143.387 23.833l-.014-.068.65.018.028.068z" fill="#fcfcfc" />
            <path d="M143.396 23.914l-.009-.081.664.018.018.08z" fill="#fff" />
            <path d="M143.9 25.242l-.59-.011v-.864l.59.015z" fill="silver" />
            <path d="M144.075 20.578l-.006-.088.57.047.009.088z" fill="#fff" />
            <path d="M144.069 20.49l-.018-.08.564.047.024.08z" fill="#fbfbfb" />
            <path d="M144.051 20.41l-.027-.066.552.046.039.067z" fill="#e6e6e6" />
            <path d="M143.31 24.367l.023-.016.612.014-.045.017z" fill="#7e7e7e" />
            <path d="M143.987 20.296l-.042-.027.142.012.287.025.091.008.06.027z" fill="#a4a4a4" />
            <path d="M143.373 24.253l.014-.075.664.017-.027.074z" fill="#fbfbfb" />
            <path d="M143.4 24.003l-.004-.089.673.018.006.088z" fill="#fff" />
            <path d="M143.396 24.093l.003-.09.676.017-.006.09zm.673-3.425l.006-.09.573.047-.009.09z" fill="#fff" />
            <path d="M143.354 24.312l.019-.06.65.017-.036.058z" fill="#d9d9d9" />
            <path d="M143.387 24.178l.01-.085.672.017-.018.085z" fill="#fff" />
            <path d="M143.333 24.35l.021-.038.633.015-.042.038z" fill="#aeaeae" />
            <path d="M144.024 20.344l-.037-.048.538.045.05.05z" fill="#c9c9c9" />
            <path d="M144.051 20.754l.018-.086.57.046-.025.085z" fill="#fbfbfb" />
            <path d="M144.024 21.49l-.037-.049.538.04.05.05z" fill="#c9c9c9" />
            <path d="M144.051 21.557l-.027-.067.552.04.039.068z" fill="#e6e6e6" />
            <path d="M143.987 21.441l-.042-.027.478.035.042.003.06.029z" fill="#a4a4a4" />
            <path d="M143.9 20.952l.045-.02.52.041-.063.018z" fill="#7b7b7b" />
            <path d="M143.945 21.414l-.045-.005.502.037.02.003z" fill="#aeaeae" />
            <path d="M143.9 21.41v-.458l.502.04v.454z" fill="#aeaeae" />
            <path d="M144.069 21.637l-.018-.08.564.04.024.081z" fill="#fbfbfb" />
            <path d="M144.069 21.815l.006-.09.573.04-.009.09z" fill="#fff" />
            <path d="M143.987 22.036l.037-.06.551.038-.05.059z" fill="#c8c8c8" />
            <path d="M144.075 21.725l-.006-.088.57.041.009.088z" fill="#fff" />
            <path d="M144.051 21.901l.018-.086.57.04-.025.085z" fill="#fbfbfb" />
            <path d="M143.9 22.095l.045-.018.52.035-.063.017z" fill="#7b7b7b" />
            <path d="M143.945 22.077l.042-.04.537.036-.059.039zm.042.51l-.042-.029.52.032.06.03z" fill="#a4a4a4" />
            <path d="M144.024 22.636l-.037-.05.538.034.05.05z" fill="#c9c9c9" />
            <path d="M144.024 20.83l.027-.076.563.045-.039.075z" fill="#e6e6e6" />
            <path d="M143.987 20.891l.037-.06.551.043-.05.06z" fill="#c8c8c8" />
            <path d="M144.024 21.976l.027-.075.563.039-.039.074z" fill="#e6e6e6" />
            <path d="M143.9 22.552v-.457l.502.034v.455z" fill="#aeaeae" />
            <path d="M143.945 22.558l-.045-.006.502.032.063.006z" fill="#7b7b7b" />
            <path d="M143.945 20.933l.042-.042.537.042-.059.04z" fill="#a4a4a4" />
            <path d="M144.069 22.963l.006-.09.573.034-.009.09z" fill="#fff" />
            <path d="M144.051 23.048l.018-.085.57.033-.025.084z" fill="#fbfbfb" />
            <path d="M143.9 23.238l.045-.017.52.03-.063.015z" fill="#7b7b7b" />
            <path d="M143.987 23.732l-.042-.03.52.027.06.03z" fill="#a4a4a4" />
            <path d="M144.024 24.269l.027-.074.563.026-.039.073z" fill="#e6e6e6" />
            <path d="M144.069 22.785l-.018-.081.564.034.024.08zm0 1.147l-.018-.081.564.028.024.08z" fill="#fbfbfb" />
            <path d="M143.945 23.702l-.045-.006.502.025.063.008z" fill="#7b7b7b" />
            <path d="M143.987 24.327l.037-.058.551.025-.05.057z" fill="#c8c8c8" />
            <path d="M144.051 22.704l-.027-.068.552.034.039.068z" fill="#e6e6e6" />
            <path d="M144.075 22.873l-.006-.088.57.034.009.088z" fill="#fff" />
            <path d="M144.024 23.123l.027-.075.563.032-.039.074zm.027.727l-.027-.067.552.027.039.069z" fill="#e6e6e6" />
            <path d="M144.075 24.02l-.006-.088.57.028.009.088z" fill="#fff" />
            <path d="M143.945 23.221l.042-.04.537.031-.059.038z" fill="#a4a4a4" />
            <path d="M144.069 24.11l.006-.09.573.028-.009.089z" fill="#fff" />
            <path d="M143.9 23.696v-.458l.502.028v.455z" fill="#b9b9b9" />
            <path d="M143.987 23.182l.037-.06.551.032-.05.058z" fill="#c8c8c8" />
            <path d="M144.051 24.195l.018-.085.57.027-.025.084z" fill="#fbfbfb" />
            <path d="M143.945 24.365l.042-.038.537.024-.059.038z" fill="#a4a4a4" />
            <path d="M144.024 23.783l-.037-.051.538.027.05.051z" fill="#c9c9c9" />
            <path d="M143.9 24.382l.045-.017.52.024-.063.015z" fill="#7b7b7b" />
            <path d="M143.16 19.308l.927.973-.142-.012-.045-.003-.217-.01-.662-.962.057.006.043.004.013.001z"
                fill="#909090" />
            <path d="M143.9 20.266l.045.003-.261-.011v-.002zm0 4.976v-.86l.502.022v.857z" fill="#aeaeae" />
            <path d="M144.465 22.59l-.063-.006v-.455l.377.043v.452z" fill="#959595" />
            <path d="M144.576 22.67l-.051-.05.403.043.062.051z" fill="#b2b2b2" />
            <path d="M144.615 22.738l-.04-.068.415.044.047.069z" fill="#c8c8c8" />
            <path d="M144.614 21.94l.025-.085.428.051-.03.084z" fill="#d8d8d8" />
            <path d="M144.402 22.129l.063-.017.391.045-.077.015z" fill="#767676" />
            <path d="M144.615 21.598l-.04-.068.415.052.047.068z" fill="#c8c8c8" />
            <path d="M144.64 22.819l-.025-.08.422.044.03.08z" fill="#d8d8d8" />
            <path d="M144.648 22.907l-.009-.088.428.044.01.088z" fill="#e1e1e1" />
            <path d="M144.64 22.996l.008-.09.429.045-.01.088z" fill="#e1e1e1" />
            <path d="M144.64 21.678l-.025-.08.422.052.03.08z" fill="#d8d8d8" />
            <path d="M144.465 22.112l.06-.04.403.047-.072.038z" fill="#959595" />
            <path d="M144.614 23.08l.025-.084.428.043-.03.083z" fill="#d8d8d8" />
            <path d="M144.648 21.766l-.009-.088.428.053.01.087z" fill="#e1e1e1" />
            <path d="M144.525 22.62l-.06-.03.314.034.077.008.072.031z" fill="#959595" />
            <path d="M144.64 21.855l.008-.09.429.053-.01.088z" fill="#e1e1e1" />
            <path d="M144.575 23.154l.04-.074.422.042-.047.073z" fill="#c8c8c8" />
            <path d="M144.402 21.446l.063.006-.042-.003z" fill="#959595" />
            <path d="M144.575 22.014l.04-.074.422.05-.047.073z" fill="#c8c8c8" />
            <path d="M144.524 22.073l.051-.059.415.049-.062.056z" fill="#b1b1b1" />
            <path d="M144.465 23.25l.06-.038.403.039-.072.037z" fill="#959595" />
            <path d="M144.402 23.266l.063-.016.391.038-.077.014zm.063.463l-.063-.008.377.034.026.004z" fill="#767676" />
            <path d="M144.614 24.221l.025-.084.428.035-.03.083z" fill="#d8d8d8" />
            <path d="M144.524 23.212l.051-.058.415.04-.062.057z" fill="#b1b1b1" />
            <path d="M144.64 24.137l.008-.09.429.036-.01.089z" fill="#e1e1e1" />
            <path d="M144.575 24.294l.04-.073.422.034-.047.072z" fill="#c8c8c8" />
            <path d="M144.525 23.76l-.06-.031.34.03.051.004.072.032z" fill="#959595" />
            <path d="M144.64 23.96l-.025-.081.422.036.03.08z" fill="#d8d8d8" />
            <path d="M144.465 24.389l.06-.038.403.032-.072.036z" fill="#959595" />
            <path d="M144.576 23.81l-.051-.05.403.035.062.051z" fill="#b2b2b2" />
            <path d="M144.402 24.404l.063-.015.391.03-.077.014z" fill="#767676" />
            <path d="M144.402 25.26v-.856l.377.029v.85z" fill="#959595" />
            <path d="M144.615 23.879l-.04-.069.415.036.047.069z" fill="#c8c8c8" />
            <path d="M144.402 23.721v-.455l.377.036v.453z" fill="#9e9e9e" />
            <path d="M144.524 24.351l.051-.057.415.033-.062.056z" fill="#b1b1b1" />
            <path d="M144.648 24.048l-.009-.088.428.036.01.087z" fill="#e1e1e1" />
            <path d="M145.077 21.818l-.01-.087.257.06.011.087z" fill="#b3b3b3" />
            <path d="M144.856 23.288l.072-.037.243.045-.078.035z" fill="#848484" />
            <path d="M145.077 22.95l-.01-.087.257.05.011.088z" fill="#b3b3b3" />
            <path d="M144.99 22.714l-.062-.051.244.05.068.052z" fill="#959595" />
            <path d="M145.067 21.906l.01-.088.258.06-.01.087z" fill="#b3b3b3" />
            <path d="M145.037 21.99l.03-.084.257.06-.033.081z" fill="#aeaeae" />
            <path d="M144.928 22.12l.062-.057.25.055-.069.055z" fill="#959595" />
            <path d="M144.856 22.157l.072-.038.243.054-.078.036z" fill="#848484" />
            <path d="M144.78 22.172l.076-.015.237.052-.085.013z" fill="#707070" />
            <path d="M145.067 23.04l.01-.09.258.05-.01.088z" fill="#b3b3b3" />
            <path d="M145.037 23.122l.03-.083.257.05-.033.081z" fill="#aeaeae" />
            <path d="M144.928 22.663l-.072-.03.152.03.067.014.018.003.079.032z" fill="#848484" />
            <path d="M145.037 22.783l-.047-.069.25.05.052.07zm-.047.412l.047-.073.254.048-.052.071z" fill="#a4a4a4" />
            <path d="M144.78 22.624l-.001-.452.229.05v.441l-.152-.03z" fill="#777" />
            <path d="M144.78 23.302l.076-.014.237.043-.085.013z" fill="#707070" />
            <path d="M144.928 23.251l.062-.056.25.046-.069.055z" fill="#959595" />
            <path d="M144.99 22.063l.047-.073.254.057-.052.071z" fill="#a4a4a4" />
            <path d="M144.78 23.755l-.001-.453.229.042v.445l-.152-.026z" fill="#7e7e7e" />
            <path d="M145.067 22.863l-.03-.08.255.05.032.08z" fill="#aeaeae" />
            <path d="M144.78 23.755l.076.008-.051-.004z" fill="#7e7e7e" />
            <path d="M145.067 23.996l-.03-.081.255.041.032.081zm-.03.259l.03-.083.257.04-.033.081z" fill="#aeaeae" />
            <path d="M144.856 24.419l.072-.036.243.035-.078.035z" fill="#848484" />
            <path d="M144.78 25.284v-.851l.228.033v.845z" fill="#777" />
            <path d="M144.78 24.433l.076-.014.237.034-.085.013z" fill="#707070" />
            <path d="M144.99 23.846l-.062-.051.244.04.068.052z" fill="#959595" />
            <path d="M144.928 23.795l-.072-.032.152.026.067.01.018.004.079.032z" fill="#848484" />
            <path d="M144.99 24.327l.047-.072.254.038-.052.07z" fill="#a4a4a4" />
            <path d="M145.067 24.172l.01-.089.258.041-.01.088z" fill="#b3b3b3" />
            <path d="M145.037 23.915l-.047-.069.25.041.052.07z" fill="#a4a4a4" />
            <path d="M144.928 24.383l.062-.056.25.037-.069.054z" fill="#959595" />
            <path d="M145.077 24.083l-.01-.087.257.041.011.087z" fill="#b3b3b3" />
            <path d="M140.302 23.412l-.055-.023-.031-.013.086.012z" fill="#696969" />
            <path d="M140.013 20.66l.053-.069.063-.072-.053.068z" fill="#7b7b7b" />
            <path d="M139.98 20.741l.033-.08.063-.074-.032.08z" fill="#7e7e7e" />
            <path d="M139.968 20.827l.011-.086.065-.073-.012.086z" fill="gray" />
            <path d="M139.98 20.914l-.012-.087.064-.073.012.088z" fill="gray" />
            <path d="M140.013 20.994l-.034-.08.065-.072.033.082z" fill="#7e7e7e" />
            <path d="M140.066 21.064l-.053-.07.064-.07.052.071z" fill="#7b7b7b" />
            <path d="M140.135 21.117l-.07-.053.064-.069.068.056z" fill="#767676" />
            <path d="M140.247 21.182l-.112-.065.08.033.087.05v.004z" fill="#707070" />
            <path d="M140.302 21.2l-.086-.05.086.011v.04z" fill="#696969" />
            <path d="M140.216 21.15l-.08-.033.061-.066.08.035z" fill="#707070" />
            <path d="M140.302 21.161l-.086-.011.06-.064.085.014z" fill="#696969" />
            <path d="M139.98 22.967l.033-.08.063-.054-.032.08z" fill="#7e7e7e" />
            <path d="M139.98 22.027l-.012-.086.064-.063.012.087z" fill="gray" />
            <path d="M140.013 22.887l.053-.07.063-.052-.053.068z" fill="#7b7b7b" />
            <path d="M139.98 21.854l.033-.08.063-.064-.032.08zm.033.254l-.034-.08.065-.063.033.082z" fill="#7e7e7e" />
            <path d="M139.968 23.054l.011-.087.065-.053-.012.087zm.012.086l-.012-.086.064-.053.012.087z" fill="gray" />
            <path d="M140.135 22.23l-.07-.053.064-.059.068.055z" fill="#767676" />
            <path d="M140.066 22.177l-.053-.07.064-.06.052.071zm-.053-.403l.053-.07.063-.062-.053.068z"
                fill="#7b7b7b" />
            <path d="M140.247 22.285l-.112-.055.08.033.087.043v.002z" fill="#707070" />
            <path d="M140.302 22.306l-.086-.043.086.011z" fill="#696969" />
            <path d="M140.302 22.274l-.086-.01.06-.055.085.013z" fill="#696969" />
            <path d="M140.216 22.263l-.08-.033.061-.057.08.036z" fill="#707070" />
            <path d="M139.968 21.94l.011-.086.065-.063-.012.087z" fill="gray" />
            <path d="M140.216 23.376l-.08-.033.061-.047.08.035z" fill="#707070" />
            <path d="M140.066 23.29l-.053-.07.064-.05.052.071z" fill="#7b7b7b" />
            <path d="M140.135 23.343l-.07-.053.064-.049.068.055z" fill="#767676" />
            <path d="M140.013 23.22l-.034-.08.065-.052.033.082z" fill="#7e7e7e" />
            <path d="M140.302 24.518l-.086-.028.086.01z" fill="#696969" />
            <path d="M140.302 21.161l.06-.061-.001.437-.06.015.001-.348z" fill="#555" />
            <path d="M145.405 25.653l.09.32-.076-.027-.087-.323z" fill="#787346" />
            <path d="M145.221 25.444l.072.06-.071-.03-.07-.06z" fill="#706b41" />
            <path d="M145.141 25.396l.08.048-.07-.03-.078-.047z" fill="#6c683f" />
            <path d="M145.717 26.6l.266.611-.083-.018-.262-.616z" fill="#777245" />
            <path d="M145.495 25.973l.222.628-.079-.024-.219-.631z" fill="#777245" />
            <path d="M145.355 25.574l.05.079-.073-.03-.05-.079z" fill="#757044" />
            <path d="M145.983 27.211l.307.591-.088-.013-.302-.596z" fill="#767144" />
            <path d="M146.29 27.802l.347.569-.092-.008-.343-.574z" fill="#757044" />
            <path d="M146.637 28.37l.385.544h-.095l-.382-.55z" fill="#746f43" />
            <path d="M145.293 25.504l.062.07-.073-.03-.06-.07z" fill="#726e43" />
            <path d="M149.57 32.839l.243 1.844-.09.137-.246-1.89z" fill="#787346" />
            <path d="M149.57 36.527l-.711 1.718-.098.192.716-1.735z" fill="#777245" />
            <path d="M147.022 28.914l.422.515-.097.007-.42-.523z" fill="#736e43" />
            <path d="M147.726 29.645l1.133 1.476-.098.045-1.133-1.509z" fill="#736f43" />
            <path d="M148.859 31.12l.711 1.719-.093.09-.716-1.763z" fill="#777245" />
            <path d="M147.444 29.429l.282.216-.098.012-.281-.22z" fill="#6f6a40" />
            <path d="M149.813 34.683l-.243 1.844-.093.175.246-1.882z" fill="#787346" />
            <path d="M137.65 29.645l.283-.216.081.007-.282.221z" fill="#6f6a40" />
            <path d="M140.156 25.444l.08-.048.06-.029-.079.048z" fill="#6c683f" />
            <path d="M136.519 31.12l1.132-1.475.081.012-1.137 1.509z" fill="#736f43" />
            <path d="M140.022 25.574l.062-.07.063-.03-.061.07z" fill="#726e43" />
            <path d="M135.807 32.839l.712-1.718.076.045-.72 1.763z" fill="#777245" />
            <path d="M140.084 25.504l.072-.06.061-.03-.07.06z" fill="#706b41" />
            <path d="M139.087 27.802l.307-.59.072-.02-.304.597z" fill="#767144" />
            <path d="M139.66 26.6l.223-.627.065-.027-.22.631z" fill="#777245" />
            <path d="M138.74 28.37l.347-.568.075-.013-.344.574z" fill="#757044" />
            <path d="M137.933 29.429l.422-.515h.08l-.42.522z" fill="#736e43" />
            <path d="M139.394 27.211l.266-.61.069-.024-.263.616z" fill="#777245" />
            <path d="M139.972 25.653l.05-.08.064-.029-.05.08z" fill="#757044" />
            <path d="M139.883 25.973l.089-.32.064-.03-.088.323z" fill="#787346" />
            <path d="M138.355 28.914l.385-.543.078-.008-.383.55z" fill="#746f43" />
            <path d="M139.729 26.577l.22-.631.256-.026-.2.635z" fill="#a59e60" />
            <path d="M140.381 25.333l.09-.02.211-.026-.081.02z" fill="#706c41" />
            <path d="M140.217 25.415l.08-.048.226-.027-.072.047z" fill="#86804e" />
            <path d="M135.807 36.527l-.243-1.844.066.137.246 1.882z" fill="#787346" />
            <path d="M135.564 34.683l.243-1.844.069.09-.246 1.891z" fill="#787346" />
            <path d="M139.466 27.193l.263-.616.275-.022-.24.62z" fill="#a39c5f" />
            <path d="M138.818 28.363l.344-.574.323-.013-.317.58z" fill="#9e985c" />
            <path d="M140.086 25.544l.06-.07.24-.028-.055.07z" fill="#979158" />
            <path d="M139.162 27.789l.304-.596.298-.018-.279.6z" fill="#a19a5e" />
            <path d="M138.435 28.913l.383-.55.35-.007-.353.557z" fill="#9c955b" />
            <path d="M140.147 25.474l.07-.06.234-.027-.065.06z" fill="#8f8953" />
            <path d="M140.036 25.623l.05-.079.245-.028-.045.08z" fill="#9e975c" />
            <path d="M139.948 25.946l.088-.323.25-.028-.08.325z" fill="#a69f61" />
            <path d="M136.595 38.437l-.72-1.735.559.171.673 1.75z" fill="#a39d5f" />
            <path d="M140.33 25.516l.056-.07.405-.024-.047.07z" fill="#b8b06b" />
            <path d="M140.286 25.595l.045-.079.413-.024-.037.079z" fill="#c2ba71" />
            <path d="M140.205 25.92l.08-.325.422-.024-.067.326z" fill="#d0c779" />
            <path d="M136.595 31.166l1.137-1.509.433.011-1.058 1.542z" fill="#9a945a" />
            <path d="M135.876 36.702l-.246-1.882.573.135.23 1.918z" fill="#a8a162" />
            <path d="M135.876 32.93l.72-1.764.51.044-.672 1.807z" fill="#a39d5f" />
            <path d="M139.2 40.994l-1.468-1.092.433.174 1.355 1.052z" fill="#8c8752" />
            <path d="M135.63 34.82l.246-1.89.558.087-.231 1.938z" fill="#a8a162" />
            <path d="M138.014 29.436l.42-.523h.381l-.389.53z" fill="#999259" />
            <path d="M140.004 26.555l.201-.635.435-.023-.167.639z" fill="#cec578" />
            <path d="M140.386 25.446l.065-.06.393-.023-.053.059z" fill="#aba463" />
            <path d="M140.6 25.306l.082-.02.354-.022-.068.02z" fill="#7b7547" />
            <path d="M137.732 39.902l-1.137-1.465.512.187 1.058 1.452z" fill="#9a945a" />
            <path d="M140.523 25.34l-.227.027.044-.018.041-.016.22-.027z" fill="#7b7648" />
            <path d="M140.523 25.34l.078-.034.367-.023-.064.033z" fill="#8c8651" />
            <path d="M140.451 25.387l.072-.047.38-.024-.059.047z" fill="#9c955b" />
            <path d="M137.732 29.657l.282-.22.412.007-.261.224z" fill="#8c8752" />
            <path d="M140.844 25.363l.06-.047.51-.018-.043.046z" fill="#aea765" />
            <path d="M138.815 28.913l.353-.557.608-.006-.296.563z" fill="#bfb76f" />
            <path d="M138.426 29.444l.39-.531h.664l-.327.537z" fill="#bab26c" />
            <path d="M139.485 27.776l.279-.601.509-.016-.233.606z" fill="#c7bf74" />
            <path d="M136.203 34.955l.23-1.938 1.036.078-.197 1.98z" fill="#d3cb7b" />
            <path d="M136.434 36.873l-.231-1.918 1.07.12.196 1.95z" fill="#d3cb7b" />
            <path d="M137.107 38.624l-.673-1.751 1.035.153.571 1.764z" fill="#ccc377" />
            <path d="M138.165 40.076l-1.058-1.452.933.166.894 1.44z" fill="#bdb56e" />
            <path d="M139.764 27.175l.24-.62.469-.02-.2.624z" fill="#cbc276" />
            <path d="M136.434 33.017l.673-1.807.933.039-.571 1.846z" fill="#ccc377" />
            <path d="M138.165 29.668l.261-.224.727.006-.22.229z" fill="#a7a061" />
            <path d="M141.067 41.714l-1.547-.586.55.118 1.284.53z" fill="#8d8752" />
            <path d="M139.52 41.128l-1.355-1.052.769.155 1.136 1.015z" fill="#a7a061" />
            <path d="M140.968 25.283l.068-.019.473-.017-.049.018z" fill="#837d4c" />
            <path d="M137.107 31.21l1.058-1.542.769.01-.894 1.57z" fill="#bdb56e" />
            <path d="M139.168 28.356l.317-.58.555-.011-.264.585z" fill="#c3bb72" />
            <path d="M140.904 25.316l.064-.033.492-.018-.046.033z" fill="#999359" />
            <path d="M141.354 41.777l-1.284-.531.744.092.923.488z" fill="#9a945a" />
            <path d="M140.64 25.897l.067-.326.566-.02-.048.328z" fill="#f2e88d" />
            <path d="M139.153 29.45l.327-.537h.908l-.236.543z" fill="#d6cd7c" />
            <path d="M139.776 28.35l.264-.585.753-.01-.191.59z" fill="#e2d884" />
            <path d="M140.79 25.422l.054-.06.527-.018-.038.059z" fill="#c1b971" />
            <path d="M140.744 25.492l.047-.07.542-.02-.033.07z" fill="#d2c97a" />
            <path d="M140.473 26.536l.167-.64.585-.017-.12.642z" fill="#efe58b" />
            <path d="M139.48 28.913l.296-.563.826-.005-.214.567z" fill="#dcd380" />
            <path d="M138.934 29.679l.22-.229.998.006-.16.23z" fill="#bdb56e" />
            <path d="M140.273 27.159l.2-.623.632-.015-.145.626z" fill="#ebe289" />
            <path d="M140.04 27.765l.233-.606.687-.012-.167.609z" fill="#e7dd86" />
            <path d="M140.707 25.57l.037-.078.556-.02-.027.08z" fill="#e0d783" />
            <path d="M138.04 31.249l.894-1.57 1.058.008-.65 1.592z" fill="#d9d07e" />
            <path d="M137.469 37.026l-.197-1.951 1.509.095.144 1.977z" fill="#f7ec90" />
            <path d="M137.469 33.095l.571-1.846 1.302.03-.417 1.879z" fill="#ece38a" />
            <path d="M140.07 41.246l-1.136-1.015 1.058.12.822.987z" fill="#bdb56e" />
            <path d="M138.04 38.79l-.571-1.764 1.456.121.417 1.774z" fill="#ece38a" />
            <path d="M137.272 35.075l.197-1.98 1.456.063-.144 2.012z" fill="#f7ec90" />
            <path d="M138.934 40.23l-.894-1.44 1.302.131.65 1.43z" fill="#d9d07e" />
            <path d="M141.105 26.52l.12-.64.693-.012-.063.643z" fill="#fffc99" />
            <path d="M141.371 25.344l.043-.046.604-.012-.022.046z" fill="#bbb36d" />
            <path d="M138.925 33.158l.417-1.879 1.577.02-.223 1.899z" fill="#fff997" />
            <path d="M141.3 25.472l.033-.07.642-.011-.017.07z" fill="#e5dc85" />
            <path d="M141.273 25.552l.027-.08.658-.012-.014.08z" fill="#f5eb8f" />
            <path d="M138.78 35.17l.145-2.012 1.771.04-.077 2.034z" fill="#ffff9e" />
            <path d="M140.152 29.456l.236-.544h1.087l-.125.547z" fill="#e9df88" />
            <path d="M141.333 25.403l.038-.059.625-.012-.02.059z" fill="#d2c97a" />
            <path d="M140.793 27.756l.167-.61.819-.007-.09.611z" fill="#fdf393" />
            <path d="M139.992 29.687l.16-.231 1.198.003-.085.233z" fill="#ccc477" />
            <path d="M138.925 37.147l-.144-1.977 1.838.062.077 1.993z" fill="#ffff9e" />
            <path d="M139.342 38.921l-.417-1.774 1.771.078.223 1.78z" fill="#fff997" />
            <path d="M140.388 28.912l.214-.567.987-.003-.114.57z" fill="#f1e78c" />
            <path d="M141.46 25.265l.049-.018.56-.01-.026.017z" fill="#89834f" />
            <path d="M140.602 28.345l.19-.59.898-.005-.101.592z" fill="#f7ed90" />
            <path d="M139.992 40.352l-.65-1.43 1.577.084.346 1.424z" fill="#ede38a" />
            <path d="M140.96 27.147l.145-.626.75-.01-.076.628z" fill="#fff897" />
            <path d="M141.225 25.88l.048-.328.67-.012-.025.328z" fill="#ffff9b" />
            <path d="M139.342 31.28l.65-1.593 1.273.005-.346 1.607z" fill="#ede38a" />
            <path d="M141.414 25.298l.046-.033.583-.011-.025.032z" fill="#a39c5f" />
            <path d="M140.92 31.3l.345-1.608 1.389.001-.009 1.613z" fill="#f7ed90" />
            <path d="M141.975 25.39l.02-.058.677-.004-.001.059z" fill="#dad17f" />
            <path d="M141.589 28.342l.1-.592.975-.002-.002.593z" fill="#fff896" />
            <path d="M141.855 26.51l.063-.642.752-.004-.002.644z" fill="#ffffa0" />
            <path d="M141.958 25.46l.017-.07.696-.003v.07z" fill="#efe58b" />
            <path d="M140.814 41.338l-.822-.986 1.273.078.436.966z" fill="#ccc477" />
            <path d="M141.779 27.139l.076-.628.813-.003-.002.628z" fill="#ffff9d" />
            <path d="M140.361 22.222v.44l-.06.013.001-.367v-.034l.06-.052z" fill="#555" />
            <path d="M141.996 25.332l.022-.046.654-.004v.046z" fill="#c2ba71" />
            <path d="M142.043 25.254l.025-.018.605-.004v.018z" fill="#8c8651" />
            <path d="M142.018 25.286l.025-.032.63-.004v.032z" fill="#a8a162" />
            <path d="M141.918 25.868l.026-.328.726-.004v.328z" fill="#ffffa3" />
            <path d="M141.475 28.912l.114-.57 1.073-.001-.003.571z" fill="#fbf192" />
            <path d="M141.737 41.826l-.923-.488.887.058.487.46z" fill="#a49d5f" />
            <path d="M141.944 25.54l.014-.08.713-.004v.08z" fill="#fff695" />
            <path d="M143.619 41.826l-.454.03.464-.46.892-.058z" fill="#a49d5f" />
            <path d="M141.69 27.75l.089-.611.887-.003-.002.612z" fill="#fffe9a" />
            <path d="M141.35 29.459l.125-.547h1.184l-.003.548h-1.306z" fill="#f3e98e" />
            <path d="M141.265 29.692l.085-.233 1.306.001-.002.233z" fill="#d4cb7b" />
            <path d="M142.67 25.864v-.328l.728.004.024.328z" fill="#ffffa3" />
            <path d="M142.666 27.136l.002-.628.815.003.072.628z" fill="#ffff9d" />
            <path d="M141.265 40.43l-.346-1.424 1.726.029.009 1.421z" fill="#f7ed90" />
            <path d="M142.67 25.456l.001-.07.697.005.017.07z" fill="#efe58b" />
            <path d="M140.696 37.225l-.077-1.993 2.019.021.002 2z" fill="#ffffa6" />
            <path d="M140.696 33.198l.223-1.899 1.726.007-.005 1.906z" fill="#ffff9e" />
            <path d="M142.668 26.508l.002-.644.752.004.06.643z" fill="#ffffa0" />
            <path d="M142.664 27.748l.002-.612.89.003.084.611z" fill="#fffe9a" />
            <path d="M140.92 39.006l-.224-1.78 1.944.026.005 1.783z" fill="#ffff9e" />
            <path d="M142.188 41.856l-.487-.46.963.02.012.45z" fill="#a9a262" />
            <path d="M141.701 41.396l-.436-.966 1.389.026.01.96z" fill="#d4cb7b" />
            <path d="M140.62 35.232l.076-2.034 1.944.014-.002 2.041z" fill="#ffffa6" />
            <path d="M142.673 25.25v-.018l.607.004.024.018z" fill="#8c8651" />
            <path d="M142.672 25.282v-.032l.632.004.023.032z" fill="#a8a162" />
            <path d="M142.671 25.387v-.059l.678.004.019.059z" fill="#dad17f" />
            <path d="M142.662 28.34l.002-.592.976.002.096.592z" fill="#fff896" />
            <path d="M142.672 25.328v-.046l.655.004.022.046z" fill="#c2ba71" />
            <path d="M142.67 25.536v-.08l.715.004.013.08z" fill="#fff695" />
            <path d="M142.654 29.693l.002-.233 1.308-.001.08.233z" fill="#d4cb7b" />
            <path d="M142.638 35.253l.002-2.041 1.946-.014.074 2.034z" fill="#ffffa6" />
            <path d="M142.645 31.306l.009-1.613 1.39-.001.33 1.607z" fill="#f7ed90" />
            <path d="M143.327 25.286l-.023-.032.585.011.046.033z" fill="#a39c5f" />
            <path d="M143.385 25.46l-.017-.07.646.013.032.07z" fill="#e5dc85" />
            <path d="M143.398 25.54l-.013-.08.661.012.027.08z" fill="#f5eb8f" />
            <path d="M143.368 25.39l-.02-.058.628.012.038.059z" fill="#d2c97a" />
            <path d="M142.676 41.867l-.012-.45.965-.02-.464.46z" fill="#a9a262" />
            <path d="M142.659 28.912l.003-.571h1.074l.108.571z" fill="#fbf192" />
            <path d="M142.654 40.456l-.009-1.421 1.729-.03-.33 1.425z" fill="#f7ed90" />
            <path d="M142.64 37.252l-.002-1.999 2.022-.021-.074 1.993z" fill="#ffffa6" />
            <path d="M142.645 39.035l-.005-1.783 1.946-.027-.212 1.78z" fill="#ffff9e" />
            <path d="M143.422 25.868l-.024-.328.675.012.047.327z" fill="#ffff9b" />
            <path d="M142.664 41.416l-.01-.96 1.39-.026-.415.966z" fill="#d4cb7b" />
            <path d="M143.304 25.254l-.024-.018.562.011.047.018z" fill="#89834f" />
            <path d="M143.349 25.332l-.022-.046.608.012.041.046z" fill="#bbb36d" />
            <path d="M142.64 33.212l.005-1.906 1.729-.007.212 1.899z" fill="#ffff9e" />
            <path d="M142.656 29.46l.003-.548h1.185l.12.547z" fill="#f3e98e" />
            <path d="M143.89 25.265l-.048-.018.478.017.067.02z" fill="#837d4c" />
            <path d="M144.044 40.43l.33-1.424 1.586-.085-.635 1.43z" fill="#ede38a" />
            <path d="M143.63 41.396l.414-.966 1.28-.078-.803.986z" fill="#ccc477" />
            <path d="M143.844 28.912l-.108-.57.993.003.209.567z" fill="#f1e78c" />
            <path d="M143.736 28.342l-.096-.592.902.006.187.589z" fill="#f7ed90" />
            <path d="M144.044 29.692l-.08-.233 1.205-.003.156.23z" fill="#ccc477" />
            <path d="M144.586 37.225l.074-1.993 1.85-.062-.141 1.977z" fill="#ffff9e" />
            <path d="M144.586 33.198l-.212-1.899 1.586-.02.409 1.879z" fill="#fff997" />
            <path d="M143.935 25.298l-.046-.033.498.018.063.033z" fill="#999359" />
            <path d="M144.374 31.3l-.33-1.608 1.28-.005.636 1.592z" fill="#ede38a" />
            <path d="M144.66 35.232l-.074-2.034 1.783-.04.14 2.012z" fill="#ffff9e" />
            <path d="M143.555 27.139l-.072-.628.754.01.141.626z" fill="#fff897" />
            <path d="M143.483 26.51l-.06-.642.697.011.117.642z" fill="#fffc99" />
            <path d="M144.374 39.006l.212-1.78 1.783-.079-.409 1.774z" fill="#fff997" />
            <path d="M143.964 29.459l-.12-.547h1.094l.231.544z" fill="#e9df88" />
            <path d="M143.64 27.75l-.085-.611.823.008.164.609z" fill="#fdf393" />
            <path d="M145.325 40.352l.635-1.43 1.317-.132-.882 1.44z" fill="#d9d07e" />
            <path d="M144.014 25.403l-.038-.059.533.019.053.059z" fill="#c1b971" />
            <path d="M144.073 25.552l-.027-.08.562.02.037.079z" fill="#e0d783" />
            <path d="M144.729 28.345l-.187-.59.76.01.262.585z" fill="#e2d884" />
            <path d="M144.521 41.338l.804-.986 1.07-.121-1.121 1.015zm.804-11.651l-.156-.231 1.01-.006.216.229z"
                fill="#bdb56e" />
            <path d="M144.938 28.912l-.21-.567.836.005.292.563z" fill="#dcd380" />
            <path d="M145.169 29.456l-.231-.544h.918l.322.538z" fill="#d6cd7c" />
            <path d="M144.046 25.472l-.032-.07.548.02.046.07z" fill="#d2c97a" />
            <path d="M143.619 41.826l.902-.488.753-.092-1.268.53z" fill="#9a945a" />
            <path d="M146.51 35.17l-.141-2.012 1.472-.063.194 1.98z" fill="#f7ec90" />
            <path d="M144.378 27.147l-.14-.626.638.015.197.623z" fill="#ebe289" />
            <path d="M146.369 37.147l.14-1.977 1.526-.095-.194 1.95z" fill="#f7ec90" />
            <path d="M144.542 27.756l-.164-.61.695.013.23.606z" fill="#e7dd86" />
            <path d="M145.96 38.921l.409-1.774 1.472-.121-.564 1.764z" fill="#ece38a" />
            <path d="M143.976 25.344l-.041-.046.515.018.06.047z" fill="#aea765" />
            <path d="M146.369 33.158l-.409-1.879 1.317-.03.564 1.846z" fill="#ece38a" />
            <path d="M144.12 25.88l-.047-.328.572.019.066.326z" fill="#f2e88d" />
            <path d="M145.96 31.28l-.635-1.593 1.07-.008.882 1.57z" fill="#d9d07e" />
            <path d="M144.237 26.52l-.117-.64.59.017.166.639z" fill="#efe58b" />
            <path d="M147.277 38.79l.564-1.764 1.056-.153-.669 1.75z" fill="#ccc377" />
            <path d="M146.395 40.23l.882-1.44.951-.166-1.05 1.452z" fill="#bdb56e" />
            <path d="M144.645 25.57l-.037-.078.42.024.045.08z" fill="#c2ba71" />
            <path d="M145.073 27.159l-.197-.623.477.02.238.619z" fill="#cbc276" />
            <path d="M148.035 35.075l-.194-1.98 1.056-.078.23 1.938z" fill="#d3cb7b" />
            <path d="M147.277 31.249l-.882-1.57.783-.01 1.05 1.54z" fill="#bdb56e" />
            <path d="M146.178 29.45l-.322-.537h.677l.386.53z" fill="#bab26c" />
            <path d="M144.45 25.316l-.063-.033.374.023.077.034z" fill="#8c8651" />
            <path d="M144.51 25.363l-.06-.047.388.024.071.047z" fill="#9c955b" />
            <path d="M144.71 25.897l-.065-.326.428.024.08.325z" fill="#d0c779" />
            <path d="M144.876 26.536l-.165-.64.442.024.2.635z" fill="#cec578" />
            <path d="M145.303 27.765l-.23-.606.518.016.277.6z" fill="#c7bf74" />
            <path d="M144.387 25.283l-.067-.019.36.023.081.02z" fill="#7b7547" />
            <path d="M144.608 25.492l-.046-.07.411.024.056.07z" fill="#b8b06b" />
            <path d="M145.564 28.35l-.261-.585.565.01.314.581z" fill="#c3bb72" />
            <path d="M145.856 28.913l-.292-.563.618.006.351.557z" fill="#bfb76f" />
            <path d="M144.562 25.422l-.053-.06.4.025.064.06z" fill="#aba463" />
            <path d="M146.395 29.679l-.217-.229.741-.006.26.224z" fill="#a7a061" />
            <path d="M147.84 33.095l-.563-1.846.951-.04.669 1.808z" fill="#ccc377" />
            <path d="M147.84 37.026l.195-1.951 1.091-.12-.23 1.918z" fill="#d3cb7b" />
            <path d="M148.228 31.21l-1.05-1.542.45-.011 1.133 1.509z" fill="#9a945a" />
            <path d="M148.897 33.017l-.669-1.807.533-.044.716 1.763z" fill="#a39d5f" />
            <path d="M144.838 25.34l-.077-.034.227.027.046.018.039.016z" fill="#7b7648" />
            <path d="M145.029 25.516l-.056-.07.249.028.06.07z" fill="#979158" />
            <path d="M145.353 26.555l-.2-.635.266.026.22.631z" fill="#a59e60" />
            <path d="M145.073 25.595l-.044-.079.253.028.05.08z" fill="#9e975c" />
            <path d="M145.591 27.175l-.238-.62.285.022.262.616z" fill="#a39c5f" />
            <path d="M144.91 25.387l-.072-.047.235.027.078.048z" fill="#86804e" />
            <path d="M145.153 25.92l-.08-.325.259.028.087.323z" fill="#a69f61" />
            <path d="M145.274 41.246l1.121-1.015.783-.155-1.345 1.052z" fill="#a7a061" />
            <path d="M145.868 27.776l-.277-.601.309.018.302.596z" fill="#a19a5e" />
            <path d="M144.973 25.446l-.064-.06.242.029.07.06z" fill="#8f8953" />
            <path d="M146.182 28.356l-.314-.58.334.013.343.574z" fill="#9e985c" />
            <path d="M144.761 25.306l-.08-.02.218.026.089.02z" fill="#706c41" />
            <path d="M146.533 28.913l-.35-.557.362.007.382.55z" fill="#9c955b" />
            <path d="M146.92 29.444l-.387-.531h.394l.42.523z" fill="#999259" />
            <path d="M144.006 41.777l1.268-.531.559-.118-1.535.586z" fill="#8d8752" />
            <path d="M147.178 29.668l-.259-.224.428-.008.28.221z" fill="#8c8752" />
            <path d="M148.228 38.624l.669-1.751.58-.17-.716 1.734z" fill="#a39d5f" />
            <path d="M147.178 40.076l1.05-1.452.533-.187-1.133 1.465z" fill="#9a945a" />
            <path d="M148.897 36.873l.23-1.918.596-.135-.246 1.882z" fill="#a8a162" />
            <path d="M145.833 41.128l1.345-1.052.45-.174-1.464 1.092z" fill="#8c8752" />
            <path d="M149.126 34.955l-.23-1.938.581-.088.246 1.891z" fill="#a8a162" />
            <path d="M145.242 21.117l-.103.065-.064.025v-.003l.086-.054z" fill="#707070" />
            <path d="M145.364 21.774l.034.08-.074-.063-.032-.08z" fill="#7e7e7e" />
            <path d="M145.075 21.161l.086-.011-.086.054z" fill="#696969" />
            <path d="M145.398 21.854l.011.087-.074-.063-.01-.087z" fill="gray" />
            <path d="M145.41 21.94l-.012.087-.074-.062.011-.087z" fill="gray" />
            <path d="M145.311 21.704l.053.07-.072-.064-.052-.068z" fill="#7b7b7b" />
            <path d="M145.398 22.027l-.034.08-.073-.06.033-.082z" fill="#7e7e7e" />
            <path d="M145.364 22.108l-.053.069-.072-.059.052-.07zm-.053.71l.053.069-.072-.054-.052-.068z"
                fill="#7b7b7b" />
            <path d="M145.311 22.177l-.069.053-.07-.057.067-.055z" fill="#767676" />
            <path d="M145.242 22.23l-.103.055-.064.026v-.001l.086-.047z" fill="#707070" />
            <path d="M145.161 22.263l-.086.047v-.036l.086-.01z" fill="#696969" />
            <path d="M145.242 22.765l.07.053-.072-.053-.068-.053z" fill="#959595" />
            <path d="M145.364 22.887l.034.08-.074-.053-.032-.08z" fill="#7e7e7e" />
            <path d="M145.41 23.054l-.012.086-.074-.052.011-.087z" fill="gray" />
            <path d="M145.364 23.22l-.053.07-.072-.049.052-.07z" fill="#7b7b7b" />
            <path d="M145.242 22.23l-.08.033-.07-.054.08-.036z" fill="#707070" />
            <path d="M145.311 23.29l-.069.053-.07-.047.067-.055z" fill="#767676" />
            <path d="M145.398 23.14l-.034.08-.073-.05.033-.082z" fill="#7e7e7e" />
            <path d="M145.161 23.376l-.086.04v-.028z" fill="#696969" />
            <path d="M145.161 23.376l-.086.012-.067-.044.085-.013z" fill="#696969" />
            <path d="M145.242 23.343l-.08.033-.07-.045.08-.035z" fill="#707070" />
            <path d="M145.398 22.967l.011.087-.074-.053-.01-.087z" fill="gray" />
            <path d="M145.161 22.263l-.086.011-.067-.052.085-.013z" fill="#696969" />
            <path d="M145.311 23.93l.053.07-.072-.044-.052-.069z" fill="#7b7b7b" />
            <path d="M145.398 24.08l.011.087-.074-.043-.01-.087z" fill="gray" />
            <path d="M145.242 24.456l-.08.034-.07-.037.08-.035z" fill="#707070" />
            <path d="M145.41 24.167l-.012.087-.074-.042.011-.088z" fill="gray" />
            <path d="M145.161 24.49l-.086.03v-.02z" fill="#696969" />
            <path d="M145.398 24.254l-.034.08-.073-.04.033-.082z" fill="#7e7e7e" />
            <path d="M145.161 24.49l-.086.01-.067-.034.085-.013z" fill="#696969" />
            <path d="M145.364 24l.034.08-.074-.043-.032-.08z" fill="#7e7e7e" />
            <path d="M145.364 24.334l-.053.07-.072-.04.052-.07z" fill="#7b7b7b" />
            <path d="M145.311 24.403l-.069.053-.07-.038.067-.054zm-.069-.525l.07.053-.072-.044-.068-.052z"
                fill="#767676" />
            <path d="M145.008 22.663v-.441l.067.052v.403zm.067 2.677l-.067-.03v-.844l.067.035v.02z" fill="#555" />
            <path d="M145.008 23.789v-.445l.067.044v.412z" fill="#5a5a5a" />
            <path d="M142.578 19.285l.106-.002-.009.947h-.002l-.52.008.357-.952h.068z" fill="#a2a2a2" />
            <path d="M142.51 19.286l-.357.952-.116.001-.365.017.676-.962.029-.001.093-.005z" fill="#9c9c9c" />
            <path d="M142.675 20.23l.01-.947h.01l.115.002.049.001.34.952z" fill="#a2a2a2" />
            <path d="M143.2 20.238l-.341-.952.058.003.092.005h.012l.662.962-.374-.017z" fill="#9c9c9c" />
            <path
                d="M142.26 19.127l-.017.014m.062-.027l-.046.013m.118-.024l-.072.01m.165-.019l-.093.009m.201-.014l-.108.005m.224-.007l-.116.002m.232.001l-.116-.003m.223.008l-.107-.006m.199.015l-.092-.009m.161.021l-.07-.012m.112.026l-.042-.014m.056.028l-.013-.015m.146.195l-.106-.016m.173.036l-.067-.02m-1.162 0l-.064.02m.169-.036l-.105.016m.138-.02v-.163m.891.164v-.161m1.383 1.177l-1.183-.977m1.906 1.175l-.068-.052"
                fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M144.78 21.042l.228.058" fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path d="M145.172 20.467l-.08-.031m.08 1.154l-.08-.032m.148.084l-.068-.052m-.316-1.22l.237.066" fill="none"
                stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M140.361 24.466l.221-.033m.371.827l-.37.024m-.001 0l-.22.027" fill="none" stroke="#000"
                stroke-width=".032" stroke-miterlimit="10" />
            <path d="M140.197 23.835l.079-.032m.284-.045l.022-.003" fill="none" stroke="#000" stroke-width=".065"
                stroke-miterlimit="10" />
            <path d="M140.361 23.344l.221-.042" fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path d="M140.504 23.763l.078-.008m.353-2.306l.018-.003" fill="none" stroke="#000" stroke-width=".065"
                stroke-miterlimit="10" />
            <path d="M140.582 21.042l.371-.05" fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path d="M140.888 21.452l.065-.006" fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M140.582 23.302l.371-.036m-.371.489l.37-.034m-.37-1.549l.371-.043" fill="none" stroke="#000"
                stroke-width=".032" stroke-miterlimit="10" />
            <path d="M140.888 22.59l.065-.006" fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M140.582 24.433l.371-.03" fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path d="M141.403 20.27l.046-.004" fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path
                d="M141.45 25.242l-.497.019m0-4.27l.496-.039m-.496.494l.496-.037m-.496.72l.496-.034m-.496.489l.496-.032m-.496 1.169l.496-.025m-.496-.43l.496-.028m.588 1.992l-.588.012m-.496-.838l.496-.022m.001-3.43l.587-.025m-.587 1.625l.587-.02m-.587-1.122l.587-.024m-.587.709l.587-.021m-.587 1.164l.587-.017m-.587.475l.587-.017m-.587.703l.587-.015m.636.86l-.636.004m0-3.845l.636-.008m-.636-.451l.636-.008m-.636 1.155l.636-.007m-.636.466l.636-.007m-.636.694l.636-.005m-.636.465l.636-.006m-.636.693l.636-.004m.637.867l-.637-.003m0-3.849l.636.008m-.636-.467l.636.008m-.636 1.14l.636.007m-.636.452l.636.007m-.636 1.141l.636.005m-.636-.464l.636.006m.591 2.021l-.59-.011m-.637-.868l.636.004"
                fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path d="M143.945 20.27l-.045-.004" fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path
                d="M143.31 21.386l.59.023m-.59-.482l.59.025m-.59 1.581l.59.02m-.59-.479l.59.021m-.59 1.125l.59.018m-.59.442l.59.016m-.59.671l.59.015m0-2.972l.502.036m-.502-.494l.502.04m0 4.268l-.502-.018"
                fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path d="M144.465 22.59l-.063-.006" fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M143.9 22.552l.502.032m-.502-.489l.502.034" fill="none" stroke="#000" stroke-width=".032"
                stroke-miterlimit="10" />
            <path d="M144.465 21.452l-.063-.006" fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M143.9 23.696l.502.025m-.502.661l.502.022m-.502-1.166l.502.028m.378 2.018l-.378-.023" fill="none"
                stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path d="M144.402 21.446l.02.003" fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M144.402 20.991l.377.05m-.377 1.088l.377.043m-.377 1.094l.377.036m-.377.419l.377.034" fill="none"
                stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path d="M144.856 23.763l-.077-.008" fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M144.402 24.404l.377.029m.229.877l-.229-.026" fill="none" stroke="#000" stroke-width=".032"
                stroke-miterlimit="10" />
            <path
                d="M140.302 23.412l-.055-.023m.055 1.951l.038.01m-.327-4.69l.053-.069m-.086.15l.033-.08m-.045.166l.011-.086m.001.173l-.012-.087m.045.167l-.034-.08m.087.15l-.053-.07m.122.123l-.07-.053m.182.118l-.112-.065m.167.087l-.055-.022m-.267 1.785l.033-.08m-.033-.86l-.012-.086m.045.946l.053-.07m-.086-.963l.033-.08m0 .334l-.034-.08m-.011 1.026l.011-.087m.001.173l-.012-.086m.167-.824l-.07-.053m.001 0l-.053-.07m0-.333l.053-.07m.181.581l-.112-.055m.167.078l-.055-.023m-.279-.345l.011-.086m.034 2.146l.053-.07m.15-.554l-.08-.033m-.07-.053l-.053-.07m-.033.86l.033-.08m-.045.167l.011-.086m.001.173l-.012-.087m.045.167l-.034-.08m.156-.911l-.07-.053m.001 1.113l-.053-.069m.122.122l-.07-.053m-.052-1.183l-.034-.08m.323 1.378l-.086-.028m0 0l-.08-.034m-.07-.526l.069-.052"
                fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M140.361 25.31l-.059.03" fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path d="M140.197 20.467l.079-.031" fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M140.302 21.161l.06-.061" fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path
                d="M140.066 20.591l.063-.072m.728-.201l1.184-.974m-1.844 2.246l.079-.032m-.21.146l.063-.062m0 0l.068-.052m-.068-1.071l.068-.052m5.208 5.186l.09.32m-.274-.529l.072.06m-.152-.108l.08.048m.496 1.156l.266.611m-.488-1.238l.222.628m-.362-1.027l.05.079m.578 1.558l.307.591m0 0l.347.569m0-.001l.385.544m-1.729-3.41l.062.07m4.215 7.265l.243 1.844m-.243 1.844l-.711 1.718m-1.837-9.331l.422.515m-7.247-6.717l.079-.032m7.45 6.965l1.133 1.476m0-.001l.711 1.719m-2.126-3.41l.282.216m2.087 5.038l-.243 1.844m-9.441-13.762l.068-.053"
                fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M140.361 21.1l.221-.058" fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path
                d="M137.65 29.645l.283-.216m2.223-3.985l.08-.048m-3.717 5.724l1.132-1.475m2.371-4.071l.062-.07m-4.277 7.335l.712-1.718m3.565-5.617l.072-.06m-1.069 2.358l.307-.59m.266-.612l.223-.627m-1.143 2.397l.347-.568m-1.154 1.627l.422-.515m1.039-1.703l.266-.61m.312-.948l.05-.08m-.139.4l.089-.32m-1.617 3.261l.385-.543m1.641-3.038l.09-.02m-.235.083l.06-.029m-4.489 11.16l-.243-1.844m0 0l.243-1.844m.788 5.598l-.72-1.735m-.068-.175l.069.175m3.324 4.292l-1.468-1.092m0 0l-1.137-1.465"
                fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M140.47 25.312l.212-.025m0 0l.354-.023" fill="none" stroke="#000" stroke-width=".032"
                stroke-miterlimit="10" />
            <path d="M141.067 41.714l-1.547-.586m-.32-.134l.32.134" fill="none" stroke="#000" stroke-width=".065"
                stroke-miterlimit="10" />
            <path d="M140.361 22.222l.221-.05" fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path d="M141.067 41.714l.287.063" fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M141.036 25.264l.473-.017" fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path d="M141.354 41.777l.383.049m1.428.03l.454-.03" fill="none" stroke="#000" stroke-width=".065"
                stroke-miterlimit="10" />
            <path d="M141.509 25.247l.56-.01" fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path d="M141.737 41.826l.451.03" fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M142.068 25.236l.605-.004" fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path d="M142.188 41.856l.488.01" fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M142.673 25.232l.607.004m0 0l.562.011" fill="none" stroke="#000" stroke-width=".032"
                stroke-miterlimit="10" />
            <path d="M142.676 41.867l.489-.01" fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M143.842 25.247l.478.017" fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path d="M143.619 41.826l.387-.05" fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M140.302 22.274l.06-.052m3.958 3.042l.36.023" fill="none" stroke="#000" stroke-width=".032"
                stroke-miterlimit="10" />
            <path d="M144.988 25.333l-.09-.02" fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M144.68 25.287l.219.025" fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path
                d="M144.298 41.714l1.535-.586m-1.827.649l.292-.063m1.866-.72l1.464-1.092m-2.555-14.535l.068.029m2.487 14.506l1.133-1.465m-2.928 2.691l.331-.134m-6.098-18.176l.063-.053m8.631 15.672l.099-.192"
                fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M140.302 23.388l.06-.044" fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path d="M140.135 23.878l.062-.043" fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M140.302 24.5l.06-.034" fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path
                d="M140.276 20.436l.228-.066m4.807.221l.053.07m0-.001l.034.081m0 0l.011.086m-.375 4.524l.041-.012m.167-4.222l-.103.065m.225.592l.034.08m-.323-.647l.064-.025m.259.672l.011.087m.001-.001l-.012.087m.012-1.2l-.012.087m-.087.79l.053.07m.034-.86l-.034.08m.034 1.033l-.034.08m0 .001l-.053.069m.053-1.183l-.053.07m0 0l-.069.053m.069 1.701l.053.069m-.053-.71l-.069.053m0 0l-.103.055m.103.48l.07.053m.052.069l.034.08m.012.087l-.012.086m-.034.08l-.053.07m-.236-.979l.064-.026m.172 1.005l-.069.053m.156-.203l-.034.08m-.289.196l.064-.027m.103-.046l-.103.046m.259-.422l.011.087m-.098.876l.053.07m.034.08l.011.087m-.167.289l-.08.034m.248-.323l-.012.087m-.237.236l-.086.03m.323-.266l-.034.08m0-.334l.034.08m-.034.254l-.053.07m0-.001l-.069.053m0-.578l.07.053"
                fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M144.78 23.302l.228.042m-.228-1.172l.228.05" fill="none" stroke="#000" stroke-width=".032"
                stroke-miterlimit="10" />
            <path d="M145.172 22.712l-.08-.032m-.312 1.075l.025.004" fill="none" stroke="#000" stroke-width=".065"
                stroke-miterlimit="10" />
            <path d="M144.78 24.433l.228.033" fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path d="M145.172 23.835l-.08-.032" fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M145.075 25.34l-.067-.03" fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path d="M145.24 20.519l.071.072m-.071 1.051l.071.062" fill="none" stroke="#000" stroke-width=".065"
                stroke-miterlimit="10" />
            <path d="M145.008 21.1l.067.061" fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path d="M145.172 22.712l.07.053" fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M145.008 22.222l.067.052m-.067 1.07l.067.044" fill="none" stroke="#000" stroke-width=".032"
                stroke-miterlimit="10" />
            <path d="M145.172 23.835l.07.043" fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
            <path d="M145.008 24.466l.067.035" fill="none" stroke="#000" stroke-width=".032" stroke-miterlimit="10" />
            <path
                d="M142.684 19.283l-.106.002m0 0l-.068.001m.349 0h-.049m.107.004l-.058-.004m.219.014l-.057-.006m-.778.01l-.033.004m.167-.015l-.029.001m2.508 2.207l.152.037m-4.504-1.168l.353-.052m0 0l.031-.004m-.612 3.489l.026-.005m.202-2.297l.078-.01m0 0l.306-.039m-.384 1.18l.078-.008m0 0l.306-.034m.385-2.31l.13-.01m.047-.004l.222-.01m0 0l.365-.017m0 .001l.116-.002m0 0l.52-.008m0 0h.002m.635.01l.373.016m0 0l.217.01m.474.04l.091.008m0 1.138l.314.04m.001 0l.076.01m-.391-1.188l.052.007m0 0l.34.05m-.392 2.219l.314.034m.001 0l.076.008m-4.554-.326v-.032m0-1.074v-.039m-.055 2.229l-.031-.014m.086.422v-.386m0 0v-.024m0 1.952v-.822m0 0v-.017m-.026-1.821l.026-.005m-.026-1.117l.026-.006m-.006 3.815l.044-.018m0 .001l.041-.017m4.692.034l-.04-.016m.001 0l-.046-.018m.087-4.172v.043m0 1.07v.036m0 1.078v.027m0 1.085v.02m0 0v.82m-.219-2.708l.152.031m-.152 1.1l.152.026m-2.198-4.504l-.116-.001m0 0h-.01m.337.01h-.012m0 0l-.092-.005m.203.015l-.042-.004m-.73-.006l-.043.005m.205-.013l-.04.002m0 0l-.093.005m2.631 2.245l.067.016m0 0l.018.004m-4.791 2.24l.059-.01m-.001 0l.144-.025m.384-3.449l.104-.01m0 .001l.28-.024m1.403-.051l.524.008m.001 0l.11.001m.635.031l.142.011m0 0l.287.025m-4.072 2.369v-.367m0 0v-.002m0-.754v-.348m0 0V21.2m0 1.475l.059-.012m-.001 0l.144-.03m-.202-1.081l.059-.015m-.001 0l.144-.036m4.571-.297v.003m0 0v.347m0 .756v.001m0 0v.366m0 .738v0m0 .001v.384m-.067-1.137l.067.014m0 0l.018.003m-.085 1.109l.067.01m0 .001l.018.003m-1.933-4.495l-.026-.003m0 0l-.013-.001m-.816-.005l-.046.004m.001 0l-.017.001"
                fill="none" stroke="#000" stroke-width=".065" stroke-miterlimit="10" />
        </g>
    </g>
`;
</script>

<svg width="54.097" height="86.342" viewBox="0 0 14.313 22.845" {...$$restProps} transition:fade>
  {@html raw}
</svg>

<style>
  svg {
    position: absolute;
  }
</style>
